import { acceptHMRUpdate, defineStore } from 'pinia'
import { upperFirst } from 'lodash-es'

import { useUserStore } from './user'

import { HttpError } from '@/helpers/errors'
import DeFiServicesService from '@/services/services/defi'

import { type DeFiService } from '@/types/services/defi'

export interface ServicesGroup {
  ids: string[]
  name: string
  blockchains: string[]
  services_ids: string[]
  enabled_date: string | null
  disabled_date: string | null
}

export interface DeFiServicesState {
  services: {
    data: DeFiService[]
    loading: boolean
  }
  updated_services: DeFiService[]
}

const groupServices = (servicesList: DeFiService[]) => {
  const services: { [key: string]: ServicesGroup } = {}
  servicesList.forEach((service) => {
    const blockchain = upperFirst(service.chain)
    const name = service.name.trim()
    if (name in services) {
      services[name].ids.push(service.id)
      services[name].blockchains.push(blockchain)
      services[name].services_ids.push(service.service_id)
    } else {
      services[name] = {
        ids: [service.id],
        name: service.name,
        blockchains: [blockchain],
        services_ids: [service.service_id],
        enabled_date: service.enabled_date,
        disabled_date: service.disabled_date,
      }
    }
  })

  return Object.values(services)
}

export const useServicesDeFiStore = defineStore('servicesDeFi', {
  state(): DeFiServicesState {
    return {
      services: {
        data: [],
        loading: true,
      },
      updated_services: [],
    }
  },

  actions: {
    async getAll() {
      this.$patch((state) => {
        state.services.loading = true
        state.services.data = []
      })

      try {
        const response = await DeFiServicesService.getAll()
        this.$patch((state) => {
          state.services.data = response.data.data || []
          state.services.loading = false
        })
      } catch (error) {
        this.services.loading = false
        throw new HttpError(error)
      }
    },

    async checkUpdatedServices(user_id: string) {
      this.updated_services = []

      try {
        const response = await DeFiServicesService.getUpdatedServices(user_id)
        this.updated_services = response.data.data || []
      } catch (error) {
        throw new HttpError(error)
      }
    },

    async setLastSeen(datetime: string) {
      const userStore = useUserStore()
      return userStore.saveUserSettings({
        last_seen_new_defi_services: datetime,
      })
    },

    async setAvailability(ids: string[], enable: boolean) {
      try {
        await DeFiServicesService.setAvailability(ids, enable)
        this.getAll()
      } catch (error) {
        throw new HttpError(error)
      }
    },
  },

  getters: {
    groupedServices(state) {
      return groupServices(state.services.data)
    },

    groupedUpdatedServices(state) {
      return groupServices(state.updated_services)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useServicesDeFiStore, import.meta.hot))
}
