<template>
  <label class="input-label">
    <slot />
    {{ label }}
    <span v-if="required && !hideAsterisk" class="input-label-required">
      <SvgIcon icon="Required" :size="5" class="relative -top-1" />
    </span>

    <Help v-if="help">{{ help }}</Help>

    <slot name="append" />
  </label>
</template>

<script lang="ts">
import './Label.scss'

import { defineComponent } from 'vue'

import propsMixin from './propsMixin'

import Help from '@/components/misc/Help'
import SvgIcon from '@/components/misc/SvgIcon'

export default defineComponent({
  components: {
    Help,
    SvgIcon,
  },

  mixins: [propsMixin],
})
</script>
