import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M10.421 5.087c-.264-.264-.396-.396-.445-.548a.67.67 0 0 1 0-.412c.049-.152.181-.284.445-.548l1.892-1.892A4 4 0 0 0 6.78 6.284c.08.325.12.488.112.591a.6.6 0 0 1-.073.261c-.047.092-.138.183-.32.365l-4.166 4.165a1.414 1.414 0 0 0 2 2L8.5 9.501c.182-.182.273-.273.364-.32a.6.6 0 0 1 .262-.074c.103-.007.266.033.592.113q.457.112.95.113a4 4 0 0 0 3.646-5.646l-1.892 1.892c-.264.264-.396.396-.548.445a.67.67 0 0 1-.412 0c-.152-.05-.284-.181-.549-.445z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }