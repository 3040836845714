import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8 1.667v12.667M13.333 8c0 3.273-3.569 5.653-4.868 6.41-.147.086-.221.13-.325.152a.8.8 0 0 1-.28 0c-.104-.023-.178-.066-.325-.152-1.299-.758-4.868-3.137-4.868-6.41V4.812c0-.533 0-.8.087-1.029.077-.202.202-.383.365-.526.183-.162.433-.255.932-.443l3.575-1.34c.138-.052.207-.078.279-.088a.7.7 0 0 1 .19 0c.072.01.141.036.28.088l3.574 1.34c.5.188.749.281.933.443.162.143.287.324.364.526.087.23.087.496.087 1.029z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }