import ApiService from '../api.service'

import type { AxiosResponse } from 'axios'
import type { DeFiServicesResponse } from '@/types/services/defi'
import type { EmptyDataResponse } from '@/types/services/common'

class DeFiServicesService {
  static getAll(): Promise<AxiosResponse<DeFiServicesResponse>> {
    return ApiService.get('/api/v1/defi_services/get_all')
  }

  static getUpdatedServices(
    user_id: string
  ): Promise<AxiosResponse<DeFiServicesResponse>> {
    return ApiService.get(`/api/v1/defi_services/get_all?user_id=${user_id}`)
  }

  static setAvailability(
    ids: string[],
    enable: boolean
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    return ApiService.post('/api/v1/defi_services/set_availability', {
      ids,
      option: enable ? 'enable' : 'disable',
    })
  }
}

export default DeFiServicesService
