<template>
  <div>
    <div
      style="transition: all 0.3s ease"
      class="w-full h-1 flex items-center bg-background rounded overflow-hidden"
    >
      <div
        class="h-1"
        style="transition: all 0.3s ease"
        :style="{
          backgroundColor: passwordStrengthStyle.color,
          width: (passwordStrength / 4) * 100 + '%',
        }"
      />
    </div>
    <p
      v-if="passwordStrength > 0"
      class="text-xs w-10 ml-3 text-right"
      :style="{ color: passwordStrengthStyle.color }"
    >
      {{ passwordStrengthStyle.text }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { checkPasswordStrength } from '@/services/password.service'

const pswStrengthMap = [
  { text: '', color: '#e7e7e7' }, // 0
  { text: 'Weak', color: '#FF414D' }, // 1
  { text: 'Fair', color: '#EAC018' }, // 2
  { text: 'Good', color: '#2985E3' }, // 3
  { text: 'Strong', color: '#2EB53B' }, // 4
  { text: 'Great!', color: '#2EB53B' }, // 5
]

export default defineComponent({
  props: {
    password: {
      type: String,
      default: null,
    },
  },

  computed: {
    passwordStrength(): number {
      const { password } = this
      const strength = checkPasswordStrength(password || '')
      return strength || 0
    },
    passwordStrengthStyle(): { text: string; color: string } {
      const style = pswStrengthMap[this.passwordStrength]
      return style
    },
  },
})
</script>
