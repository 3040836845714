import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M2.333 6.667V7A5.66 5.66 0 0 0 5 11.81M1.067 7.667 2.4 6.334l1.334 1.333m.259-4.675a5.667 5.667 0 0 1 8.014 0l.236.236m-8.25-.236a5.7 5.7 0 0 0-.695.842zm6.553.519h1.885V1.625m2.236 6.708c0 .737-1.642 1.334-3.667 1.334S7.334 9.07 7.334 8.333m7.333 0C14.667 7.597 13.025 7 11 7s-3.666.597-3.666 1.333m7.333 0V11M7.334 8.333v5.029m0-5.029c0-.414.519-.784 1.333-1.028M7.334 8.333V11m7.333 0c0 .736-1.642 1.333-3.667 1.333S7.334 11.736 7.334 11m7.333 0v2.667C14.667 14.403 13.025 15 11 15s-3.666-.597-3.666-1.333V11" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }