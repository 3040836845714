import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.25 8.042h-.876c-.814 0-1.22 0-1.552.1-.746.226-1.33.81-1.556 1.556-.1.33-.1.738-.1 1.552m13.501-2.917c0 .737-1.641 1.334-3.666 1.334S7.334 9.07 7.334 8.333m7.333 0c0-.736-1.641-1.333-3.666-1.333s-3.667.597-3.667 1.333m7.333 0V11M7.334 8.333v5.029m0-5.029c0-.414.519-.784 1.333-1.028M7.334 8.333V11m7.333 0c0 .736-1.641 1.333-3.666 1.333S7.334 11.736 7.334 11m7.333 0v2.667c0 .736-1.641 1.333-3.666 1.333s-3.667-.597-3.667-1.333V11m1.124-7.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }