<template>
  <div>
    <PanelToolbar
      :name="currentYearName"
      :aria-label-prev="$t('ui.components.datepicker.previous_year')"
      :aria-label-next="$t('ui.components.datepicker.next_year')"
      @prev="prev"
      @next="next"
      @next-panel="$emit('next-panel')"
    />

    <div class="calendar-months">
      <div
        v-for="month in months"
        :key="month.value"
        class="month"
        :class="{
          active: month.active,
          selectable: month.selectable,
        }"
        @click.stop="selectMonth(month)"
      >
        {{ month.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { addYears, format, setMonth } from 'date-fns'
import PanelMixin from './panelMixin'
import PanelToolbar from './PanelToolbar.vue'

interface Month {
  label: string
  active: boolean
  selectable: boolean
  value: number
}

export default defineComponent({
  components: {
    PanelToolbar,
  },

  mixins: [PanelMixin],

  emits: ['next-panel', 'update:modelValue', 'close'],

  computed: {
    months(): Month[] {
      const months: Month[] = []

      for (let i = 0; i < 12; i++) {
        months.push({
          label: this.$t(`ui.components.datepicker.months.${i + 1}`),
          value: i,
          active:
            i == this.selectedMonth &&
            this.modelValue.getFullYear() == this.selectedYear,
          selectable: this.isMonthAllowed(i, this.modelValue.getFullYear()),
        })
      }

      return months
    },
    currentYearName(): string {
      return format(this.modelValue, 'yyyy')
    },
  },

  methods: {
    changeYear(diff: number): void {
      const newDate = addYears(this.modelValue, diff)
      this.$emit('update:modelValue', newDate)
    },
    prev(): void {
      this.changeYear(-1)
    },
    next(): void {
      this.changeYear(1)
    },
    selectMonth(month: Month): void {
      const newDate = setMonth(this.modelValue, month.value)
      this.$emit('update:modelValue', newDate)
      this.$emit('close')
    },
  },
})
</script>
