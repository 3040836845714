import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M13.333 11.333H2.667m0 0 2.666-2.666m-2.666 2.666L5.333 14M2.667 4.667h10.666m0 0L10.667 2m2.666 2.667-2.666 2.666" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }