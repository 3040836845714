<template>
  <div class="bg-surface w-full fixed top-0 z-101">
    <div
      class="flex justify-center h-12 bg-orange-alpha text-orange-53 items-center text-sm md:text-base"
    >
      <span v-if="days > 0">
        Your trial ends in
        <b class="text-secondary">{{ days }} day{{ days === 1 ? '' : 's' }}</b>
      </span>
      <span v-else>Your trial period is over</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    days: {
      type: Number,
      default: null,
    },
  },
})
</script>
