import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8.7 2.3C8.7 3.1 7 3.7 5 3.7s-3.7-.6-3.7-1.3m7.4-.1C8.7 1.6 7 1 5 1s-3.7.6-3.7 1.3m7.4 0v3c0 .3-.9.6-1.3.7q-1.05.3-2.4.3c-2 0-3.7-.6-3.7-1.3m0-2.7v8c0 .7 1.6 1.3 3.7 1.3.9 0 1.7-.1 2.3-.3m-6-3.6C1.3 8.4 3 9 5 9c.9 0 1.7-.1 2.3-.3m7.4-.4c0 .7-1.6 1.3-3.7 1.3-2 0-3.7-.6-3.7-1.3m7.4 0C14.7 7.6 13 7 11 7s-3.7.6-3.7 1.3m7.4 0V11M7.3 8.3v5m0-5c0-.4.5-.8 1.3-1m-1.3 1V11m7.4 0c0 .7-1.6 1.3-3.7 1.3-2 0-3.7-.6-3.7-1.3m7.4 0v2.7c0 .7-1.6 1.3-3.7 1.3-2 0-3.7-.6-3.7-1.3V11M14.7 1l-3.4 3.4m0-3.4 3.4 3.4" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }