import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14 7.334 8.937 2.27c-.346-.346-.519-.52-.72-.643a2 2 0 0 0-.579-.24c-.23-.054-.474-.054-.963-.054H4M5 6.5l3.5 3.5M2 5.802v1.316c0 .326 0 .489.037.642q.05.205.16.386c.082.134.197.25.428.48l5.2 5.2c.528.528.792.792 1.096.891.268.087.557.087.824 0 .305-.099.569-.363 1.097-.891l1.65-1.65c.527-.528.791-.792.89-1.096a1.33 1.33 0 0 0 0-.824c-.098-.305-.362-.569-.89-1.097L7.625 4.292c-.23-.23-.346-.346-.48-.429a1.3 1.3 0 0 0-.386-.16c-.153-.036-.317-.036-.643-.036H4.133c-.746 0-1.12 0-1.405.145-.25.128-.455.332-.583.583C2 4.68 2 5.053 2 5.8" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }