import _404Layout from '@/layouts/404.vue'

import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    component: _404Layout,
  },
]

export default routes
