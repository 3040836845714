import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8 13.334V8.667m0 0H5.5m2.5 0h2.5M14.667 6H1.333m2.334 6h-.2c-.747 0-1.12 0-1.406-.145a1.33 1.33 0 0 1-.582-.583c-.146-.285-.146-.658-.146-1.405V4.8c0-.746 0-1.12.146-1.405.127-.25.331-.455.582-.583.285-.145.659-.145 1.406-.145h9.066c.747 0 1.12 0 1.406.145.25.128.454.332.582.583.146.285.146.659.146 1.405v5.067c0 .747 0 1.12-.146 1.405-.128.251-.332.455-.582.583-.286.145-.659.145-1.406.145h-.2" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }