import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m7.333 1.334 2 2m0 0-2 2m2-2h-4.8c-1.12 0-1.68 0-2.108.217a2 2 0 0 0-.874.875c-.218.427-.218.987-.218 2.108v3.8c0 .31 0 .464.017.594a2 2 0 0 0 1.722 1.722c.13.017.285.017.595.017m3 0h4.8c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874c.218-.428.218-.988.218-2.108v-3.8c0-.31 0-.465-.018-.595a2 2 0 0 0-1.721-1.721c-.13-.018-.285-.018-.595-.018m-5.666 9.334 2 2m-2-2 2-2" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }