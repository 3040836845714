<template>
  <Modal
    :show="showModal"
    icon="Refresh"
    :title="$t('messages.switch_root_title')"
    :confirm-text="$t('ui.common.switch')"
    :confirm="switchRoot"
    @close="showModal = false"
  >
    <p
      v-html="
        $t('messages.do_you_want_switch_root', {
          root: rootName,
          product: productName,
        })
      "
      class="mb-8"
    />
  </Modal>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useEventBus } from '@/composables/event_bus'
import { useUserStore } from '@/stores/user'
import Modal from '@/components/misc/Modal'
import type { StartSessionWithSelectedEntityParams } from '@/stores/user'

const eventBus = useEventBus()
const userStore = useUserStore()
const showModal = ref(false)
const rootName = ref('')
const productName = ref('')
const startSessionParams = ref<StartSessionWithSelectedEntityParams | null>(
  null
)

const switchRoot = async () => {
  if (!startSessionParams.value) {
    return
  }

  userStore.isLoggingOut = true

  try {
    await userStore.startSession(startSessionParams.value)
    window.location.reload()
  } catch (err) {
    eventBus.emit('error', err)
  }
}

onMounted(() => {
  const searchParams = new URLSearchParams(window.location.search)
  const requiredEntityId = searchParams.get('entity_id')

  if (requiredEntityId && requiredEntityId != userStore.entity?.id) {
    userStore.relations.forEach((relation) => {
      if (relation.root_id != userStore.root.id) {
        const entityRelation = relation.entities.find(
          (entityRelation) =>
            entityRelation.entity_id == requiredEntityId &&
            (relation.is_superadmin || entityRelation.roles.includes('admin'))
        )

        if (entityRelation) {
          rootName.value = relation.root_name
          productName.value = entityRelation.name
          startSessionParams.value = {
            user_id: userStore.user!.id,
            root_id: relation.root_id,
            entity_id: relation.root_user_entity_id,
            role: relation.is_superadmin ? 'superadmin' : 'admin',
            selected_entity_id: entityRelation.entity_id,
          }
          showModal.value = true
        }
      }
    })
  }
})
</script>
