<template>
  <img class="currency-icon" :src="src" :alt="alt" />
</template>

<script lang="ts">
import './CurrencyIcon.scss'

import { defineComponent, type PropType } from 'vue'
import type { Currency } from '@/types/services/global'

export default defineComponent({
  props: {
    currency: {
      type: Object as PropType<Currency | null>,
      default: null,
    },
  },

  data() {
    const placeholderIcon = `${
      import.meta.env.BASE_URL
    }currencies/default-currency-icon.png`

    return {
      placeholderIcon,
      src: placeholderIcon,
    }
  },

  computed: {
    alt(): string {
      return this.currency?.name || '-'
    },
  },

  watch: {
    currency() {
      this.src = this.placeholderIcon
      this.setCurrencyIconSrc()
    },
  },

  created() {
    this.setCurrencyIconSrc()
  },

  methods: {
    getCurrencyIcon(): string {
      const { currency } = this
      if (currency == null || !currency.has_image) {
        return this.placeholderIcon
      } else if (currency.is_fiat) {
        return `${import.meta.env.VUE_APP_STATIC_FILES_URL}/${currency.id}.png`
      } else {
        return `${import.meta.env.VUE_APP_STATIC_FILES_URL}/${currency.id}.jpg`
      }
    },
    setCurrencyIconSrc() {
      const src = this.getCurrencyIcon()

      if (src !== this.placeholderIcon) {
        const image = new Image()
        image.onload = () => {
          this.src = src
        }
        image.src = src
      } else {
        this.src = this.placeholderIcon
      }
    },
  },
})
</script>
