import type { AccountType } from '@/types/services/assets'
import type { ValuationMethodType } from '@/types/services/global'
import type { EntityType } from '@/types/services/root'

type friendlyAccountTypesOptions = {
  [key in AccountType]: string
}

type friendlyEntityTypesOptions = {
  [key in EntityType]: string
}

type friendlyValuationMethodsOptions = {
  [key in ValuationMethodType]: string
}

const friendlyTransferTypes = {
  deposit: 'Deposit',
  management: 'Management',
  standard: 'Standard',
  withdrawal: 'Withdrawal',
  fee_withdrawal: 'Fee Withdrawal',
  transaction: 'Transaction',
  fee: 'Fee',
}

const friendlyEntryTypes: friendlyAccountTypesOptions = {
  manual_entry: 'Crypto Asset',
  ico: 'Custom Entry',
  exchange: 'Exchange',
  blockchain: 'Blockchain',
}

const friendlyValuationMethods: friendlyValuationMethodsOptions = {
  coingecko_origin: 'Coingecko Origin',
  coingecko_average: 'Coingecko Average',
  _sigil: 'Sigil',
}

const accountStatuses = {
  fail: 'Disconnected',
  done: 'Connected',
}

const friendlyEntityTypes: friendlyEntityTypesOptions = {
  portfolio: 'Portfolio',
  fund_product: 'Fund',
  root_user: 'User',
  accounting: 'Accounting',
}

export {
  friendlyTransferTypes,
  friendlyEntryTypes,
  friendlyValuationMethods,
  accountStatuses,
  friendlyEntityTypes,
}
