import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m4 4 1.333-1.333m0 0L4 1.334m1.333 1.333H4a2.667 2.667 0 0 0-2.667 2.667M12 12l-1.333 1.334m0 0L12 14.667m-1.333-1.333H12a2.667 2.667 0 0 0 2.667-2.667M6.793 4.334a4.002 4.002 0 0 1 7.874 1 4 4 0 0 1-3 3.873m-2.334 1.46a4 4 0 1 1-8 0 4 4 0 0 1 8 0" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }