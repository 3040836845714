<template>
  <Select
    v-bind="$attrs"
    :selected-option="selectedOption"
    :disabled="disabled"
    :options="options"
    :clearable="clearable"
    @update:model-value="setTime"
  >
    <template #icon>
      <SvgIcon icon="Clock" class="h-4 w-4" />
    </template>
  </Select>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { mapState } from 'pinia'
import { startOfDay } from 'date-fns'

import { useUserStore } from '@/stores/user'

import InternalValue from '@/components/mixins/internalValue'
import Select from '@/components/controls/Select'
import SvgIcon from '@/components/misc/SvgIcon'

interface TimeOption {
  date: Date
  text: string
}

export default defineComponent({
  components: {
    Select,
    SvgIcon,
  },

  mixins: [InternalValue],

  props: {
    modelValue: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 15,
    },
    hour12: {
      type: Boolean,
      // Time format (15:00 vs 03:00 PM)
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedOption: null as TimeOption | null,
    }
  },

  computed: {
    ...mapState(useUserStore, ['entity', 'dateWithTzOffset']),
    now(): Date {
      const { entity } = this
      if (entity) {
        return this.dateWithTzOffset(new Date(), entity.id)
      } else {
        return new Date()
      }
    },
    options(): TimeOption[] {
      const date = startOfDay(new Date(this.internalValue || this.now))
      // Date number of passed date
      const dateNumber = date.getDate()

      const options: TimeOption[] = []
      // Fill options until we reach next day
      while (date.getDate() === dateNumber) {
        const text = date.toLocaleTimeString('en-GB', {
          hour12: this.hour12,
          hour: '2-digit',
          minute: '2-digit',
        })
        options.push({
          date: new Date(date),
          text: text,
        })
        date.setMinutes(date.getMinutes() + this.step)
      }
      return options
    },
  },

  watch: {
    options() {
      this.findSelectedOption()
    },
  },

  mounted() {
    this.findSelectedOption()
  },

  methods: {
    setTime(option: TimeOption) {
      this.internalValue = option.date
    },
    findSelectedOption() {
      if (
        !this.internalValue ||
        this.internalValue.getTime() == this.selectedOption?.date.getTime()
      ) {
        return
      }

      for (var i = 0; i < this.options.length; i++) {
        const curr = this.options[i]
        // in condition ignore miliseconds
        if (
          Math.floor(curr.date.getTime() / 1000) ==
          Math.floor(this.internalValue.getTime() / 1000)
        ) {
          this.selectedOption = curr
          return
        }
      }
    },
  },
})
</script>
