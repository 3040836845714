<template>
  <div class="login-form">
    <p class="text-xxl font-bold mb-4">
      {{ $t('ui.common.sign_up') }}
    </p>
    <p class="text-secondary mb-8">
      {{ $t('ui.common.log_in_moto') }}
    </p>

    <ModalErrors
      v-if="formErrors"
      :negative-offset="12"
      :messages="formErrors"
    />

    <Form
      id="sign-up-form"
      :validation-schema="validationSchema"
      class="relative"
      @submit="signUp"
    >
      <div
        v-show="showLoader"
        class="absolute w-full h-full bg-surface z-100 top-0 bottom-0 flex items-center justify-center"
      >
        <Spinner width="100" class="fill-brand" />
      </div>

      <BasicInput
        id="sign-up-first-name"
        v-model="first_name"
        name="first_name"
        :label="$t('ui.common.firstname')"
        class="mt-4"
        required
      />

      <BasicInput
        id="sign-up-last-name"
        v-model="last_name"
        name="last_name"
        :label="$t('ui.common.lastname')"
        class="mt-1"
        required
      />

      <BasicInput
        id="sign-up-email"
        v-model="email"
        name="email"
        :label="$t('ui.common.choose_email')"
        class="mt-1"
        required
      />

      <BasicInput
        id="sign-up-password"
        v-model="password"
        name="password"
        :type="'password'"
        :label="$t('ui.common.password')"
        class="mt-1"
        required
      />

      <PasswordStrength
        class="w-full flex items-center h-4"
        :password="password"
      />

      <BasicInput
        id="sign-up-password-confirm"
        v-model="password_confirm"
        name="password_confirm"
        autocomplete="new-password"
        :type="'password'"
        :label="$t('ui.common.confirm_password')"
        class="mt-4"
        required
      />

      <!-- eslint-disable vue/no-v-html -->
      <p
        class="text-xss text-center text-secondary mb-4"
        v-html="$t('ui.common.privacy_policy')"
      />
      <!-- eslint-enable vue/no-v-html -->

      <div class="flex justify-center">
        <Button
          id="sign-up-submit"
          button-type="submit"
          class="mt-3 w-32"
          type="primary"
        >
          {{ $t('ui.common.sign_up') }}
        </Button>
      </div>

      <div class="text-xs mt-10 text-secondary text-center">
        {{ $t('ui.common.already_have_an_account') }}
        <a
          id="sign-up-sign-in-link"
          class="text-brand font-medium ml-1"
          href="/signin"
        >
          {{ $t('ui.common.log_in') }}
        </a>
      </div>
    </Form>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { Form } from 'vee-validate'
import { object, ref, string } from 'yup'

import { useUserStore } from '@/stores/user'

import { retrieveErrorsMessages } from '@/helpers/errors'
import Spinner from '@/assets/loaders/Spinner.svg?component'

import BasicInput from '@/components/controls/BasicInput'
import Button from '@/components/controls/Button'
import ModalErrors from '@/components/misc/Modal/ModalErrors.vue'
import PasswordStrength from '@/components/misc/PasswordStrength'

import i18n from '@/langs'

export default {
  components: {
    BasicInput,
    Button,
    Form,
    ModalErrors,
    Spinner,
    PasswordStrength,
  },

  data() {
    return {
      showLoader: false,
      showWrongRegistration: false,
      email: '',
      password: '',
      password_confirm: '',
      first_name: '',
      last_name: '',
      formErrors: null,

      userStore: useUserStore(),

      validationSchema: markRaw(
        object({
          first_name: string().required(),
          last_name: string().required(),
          email: string().required().email(),
          password: string().required().min(8).passStrength(),
          password_confirm: string().oneOf(
            [ref('password')],
            i18n.global.t('validation.password_must_match')
          ),
        })
      ),
    }
  },

  methods: {
    async signUp() {
      this.showLoader = true
      this.formErrors = null
      const { email, password, first_name, last_name } = this

      try {
        const { data: registerData } = await this.userStore.registerUser({
          email,
          password,
          first_name,
          last_name,
        })

        await this.userStore.startSession({
          user_id: registerData.user_id,
          root_id: registerData.root_id,
          entity_id: registerData.entity_id,
          role: 'superadmin',
        })
        this.$router.push('/')
      } catch (err) {
        this.formErrors = retrieveErrorsMessages(err)
        this.sending = false
      } finally {
        this.showLoader = false
      }
    },
  },
}
</script>
