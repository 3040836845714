import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m6.714 9.286-2.571 2.571m5.143-2.571 2.571 2.571M4.143 4.143l2.571 2.571m2.572 0 2.571-2.571m-7.286 8.571a1.286 1.286 0 1 1-2.571 0 1.286 1.286 0 0 1 2.571 0M9.714 8a1.714 1.714 0 1 1-3.428 0 1.714 1.714 0 0 1 3.428 0M14 12.714a1.286 1.286 0 1 1-2.571 0 1.286 1.286 0 0 1 2.571 0m0-9.428a1.286 1.286 0 1 1-2.571 0 1.286 1.286 0 0 1 2.571 0m-9.429 0a1.286 1.286 0 1 1-2.571 0 1.286 1.286 0 0 1 2.571 0" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }