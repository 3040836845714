<template>
  <transition name="modal" appear>
    <div
      v-if="show"
      class="crypkit-modal-wrapper"
      @mousedown="wrapperClickHandler"
      @mouseup="wrapperClickHandler"
    >
      <div
        class="modal-body"
        :class="{ 'modal-body__bottom': bottom }"
        :style="modalStyle"
      >
        <div
          class="modal-content card"
          :class="{ 'overflow-hidden': loading, 'bulky-border': bulkyBorder }"
        >
          <div
            class="flex items-center"
            :class="!icon && !title ? 'justify-end' : 'justify-between'"
          >
            <SvgIcon v-if="icon" :icon="icon" size="lg" class="mr-4" />
            <div v-if="title" class="modal-title">
              <slot name="title-prepend" />
              {{ title }}
              <slot name="title-append" />
            </div>
            <div v-if="!hideClose" class="close-icon" @click="closeModal">
              <SvgIcon icon="Close" />
            </div>
          </div>
          <ModalErrors v-if="errorMessage.length" :messages="errorMessage" />

          <div class="mt-8">
            <slot />

            <div v-if="!hideButtons" class="flex justify-between mt-2">
              <Button
                id="modal-button-cancel"
                type="secondary"
                @click="closeModal"
              >
                {{ cancelText || $t('ui.common.close') }}
              </Button>
              <Button
                v-if="!hideConfirmButton"
                id="modal-button-confirm"
                :type="confirmType"
                :disabled="confirmDisabled || loading"
                @click="confirm"
              >
                {{ confirmText || $t('ui.common.confirm') }}
              </Button>
            </div>
          </div>

          <div v-if="loading" class="crypkit-modal-loading">
            <Spinner width="100" class="fill-brand" />
            <span v-if="loadingText">
              {{ loadingText }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import './Modal.scss'

import { defineComponent, type PropType } from 'vue'
import Spinner from '@/assets/loaders/Spinner.svg?component'
import Button from '@/components/controls/Button'
import SvgIcon from '@/components/misc/SvgIcon'
import ModalErrors from './ModalErrors.vue'
import type { ModalSize } from '@/types/components/modal'
import type { ButtonType } from '@/types/components/button'

export default defineComponent({
  components: {
    Spinner,
    Button,
    SvgIcon,
    ModalErrors,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String as PropType<string | null>,
      default: null,
    },
    size: {
      default: 'sm',
      type: String as PropType<ModalSize>,
    },
    title: {
      default: '',
      type: String,
    },
    emitClose: {
      default: false,
      required: false,
      type: Boolean,
    },
    show: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String as PropType<string | null>,
      default: null,
    },
    errorMessage: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    cancelText: {
      type: String,
      default: null,
    },
    confirm: {
      type: Function as PropType<(e: Event) => void>,
      default: () => {},
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    hideConfirmButton: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: null,
    },
    confirmType: {
      type: String as PropType<ButtonType>,
      default: 'primary',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    bulkyBorder: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  data(): { mouseUpElement: HTMLElement | null } {
    return {
      mouseUpElement: null,
    }
  },

  computed: {
    modalStyle(): { 'max-width': string } {
      let width = '720px' // md
      if (this.size === 'xs') {
        width = '320px'
      } else if (this.size === 'sm') {
        width = '520px'
      } else if (this.size === 'lg') {
        width = '1020px'
      } else if (this.size === 'xl') {
        width = '1280px'
      }

      return {
        'max-width': width,
      }
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(value: boolean): void {
        if (value) {
          document.body.classList.add('overflow-hidden')
        } else {
          document.body.classList.remove('overflow-hidden')
        }
      },
    },
  },

  beforeUnmount() {
    document.body.classList.remove('overflow-hidden')
  },

  methods: {
    closeModal(): void {
      this.$emit('close')
    },
    wrapperClickHandler(event: MouseEvent): void {
      const { mouseUpElement } = this
      // This prevents accidental closing of modal
      // when user drags the mouse out of modal body
      const el = event.target as HTMLElement
      const mouseDownClose = el?.classList.contains('crypkit-modal-wrapper')
      const mouseUpClose =
        mouseUpElement &&
        mouseUpElement?.classList.contains('crypkit-modal-wrapper')
      this.mouseUpElement = el
      // If clicked element is not modal body
      if (mouseDownClose && mouseUpClose) {
        this.closeModal()
      }
    },
  },
})
</script>
