/**
 * GitLab's emojis - https://docs.gitlab.com/17.2/ee/development/fe_guide/emojis.html
 *
 * This is simplified JSON from https://raw.githubusercontent.com/bonusly/gemojione/master/config/index.json
 */
export const EMOJI = {
  '100': '💯',
  '1234': '🔢',
  '8ball': '🎱',
  a: '🅰',
  ab: '🆎',
  abc: '🔤',
  abcd: '🔡',
  accept: '🉑',
  aerial_tramway: '🚡',
  airplane: '✈',
  airplane_arriving: '🛬',
  airplane_departure: '🛫',
  airplane_small: '🛩',
  alarm_clock: '⏰',
  alembic: '⚗',
  alien: '👽',
  ambulance: '🚑',
  amphora: '🏺',
  anchor: '⚓',
  angel: '👼',
  angel_tone1: '👼🏻',
  angel_tone2: '👼🏼',
  angel_tone3: '👼🏽',
  angel_tone4: '👼🏾',
  angel_tone5: '👼🏿',
  anger: '💢',
  anger_right: '🗯',
  angry: '😠',
  anguished: '😧',
  ant: '🐜',
  apple: '🍎',
  aquarius: '♒',
  aries: '♈',
  arrow_backward: '◀',
  arrow_double_down: '⏬',
  arrow_double_up: '⏫',
  arrow_down: '⬇',
  arrow_down_small: '🔽',
  arrow_forward: '▶',
  arrow_heading_down: '⤵',
  arrow_heading_up: '⤴',
  arrow_left: '⬅',
  arrow_lower_left: '↙',
  arrow_lower_right: '↘',
  arrow_right: '➡',
  arrow_right_hook: '↪',
  arrow_up: '⬆',
  arrow_up_down: '↕',
  arrow_up_small: '🔼',
  arrow_upper_left: '↖',
  arrow_upper_right: '↗',
  arrows_clockwise: '🔃',
  arrows_counterclockwise: '🔄',
  art: '🎨',
  articulated_lorry: '🚛',
  asterisk: '*⃣',
  astonished: '😲',
  athletic_shoe: '👟',
  atm: '🏧',
  atom: '⚛',
  avocado: '🥑',
  b: '🅱',
  baby: '👶',
  baby_bottle: '🍼',
  baby_chick: '🐤',
  baby_symbol: '🚼',
  baby_tone1: '👶🏻',
  baby_tone2: '👶🏼',
  baby_tone3: '👶🏽',
  baby_tone4: '👶🏾',
  baby_tone5: '👶🏿',
  back: '🔙',
  bacon: '🥓',
  badminton: '🏸',
  baggage_claim: '🛄',
  balloon: '🎈',
  ballot_box: '🗳',
  ballot_box_with_check: '☑',
  bamboo: '🎍',
  banana: '🍌',
  bangbang: '‼',
  bank: '🏦',
  bar_chart: '📊',
  barber: '💈',
  baseball: '⚾',
  basketball: '🏀',
  basketball_player: '⛹',
  basketball_player_tone1: '⛹🏻',
  basketball_player_tone2: '⛹🏼',
  basketball_player_tone3: '⛹🏽',
  basketball_player_tone4: '⛹🏾',
  basketball_player_tone5: '⛹🏿',
  bat: '🦇',
  bath: '🛀',
  bath_tone1: '🛀🏻',
  bath_tone2: '🛀🏼',
  bath_tone3: '🛀🏽',
  bath_tone4: '🛀🏾',
  bath_tone5: '🛀🏿',
  bathtub: '🛁',
  battery: '🔋',
  beach: '🏖',
  beach_umbrella: '⛱',
  bear: '🐻',
  bed: '🛏',
  bee: '🐝',
  beer: '🍺',
  beers: '🍻',
  beetle: '🐞',
  beginner: '🔰',
  bell: '🔔',
  bellhop: '🛎',
  bento: '🍱',
  bicyclist: '🚴',
  bicyclist_tone1: '🚴🏻',
  bicyclist_tone2: '🚴🏼',
  bicyclist_tone3: '🚴🏽',
  bicyclist_tone4: '🚴🏾',
  bicyclist_tone5: '🚴🏿',
  bike: '🚲',
  bikini: '👙',
  biohazard: '☣',
  bird: '🐦',
  birthday: '🎂',
  black_circle: '⚫',
  black_heart: '🖤',
  black_joker: '🃏',
  black_large_square: '⬛',
  black_medium_small_square: '◾',
  black_medium_square: '◼',
  black_nib: '✒',
  black_small_square: '▪',
  black_square_button: '🔲',
  blossom: '🌼',
  blowfish: '🐡',
  blue_book: '📘',
  blue_car: '🚙',
  blue_heart: '💙',
  blush: '😊',
  boar: '🐗',
  bomb: '💣',
  book: '📖',
  bookmark: '🔖',
  bookmark_tabs: '📑',
  books: '📚',
  boom: '💥',
  boot: '👢',
  bouquet: '💐',
  bow: '🙇',
  bow_and_arrow: '🏹',
  bow_tone1: '🙇🏻',
  bow_tone2: '🙇🏼',
  bow_tone3: '🙇🏽',
  bow_tone4: '🙇🏾',
  bow_tone5: '🙇🏿',
  bowling: '🎳',
  boxing_glove: '🥊',
  boy: '👦',
  boy_tone1: '👦🏻',
  boy_tone2: '👦🏼',
  boy_tone3: '👦🏽',
  boy_tone4: '👦🏾',
  boy_tone5: '👦🏿',
  bread: '🍞',
  bride_with_veil: '👰',
  bride_with_veil_tone1: '👰🏻',
  bride_with_veil_tone2: '👰🏼',
  bride_with_veil_tone3: '👰🏽',
  bride_with_veil_tone4: '👰🏾',
  bride_with_veil_tone5: '👰🏿',
  bridge_at_night: '🌉',
  briefcase: '💼',
  broken_heart: '💔',
  bug: '🐛',
  bulb: '💡',
  bullettrain_front: '🚅',
  bullettrain_side: '🚄',
  burrito: '🌯',
  bus: '🚌',
  busstop: '🚏',
  bust_in_silhouette: '👤',
  busts_in_silhouette: '👥',
  butterfly: '🦋',
  cactus: '🌵',
  cake: '🍰',
  calendar: '📆',
  calendar_spiral: '🗓',
  call_me: '🤙',
  call_me_tone1: '🤙🏻',
  call_me_tone2: '🤙🏼',
  call_me_tone3: '🤙🏽',
  call_me_tone4: '🤙🏾',
  call_me_tone5: '🤙🏿',
  calling: '📲',
  camel: '🐫',
  camera: '📷',
  camera_with_flash: '📸',
  camping: '🏕',
  cancer: '♋',
  candle: '🕯',
  candy: '🍬',
  canoe: '🛶',
  capital_abcd: '🔠',
  capricorn: '♑',
  card_box: '🗃',
  card_index: '📇',
  carousel_horse: '🎠',
  carrot: '🥕',
  cartwheel: '🤸',
  cartwheel_tone1: '🤸🏻',
  cartwheel_tone2: '🤸🏼',
  cartwheel_tone3: '🤸🏽',
  cartwheel_tone4: '🤸🏾',
  cartwheel_tone5: '🤸🏿',
  cat: '🐱',
  cat2: '🐈',
  cd: '💿',
  chains: '⛓',
  champagne: '🍾',
  champagne_glass: '🥂',
  chart: '💹',
  chart_with_downwards_trend: '📉',
  chart_with_upwards_trend: '📈',
  checkered_flag: '🏁',
  cheese: '🧀',
  cherries: '🍒',
  cherry_blossom: '🌸',
  chestnut: '🌰',
  chicken: '🐔',
  children_crossing: '🚸',
  chipmunk: '🐿',
  chocolate_bar: '🍫',
  christmas_tree: '🎄',
  church: '⛪',
  cinema: '🎦',
  circus_tent: '🎪',
  city_dusk: '🌆',
  city_sunset: '🌇',
  cityscape: '🏙',
  cl: '🆑',
  clap: '👏',
  clap_tone1: '👏🏻',
  clap_tone2: '👏🏼',
  clap_tone3: '👏🏽',
  clap_tone4: '👏🏾',
  clap_tone5: '👏🏿',
  clapper: '🎬',
  classical_building: '🏛',
  clipboard: '📋',
  clock: '🕰',
  clock1: '🕐',
  clock10: '🕙',
  clock1030: '🕥',
  clock11: '🕚',
  clock1130: '🕦',
  clock12: '🕛',
  clock1230: '🕧',
  clock130: '🕜',
  clock2: '🕑',
  clock230: '🕝',
  clock3: '🕒',
  clock330: '🕞',
  clock4: '🕓',
  clock430: '🕟',
  clock5: '🕔',
  clock530: '🕠',
  clock6: '🕕',
  clock630: '🕡',
  clock7: '🕖',
  clock730: '🕢',
  clock8: '🕗',
  clock830: '🕣',
  clock9: '🕘',
  clock930: '🕤',
  closed_book: '📕',
  closed_lock_with_key: '🔐',
  closed_umbrella: '🌂',
  cloud: '☁',
  cloud_lightning: '🌩',
  cloud_rain: '🌧',
  cloud_snow: '🌨',
  cloud_tornado: '🌪',
  clown: '🤡',
  clubs: '♣',
  cocktail: '🍸',
  coffee: '☕',
  coffin: '⚰',
  cold_sweat: '😰',
  comet: '☄',
  compression: '🗜',
  computer: '💻',
  confetti_ball: '🎊',
  confounded: '😖',
  confused: '😕',
  congratulations: '㊗',
  construction: '🚧',
  construction_site: '🏗',
  construction_worker: '👷',
  construction_worker_tone1: '👷🏻',
  construction_worker_tone2: '👷🏼',
  construction_worker_tone3: '👷🏽',
  construction_worker_tone4: '👷🏾',
  construction_worker_tone5: '👷🏿',
  control_knobs: '🎛',
  convenience_store: '🏪',
  cookie: '🍪',
  cooking: '🍳',
  cool: '🆒',
  cop: '👮',
  cop_tone1: '👮🏻',
  cop_tone2: '👮🏼',
  cop_tone3: '👮🏽',
  cop_tone4: '👮🏾',
  cop_tone5: '👮🏿',
  copyright: '©',
  corn: '🌽',
  couch: '🛋',
  couple: '👫',
  couple_mm: '👨‍❤️‍👨',
  couple_with_heart: '💑',
  couple_ww: '👩‍❤️‍👩',
  couplekiss: '💏',
  cow: '🐮',
  cow2: '🐄',
  cowboy: '🤠',
  crab: '🦀',
  crayon: '🖍',
  credit_card: '💳',
  crescent_moon: '🌙',
  cricket: '🦗',
  crocodile: '🐊',
  croissant: '🥐',
  cross: '✝',
  crossed_flags: '🎌',
  crossed_swords: '⚔',
  crown: '👑',
  cruise_ship: '🛳',
  cry: '😢',
  crying_cat_face: '😿',
  crystal_ball: '🔮',
  cucumber: '🥒',
  cupid: '💘',
  curly_loop: '➰',
  currency_exchange: '💱',
  curry: '🍛',
  custard: '🍮',
  customs: '🛃',
  cyclone: '🌀',
  dagger: '🗡',
  dancer: '💃',
  dancer_tone1: '💃🏻',
  dancer_tone2: '💃🏼',
  dancer_tone3: '💃🏽',
  dancer_tone4: '💃🏾',
  dancer_tone5: '💃🏿',
  dancers: '👯',
  dango: '🍡',
  dark_sunglasses: '🕶',
  dart: '🎯',
  dash: '💨',
  date: '📅',
  deciduous_tree: '🌳',
  deer: '🦌',
  department_store: '🏬',
  desert: '🏜',
  desktop: '🖥',
  diamond_shape_with_a_dot_inside: '💠',
  diamonds: '♦',
  disappointed: '😞',
  disappointed_relieved: '😥',
  dividers: '🗂',
  dizzy: '💫',
  dizzy_face: '😵',
  do_not_litter: '🚯',
  dog: '🐶',
  dog2: '🐕',
  dollar: '💵',
  dolls: '🎎',
  dolphin: '🐬',
  door: '🚪',
  doughnut: '🍩',
  dove: '🕊',
  dragon: '🐉',
  dragon_face: '🐲',
  dress: '👗',
  dromedary_camel: '🐪',
  drooling_face: '🤤',
  droplet: '💧',
  drum: '🥁',
  duck: '🦆',
  dvd: '📀',
  'e-mail': '📧',
  eagle: '🦅',
  ear: '👂',
  ear_of_rice: '🌾',
  ear_tone1: '👂🏻',
  ear_tone2: '👂🏼',
  ear_tone3: '👂🏽',
  ear_tone4: '👂🏾',
  ear_tone5: '👂🏿',
  earth_africa: '🌍',
  earth_americas: '🌎',
  earth_asia: '🌏',
  egg: '🥚',
  eggplant: '🍆',
  eight: '8️⃣',
  eight_pointed_black_star: '✴',
  eight_spoked_asterisk: '✳',
  eject: '⏏',
  electric_plug: '🔌',
  elephant: '🐘',
  end: '🔚',
  envelope: '✉',
  envelope_with_arrow: '📩',
  euro: '💶',
  european_castle: '🏰',
  european_post_office: '🏤',
  evergreen_tree: '🌲',
  exclamation: '❗',
  expressionless: '😑',
  eye: '👁',
  eye_in_speech_bubble: '👁‍🗨',
  eyeglasses: '👓',
  eyes: '👀',
  face_palm: '🤦',
  face_palm_tone1: '🤦🏻',
  face_palm_tone2: '🤦🏼',
  face_palm_tone3: '🤦🏽',
  face_palm_tone4: '🤦🏾',
  face_palm_tone5: '🤦🏿',
  factory: '🏭',
  fallen_leaf: '🍂',
  family: '👪',
  family_mmb: '👨‍👨‍👦',
  family_mmbb: '👨‍👨‍👦‍👦',
  family_mmg: '👨‍👨‍👧',
  family_mmgb: '👨‍👨‍👧‍👦',
  family_mmgg: '👨‍👨‍👧‍👧',
  family_mwbb: '👨‍👩‍👦‍👦',
  family_mwg: '👨‍👩‍👧',
  family_mwgb: '👨‍👩‍👧‍👦',
  family_mwgg: '👨‍👩‍👧‍👧',
  family_wwb: '👩‍👩‍👦',
  family_wwbb: '👩‍👩‍👦‍👦',
  family_wwg: '👩‍👩‍👧',
  family_wwgb: '👩‍👩‍👧‍👦',
  family_wwgg: '👩‍👩‍👧‍👧',
  fast_forward: '⏩',
  fax: '📠',
  fearful: '😨',
  feet: '🐾',
  fencer: '🤺',
  ferris_wheel: '🎡',
  ferry: '⛴',
  field_hockey: '🏑',
  file_cabinet: '🗄',
  file_folder: '📁',
  film_frames: '🎞',
  fingers_crossed: '🤞',
  fingers_crossed_tone1: '🤞🏻',
  fingers_crossed_tone2: '🤞🏼',
  fingers_crossed_tone3: '🤞🏽',
  fingers_crossed_tone4: '🤞🏾',
  fingers_crossed_tone5: '🤞🏿',
  fire: '🔥',
  fire_engine: '🚒',
  fireworks: '🎆',
  first_place: '🥇',
  first_quarter_moon: '🌓',
  first_quarter_moon_with_face: '🌛',
  fish: '🐟',
  fish_cake: '🍥',
  fishing_pole_and_fish: '🎣',
  fist: '✊',
  fist_tone1: '✊🏻',
  fist_tone2: '✊🏼',
  fist_tone3: '✊🏽',
  fist_tone4: '✊🏾',
  fist_tone5: '✊🏿',
  five: '5️⃣',
  flag_ac: '🇦🇨',
  flag_ad: '🇦🇩',
  flag_ae: '🇦🇪',
  flag_af: '🇦🇫',
  flag_ag: '🇦🇬',
  flag_ai: '🇦🇮',
  flag_al: '🇦🇱',
  flag_am: '🇦🇲',
  flag_ao: '🇦🇴',
  flag_aq: '🇦🇶',
  flag_ar: '🇦🇷',
  flag_as: '🇦🇸',
  flag_at: '🇦🇹',
  flag_au: '🇦🇺',
  flag_aw: '🇦🇼',
  flag_ax: '🇦🇽',
  flag_az: '🇦🇿',
  flag_ba: '🇧🇦',
  flag_bb: '🇧🇧',
  flag_bd: '🇧🇩',
  flag_be: '🇧🇪',
  flag_bf: '🇧🇫',
  flag_bg: '🇧🇬',
  flag_bh: '🇧🇭',
  flag_bi: '🇧🇮',
  flag_bj: '🇧🇯',
  flag_bl: '🇧🇱',
  flag_black: '🏴',
  flag_bm: '🇧🇲',
  flag_bn: '🇧🇳',
  flag_bo: '🇧🇴',
  flag_bq: '🇧🇶',
  flag_br: '🇧🇷',
  flag_bs: '🇧🇸',
  flag_bt: '🇧🇹',
  flag_bv: '🇧🇻',
  flag_bw: '🇧🇼',
  flag_by: '🇧🇾',
  flag_bz: '🇧🇿',
  flag_ca: '🇨🇦',
  flag_cc: '🇨🇨',
  flag_cd: '🇨🇩',
  flag_cf: '🇨🇫',
  flag_cg: '🇨🇬',
  flag_ch: '🇨🇭',
  flag_ci: '🇨🇮',
  flag_ck: '🇨🇰',
  flag_cl: '🇨🇱',
  flag_cm: '🇨🇲',
  flag_cn: '🇨🇳',
  flag_co: '🇨🇴',
  flag_cp: '🇨🇵',
  flag_cr: '🇨🇷',
  flag_cu: '🇨🇺',
  flag_cv: '🇨🇻',
  flag_cw: '🇨🇼',
  flag_cx: '🇨🇽',
  flag_cy: '🇨🇾',
  flag_cz: '🇨🇿',
  flag_de: '🇩🇪',
  flag_dg: '🇩🇬',
  flag_dj: '🇩🇯',
  flag_dk: '🇩🇰',
  flag_dm: '🇩🇲',
  flag_do: '🇩🇴',
  flag_dz: '🇩🇿',
  flag_ea: '🇪🇦',
  flag_ec: '🇪🇨',
  flag_ee: '🇪🇪',
  flag_eg: '🇪🇬',
  flag_eh: '🇪🇭',
  flag_er: '🇪🇷',
  flag_es: '🇪🇸',
  flag_et: '🇪🇹',
  flag_eu: '🇪🇺',
  flag_fi: '🇫🇮',
  flag_fj: '🇫🇯',
  flag_fk: '🇫🇰',
  flag_fm: '🇫🇲',
  flag_fo: '🇫🇴',
  flag_fr: '🇫🇷',
  flag_ga: '🇬🇦',
  flag_gb: '🇬🇧',
  flag_gd: '🇬🇩',
  flag_ge: '🇬🇪',
  flag_gf: '🇬🇫',
  flag_gg: '🇬🇬',
  flag_gh: '🇬🇭',
  flag_gi: '🇬🇮',
  flag_gl: '🇬🇱',
  flag_gm: '🇬🇲',
  flag_gn: '🇬🇳',
  flag_gp: '🇬🇵',
  flag_gq: '🇬🇶',
  flag_gr: '🇬🇷',
  flag_gs: '🇬🇸',
  flag_gt: '🇬🇹',
  flag_gu: '🇬🇺',
  flag_gw: '🇬🇼',
  flag_gy: '🇬🇾',
  flag_hk: '🇭🇰',
  flag_hm: '🇭🇲',
  flag_hn: '🇭🇳',
  flag_hr: '🇭🇷',
  flag_ht: '🇭🇹',
  flag_hu: '🇭🇺',
  flag_ic: '🇮🇨',
  flag_id: '🇮🇩',
  flag_ie: '🇮🇪',
  flag_il: '🇮🇱',
  flag_im: '🇮🇲',
  flag_in: '🇮🇳',
  flag_io: '🇮🇴',
  flag_iq: '🇮🇶',
  flag_ir: '🇮🇷',
  flag_is: '🇮🇸',
  flag_it: '🇮🇹',
  flag_je: '🇯🇪',
  flag_jm: '🇯🇲',
  flag_jo: '🇯🇴',
  flag_jp: '🇯🇵',
  flag_ke: '🇰🇪',
  flag_kg: '🇰🇬',
  flag_kh: '🇰🇭',
  flag_ki: '🇰🇮',
  flag_km: '🇰🇲',
  flag_kn: '🇰🇳',
  flag_kp: '🇰🇵',
  flag_kr: '🇰🇷',
  flag_kw: '🇰🇼',
  flag_ky: '🇰🇾',
  flag_kz: '🇰🇿',
  flag_la: '🇱🇦',
  flag_lb: '🇱🇧',
  flag_lc: '🇱🇨',
  flag_li: '🇱🇮',
  flag_lk: '🇱🇰',
  flag_lr: '🇱🇷',
  flag_ls: '🇱🇸',
  flag_lt: '🇱🇹',
  flag_lu: '🇱🇺',
  flag_lv: '🇱🇻',
  flag_ly: '🇱🇾',
  flag_ma: '🇲🇦',
  flag_mc: '🇲🇨',
  flag_md: '🇲🇩',
  flag_me: '🇲🇪',
  flag_mf: '🇲🇫',
  flag_mg: '🇲🇬',
  flag_mh: '🇲🇭',
  flag_mk: '🇲🇰',
  flag_ml: '🇲🇱',
  flag_mm: '🇲🇲',
  flag_mn: '🇲🇳',
  flag_mo: '🇲🇴',
  flag_mp: '🇲🇵',
  flag_mq: '🇲🇶',
  flag_mr: '🇲🇷',
  flag_ms: '🇲🇸',
  flag_mt: '🇲🇹',
  flag_mu: '🇲🇺',
  flag_mv: '🇲🇻',
  flag_mw: '🇲🇼',
  flag_mx: '🇲🇽',
  flag_my: '🇲🇾',
  flag_mz: '🇲🇿',
  flag_na: '🇳🇦',
  flag_nc: '🇳🇨',
  flag_ne: '🇳🇪',
  flag_nf: '🇳🇫',
  flag_ng: '🇳🇬',
  flag_ni: '🇳🇮',
  flag_nl: '🇳🇱',
  flag_no: '🇳🇴',
  flag_np: '🇳🇵',
  flag_nr: '🇳🇷',
  flag_nu: '🇳🇺',
  flag_nz: '🇳🇿',
  flag_om: '🇴🇲',
  flag_pa: '🇵🇦',
  flag_pe: '🇵🇪',
  flag_pf: '🇵🇫',
  flag_pg: '🇵🇬',
  flag_ph: '🇵🇭',
  flag_pk: '🇵🇰',
  flag_pl: '🇵🇱',
  flag_pm: '🇵🇲',
  flag_pn: '🇵🇳',
  flag_pr: '🇵🇷',
  flag_ps: '🇵🇸',
  flag_pt: '🇵🇹',
  flag_pw: '🇵🇼',
  flag_py: '🇵🇾',
  flag_qa: '🇶🇦',
  flag_re: '🇷🇪',
  flag_ro: '🇷🇴',
  flag_rs: '🇷🇸',
  flag_ru: '🇷🇺',
  flag_rw: '🇷🇼',
  flag_sa: '🇸🇦',
  flag_sb: '🇸🇧',
  flag_sc: '🇸🇨',
  flag_sd: '🇸🇩',
  flag_se: '🇸🇪',
  flag_sg: '🇸🇬',
  flag_sh: '🇸🇭',
  flag_si: '🇸🇮',
  flag_sj: '🇸🇯',
  flag_sk: '🇸🇰',
  flag_sl: '🇸🇱',
  flag_sm: '🇸🇲',
  flag_sn: '🇸🇳',
  flag_so: '🇸🇴',
  flag_sr: '🇸🇷',
  flag_ss: '🇸🇸',
  flag_st: '🇸🇹',
  flag_sv: '🇸🇻',
  flag_sx: '🇸🇽',
  flag_sy: '🇸🇾',
  flag_sz: '🇸🇿',
  flag_ta: '🇹🇦',
  flag_tc: '🇹🇨',
  flag_td: '🇹🇩',
  flag_tf: '🇹🇫',
  flag_tg: '🇹🇬',
  flag_th: '🇹🇭',
  flag_tj: '🇹🇯',
  flag_tk: '🇹🇰',
  flag_tl: '🇹🇱',
  flag_tm: '🇹🇲',
  flag_tn: '🇹🇳',
  flag_to: '🇹🇴',
  flag_tr: '🇹🇷',
  flag_tt: '🇹🇹',
  flag_tv: '🇹🇻',
  flag_tw: '🇹🇼',
  flag_tz: '🇹🇿',
  flag_ua: '🇺🇦',
  flag_ug: '🇺🇬',
  flag_um: '🇺🇲',
  flag_us: '🇺🇸',
  flag_uy: '🇺🇾',
  flag_uz: '🇺🇿',
  flag_va: '🇻🇦',
  flag_vc: '🇻🇨',
  flag_ve: '🇻🇪',
  flag_vg: '🇻🇬',
  flag_vi: '🇻🇮',
  flag_vn: '🇻🇳',
  flag_vu: '🇻🇺',
  flag_wf: '🇼🇫',
  flag_white: '🏳',
  flag_ws: '🇼🇸',
  flag_xk: '🇽🇰',
  flag_ye: '🇾🇪',
  flag_yt: '🇾🇹',
  flag_za: '🇿🇦',
  flag_zm: '🇿🇲',
  flag_zw: '🇿🇼',
  flags: '🎏',
  flashlight: '🔦',
  'fleur-de-lis': '⚜',
  floppy_disk: '💾',
  flower_playing_cards: '🎴',
  flushed: '😳',
  fog: '🌫',
  foggy: '🌁',
  football: '🏈',
  footprints: '👣',
  fork_and_knife: '🍴',
  fork_knife_plate: '🍽',
  fountain: '⛲',
  four: '4️⃣',
  four_leaf_clover: '🍀',
  fox: '🦊',
  frame_photo: '🖼',
  free: '🆓',
  french_bread: '🥖',
  fried_shrimp: '🍤',
  fries: '🍟',
  frog: '🐸',
  frowning: '😦',
  frowning2: '☹',
  fuelpump: '⛽',
  full_moon: '🌕',
  full_moon_with_face: '🌝',
  game_die: '🎲',
  gear: '⚙',
  gem: '💎',
  gay_pride_flag: '🏳🌈',
  gemini: '♊',
  ghost: '👻',
  gift: '🎁',
  gift_heart: '💝',
  girl: '👧',
  girl_tone1: '👧🏻',
  girl_tone2: '👧🏼',
  girl_tone3: '👧🏽',
  girl_tone4: '👧🏾',
  girl_tone5: '👧🏿',
  globe_with_meridians: '🌐',
  goal: '🥅',
  goat: '🐐',
  golf: '⛳',
  golfer: '🏌',
  gorilla: '🦍',
  grapes: '🍇',
  green_apple: '🍏',
  green_book: '📗',
  green_heart: '💚',
  grey_exclamation: '❕',
  grey_question: '❔',
  grimacing: '😬',
  grin: '😁',
  grinning: '😀',
  guardsman: '💂',
  guardsman_tone1: '💂🏻',
  guardsman_tone2: '💂🏼',
  guardsman_tone3: '💂🏽',
  guardsman_tone4: '💂🏾',
  guardsman_tone5: '💂🏿',
  guitar: '🎸',
  gun: '🔫',
  haircut: '💇',
  haircut_tone1: '💇🏻',
  haircut_tone2: '💇🏼',
  haircut_tone3: '💇🏽',
  haircut_tone4: '💇🏾',
  haircut_tone5: '💇🏿',
  hamburger: '🍔',
  hammer: '🔨',
  hammer_pick: '⚒',
  hamster: '🐹',
  hand_splayed: '🖐',
  hand_splayed_tone1: '🖐🏻',
  hand_splayed_tone2: '🖐🏼',
  hand_splayed_tone3: '🖐🏽',
  hand_splayed_tone4: '🖐🏾',
  hand_splayed_tone5: '🖐🏿',
  handbag: '👜',
  handball: '🤾',
  handball_tone1: '🤾🏻',
  handball_tone2: '🤾🏼',
  handball_tone3: '🤾🏽',
  handball_tone4: '🤾🏾',
  handball_tone5: '🤾🏿',
  handshake: '🤝',
  hash: '#⃣',
  hatched_chick: '🐥',
  hatching_chick: '🐣',
  head_bandage: '🤕',
  headphones: '🎧',
  hear_no_evil: '🙉',
  heart: '❤',
  heart_decoration: '💟',
  heart_exclamation: '❣',
  heart_eyes: '😍',
  heart_eyes_cat: '😻',
  heartbeat: '💓',
  heartpulse: '💗',
  hearts: '♥',
  heavy_check_mark: '✔',
  heavy_division_sign: '➗',
  heavy_dollar_sign: '💲',
  heavy_minus_sign: '➖',
  heavy_multiplication_x: '✖',
  heavy_plus_sign: '➕',
  helicopter: '🚁',
  helmet_with_cross: '⛑',
  herb: '🌿',
  hibiscus: '🌺',
  high_brightness: '🔆',
  high_heel: '👠',
  hockey: '🏒',
  hole: '🕳',
  homes: '🏘',
  honey_pot: '🍯',
  horse: '🐴',
  horse_racing: '🏇',
  horse_racing_tone1: '🏇🏻',
  horse_racing_tone2: '🏇🏼',
  horse_racing_tone3: '🏇🏽',
  horse_racing_tone4: '🏇🏾',
  horse_racing_tone5: '🏇🏿',
  hospital: '🏥',
  hot_pepper: '🌶',
  hotdog: '🌭',
  hotel: '🏨',
  hotsprings: '♨',
  hourglass: '⌛',
  hourglass_flowing_sand: '⏳',
  house: '🏠',
  house_abandoned: '🏚',
  house_with_garden: '🏡',
  hugging: '🤗',
  hushed: '😯',
  ice_cream: '🍨',
  ice_skate: '⛸',
  icecream: '🍦',
  id: '🆔',
  ideograph_advantage: '🉐',
  imp: '👿',
  inbox_tray: '📥',
  incoming_envelope: '📨',
  information_desk_person: '💁',
  information_desk_person_tone1: '💁🏻',
  information_desk_person_tone2: '💁🏼',
  information_desk_person_tone3: '💁🏽',
  information_desk_person_tone4: '💁🏾',
  information_desk_person_tone5: '💁🏿',
  information_source: 'ℹ',
  innocent: '😇',
  interrobang: '⁉',
  iphone: '📱',
  island: '🏝',
  izakaya_lantern: '🏮',
  jack_o_lantern: '🎃',
  japan: '🗾',
  japanese_castle: '🏯',
  japanese_goblin: '👺',
  japanese_ogre: '👹',
  jeans: '👖',
  joy: '😂',
  joy_cat: '😹',
  joystick: '🕹',
  juggling: '🤹',
  juggling_tone1: '🤹🏻',
  juggling_tone2: '🤹🏼',
  juggling_tone3: '🤹🏽',
  juggling_tone4: '🤹🏾',
  juggling_tone5: '🤹🏿',
  kaaba: '🕋',
  key: '🔑',
  key2: '🗝',
  keyboard: '⌨',
  kimono: '👘',
  kiss: '💋',
  kiss_mm: '👨‍❤️‍💋‍👨',
  kiss_ww: '👩‍❤️‍💋‍👩',
  kissing: '😗',
  kissing_cat: '😽',
  kissing_closed_eyes: '😚',
  kissing_heart: '😘',
  kissing_smiling_eyes: '😙',
  kiwi: '🥝',
  knife: '🔪',
  koala: '🐨',
  koko: '🈁',
  label: '🏷',
  blue_circle: '🔵',
  large_blue_diamond: '🔷',
  large_orange_diamond: '🔶',
  last_quarter_moon: '🌗',
  last_quarter_moon_with_face: '🌜',
  laughing: '😆',
  leaves: '🍃',
  ledger: '📒',
  left_facing_fist: '🤛',
  left_facing_fist_tone1: '🤛🏻',
  left_facing_fist_tone2: '🤛🏼',
  left_facing_fist_tone3: '🤛🏽',
  left_facing_fist_tone4: '🤛🏾',
  left_facing_fist_tone5: '🤛🏿',
  left_luggage: '🛅',
  left_right_arrow: '↔',
  leftwards_arrow_with_hook: '↩',
  lemon: '🍋',
  leo: '♌',
  leopard: '🐆',
  level_slider: '🎚',
  levitate: '🕴',
  libra: '♎',
  lifter: '🏋',
  lifter_tone1: '🏋🏻',
  lifter_tone2: '🏋🏼',
  lifter_tone3: '🏋🏽',
  lifter_tone4: '🏋🏾',
  lifter_tone5: '🏋🏿',
  light_rail: '🚈',
  link: '🔗',
  lion_face: '🦁',
  lips: '👄',
  lipstick: '💄',
  lizard: '🦎',
  lock: '🔒',
  lock_with_ink_pen: '🔏',
  lollipop: '🍭',
  loop: '➿',
  loud_sound: '🔊',
  loudspeaker: '📢',
  love_hotel: '🏩',
  love_letter: '💌',
  low_brightness: '🔅',
  lying_face: '🤥',
  m: 'Ⓜ',
  mag: '🔍',
  mag_right: '🔎',
  mahjong: '🀄',
  mailbox: '📫',
  mailbox_closed: '📪',
  mailbox_with_mail: '📬',
  mailbox_with_no_mail: '📭',
  man: '👨',
  man_dancing: '🕺',
  man_dancing_tone1: '🕺🏻',
  man_dancing_tone2: '🕺🏼',
  man_dancing_tone3: '🕺🏽',
  man_dancing_tone4: '🕺🏾',
  man_dancing_tone5: '🕺🏿',
  man_in_tuxedo: '🤵',
  man_in_tuxedo_tone1: '🤵🏻',
  man_in_tuxedo_tone2: '🤵🏼',
  man_in_tuxedo_tone3: '🤵🏽',
  man_in_tuxedo_tone4: '🤵🏾',
  man_in_tuxedo_tone5: '🤵🏿',
  man_tone1: '👨🏻',
  man_tone2: '👨🏼',
  man_tone3: '👨🏽',
  man_tone4: '👨🏾',
  man_tone5: '👨🏿',
  man_with_gua_pi_mao: '👲',
  man_with_gua_pi_mao_tone1: '👲🏻',
  man_with_gua_pi_mao_tone2: '👲🏼',
  man_with_gua_pi_mao_tone3: '👲🏽',
  man_with_gua_pi_mao_tone4: '👲🏾',
  man_with_gua_pi_mao_tone5: '👲🏿',
  man_with_turban: '👳',
  man_with_turban_tone1: '👳🏻',
  man_with_turban_tone2: '👳🏼',
  man_with_turban_tone3: '👳🏽',
  man_with_turban_tone4: '👳🏾',
  man_with_turban_tone5: '👳🏿',
  mans_shoe: '👞',
  map: '🗺',
  maple_leaf: '🍁',
  martial_arts_uniform: '🥋',
  mask: '😷',
  massage: '💆',
  massage_tone1: '💆🏻',
  massage_tone2: '💆🏼',
  massage_tone3: '💆🏽',
  massage_tone4: '💆🏾',
  massage_tone5: '💆🏿',
  meat_on_bone: '🍖',
  medal: '🏅',
  mega: '📣',
  melon: '🍈',
  menorah: '🕎',
  mens: '🚹',
  metal: '🤘',
  metal_tone1: '🤘🏻',
  metal_tone2: '🤘🏼',
  metal_tone3: '🤘🏽',
  metal_tone4: '🤘🏾',
  metal_tone5: '🤘🏿',
  metro: '🚇',
  microphone: '🎤',
  microphone2: '🎙',
  microscope: '🔬',
  middle_finger: '🖕',
  middle_finger_tone1: '🖕🏻',
  middle_finger_tone2: '🖕🏼',
  middle_finger_tone3: '🖕🏽',
  middle_finger_tone4: '🖕🏾',
  middle_finger_tone5: '🖕🏿',
  military_medal: '🎖',
  milk: '🥛',
  milky_way: '🌌',
  minibus: '🚐',
  minidisc: '💽',
  mobile_phone_off: '📴',
  money_mouth: '🤑',
  money_with_wings: '💸',
  moneybag: '💰',
  monkey: '🐒',
  monkey_face: '🐵',
  monorail: '🚝',
  mortar_board: '🎓',
  mosque: '🕌',
  motor_scooter: '🛵',
  motorboat: '🛥',
  motorcycle: '🏍',
  motorway: '🛣',
  mount_fuji: '🗻',
  mountain: '⛰',
  mountain_bicyclist: '🚵',
  mountain_bicyclist_tone1: '🚵🏻',
  mountain_bicyclist_tone2: '🚵🏼',
  mountain_bicyclist_tone3: '🚵🏽',
  mountain_bicyclist_tone4: '🚵🏾',
  mountain_bicyclist_tone5: '🚵🏿',
  mountain_cableway: '🚠',
  mountain_railway: '🚞',
  mountain_snow: '🏔',
  mouse: '🐭',
  mouse2: '🐁',
  mouse_three_button: '🖱',
  movie_camera: '🎥',
  moyai: '🗿',
  mrs_claus: '🤶',
  mrs_claus_tone1: '🤶🏻',
  mrs_claus_tone2: '🤶🏼',
  mrs_claus_tone3: '🤶🏽',
  mrs_claus_tone4: '🤶🏾',
  mrs_claus_tone5: '🤶🏿',
  muscle: '💪',
  muscle_tone1: '💪🏻',
  muscle_tone2: '💪🏼',
  muscle_tone3: '💪🏽',
  muscle_tone4: '💪🏾',
  muscle_tone5: '💪🏿',
  mushroom: '🍄',
  musical_keyboard: '🎹',
  musical_note: '🎵',
  musical_score: '🎼',
  mute: '🔇',
  nail_care: '💅',
  nail_care_tone1: '💅🏻',
  nail_care_tone2: '💅🏼',
  nail_care_tone3: '💅🏽',
  nail_care_tone4: '💅🏾',
  nail_care_tone5: '💅🏿',
  name_badge: '📛',
  nauseated_face: '🤢',
  necktie: '👔',
  negative_squared_cross_mark: '❎',
  nerd: '🤓',
  neutral_face: '😐',
  new: '🆕',
  new_moon: '🌑',
  new_moon_with_face: '🌚',
  newspaper: '📰',
  newspaper2: '🗞',
  ng: '🆖',
  night_with_stars: '🌃',
  nine: '9️⃣',
  no_bell: '🔕',
  no_bicycles: '🚳',
  no_entry: '⛔',
  no_entry_sign: '🚫',
  no_good: '🙅',
  no_good_tone1: '🙅🏻',
  no_good_tone2: '🙅🏼',
  no_good_tone3: '🙅🏽',
  no_good_tone4: '🙅🏾',
  no_good_tone5: '🙅🏿',
  no_mobile_phones: '📵',
  no_mouth: '😶',
  no_pedestrians: '🚷',
  no_smoking: '🚭',
  'non-potable_water': '🚱',
  nose: '👃',
  nose_tone1: '👃🏻',
  nose_tone2: '👃🏼',
  nose_tone3: '👃🏽',
  nose_tone4: '👃🏾',
  nose_tone5: '👃🏿',
  notebook: '📓',
  notebook_with_decorative_cover: '📔',
  notepad_spiral: '🗒',
  notes: '🎶',
  nut_and_bolt: '🔩',
  o: '⭕',
  o2: '🅾',
  ocean: '🌊',
  octagonal_sign: '🛑',
  octopus: '🐙',
  oden: '🍢',
  office: '🏢',
  oil: '🛢',
  ok: '🆗',
  ok_hand: '👌',
  ok_hand_tone1: '👌🏻',
  ok_hand_tone2: '👌🏼',
  ok_hand_tone3: '👌🏽',
  ok_hand_tone4: '👌🏾',
  ok_hand_tone5: '👌🏿',
  ok_woman: '🙆',
  ok_woman_tone1: '🙆🏻',
  ok_woman_tone2: '🙆🏼',
  ok_woman_tone3: '🙆🏽',
  ok_woman_tone4: '🙆🏾',
  ok_woman_tone5: '🙆🏿',
  older_man: '👴',
  older_man_tone1: '👴🏻',
  older_man_tone2: '👴🏼',
  older_man_tone3: '👴🏽',
  older_man_tone4: '👴🏾',
  older_man_tone5: '👴🏿',
  older_woman: '👵',
  older_woman_tone1: '👵🏻',
  older_woman_tone2: '👵🏼',
  older_woman_tone3: '👵🏽',
  older_woman_tone4: '👵🏾',
  older_woman_tone5: '👵🏿',
  om_symbol: '🕉',
  on: '🔛',
  oncoming_automobile: '🚘',
  oncoming_bus: '🚍',
  oncoming_police_car: '🚔',
  oncoming_taxi: '🚖',
  one: '1️⃣',
  open_file_folder: '📂',
  open_hands: '👐',
  open_hands_tone1: '👐🏻',
  open_hands_tone2: '👐🏼',
  open_hands_tone3: '👐🏽',
  open_hands_tone4: '👐🏾',
  open_hands_tone5: '👐🏿',
  open_mouth: '😮',
  ophiuchus: '⛎',
  orange_book: '📙',
  orthodox_cross: '☦',
  outbox_tray: '📤',
  owl: '🦉',
  ox: '🐂',
  package: '📦',
  page_facing_up: '📄',
  page_with_curl: '📃',
  pager: '📟',
  paintbrush: '🖌',
  palm_tree: '🌴',
  pancakes: '🥞',
  panda_face: '🐼',
  paperclip: '📎',
  paperclips: '🖇',
  park: '🏞',
  parking: '🅿',
  part_alternation_mark: '〽',
  partly_sunny: '⛅',
  passport_control: '🛂',
  pause_button: '⏸',
  peace: '☮',
  peach: '🍑',
  peanuts: '🥜',
  pear: '🍐',
  pen_ballpoint: '🖊',
  pen_fountain: '🖋',
  pencil: '📝',
  pencil2: '✏',
  penguin: '🐧',
  pensive: '😔',
  performing_arts: '🎭',
  persevere: '😣',
  person_frowning: '🙍',
  person_frowning_tone1: '🙍🏻',
  person_frowning_tone2: '🙍🏼',
  person_frowning_tone3: '🙍🏽',
  person_frowning_tone4: '🙍🏾',
  person_frowning_tone5: '🙍🏿',
  person_with_blond_hair: '👱',
  person_with_blond_hair_tone1: '👱🏻',
  person_with_blond_hair_tone2: '👱🏼',
  person_with_blond_hair_tone3: '👱🏽',
  person_with_blond_hair_tone4: '👱🏾',
  person_with_blond_hair_tone5: '👱🏿',
  person_with_pouting_face: '🙎',
  person_with_pouting_face_tone1: '🙎🏻',
  person_with_pouting_face_tone2: '🙎🏼',
  person_with_pouting_face_tone3: '🙎🏽',
  person_with_pouting_face_tone4: '🙎🏾',
  person_with_pouting_face_tone5: '🙎🏿',
  pick: '⛏',
  pig: '🐷',
  pig2: '🐖',
  pig_nose: '🐽',
  pill: '💊',
  pineapple: '🍍',
  ping_pong: '🏓',
  pisces: '♓',
  pizza: '🍕',
  place_of_worship: '🛐',
  play_pause: '⏯',
  point_down: '👇',
  point_down_tone1: '👇🏻',
  point_down_tone2: '👇🏼',
  point_down_tone3: '👇🏽',
  point_down_tone4: '👇🏾',
  point_down_tone5: '👇🏿',
  point_left: '👈',
  point_left_tone1: '👈🏻',
  point_left_tone2: '👈🏼',
  point_left_tone3: '👈🏽',
  point_left_tone4: '👈🏾',
  point_left_tone5: '👈🏿',
  point_right: '👉',
  point_right_tone1: '👉🏻',
  point_right_tone2: '👉🏼',
  point_right_tone3: '👉🏽',
  point_right_tone4: '👉🏾',
  point_right_tone5: '👉🏿',
  point_up: '☝',
  point_up_2: '👆',
  point_up_2_tone1: '👆🏻',
  point_up_2_tone2: '👆🏼',
  point_up_2_tone3: '👆🏽',
  point_up_2_tone4: '👆🏾',
  point_up_2_tone5: '👆🏿',
  point_up_tone1: '☝🏻',
  point_up_tone2: '☝🏼',
  point_up_tone3: '☝🏽',
  point_up_tone4: '☝🏾',
  point_up_tone5: '☝🏿',
  police_car: '🚓',
  poodle: '🐩',
  poop: '💩',
  popcorn: '🍿',
  post_office: '🏣',
  postal_horn: '📯',
  postbox: '📮',
  potable_water: '🚰',
  potato: '🥔',
  pouch: '👝',
  poultry_leg: '🍗',
  pound: '💷',
  pouting_cat: '😾',
  pray: '🙏',
  pray_tone1: '🙏🏻',
  pray_tone2: '🙏🏼',
  pray_tone3: '🙏🏽',
  pray_tone4: '🙏🏾',
  pray_tone5: '🙏🏿',
  prayer_beads: '📿',
  pregnant_woman: '🤰',
  pregnant_woman_tone1: '🤰🏻',
  pregnant_woman_tone2: '🤰🏼',
  pregnant_woman_tone3: '🤰🏽',
  pregnant_woman_tone4: '🤰🏾',
  pregnant_woman_tone5: '🤰🏿',
  prince: '🤴',
  prince_tone1: '🤴🏻',
  prince_tone2: '🤴🏼',
  prince_tone3: '🤴🏽',
  prince_tone4: '🤴🏾',
  prince_tone5: '🤴🏿',
  princess: '👸',
  princess_tone1: '👸🏻',
  princess_tone2: '👸🏼',
  princess_tone3: '👸🏽',
  princess_tone4: '👸🏾',
  princess_tone5: '👸🏿',
  printer: '🖨',
  projector: '📽',
  punch: '👊',
  punch_tone1: '👊🏻',
  punch_tone2: '👊🏼',
  punch_tone3: '👊🏽',
  punch_tone4: '👊🏾',
  punch_tone5: '👊🏿',
  purple_heart: '💜',
  purse: '👛',
  pushpin: '📌',
  put_litter_in_its_place: '🚮',
  question: '❓',
  rabbit: '🐰',
  rabbit2: '🐇',
  race_car: '🏎',
  racehorse: '🐎',
  radio: '📻',
  radio_button: '🔘',
  radioactive: '☢',
  rage: '😡',
  railway_car: '🚃',
  railway_track: '🛤',
  rainbow: '🌈',
  raised_back_of_hand: '🤚',
  raised_back_of_hand_tone1: '🤚🏻',
  raised_back_of_hand_tone2: '🤚🏼',
  raised_back_of_hand_tone3: '🤚🏽',
  raised_back_of_hand_tone4: '🤚🏾',
  raised_back_of_hand_tone5: '🤚🏿',
  raised_hand: '✋',
  raised_hand_tone1: '✋🏻',
  raised_hand_tone2: '✋🏼',
  raised_hand_tone3: '✋🏽',
  raised_hand_tone4: '✋🏾',
  raised_hand_tone5: '✋🏿',
  raised_hands: '🙌',
  raised_hands_tone1: '🙌🏻',
  raised_hands_tone2: '🙌🏼',
  raised_hands_tone3: '🙌🏽',
  raised_hands_tone4: '🙌🏾',
  raised_hands_tone5: '🙌🏿',
  raising_hand: '🙋',
  raising_hand_tone1: '🙋🏻',
  raising_hand_tone2: '🙋🏼',
  raising_hand_tone3: '🙋🏽',
  raising_hand_tone4: '🙋🏾',
  raising_hand_tone5: '🙋🏿',
  ram: '🐏',
  ramen: '🍜',
  rat: '🐀',
  record_button: '⏺',
  recycle: '♻',
  red_car: '🚗',
  red_circle: '🔴',
  registered: '®',
  relaxed: '☺',
  relieved: '😌',
  reminder_ribbon: '🎗',
  repeat: '🔁',
  repeat_one: '🔂',
  restroom: '🚻',
  revolving_hearts: '💞',
  rewind: '⏪',
  rhino: '🦏',
  ribbon: '🎀',
  rice: '🍚',
  rice_ball: '🍙',
  rice_cracker: '🍘',
  rice_scene: '🎑',
  right_facing_fist: '🤜',
  right_facing_fist_tone1: '🤜🏻',
  right_facing_fist_tone2: '🤜🏼',
  right_facing_fist_tone3: '🤜🏽',
  right_facing_fist_tone4: '🤜🏾',
  right_facing_fist_tone5: '🤜🏿',
  ring: '💍',
  robot: '🤖',
  rocket: '🚀',
  rofl: '🤣',
  roller_coaster: '🎢',
  rolling_eyes: '🙄',
  rooster: '🐓',
  rose: '🌹',
  rosette: '🏵',
  rotating_light: '🚨',
  round_pushpin: '📍',
  rowboat: '🚣',
  rowboat_tone1: '🚣🏻',
  rowboat_tone2: '🚣🏼',
  rowboat_tone3: '🚣🏽',
  rowboat_tone4: '🚣🏾',
  rowboat_tone5: '🚣🏿',
  rugby_football: '🏉',
  runner: '🏃',
  runner_tone1: '🏃🏻',
  runner_tone2: '🏃🏼',
  runner_tone3: '🏃🏽',
  runner_tone4: '🏃🏾',
  runner_tone5: '🏃🏿',
  running_shirt_with_sash: '🎽',
  sa: '🈂',
  sagittarius: '♐',
  sailboat: '⛵',
  sake: '🍶',
  salad: '🥗',
  sandal: '👡',
  santa: '🎅',
  santa_tone1: '🎅🏻',
  santa_tone2: '🎅🏼',
  santa_tone3: '🎅🏽',
  santa_tone4: '🎅🏾',
  santa_tone5: '🎅🏿',
  satellite: '📡',
  satellite_orbital: '🛰',
  saxophone: '🎷',
  scales: '⚖',
  school: '🏫',
  school_satchel: '🎒',
  scissors: '✂',
  scooter: '🛴',
  scorpion: '🦂',
  scorpius: '♏',
  scream: '😱',
  scream_cat: '🙀',
  scroll: '📜',
  seat: '💺',
  second_place: '🥈',
  secret: '㊙',
  see_no_evil: '🙈',
  seedling: '🌱',
  selfie: '🤳',
  selfie_tone1: '🤳🏻',
  selfie_tone2: '🤳🏼',
  selfie_tone3: '🤳🏽',
  selfie_tone4: '🤳🏾',
  selfie_tone5: '🤳🏿',
  seven: '7️⃣',
  shallow_pan_of_food: '🥘',
  shamrock: '☘',
  shark: '🦈',
  shaved_ice: '🍧',
  sheep: '🐑',
  shell: '🐚',
  shield: '🛡',
  shinto_shrine: '⛩',
  ship: '🚢',
  shirt: '👕',
  shopping_bags: '🛍',
  shopping_cart: '🛒',
  shower: '🚿',
  shrimp: '🦐',
  shrug: '🤷',
  shrug_tone1: '🤷🏻',
  shrug_tone2: '🤷🏼',
  shrug_tone3: '🤷🏽',
  shrug_tone4: '🤷🏾',
  shrug_tone5: '🤷🏿',
  signal_strength: '📶',
  six: '6️⃣',
  six_pointed_star: '🔯',
  ski: '🎿',
  skier: '⛷',
  skull: '💀',
  skull_crossbones: '☠',
  sleeping: '😴',
  sleeping_accommodation: '🛌',
  sleepy: '😪',
  slight_frown: '🙁',
  slight_smile: '🙂',
  slot_machine: '🎰',
  small_blue_diamond: '🔹',
  small_orange_diamond: '🔸',
  small_red_triangle: '🔺',
  small_red_triangle_down: '🔻',
  smile: '😄',
  smile_cat: '😸',
  smiley: '😃',
  smiley_cat: '😺',
  smiling_imp: '😈',
  smirk: '😏',
  smirk_cat: '😼',
  smoking: '🚬',
  snail: '🐌',
  snake: '🐍',
  sneezing_face: '🤧',
  snowboarder: '🏂',
  snowflake: '❄',
  snowman: '⛄',
  snowman2: '☃',
  sob: '😭',
  soccer: '⚽',
  soon: '🔜',
  sos: '🆘',
  sound: '🔉',
  space_invader: '👾',
  spades: '♠',
  spaghetti: '🍝',
  sparkle: '❇',
  sparkler: '🎇',
  sparkles: '✨',
  sparkling_heart: '💖',
  speak_no_evil: '🙊',
  speaker: '🔈',
  speaking_head: '🗣',
  speech_balloon: '💬',
  speech_left: '🗨',
  speedboat: '🚤',
  spider: '🕷',
  spider_web: '🕸',
  spoon: '🥄',
  spy: '🕵',
  spy_tone1: '🕵🏻',
  spy_tone2: '🕵🏼',
  spy_tone3: '🕵🏽',
  spy_tone4: '🕵🏾',
  spy_tone5: '🕵🏿',
  squid: '🦑',
  stadium: '🏟',
  star: '⭐',
  star2: '🌟',
  star_and_crescent: '☪',
  star_of_david: '✡',
  stars: '🌠',
  station: '🚉',
  statue_of_liberty: '🗽',
  steam_locomotive: '🚂',
  stew: '🍲',
  stop_button: '⏹',
  stopwatch: '⏱',
  straight_ruler: '📏',
  strawberry: '🍓',
  stuck_out_tongue: '😛',
  stuck_out_tongue_closed_eyes: '😝',
  stuck_out_tongue_winking_eye: '😜',
  stuffed_flatbread: '🥙',
  sun_with_face: '🌞',
  sunflower: '🌻',
  sunglasses: '😎',
  sunny: '☀',
  sunrise: '🌅',
  sunrise_over_mountains: '🌄',
  surfer: '🏄',
  surfer_tone1: '🏄🏻',
  surfer_tone2: '🏄🏼',
  surfer_tone3: '🏄🏽',
  surfer_tone4: '🏄🏾',
  surfer_tone5: '🏄🏿',
  sushi: '🍣',
  suspension_railway: '🚟',
  sweat: '😓',
  sweat_drops: '💦',
  sweat_smile: '😅',
  sweet_potato: '🍠',
  swimmer: '🏊',
  swimmer_tone1: '🏊🏻',
  swimmer_tone2: '🏊🏼',
  swimmer_tone3: '🏊🏽',
  swimmer_tone4: '🏊🏾',
  swimmer_tone5: '🏊🏿',
  symbols: '🔣',
  synagogue: '🕍',
  syringe: '💉',
  taco: '🌮',
  tada: '🎉',
  tanabata_tree: '🎋',
  tangerine: '🍊',
  taurus: '♉',
  taxi: '🚕',
  tea: '🍵',
  telephone: '☎',
  telephone_receiver: '📞',
  telescope: '🔭',
  ten: '🔟',
  tennis: '🎾',
  tent: '⛺',
  thermometer: '🌡',
  thermometer_face: '🤒',
  thinking: '🤔',
  third_place: '🥉',
  thought_balloon: '💭',
  three: '3️⃣',
  thumbsdown: '👎',
  thumbsdown_tone1: '👎🏻',
  thumbsdown_tone2: '👎🏼',
  thumbsdown_tone3: '👎🏽',
  thumbsdown_tone4: '👎🏾',
  thumbsdown_tone5: '👎🏿',
  thumbsup: '👍',
  thumbsup_tone1: '👍🏻',
  thumbsup_tone2: '👍🏼',
  thumbsup_tone3: '👍🏽',
  thumbsup_tone4: '👍🏾',
  thumbsup_tone5: '👍🏿',
  thunder_cloud_rain: '⛈',
  ticket: '🎫',
  tickets: '🎟',
  tiger: '🐯',
  tiger2: '🐅',
  timer: '⏲',
  tired_face: '😫',
  tm: '™',
  toilet: '🚽',
  tokyo_tower: '🗼',
  tomato: '🍅',
  tone1: '🏻',
  tone2: '🏼',
  tone3: '🏽',
  tone4: '🏾',
  tone5: '🏿',
  tongue: '👅',
  tools: '🛠',
  top: '🔝',
  tophat: '🎩',
  track_next: '⏭',
  track_previous: '⏮',
  trackball: '🖲',
  tractor: '🚜',
  traffic_light: '🚥',
  train: '🚋',
  train2: '🚆',
  tram: '🚊',
  triangular_flag_on_post: '🚩',
  triangular_ruler: '📐',
  trident: '🔱',
  triumph: '😤',
  trolleybus: '🚎',
  trophy: '🏆',
  tropical_drink: '🍹',
  tropical_fish: '🐠',
  truck: '🚚',
  trumpet: '🎺',
  tulip: '🌷',
  tumbler_glass: '🥃',
  turkey: '🦃',
  turtle: '🐢',
  tv: '📺',
  twisted_rightwards_arrows: '🔀',
  two: '2️⃣',
  two_hearts: '💕',
  two_men_holding_hands: '👬',
  two_women_holding_hands: '👭',
  u5272: '🈹',
  u5408: '🈴',
  u55b6: '🈺',
  u6307: '🈯',
  u6708: '🈷',
  u6709: '🈶',
  u6e80: '🈵',
  u7121: '🈚',
  u7533: '🈸',
  u7981: '🈲',
  u7a7a: '🈳',
  umbrella: '☔',
  umbrella2: '☂',
  unamused: '😒',
  underage: '🔞',
  unicorn: '🦄',
  unlock: '🔓',
  up: '🆙',
  upside_down: '🙃',
  urn: '⚱',
  v: '✌',
  v_tone1: '✌🏻',
  v_tone2: '✌🏼',
  v_tone3: '✌🏽',
  v_tone4: '✌🏾',
  v_tone5: '✌🏿',
  vertical_traffic_light: '🚦',
  vhs: '📼',
  vibration_mode: '📳',
  video_camera: '📹',
  video_game: '🎮',
  violin: '🎻',
  virgo: '♍',
  volcano: '🌋',
  volleyball: '🏐',
  vs: '🆚',
  vulcan: '🖖',
  vulcan_tone1: '🖖🏻',
  vulcan_tone2: '🖖🏼',
  vulcan_tone3: '🖖🏽',
  vulcan_tone4: '🖖🏾',
  vulcan_tone5: '🖖🏿',
  walking: '🚶',
  walking_tone1: '🚶🏻',
  walking_tone2: '🚶🏼',
  walking_tone3: '🚶🏽',
  walking_tone4: '🚶🏾',
  walking_tone5: '🚶🏿',
  waning_crescent_moon: '🌘',
  waning_gibbous_moon: '🌖',
  warning: '⚠',
  wastebasket: '🗑',
  watch: '⌚',
  water_buffalo: '🐃',
  water_polo: '🤽',
  water_polo_tone1: '🤽🏻',
  water_polo_tone2: '🤽🏼',
  water_polo_tone3: '🤽🏽',
  water_polo_tone4: '🤽🏾',
  water_polo_tone5: '🤽🏿',
  watermelon: '🍉',
  wave: '👋',
  wave_tone1: '👋🏻',
  wave_tone2: '👋🏼',
  wave_tone3: '👋🏽',
  wave_tone4: '👋🏾',
  wave_tone5: '👋🏿',
  wavy_dash: '〰',
  waxing_crescent_moon: '🌒',
  waxing_gibbous_moon: '🌔',
  wc: '🚾',
  weary: '😩',
  wedding: '💒',
  whale: '🐳',
  whale2: '🐋',
  wheel_of_dharma: '☸',
  wheelchair: '♿',
  white_check_mark: '✅',
  white_circle: '⚪',
  white_flower: '💮',
  white_large_square: '⬜',
  white_medium_small_square: '◽',
  white_medium_square: '◻',
  white_small_square: '▫',
  white_square_button: '🔳',
  white_sun_cloud: '🌥',
  white_sun_rain_cloud: '🌦',
  white_sun_small_cloud: '🌤',
  wilted_rose: '🥀',
  wind_blowing_face: '🌬',
  wind_chime: '🎐',
  wine_glass: '🍷',
  wink: '😉',
  wolf: '🐺',
  woman: '👩',
  woman_tone1: '👩🏻',
  woman_tone2: '👩🏼',
  woman_tone3: '👩🏽',
  woman_tone4: '👩🏾',
  woman_tone5: '👩🏿',
  womans_clothes: '👚',
  womans_hat: '👒',
  womens: '🚺',
  worried: '😟',
  wrench: '🔧',
  wrestlers: '🤼',
  writing_hand: '✍',
  writing_hand_tone1: '✍🏻',
  writing_hand_tone2: '✍🏼',
  writing_hand_tone3: '✍🏽',
  writing_hand_tone4: '✍🏾',
  writing_hand_tone5: '✍🏿',
  x: '❌',
  yellow_heart: '💛',
  yen: '💴',
  yin_yang: '☯',
  yum: '😋',
  zap: '⚡',
  zero: '0️⃣',
  zipper_mouth: '🤐',
  zzz: '💤',
  frowning_face: '☹️',
  blonde_woman: '👱‍♀️',
  woman_with_turban: '👳‍♀️',
  policewoman: '👮‍♀️',
  construction_worker_woman: '👷‍♀️',
  guardswoman: '💂‍♀️',
  female_detective: '🕵️‍♀️',
  male_detective: '🕵️',
  woman_health_worker: '👩‍⚕️',
  man_health_worker: '👨‍⚕️',
  woman_farmer: '👩‍🌾',
  man_farmer: '👨‍🌾',
  woman_cook: '👩‍🍳',
  man_cook: '👨‍🍳',
  woman_student: '👩‍🎓',
  man_student: '👨‍🎓',
  woman_singer: '👩‍🎤',
  man_singer: '👨‍🎤',
  woman_teacher: '👩‍🏫',
  man_teacher: '👨‍🏫',
  woman_factory_worker: '👩‍🏭',
  man_factory_worker: '👨‍🏭',
  woman_technologist: '👩‍💻',
  man_technologist: '👨‍💻',
  woman_office_worker: '👩‍💼',
  man_office_worker: '👨‍💼',
  woman_mechanic: '👩‍🔧',
  man_mechanic: '👨‍🔧',
  woman_scientist: '👩‍🔬',
  man_scientist: '👨‍🔬',
  woman_artist: '👩‍🎨',
  man_artist: '👨‍🎨',
  woman_firefighter: '👩‍🚒',
  man_firefighter: '👨‍🚒',
  woman_pilot: '👩‍✈️',
  man_pilot: '👨‍✈️',
  woman_astronaut: '👩‍🚀',
  man_astronaut: '👨‍🚀',
  woman_judge: '👩‍⚖️',
  man_judge: '👨‍⚖️',
  bowing_woman: '🙇‍♀️',
  tipping_hand_man: '💁‍♂️',
  no_good_man: '🙅‍♂️',
  ok_man: '🙆‍♂️',
  raising_hand_man: '🙋‍♂️',
  woman_facepalming: '🤦‍♀️',
  man_facepalming: '🤦‍♂️',
  woman_shrugging: '🤷‍♀️',
  man_shrugging: '🤷‍♂️',
  pouting_man: '🙎‍♂️',
  frowning_man: '🙍‍♂️',
  haircut_man: '💇‍♂️',
  massage_man: '💆‍♂️',
  dancing_men: '👯‍♂️',
  walking_woman: '🚶‍♀️',
  running_woman: '🏃‍♀️',
  family_woman_boy: '👩‍👦',
  family_woman_girl: '👩‍👧',
  family_woman_girl_boy: '👩‍👧‍👦',
  family_woman_boy_boy: '👩‍👦‍👦',
  family_woman_girl_girl: '👩‍👧‍👧',
  family_man_boy: '👨‍👦',
  family_man_girl: '👨‍👧',
  family_man_girl_boy: '👨‍👧‍👦',
  family_man_boy_boy: '👨‍👦‍👦',
  family_man_girl_girl: '👨‍👧‍👧',
  open_umbrella: '☂️',
  snowman_with_snow: '☃️',
  weight_lifting_woman: '🏋️‍♀️',
  weight_lifting_man: '🏋️',
  women_wrestling: '🤼‍♀️',
  men_wrestling: '🤼‍♂️',
  woman_cartwheeling: '🤸‍♀️',
  man_cartwheeling: '🤸‍♂️',
  basketball_woman: '⛹️‍♀️',
  basketball_man: '⛹️',
  woman_playing_handball: '🤾‍♀️',
  man_playing_handball: '🤾‍♂️',
  golfing_woman: '🏌️‍♀️',
  golfing_man: '🏌️',
  surfing_woman: '🏄‍♀️',
  swimming_woman: '🏊‍♀️',
  woman_playing_water_polo: '🤽‍♀️',
  man_playing_water_polo: '🤽‍♂️',
  rowing_woman: '🚣‍♀️',
  biking_woman: '🚴‍♀️',
  mountain_biking_woman: '🚵‍♀️',
  woman_juggling: '🤹‍♀️',
  man_juggling: '🤹‍♂️',
  boat: '⛵️',
  phone: '☎️',
  balance_scale: '⚖️',
  heavy_heart_exclamation: '❣️',
  fleur_de_lis: '⚜️',
  white_flag: '🏳️',
  orange_heart: '🧡',
  infinity: '♾️',
  digit_zero: '0️⃣',
  digit_one: '1️⃣',
  digit_two: '2️⃣',
  digit_three: '3️⃣',
  digit_four: '4️⃣',
  digit_five: '5️⃣',
  digit_six: '6️⃣',
  digit_seven: '7️⃣',
  digit_eight: '8️⃣',
  digit_nine: '9️⃣',
  pound_symbol: '#️⃣',
  asterisk_symbol: '*️⃣',
  female_sign: '♀',
  male_sign: '♂',
  medical_symbol: '⚕',
  softball: '🥎',
  lacrosse: '🥍',
  flying_disc: '🥏',
  skateboard: '🛹',
  curling_stone: '🥌',
  sled: '🛷',
  snowboarder_tone1: '🏂🏻',
  snowboarder_tone2: '🏂🏼',
  snowboarder_tone3: '🏂🏽',
  snowboarder_tone4: '🏂🏾',
  snowboarder_tone5: '🏂🏿',
  woman_lifting_weights: '🏋️‍♀️',
  woman_lifting_weights_tone1: '🏋🏻‍♀️',
  woman_lifting_weights_tone2: '🏋🏼‍♀️',
  woman_lifting_weights_tone3: '🏋🏽‍♀️',
  woman_lifting_weights_tone4: '🏋🏾‍♀️',
  woman_lifting_weights_tone5: '🏋🏿‍♀️',
  man_lifting_weights: '🏋️‍♂️',
  man_lifting_weights_tone1: '🏋🏻‍♂️',
  man_lifting_weights_tone2: '🏋🏼‍♂️',
  man_lifting_weights_tone3: '🏋🏽‍♂️',
  man_lifting_weights_tone4: '🏋🏾‍♂️',
  man_lifting_weights_tone5: '🏋🏿‍♂️',
  woman_cartwheeling_tone1: '🤸🏻‍♀️',
  woman_cartwheeling_tone2: '🤸🏼‍♀️',
  woman_cartwheeling_tone3: '🤸🏽‍♀️',
  woman_cartwheeling_tone4: '🤸🏾‍♀️',
  woman_cartwheeling_tone5: '🤸🏿‍♀️',
  man_cartwheeling_tone1: '🤸🏻‍♂️',
  man_cartwheeling_tone2: '🤸🏼‍♂️',
  man_cartwheeling_tone3: '🤸🏽‍♂️',
  man_cartwheeling_tone4: '🤸🏾‍♂️',
  man_cartwheeling_tone5: '🤸🏿‍♂️',
  woman_bouncing_ball: '⛹️‍♀️',
  woman_bouncing_ball_tone1: '⛹🏻‍♀️',
  woman_bouncing_ball_tone2: '⛹🏼‍♀️',
  woman_bouncing_ball_tone3: '⛹🏽‍♀️',
  woman_bouncing_ball_tone4: '⛹🏾‍♀️',
  woman_bouncing_ball_tone5: '⛹🏿‍♀️',
  man_bouncing_ball: '⛹️‍♂️',
  man_bouncing_ball_tone1: '⛹🏻‍♂️',
  man_bouncing_ball_tone2: '⛹🏼‍♂️',
  man_bouncing_ball_tone3: '⛹🏽‍♂️',
  man_bouncing_ball_tone4: '⛹🏾‍♂️',
  man_bouncing_ball_tone5: '⛹🏿‍♂️',
  woman_playing_handball_tone1: '🤾🏻‍♀️',
  woman_playing_handball_tone2: '🤾🏼‍♀️',
  woman_playing_handball_tone3: '🤾🏽‍♀️',
  woman_playing_handball_tone4: '🤾🏾‍♀️',
  woman_playing_handball_tone5: '🤾🏿‍♀️',
  man_playing_handball_tone1: '🤾🏻‍♂️',
  man_playing_handball_tone2: '🤾🏼‍♂️',
  man_playing_handball_tone3: '🤾🏽‍♂️',
  man_playing_handball_tone4: '🤾🏾‍♂️',
  man_playing_handball_tone5: '🤾🏿‍♂️',
  person_golfing_tone1: '🏌🏻',
  person_golfing_tone2: '🏌🏼',
  person_golfing_tone3: '🏌🏽',
  person_golfing_tone4: '🏌🏾',
  person_golfing_tone5: '🏌🏿',
  woman_golfing: '🏌️‍♀️',
  woman_golfing_tone1: '🏌🏻‍♀️',
  woman_golfing_tone2: '🏌🏼‍♀️',
  woman_golfing_tone3: '🏌🏽‍♀️',
  woman_golfing_tone4: '🏌🏾‍♀️',
  woman_golfing_tone5: '🏌🏿‍♀️',
  man_golfing: '🏌️‍♂️',
  man_golfing_tone1: '🏌🏻‍♂️',
  man_golfing_tone2: '🏌🏼‍♂️',
  man_golfing_tone3: '🏌🏽‍♂️',
  man_golfing_tone4: '🏌🏾‍♂️',
  man_golfing_tone5: '🏌🏿‍♂️',
  person_in_lotus_position: '🧘',
  person_in_lotus_position_tone1: '🧘🏻',
  person_in_lotus_position_tone2: '🧘🏼',
  person_in_lotus_position_tone3: '🧘🏽',
  person_in_lotus_position_tone4: '🧘🏾',
  person_in_lotus_position_tone5: '🧘🏿',
  woman_in_lotus_position: '🧘‍♀️',
  woman_in_lotus_position_tone1: '🧘🏻‍♀️',
  woman_in_lotus_position_tone2: '🧘🏼‍♀️',
  woman_in_lotus_position_tone3: '🧘🏽‍♀️',
  woman_in_lotus_position_tone4: '🧘🏾‍♀️',
  woman_in_lotus_position_tone5: '🧘🏿‍♀️',
  man_in_lotus_position: '🧘‍♂️',
  man_in_lotus_position_tone1: '🧘🏻‍♂️',
  man_in_lotus_position_tone2: '🧘🏼‍♂️',
  man_in_lotus_position_tone3: '🧘🏽‍♂️',
  man_in_lotus_position_tone4: '🧘🏾‍♂️',
  man_in_lotus_position_tone5: '🧘🏿‍♂️',
  woman_surfing: '🏄‍♀️',
  woman_surfing_tone1: '🏄🏻‍♀️',
  woman_surfing_tone2: '🏄🏼‍♀️',
  woman_surfing_tone3: '🏄🏽‍♀️',
  woman_surfing_tone4: '🏄🏾‍♀️',
  woman_surfing_tone5: '🏄🏿‍♀️',
  man_surfing: '🏄‍♂️',
  man_surfing_tone1: '🏄🏻‍♂️',
  man_surfing_tone2: '🏄🏼‍♂️',
  man_surfing_tone3: '🏄🏽‍♂️',
  man_surfing_tone4: '🏄🏾‍♂️',
  man_surfing_tone5: '🏄🏿‍♂️',
  woman_swimming: '🏊‍♀️',
  woman_swimming_tone1: '🏊🏻‍♀️',
  woman_swimming_tone2: '🏊🏼‍♀️',
  woman_swimming_tone3: '🏊🏽‍♀️',
  woman_swimming_tone4: '🏊🏾‍♀️',
  woman_swimming_tone5: '🏊🏿‍♀️',
  man_swimming: '🏊‍♂️',
  man_swimming_tone1: '🏊🏻‍♂️',
  man_swimming_tone2: '🏊🏼‍♂️',
  man_swimming_tone3: '🏊🏽‍♂️',
  man_swimming_tone4: '🏊🏾‍♂️',
  man_swimming_tone5: '🏊🏿‍♂️',
  woman_playing_water_polo_tone1: '🤽🏻‍♀️',
  woman_playing_water_polo_tone2: '🤽🏼‍♀️',
  woman_playing_water_polo_tone3: '🤽🏽‍♀️',
  woman_playing_water_polo_tone4: '🤽🏾‍♀️',
  woman_playing_water_polo_tone5: '🤽🏿‍♀️',
  man_playing_water_polo_tone1: '🤽🏻‍♂️',
  man_playing_water_polo_tone2: '🤽🏼‍♂️',
  man_playing_water_polo_tone3: '🤽🏽‍♂️',
  man_playing_water_polo_tone4: '🤽🏾‍♂️',
  man_playing_water_polo_tone5: '🤽🏿‍♂️',
  woman_rowing_boat: '🚣‍♀️',
  woman_rowing_boat_tone1: '🚣🏻‍♀️',
  woman_rowing_boat_tone2: '🚣🏼‍♀️',
  woman_rowing_boat_tone3: '🚣🏽‍♀️',
  woman_rowing_boat_tone4: '🚣🏾‍♀️',
  woman_rowing_boat_tone5: '🚣🏿‍♀️',
  man_rowing_boat: '🚣‍♂️',
  man_rowing_boat_tone1: '🚣🏻‍♂️',
  man_rowing_boat_tone2: '🚣🏼‍♂️',
  man_rowing_boat_tone3: '🚣🏽‍♂️',
  man_rowing_boat_tone4: '🚣🏾‍♂️',
  man_rowing_boat_tone5: '🚣🏿‍♂️',
  person_climbing: '🧗',
  person_climbing_tone1: '🧗🏻',
  person_climbing_tone2: '🧗🏼',
  person_climbing_tone3: '🧗🏽',
  person_climbing_tone4: '🧗🏾',
  person_climbing_tone5: '🧗🏿',
  woman_climbing: '🧗‍♀️',
  woman_climbing_tone1: '🧗🏻‍♀️',
  woman_climbing_tone2: '🧗🏼‍♀️',
  woman_climbing_tone3: '🧗🏽‍♀️',
  woman_climbing_tone4: '🧗🏾‍♀️',
  woman_climbing_tone5: '🧗🏿‍♀️',
  man_climbing: '🧗‍♂️',
  man_climbing_tone1: '🧗🏻‍♂️',
  man_climbing_tone2: '🧗🏼‍♂️',
  man_climbing_tone3: '🧗🏽‍♂️',
  man_climbing_tone4: '🧗🏾‍♂️',
  man_climbing_tone5: '🧗🏿‍♂️',
  woman_mountain_biking: '🚵‍♀️',
  woman_mountain_biking_tone1: '🚵🏻‍♀️',
  woman_mountain_biking_tone2: '🚵🏼‍♀️',
  woman_mountain_biking_tone3: '🚵🏽‍♀️',
  woman_mountain_biking_tone4: '🚵🏾‍♀️',
  woman_mountain_biking_tone5: '🚵🏿‍♀️',
  man_mountain_biking: '🚵‍♂️',
  man_mountain_biking_tone1: '🚵🏻‍♂️',
  man_mountain_biking_tone2: '🚵🏼‍♂️',
  man_mountain_biking_tone3: '🚵🏽‍♂️',
  man_mountain_biking_tone4: '🚵🏾‍♂️',
  man_mountain_biking_tone5: '🚵🏿‍♂️',
  woman_biking: '🚴‍♀️',
  woman_biking_tone1: '🚴🏻‍♀️',
  woman_biking_tone2: '🚴🏼‍♀️',
  woman_biking_tone3: '🚴🏽‍♀️',
  woman_biking_tone4: '🚴🏾‍♀️',
  woman_biking_tone5: '🚴🏿‍♀️',
  man_biking: '🚴‍♂️',
  man_biking_tone1: '🚴🏻‍♂️',
  man_biking_tone2: '🚴🏼‍♂️',
  man_biking_tone3: '🚴🏽‍♂️',
  man_biking_tone4: '🚴🏾‍♂️',
  man_biking_tone5: '🚴🏿‍♂️',
  woman_juggling_tone1: '🤹🏻‍♀️',
  woman_juggling_tone2: '🤹🏼‍♀️',
  woman_juggling_tone3: '🤹🏽‍♀️',
  woman_juggling_tone4: '🤹🏾‍♀️',
  woman_juggling_tone5: '🤹🏿‍♀️',
  man_juggling_tone1: '🤹🏻‍♂️',
  man_juggling_tone2: '🤹🏼‍♂️',
  man_juggling_tone3: '🤹🏽‍♂️',
  man_juggling_tone4: '🤹🏾‍♂️',
  man_juggling_tone5: '🤹🏿‍♂️',
  chess_pawn: '♟️',
  jigsaw: '🧩',
  compass: '🧭',
  magnet: '🧲',
  fire_extinguisher: '🧯',
  nazar_amulet: '🧿',
  bricks: '🧱',
  toolbox: '🧰',
  test_tube: '🧪',
  petri_dish: '🧫',
  dna: '🧬',
  abacus: '🧮',
  broom: '🧹',
  basket: '🧺',
  roll_of_paper: '🧻',
  soap: '🧼',
  sponge: '🧽',
  squeeze_bottle: '🧴',
  thread: '🧵',
  yarn: '🧶',
  person_in_bed_tone1: '🛌🏻',
  person_in_bed_tone2: '🛌🏼',
  person_in_bed_tone3: '🛌🏽',
  person_in_bed_tone4: '🛌🏾',
  person_in_bed_tone5: '🛌🏿',
  teddy_bear: '🧸',
  red_envelope: '🧧',
  receipt: '🧾',
  safety_pin: '🧷',
  raccoon: '🦝',
  kangaroo: '🦘',
  badger: '🦡',
  swan: '🦢',
  parrot: '🦜',
  peacock: '🦚',
  mosquito: '🦟',
  microbe: '🦠',
  t_rex: '🦖',
  sauropod: '🦕',
  lobster: '🦞',
  zebra: '🦓',
  hippopotamus: '🦛',
  giraffe: '🦒',
  llama: '🦙',
  hedgehog: '🦔',
  mango: '🥭',
  coconut: '🥥',
  broccoli: '🥦',
  leafy_green: '🥬',
  pretzel: '🥨',
  bagel: '🥯',
  cut_of_meat: '🥩',
  sandwich: '🥪',
  canned_food: '🥫',
  fortune_cookie: '🥠',
  pie: '🥧',
  moon_cake: '🥮',
  cupcake: '🧁',
  salt: '🧂',
  dumpling: '🥟',
  cup_with_straw: '🥤',
  bowl_with_spoon: '🥣',
  takeout_box: '🥡',
  chopsticks: '🥢',
  smiling_face_with_3_hearts: '🥰',
  zany_face: '🤪',
  face_with_raised_eyebrow: '🤨',
  face_with_monocle: '🧐',
  star_struck: '🤩',
  partying_face: '🥳',
  face_with_symbols_over_mouth: '🤬',
  exploding_head: '🤯',
  hot_face: '🥵',
  cold_face: '🥶',
  pleading_face: '🥺',
  face_with_hand_over_mouth: '🤭',
  shushing_face: '🤫',
  woozy_face: '🥴',
  face_vomiting: '🤮',
  palms_up_together: '🤲',
  palms_up_together_tone1: '🤲🏻',
  palms_up_together_tone2: '🤲🏼',
  palms_up_together_tone3: '🤲🏽',
  palms_up_together_tone4: '🤲🏾',
  palms_up_together_tone5: '🤲🏿',
  love_you_gesture: '🤟',
  love_you_gesture_tone1: '🤟🏻',
  love_you_gesture_tone2: '🤟🏼',
  love_you_gesture_tone3: '🤟🏽',
  love_you_gesture_tone4: '🤟🏾',
  love_you_gesture_tone5: '🤟🏿',
  leg: '🦵',
  leg_tone1: '🦵🏻',
  leg_tone2: '🦵🏼',
  leg_tone3: '🦵🏽',
  leg_tone4: '🦵🏾',
  leg_tone5: '🦵🏿',
  foot: '🦶',
  foot_tone1: '🦶🏻',
  foot_tone2: '🦶🏼',
  foot_tone3: '🦶🏽',
  foot_tone4: '🦶🏾',
  foot_tone5: '🦶🏿',
  brain: '🧠',
  bone: '🦴',
  tooth: '🦷',
  child: '🧒',
  child_tone1: '🧒🏻',
  child_tone2: '🧒🏼',
  child_tone3: '🧒🏽',
  child_tone4: '🧒🏾',
  child_tone5: '🧒🏿',
  adult: '🧑',
  adult_tone1: '🧑🏻',
  adult_tone2: '🧑🏼',
  adult_tone3: '🧑🏽',
  adult_tone4: '🧑🏾',
  adult_tone5: '🧑🏿',
  'blond-haired_woman': '👱‍♀️',
  'blond-haired_woman_tone1': '👱🏻‍♀️',
  'blond-haired_woman_tone2': '👱🏼‍♀️',
  'blond-haired_woman_tone3': '👱🏽‍♀️',
  'blond-haired_woman_tone4': '👱🏾‍♀️',
  'blond-haired_woman_tone5': '👱🏿‍♀️',
  'blond-haired_man': '👱‍♂️',
  'blond-haired_man_tone1': '👱🏻‍♂️',
  'blond-haired_man_tone2': '👱🏼‍♂️',
  'blond-haired_man_tone3': '👱🏽‍♂️',
  'blond-haired_man_tone4': '👱🏾‍♂️',
  'blond-haired_man_tone5': '👱🏿‍♂️',
  woman_red_haired: '👩‍🦰',
  woman_red_haired_tone1: '👩🏻‍🦰',
  woman_red_haired_tone2: '👩🏼‍🦰',
  woman_red_haired_tone3: '👩🏽‍🦰',
  woman_red_haired_tone4: '👩🏾‍🦰',
  woman_red_haired_tone5: '👩🏿‍🦰',
  man_red_haired: '👨‍🦰',
  man_red_haired_tone1: '👨🏻‍🦰',
  man_red_haired_tone2: '👨🏼‍🦰',
  man_red_haired_tone3: '👨🏽‍🦰',
  man_red_haired_tone4: '👨🏾‍🦰',
  man_red_haired_tone5: '👨🏿‍🦰',
  woman_curly_haired: '👩‍🦱',
  woman_curly_haired_tone1: '👩🏻‍🦱',
  woman_curly_haired_tone2: '👩🏼‍🦱',
  woman_curly_haired_tone3: '👩🏽‍🦱',
  woman_curly_haired_tone4: '👩🏾‍🦱',
  woman_curly_haired_tone5: '👩🏿‍🦱',
  man_curly_haired: '👨‍🦱',
  man_curly_haired_tone1: '👨🏻‍🦱',
  man_curly_haired_tone2: '👨🏼‍🦱',
  man_curly_haired_tone3: '👨🏽‍🦱',
  man_curly_haired_tone4: '👨🏾‍🦱',
  man_curly_haired_tone5: '👨🏿‍🦱',
  woman_white_haired: '👩‍🦳',
  woman_white_haired_tone1: '👩🏻‍🦳',
  woman_white_haired_tone2: '👩🏼‍🦳',
  woman_white_haired_tone3: '👩🏽‍🦳',
  woman_white_haired_tone4: '👩🏾‍🦳',
  woman_white_haired_tone5: '👩🏿‍🦳',
  man_white_haired: '👨‍🦳',
  man_white_haired_tone1: '👨🏻‍🦳',
  man_white_haired_tone2: '👨🏼‍🦳',
  man_white_haired_tone3: '👨🏽‍🦳',
  man_white_haired_tone4: '👨🏾‍🦳',
  man_white_haired_tone5: '👨🏿‍🦳',
  woman_bald: '👩‍🦲',
  woman_bald_tone1: '👩🏻‍🦲',
  woman_bald_tone2: '👩🏼‍🦲',
  woman_bald_tone3: '👩🏽‍🦲',
  woman_bald_tone4: '👩🏾‍🦲',
  woman_bald_tone5: '👩🏿‍🦲',
  man_bald: '👨‍🦲',
  man_bald_tone1: '👨🏻‍🦲',
  man_bald_tone2: '👨🏼‍🦲',
  man_bald_tone3: '👨🏽‍🦲',
  man_bald_tone4: '👨🏾‍🦲',
  man_bald_tone5: '👨🏿‍🦲',
  bearded_person: '🧔',
  bearded_person_tone1: '🧔🏻',
  bearded_person_tone2: '🧔🏼',
  bearded_person_tone3: '🧔🏽',
  bearded_person_tone4: '🧔🏾',
  bearded_person_tone5: '🧔🏿',
  older_adult: '🧓',
  older_adult_tone1: '🧓🏻',
  older_adult_tone2: '🧓🏼',
  older_adult_tone3: '🧓🏽',
  older_adult_tone4: '🧓🏾',
  older_adult_tone5: '🧓🏿',
  woman_wearing_turban: '👳‍♀️',
  woman_wearing_turban_tone1: '👳🏻‍♀️',
  woman_wearing_turban_tone2: '👳🏼‍♀️',
  woman_wearing_turban_tone3: '👳🏽‍♀️',
  woman_wearing_turban_tone4: '👳🏾‍♀️',
  woman_wearing_turban_tone5: '👳🏿‍♀️',
  man_wearing_turban: '👳‍♂️',
  man_wearing_turban_tone1: '👳🏻‍♂️',
  man_wearing_turban_tone2: '👳🏼‍♂️',
  man_wearing_turban_tone3: '👳🏽‍♂️',
  man_wearing_turban_tone4: '👳🏾‍♂️',
  man_wearing_turban_tone5: '👳🏿‍♂️',
  woman_with_headscarf: '🧕',
  woman_with_headscarf_tone1: '🧕🏻',
  woman_with_headscarf_tone2: '🧕🏼',
  woman_with_headscarf_tone3: '🧕🏽',
  woman_with_headscarf_tone4: '🧕🏾',
  woman_with_headscarf_tone5: '🧕🏿',
  woman_police_officer: '👮‍♀️',
  woman_police_officer_tone1: '👮🏻‍♀️',
  woman_police_officer_tone2: '👮🏼‍♀️',
  woman_police_officer_tone3: '👮🏽‍♀️',
  woman_police_officer_tone4: '👮🏾‍♀️',
  woman_police_officer_tone5: '👮🏿‍♀️',
  man_police_officer: '👮‍♂️',
  man_police_officer_tone1: '👮🏻‍♂️',
  man_police_officer_tone2: '👮🏼‍♂️',
  man_police_officer_tone3: '👮🏽‍♂️',
  man_police_officer_tone4: '👮🏾‍♂️',
  man_police_officer_tone5: '👮🏿‍♂️',
  woman_construction_worker: '👷‍♀️',
  woman_construction_worker_tone1: '👷🏻‍♀️',
  woman_construction_worker_tone2: '👷🏼‍♀️',
  woman_construction_worker_tone3: '👷🏽‍♀️',
  woman_construction_worker_tone4: '👷🏾‍♀️',
  woman_construction_worker_tone5: '👷🏿‍♀️',
  man_construction_worker: '👷‍♂️',
  man_construction_worker_tone1: '👷🏻‍♂️',
  man_construction_worker_tone2: '👷🏼‍♂️',
  man_construction_worker_tone3: '👷🏽‍♂️',
  man_construction_worker_tone4: '👷🏾‍♂️',
  man_construction_worker_tone5: '👷🏿‍♂️',
  woman_guard: '💂‍♀️',
  woman_guard_tone1: '💂🏻‍♀️',
  woman_guard_tone2: '💂🏼‍♀️',
  woman_guard_tone3: '💂🏽‍♀️',
  woman_guard_tone4: '💂🏾‍♀️',
  woman_guard_tone5: '💂🏿‍♀️',
  man_guard: '💂‍♂️',
  man_guard_tone1: '💂🏻‍♂️',
  man_guard_tone2: '💂🏼‍♂️',
  man_guard_tone3: '💂🏽‍♂️',
  man_guard_tone4: '💂🏾‍♂️',
  man_guard_tone5: '💂🏿‍♂️',
  woman_detective: '🕵️‍♀️',
  woman_detective_tone1: '🕵🏻‍♀️',
  woman_detective_tone2: '🕵🏼‍♀️',
  woman_detective_tone3: '🕵🏽‍♀️',
  woman_detective_tone4: '🕵🏾‍♀️',
  woman_detective_tone5: '🕵🏿‍♀️',
  man_detective: '🕵️‍♂️',
  man_detective_tone1: '🕵🏻‍♂️',
  man_detective_tone2: '🕵🏼‍♂️',
  man_detective_tone3: '🕵🏽‍♂️',
  man_detective_tone4: '🕵🏾‍♂️',
  man_detective_tone5: '🕵🏿‍♂️',
  woman_health_worker_tone1: '👩🏻‍⚕️',
  woman_health_worker_tone2: '👩🏼‍⚕️',
  woman_health_worker_tone3: '👩🏽‍⚕️',
  woman_health_worker_tone4: '👩🏾‍⚕️',
  woman_health_worker_tone5: '👩🏿‍⚕️',
  man_health_worker_tone1: '👨🏻‍⚕️',
  man_health_worker_tone2: '👨🏼‍⚕️',
  man_health_worker_tone3: '👨🏽‍⚕️',
  man_health_worker_tone4: '👨🏾‍⚕️',
  man_health_worker_tone5: '👨🏿‍⚕️',
  woman_farmer_tone1: '👩🏻‍🌾',
  woman_farmer_tone2: '👩🏼‍🌾',
  woman_farmer_tone3: '👩🏽‍🌾',
  woman_farmer_tone4: '👩🏾‍🌾',
  woman_farmer_tone5: '👩🏿‍🌾',
  man_farmer_tone1: '👨🏻‍🌾',
  man_farmer_tone2: '👨🏼‍🌾',
  man_farmer_tone3: '👨🏽‍🌾',
  man_farmer_tone4: '👨🏾‍🌾',
  man_farmer_tone5: '👨🏿‍🌾',
  woman_cook_tone1: '👩🏻‍🍳',
  woman_cook_tone2: '👩🏼‍🍳',
  woman_cook_tone3: '👩🏽‍🍳',
  woman_cook_tone4: '👩🏾‍🍳',
  woman_cook_tone5: '👩🏿‍🍳',
  man_cook_tone1: '👨🏻‍🍳',
  man_cook_tone2: '👨🏼‍🍳',
  man_cook_tone3: '👨🏽‍🍳',
  man_cook_tone4: '👨🏾‍🍳',
  man_cook_tone5: '👨🏿‍🍳',
  woman_student_tone1: '👩🏻‍🎓',
  woman_student_tone2: '👩🏼‍🎓',
  woman_student_tone3: '👩🏽‍🎓',
  woman_student_tone4: '👩🏾‍🎓',
  woman_student_tone5: '👩🏿‍🎓',
  man_student_tone1: '👨🏻‍🎓',
  man_student_tone2: '👨🏼‍🎓',
  man_student_tone3: '👨🏽‍🎓',
  man_student_tone4: '👨🏾‍🎓',
  man_student_tone5: '👨🏿‍🎓',
  woman_singer_tone1: '👩🏻‍🎤',
  woman_singer_tone2: '👩🏼‍🎤',
  woman_singer_tone3: '👩🏽‍🎤',
  woman_singer_tone4: '👩🏾‍🎤',
  woman_singer_tone5: '👩🏿‍🎤',
  man_singer_tone1: '👨🏻‍🎤',
  man_singer_tone2: '👨🏼‍🎤',
  man_singer_tone3: '👨🏽‍🎤',
  man_singer_tone4: '👨🏾‍🎤',
  man_singer_tone5: '👨🏿‍🎤',
  woman_teacher_tone1: '👩🏻‍🏫',
  woman_teacher_tone2: '👩🏼‍🏫',
  woman_teacher_tone3: '👩🏽‍🏫',
  woman_teacher_tone4: '👩🏾‍🏫',
  woman_teacher_tone5: '👩🏿‍🏫',
  man_teacher_tone1: '👨🏻‍🏫',
  man_teacher_tone2: '👨🏼‍🏫',
  man_teacher_tone3: '👨🏽‍🏫',
  man_teacher_tone4: '👨🏾‍🏫',
  man_teacher_tone5: '👨🏿‍🏫',
  woman_factory_worker_tone1: '👩🏻‍🏭',
  woman_factory_worker_tone2: '👩🏼‍🏭',
  woman_factory_worker_tone3: '👩🏽‍🏭',
  woman_factory_worker_tone4: '👩🏾‍🏭',
  woman_factory_worker_tone5: '👩🏿‍🏭',
  man_factory_worker_tone1: '👨🏻‍🏭',
  man_factory_worker_tone2: '👨🏼‍🏭',
  man_factory_worker_tone3: '👨🏽‍🏭',
  man_factory_worker_tone4: '👨🏾‍🏭',
  man_factory_worker_tone5: '👨🏿‍🏭',
  woman_technologist_tone1: '👩🏻‍💻',
  woman_technologist_tone2: '👩🏼‍💻',
  woman_technologist_tone3: '👩🏽‍💻',
  woman_technologist_tone4: '👩🏾‍💻',
  woman_technologist_tone5: '👩🏿‍💻',
  man_technologist_tone1: '👨🏻‍💻',
  man_technologist_tone2: '👨🏼‍💻',
  man_technologist_tone3: '👨🏽‍💻',
  man_technologist_tone4: '👨🏾‍💻',
  man_technologist_tone5: '👨🏿‍💻',
  woman_office_worker_tone1: '👩🏻‍💼',
  woman_office_worker_tone2: '👩🏼‍💼',
  woman_office_worker_tone3: '👩🏽‍💼',
  woman_office_worker_tone4: '👩🏾‍💼',
  woman_office_worker_tone5: '👩🏿‍💼',
  man_office_worker_tone1: '👨🏻‍💼',
  man_office_worker_tone2: '👨🏼‍💼',
  man_office_worker_tone3: '👨🏽‍💼',
  man_office_worker_tone4: '👨🏾‍💼',
  man_office_worker_tone5: '👨🏿‍💼',
  woman_mechanic_tone1: '👩🏻‍🔧',
  woman_mechanic_tone2: '👩🏼‍🔧',
  woman_mechanic_tone3: '👩🏽‍🔧',
  woman_mechanic_tone4: '👩🏾‍🔧',
  woman_mechanic_tone5: '👩🏿‍🔧',
  man_mechanic_tone1: '👨🏻‍🔧',
  man_mechanic_tone2: '👨🏼‍🔧',
  man_mechanic_tone3: '👨🏽‍🔧',
  man_mechanic_tone4: '👨🏾‍🔧',
  man_mechanic_tone5: '👨🏿‍🔧',
  woman_scientist_tone1: '👩🏻‍🔬',
  woman_scientist_tone2: '👩🏼‍🔬',
  woman_scientist_tone3: '👩🏽‍🔬',
  woman_scientist_tone4: '👩🏾‍🔬',
  woman_scientist_tone5: '👩🏿‍🔬',
  man_scientist_tone1: '👨🏻‍🔬',
  man_scientist_tone2: '👨🏼‍🔬',
  man_scientist_tone3: '👨🏽‍🔬',
  man_scientist_tone4: '👨🏾‍🔬',
  man_scientist_tone5: '👨🏿‍🔬',
  woman_artist_tone1: '👩🏻‍🎨',
  woman_artist_tone2: '👩🏼‍🎨',
  woman_artist_tone3: '👩🏽‍🎨',
  woman_artist_tone4: '👩🏾‍🎨',
  woman_artist_tone5: '👩🏿‍🎨',
  man_artist_tone1: '👨🏻‍🎨',
  man_artist_tone2: '👨🏼‍🎨',
  man_artist_tone3: '👨🏽‍🎨',
  man_artist_tone4: '👨🏾‍🎨',
  man_artist_tone5: '👨🏿‍🎨',
  woman_firefighter_tone1: '👩🏻‍🚒',
  woman_firefighter_tone2: '👩🏼‍🚒',
  woman_firefighter_tone3: '👩🏽‍🚒',
  woman_firefighter_tone4: '👩🏾‍🚒',
  woman_firefighter_tone5: '👩🏿‍🚒',
  man_firefighter_tone1: '👨🏻‍🚒',
  man_firefighter_tone2: '👨🏼‍🚒',
  man_firefighter_tone3: '👨🏽‍🚒',
  man_firefighter_tone4: '👨🏾‍🚒',
  man_firefighter_tone5: '👨🏿‍🚒',
  woman_pilot_tone1: '👩🏻‍✈️',
  woman_pilot_tone2: '👩🏼‍✈️',
  woman_pilot_tone3: '👩🏽‍✈️',
  woman_pilot_tone4: '👩🏾‍✈️',
  woman_pilot_tone5: '👩🏿‍✈️',
  man_pilot_tone1: '👨🏻‍✈️',
  man_pilot_tone2: '👨🏼‍✈️',
  man_pilot_tone3: '👨🏽‍✈️',
  man_pilot_tone4: '👨🏾‍✈️',
  man_pilot_tone5: '👨🏿‍✈️',
  woman_astronaut_tone1: '👩🏻‍🚀',
  woman_astronaut_tone2: '👩🏼‍🚀',
  woman_astronaut_tone3: '👩🏽‍🚀',
  woman_astronaut_tone4: '👩🏾‍🚀',
  woman_astronaut_tone5: '👩🏿‍🚀',
  man_astronaut_tone1: '👨🏻‍🚀',
  man_astronaut_tone2: '👨🏼‍🚀',
  man_astronaut_tone3: '👨🏽‍🚀',
  man_astronaut_tone4: '👨🏾‍🚀',
  man_astronaut_tone5: '👨🏿‍🚀',
  woman_judge_tone1: '👩🏻‍⚖️',
  woman_judge_tone2: '👩🏼‍⚖️',
  woman_judge_tone3: '👩🏽‍⚖️',
  woman_judge_tone4: '👩🏾‍⚖️',
  woman_judge_tone5: '👩🏿‍⚖️',
  man_judge_tone1: '👨🏻‍⚖️',
  man_judge_tone2: '👨🏼‍⚖️',
  man_judge_tone3: '👨🏽‍⚖️',
  man_judge_tone4: '👨🏾‍⚖️',
  man_judge_tone5: '👨🏿‍⚖️',
  superhero: '🦸',
  superhero_tone1: '🦸🏻',
  superhero_tone2: '🦸🏼',
  superhero_tone3: '🦸🏽',
  superhero_tone4: '🦸🏾',
  superhero_tone5: '🦸🏿',
  woman_superhero: '🦸‍♀️',
  woman_superhero_tone1: '🦸🏻‍♀️',
  woman_superhero_tone2: '🦸🏼‍♀️',
  woman_superhero_tone3: '🦸🏽‍♀️',
  woman_superhero_tone4: '🦸🏾‍♀️',
  woman_superhero_tone5: '🦸🏿‍♀️',
  man_superhero: '🦸‍♂️',
  man_superhero_tone1: '🦸🏻‍♂️',
  man_superhero_tone2: '🦸🏼‍♂️',
  man_superhero_tone3: '🦸🏽‍♂️',
  man_superhero_tone4: '🦸🏾‍♂️',
  man_superhero_tone5: '🦸🏿‍♂️',
  supervillain: '🦹',
  supervillain_tone1: '🦹🏻',
  supervillain_tone2: '🦹🏼',
  supervillain_tone3: '🦹🏽',
  supervillain_tone4: '🦹🏾',
  supervillain_tone5: '🦹🏿',
  woman_supervillain_tone1: '🦹🏻‍♀️',
  woman_supervillain: '🦹‍♀️',
  woman_supervillain_tone2: '🦹🏼‍♀️',
  woman_supervillain_tone3: '🦹🏽‍♀️',
  woman_supervillain_tone4: '🦹🏾‍♀️',
  woman_supervillain_tone5: '🦹🏿‍♀️',
  man_supervillain: '🦹‍♂️',
  man_supervillain_tone1: '🦹🏻‍♂️',
  man_supervillain_tone2: '🦹🏼‍♂️',
  man_supervillain_tone3: '🦹🏽‍♂️',
  man_supervillain_tone4: '🦹🏾‍♂️',
  man_supervillain_tone5: '🦹🏿‍♂️',
  mage: '🧙',
  mage_tone1: '🧙🏻',
  mage_tone2: '🧙🏼',
  mage_tone3: '🧙🏽',
  mage_tone4: '🧙🏾',
  mage_tone5: '🧙🏿',
  woman_mage: '🧙‍♀️',
  woman_mage_tone1: '🧙🏻‍♀️',
  woman_mage_tone2: '🧙🏼‍♀️',
  woman_mage_tone3: '🧙🏽‍♀️',
  woman_mage_tone4: '🧙🏾‍♀️',
  woman_mage_tone5: '🧙🏿‍♀️',
  man_mage: '🧙‍♂️',
  man_mage_tone1: '🧙🏻‍♂️',
  man_mage_tone2: '🧙🏼‍♂️',
  man_mage_tone3: '🧙🏽‍♂️',
  man_mage_tone4: '🧙🏾‍♂️',
  man_mage_tone5: '🧙🏿‍♂️',
  elf: '🧝',
  elf_tone1: '🧝🏻',
  elf_tone2: '🧝🏼',
  elf_tone3: '🧝🏽',
  elf_tone4: '🧝🏾',
  elf_tone5: '🧝🏿',
  woman_elf: '🧝‍♀️',
  woman_elf_tone1: '🧝🏻‍♀️',
  woman_elf_tone2: '🧝🏼‍♀️',
  woman_elf_tone3: '🧝🏽‍♀️',
  woman_elf_tone4: '🧝🏾‍♀️',
  woman_elf_tone5: '🧝🏿‍♀️',
  man_elf: '🧝‍♂️',
  man_elf_tone1: '🧝🏻‍♂️',
  man_elf_tone2: '🧝🏼‍♂️',
  man_elf_tone3: '🧝🏽‍♂️',
  man_elf_tone4: '🧝🏾‍♂️',
  man_elf_tone5: '🧝🏿‍♂️',
  vampire: '🧛',
  vampire_tone1: '🧛🏻',
  vampire_tone2: '🧛🏼',
  vampire_tone3: '🧛🏽',
  vampire_tone4: '🧛🏾',
  vampire_tone5: '🧛🏿',
  woman_vampire: '🧛‍♀️',
  woman_vampire_tone1: '🧛🏻‍♀️',
  woman_vampire_tone2: '🧛🏼‍♀️',
  woman_vampire_tone3: '🧛🏽‍♀️',
  woman_vampire_tone4: '🧛🏾‍♀️',
  woman_vampire_tone5: '🧛🏿‍♀️',
  man_vampire: '🧛‍♂️',
  man_vampire_tone1: '🧛🏻‍♂️',
  man_vampire_tone2: '🧛🏼‍♂️',
  man_vampire_tone3: '🧛🏽‍♂️',
  man_vampire_tone4: '🧛🏾‍♂️',
  man_vampire_tone5: '🧛🏿‍♂️',
  zombie: '🧟',
  woman_zombie: '🧟‍♀️',
  man_zombie: '🧟‍♂️',
  genie: '🧞',
  woman_genie: '🧞‍♀️',
  man_genie: '🧞‍♂️',
  merperson: '🧜',
  merperson_tone1: '🧜🏻',
  merperson_tone2: '🧜🏼',
  merperson_tone3: '🧜🏽',
  merperson_tone4: '🧜🏾',
  merperson_tone5: '🧜🏿',
  mermaid: '🧜‍♀️',
  mermaid_tone1: '🧜🏻‍♀️',
  mermaid_tone2: '🧜🏼‍♀️',
  mermaid_tone3: '🧜🏽‍♀️',
  mermaid_tone4: '🧜🏾‍♀️',
  mermaid_tone5: '🧜🏿‍♀️',
  merman: '🧜‍♂️',
  merman_tone1: '🧜🏻‍♂️',
  merman_tone2: '🧜🏼‍♂️',
  merman_tone3: '🧜🏽‍♂️',
  merman_tone4: '🧜🏾‍♂️',
  merman_tone5: '🧜🏿‍♂️',
  fairy: '🧚',
  fairy_tone1: '🧚🏻',
  fairy_tone2: '🧚🏼',
  fairy_tone3: '🧚🏽',
  fairy_tone4: '🧚🏾',
  fairy_tone5: '🧚🏿',
  woman_fairy: '🧚‍♀️',
  woman_fairy_tone1: '🧚🏻‍♀️',
  woman_fairy_tone2: '🧚🏼‍♀️',
  woman_fairy_tone3: '🧚🏽‍♀️',
  woman_fairy_tone4: '🧚🏾‍♀️',
  woman_fairy_tone5: '🧚🏿‍♀️',
  man_fairy: '🧚‍♂️',
  man_fairy_tone1: '🧚🏻‍♂️',
  man_fairy_tone2: '🧚🏼‍♂️',
  man_fairy_tone3: '🧚🏽‍♂️',
  man_fairy_tone4: '🧚🏾‍♂️',
  man_fairy_tone5: '🧚🏿‍♂️',
  breast_feeding: '🤱',
  breast_feeding_tone1: '🤱🏻',
  breast_feeding_tone2: '🤱🏼',
  breast_feeding_tone3: '🤱🏽',
  breast_feeding_tone4: '🤱🏾',
  breast_feeding_tone5: '🤱🏿',
  woman_bowing: '🙇‍♀️',
  woman_bowing_tone1: '🙇🏻‍♀️',
  woman_bowing_tone2: '🙇🏼‍♀️',
  woman_bowing_tone3: '🙇🏽‍♀️',
  woman_bowing_tone4: '🙇🏾‍♀️',
  woman_bowing_tone5: '🙇🏿‍♀️',
  man_bowing: '🙇‍♂️',
  man_bowing_tone1: '🙇🏻‍♂️',
  man_bowing_tone2: '🙇🏼‍♂️',
  man_bowing_tone3: '🙇🏽‍♂️',
  man_bowing_tone4: '🙇🏾‍♂️',
  man_bowing_tone5: '🙇🏿‍♂️',
  woman_tipping_hand: '💁‍♀️',
  woman_tipping_hand_tone1: '💁🏻‍♀️',
  woman_tipping_hand_tone2: '💁🏼‍♀️',
  woman_tipping_hand_tone3: '💁🏽‍♀️',
  woman_tipping_hand_tone4: '💁🏾‍♀️',
  woman_tipping_hand_tone5: '💁🏿‍♀️',
  man_tipping_hand: '💁‍♂️',
  man_tipping_hand_tone1: '💁🏻‍♂️',
  man_tipping_hand_tone2: '💁🏼‍♂️',
  man_tipping_hand_tone3: '💁🏽‍♂️',
  man_tipping_hand_tone4: '💁🏾‍♂️',
  man_tipping_hand_tone5: '💁🏿‍♂️',
  woman_gesturing_no: '🙅‍♀️',
  woman_gesturing_no_tone1: '🙅🏻‍♀️',
  woman_gesturing_no_tone2: '🙅🏼‍♀️',
  woman_gesturing_no_tone3: '🙅🏽‍♀️',
  woman_gesturing_no_tone4: '🙅🏾‍♀️',
  woman_gesturing_no_tone5: '🙅🏿‍♀️',
  man_gesturing_no: '🙅‍♂️',
  man_gesturing_no_tone1: '🙅🏻‍♂️',
  man_gesturing_no_tone2: '🙅🏼‍♂️',
  man_gesturing_no_tone3: '🙅🏽‍♂️',
  man_gesturing_no_tone4: '🙅🏾‍♂️',
  man_gesturing_no_tone5: '🙅🏿‍♂️',
  woman_gesturing_ok: '🙆‍♀️',
  woman_gesturing_ok_tone1: '🙆🏻‍♀️',
  woman_gesturing_ok_tone2: '🙆🏼‍♀️',
  woman_gesturing_ok_tone3: '🙆🏽‍♀️',
  woman_gesturing_ok_tone4: '🙆🏾‍♀️',
  woman_gesturing_ok_tone5: '🙆🏿‍♀️',
  man_gesturing_ok: '🙆‍♂️',
  man_gesturing_ok_tone1: '🙆🏻‍♂️',
  man_gesturing_ok_tone2: '🙆🏼‍♂️',
  man_gesturing_ok_tone3: '🙆🏽‍♂️',
  man_gesturing_ok_tone4: '🙆🏾‍♂️',
  man_gesturing_ok_tone5: '🙆🏿‍♂️',
  woman_raising_hand: '🙋‍♀️',
  woman_raising_hand_tone1: '🙋🏻‍♀️',
  woman_raising_hand_tone2: '🙋🏼‍♀️',
  woman_raising_hand_tone3: '🙋🏽‍♀️',
  woman_raising_hand_tone4: '🙋🏾‍♀️',
  woman_raising_hand_tone5: '🙋🏿‍♀️',
  man_raising_hand: '🙋‍♂️',
  man_raising_hand_tone1: '🙋🏻‍♂️',
  man_raising_hand_tone2: '🙋🏼‍♂️',
  man_raising_hand_tone3: '🙋🏽‍♂️',
  man_raising_hand_tone4: '🙋🏾‍♂️',
  man_raising_hand_tone5: '🙋🏿‍♂️',
  woman_facepalming_tone1: '🤦🏻‍♀️',
  woman_facepalming_tone2: '🤦🏼‍♀️',
  woman_facepalming_tone3: '🤦🏽‍♀️',
  woman_facepalming_tone4: '🤦🏾‍♀️',
  woman_facepalming_tone5: '🤦🏿‍♀️',
  man_facepalming_tone1: '🤦🏻‍♂️',
  man_facepalming_tone2: '🤦🏼‍♂️',
  man_facepalming_tone3: '🤦🏽‍♂️',
  man_facepalming_tone4: '🤦🏾‍♂️',
  man_facepalming_tone5: '🤦🏿‍♂️',
  woman_shrugging_tone1: '🤷🏻‍♀️',
  woman_shrugging_tone2: '🤷🏼‍♀️',
  woman_shrugging_tone3: '🤷🏽‍♀️',
  woman_shrugging_tone4: '🤷🏾‍♀️',
  woman_shrugging_tone5: '🤷🏿‍♀️',
  man_shrugging_tone1: '🤷🏻‍♂️',
  man_shrugging_tone2: '🤷🏼‍♂️',
  man_shrugging_tone3: '🤷🏽‍♂️',
  man_shrugging_tone4: '🤷🏾‍♂️',
  man_shrugging_tone5: '🤷🏿‍♂️',
  woman_pouting: '🙎‍♀️',
  woman_pouting_tone1: '🙎🏻‍♀️',
  woman_pouting_tone2: '🙎🏼‍♀️',
  woman_pouting_tone3: '🙎🏽‍♀️',
  woman_pouting_tone4: '🙎🏾‍♀️',
  woman_pouting_tone5: '🙎🏿‍♀️',
  man_pouting: '🙎‍♂️',
  man_pouting_tone1: '🙎🏻‍♂️',
  man_pouting_tone2: '🙎🏼‍♂️',
  man_pouting_tone3: '🙎🏽‍♂️',
  man_pouting_tone4: '🙎🏾‍♂️',
  man_pouting_tone5: '🙎🏿‍♂️',
  woman_frowning: '🙍‍♀️',
  woman_frowning_tone1: '🙍🏻‍♀️',
  woman_frowning_tone2: '🙍🏼‍♀️',
  woman_frowning_tone3: '🙍🏽‍♀️',
  woman_frowning_tone4: '🙍🏾‍♀️',
  woman_frowning_tone5: '🙍🏿‍♀️',
  man_frowning: '🙍‍♂️',
  man_frowning_tone1: '🙍🏻‍♂️',
  man_frowning_tone2: '🙍🏼‍♂️',
  man_frowning_tone3: '🙍🏽‍♂️',
  man_frowning_tone4: '🙍🏾‍♂️',
  man_frowning_tone5: '🙍🏿‍♂️',
  woman_getting_haircut: '💇‍♀️',
  woman_getting_haircut_tone1: '💇🏻‍♀️',
  woman_getting_haircut_tone2: '💇🏼‍♀️',
  woman_getting_haircut_tone3: '💇🏽‍♀️',
  woman_getting_haircut_tone4: '💇🏾‍♀️',
  woman_getting_haircut_tone5: '💇🏿‍♀️',
  man_getting_haircut: '💇‍♂️',
  man_getting_haircut_tone1: '💇🏻‍♂️',
  man_getting_haircut_tone2: '💇🏼‍♂️',
  man_getting_haircut_tone3: '💇🏽‍♂️',
  man_getting_haircut_tone4: '💇🏾‍♂️',
  man_getting_haircut_tone5: '💇🏿‍♂️',
  woman_getting_face_massage: '💆‍♀️',
  woman_getting_face_massage_tone1: '💆🏻‍♀️',
  woman_getting_face_massage_tone2: '💆🏼‍♀️',
  woman_getting_face_massage_tone3: '💆🏽‍♀️',
  woman_getting_face_massage_tone4: '💆🏾‍♀️',
  woman_getting_face_massage_tone5: '💆🏿‍♀️',
  man_getting_face_massage: '💆‍♂️',
  man_getting_face_massage_tone1: '💆🏻‍♂️',
  man_getting_face_massage_tone2: '💆🏼‍♂️',
  man_getting_face_massage_tone3: '💆🏽‍♂️',
  man_getting_face_massage_tone4: '💆🏾‍♂️',
  man_getting_face_massage_tone5: '💆🏿‍♂️',
  person_in_steamy_room: '🧖',
  person_in_steamy_room_tone1: '🧖🏻',
  person_in_steamy_room_tone2: '🧖🏼',
  person_in_steamy_room_tone3: '🧖🏽',
  person_in_steamy_room_tone4: '🧖🏾',
  person_in_steamy_room_tone5: '🧖🏿',
  woman_in_steamy_room: '🧖‍♀️',
  woman_in_steamy_room_tone1: '🧖🏻‍♀️',
  woman_in_steamy_room_tone2: '🧖🏼‍♀️',
  woman_in_steamy_room_tone3: '🧖🏽‍♀️',
  woman_in_steamy_room_tone4: '🧖🏾‍♀️',
  woman_in_steamy_room_tone5: '🧖🏿‍♀️',
  man_in_steamy_room: '🧖‍♂️',
  man_in_steamy_room_tone1: '🧖🏻‍♂️',
  man_in_steamy_room_tone2: '🧖🏼‍♂️',
  man_in_steamy_room_tone3: '🧖🏽‍♂️',
  man_in_steamy_room_tone4: '🧖🏾‍♂️',
  man_in_steamy_room_tone5: '🧖🏿‍♂️',
  women_with_bunny_ears_partying: '👯‍♀️',
  men_with_bunny_ears_partying: '👯‍♂️',
  levitate_tone1: '🕴🏻',
  levitate_tone2: '🕴🏼',
  levitate_tone3: '🕴🏽',
  levitate_tone4: '🕴🏾',
  levitate_tone5: '🕴🏿',
  woman_walking: '🚶‍♀️',
  woman_walking_tone1: '🚶🏻‍♀️',
  woman_walking_tone2: '🚶🏼‍♀️',
  woman_walking_tone3: '🚶🏽‍♀️',
  woman_walking_tone4: '🚶🏾‍♀️',
  woman_walking_tone5: '🚶🏿‍♀️',
  man_walking: '🚶‍♂️',
  man_walking_tone1: '🚶🏻‍♂️',
  man_walking_tone2: '🚶🏼‍♂️',
  man_walking_tone3: '🚶🏽‍♂️',
  man_walking_tone4: '🚶🏾‍♂️',
  man_walking_tone5: '🚶🏿‍♂️',
  woman_running: '🏃‍♀️',
  woman_running_tone1: '🏃🏻‍♀️',
  woman_running_tone2: '🏃🏼‍♀️',
  woman_running_tone3: '🏃🏽‍♀️',
  woman_running_tone4: '🏃🏾‍♀️',
  woman_running_tone5: '🏃🏿‍♀️',
  man_running: '🏃‍♂️',
  man_running_tone1: '🏃🏻‍♂️',
  man_running_tone2: '🏃🏼‍♂️',
  man_running_tone3: '🏃🏽‍♂️',
  man_running_tone4: '🏃🏾‍♂️',
  man_running_tone5: '🏃🏿‍♂️',
  couple_with_heart_woman_man: '👩‍❤️‍👨',
  kiss_woman_man: '👩‍❤️‍💋‍👨',
  family_man_woman_boy: '👨‍👩‍👦',
  coat: '🧥',
  lab_coat: '🥼',
  hiking_boot: '🥾',
  womans_flat_shoe: '🥿',
  socks: '🧦',
  gloves: '🧤',
  scarf: '🧣',
  billed_cap: '🧢',
  goggles: '🥽',
  red_haired: '🦰',
  curly_haired: '🦱',
  white_haired: '🦳',
  bald: '🦲',
  regional_indicator_z: '🇿',
  regional_indicator_y: '🇾',
  regional_indicator_x: '🇽',
  regional_indicator_w: '🇼',
  regional_indicator_v: '🇻',
  regional_indicator_u: '🇺',
  regional_indicator_t: '🇹',
  regional_indicator_s: '🇸',
  regional_indicator_r: '🇷',
  regional_indicator_q: '🇶',
  regional_indicator_p: '🇵',
  regional_indicator_o: '🇴',
  regional_indicator_n: '🇳',
  regional_indicator_m: '🇲',
  regional_indicator_l: '🇱',
  regional_indicator_k: '🇰',
  regional_indicator_j: '🇯',
  regional_indicator_i: '🇮',
  regional_indicator_h: '🇭',
  regional_indicator_g: '🇬',
  regional_indicator_f: '🇫',
  regional_indicator_e: '🇪',
  regional_indicator_d: '🇩',
  regional_indicator_c: '🇨',
  regional_indicator_b: '🇧',
  regional_indicator_a: '🇦',
  luggage: '🧳',
  flying_saucer: '🛸',
  firecracker: '🧨',
  pirate_flag: '🏴‍☠️',
  england: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
  scotland: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  wales: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
  united_nations: '🇺🇳',
}
