import { defineComponent, type PropType } from 'vue'
import LimitMixin from '../limitsMixin'

export default defineComponent({
  mixins: [LimitMixin],

  props: {
    modelValue: {
      type: Date as PropType<Date>,
      default: null,
    },
    selected: {
      type: Array as PropType<Date[]>,
      default: null,
    },
  },

  computed: {
    selectedMonth(): number {
      const { selected } = this
      if (!selected || selected.length == 0) {
        return -1
      }
      return selected[0].getMonth()
    },
    selectedYear(): number {
      const { selected } = this
      if (!selected || selected.length == 0) {
        return -1
      }
      return selected[0].getFullYear()
    },
  },
})
