<template>
  <div>
    <div class="py-10 w-full rounded bg-grey-alpha flex flex-col items-center">
      <span
        class="inline-flex bg-surface h-12 w-12 rounded text-center mb-4 justify-center items-center"
      >
        <SvgIcon icon="Box" class="text-brand inline" size="lg" />
      </span>
      <p class="font-medium text-xl mb-4">
        <template
          v-if="hasPortfolioPackage && hasFundPackage && hasAccountingPackage"
        >
          {{ $t('ui.common.add_portfolio_or_fund_or_accounting') }}
        </template>
        <template v-else-if="hasPortfolioPackage && hasFundPackage">
          {{ $t('ui.common.add_portfolio_or_fund') }}
        </template>
        <template v-else-if="hasPortfolioPackage && hasAccountingPackage">
          {{ $t('ui.common.add_portfolio_or_accounting') }}
        </template>
        <template v-else-if="hasFundPackage && hasAccountingPackage">
          {{ $t('ui.common.add_fund_or_accounting') }}
        </template>
        <template v-else-if="hasFundPackage">
          {{ $t('ui.funds.add_fund') }}
        </template>
        <template v-else-if="hasPortfolioPackage">
          {{ $t('ui.portfolios.add_portfolio') }}
        </template>
        <template v-else-if="hasAccountingPackage">
          {{ $t('ui.accountings.add_accounting') }}
        </template>
      </p>
      <p class="text-secondary text-base mb-8 max-w-xs text-center">
        {{ $t('ui.common.create_first_entity_info') }}
      </p>

      <div class="flex">
        <Button
          v-if="hasPortfolioPackage"
          type="transparent"
          icon="Settings2"
          class="mx-2"
          @click="$router.push({ name: 'SettingsPortfoliosView' })"
        >
          {{ $t('ui.common.manage') }}
          <SvgIcon icon="ArrowRight" class="mx-2" />
          {{ $t('ui.common.portfolios') }}
        </Button>

        <Button
          v-if="hasFundPackage"
          type="transparent"
          icon="Settings2"
          class="mx-2"
          @click="$router.push({ name: 'SettingsFundsView' })"
        >
          {{ $t('ui.common.manage') }}
          <SvgIcon icon="ArrowRight" class="mx-2" />
          {{ $t('ui.common.funds') }}
        </Button>

        <Button
          v-if="hasAccountingPackage"
          type="transparent"
          icon="Settings2"
          class="mx-2"
          @click="$router.push({ name: 'AccountingSettingsView' })"
        >
          {{ $t('ui.common.manage') }}
          <SvgIcon icon="ArrowRight" class="mx-2" />
          {{ $t('ui.common.accounting') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useUserStore } from '@/stores/user'

import Button from '@/components/controls/Button'
import SvgIcon from '@/components/misc/SvgIcon'

export default defineComponent({
  components: {
    Button,
    SvgIcon,
  },

  computed: {
    ...mapState(useUserStore, ['hasPackage']),
    hasPortfolioPackage(): boolean {
      return this.hasPackage(2) || this.hasPackage(37)
    },
    hasFundPackage(): boolean {
      return this.hasPackage(3) || this.hasPackage(43)
    },
    hasAccountingPackage(): boolean {
      return this.hasPackage(5) || this.hasPackage(47)
    },
  },
})
</script>
