<template>
  <button
    :id="id"
    :class="[
      size,
      type,
      {
        disabled: disabled,
        'only-icon': onlyIcon,
        loading: loading,
      },
    ]"
    class="crypkit-button"
    :type="buttonType"
    @click="($event) => !disabled && $emit('click', $event)"
  >
    <span class="flex items-center" :class="{ 'opacity-0': loading }">
      <!-- Display button icon via props -->
      <SvgIcon
        v-if="icon"
        :icon="icon"
        :class="{ 'mr-2': !onlyIcon }"
        :size="size == 'xs' ? 14 : 'md'"
      />
      <!-- Button text passed via slot-->
      <slot v-if="!onlyIcon" />
    </span>

    <span
      v-if="loading"
      class="absolute left-0 right-0 top-0 bottom-0 text-center"
    >
      <Spinner class="inline-block h-full fill-current" />
    </span>
  </button>
</template>

<script lang="ts">
import './Button.scss'
import './ButtonGroup.scss'

import { defineComponent, type PropType } from 'vue'
import Spinner from '@/assets/loaders/Spinner.svg?component'
import SvgIcon from '@/components/misc/SvgIcon'
import type { ButtonSize, ButtonType } from '@/types/components/button'

export default defineComponent({
  components: {
    SvgIcon,
    Spinner,
  },

  props: {
    icon: {
      type: String,
      default: '',
      required: false,
    },
    type: {
      type: String as PropType<ButtonType>,
      default: 'primary',
      required: false,
    },
    buttonType: {
      type: String as PropType<'button' | 'submit' | 'reset' | undefined>,
      default: 'button',
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  data() {
    return {
      value: null,
    }
  },
})
</script>
