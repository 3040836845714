<template>
  <Modal
    :title="$t('ui.common.list_of_current_reminders')"
    icon="Info"
    :loading="loading"
    :error-message="errorMessage"
    show
    hide-buttons
    @close="$emit('close')"
  >
    <div>
      <div
        v-for="(notification, index) in notifications"
        :key="notification.id"
        class="flex items-center py-2"
        :class="{ 'border-t border-outline': index !== 0 }"
      >
        <div class="flex-grow pr-3">
          <div class="text-xss">
            {{ formatDateTime(notification.date) }}
          </div>
          <div>{{ notification.message }}</div>
        </div>
        <div class="close-icon" @click.stop="markAsDone(notification)">
          <SvgIcon icon="Close" width="12" />
        </div>
      </div>
      <div
        v-if="notifications.length === 0"
        class="text-center text-secondary p-4"
      >
        <em>{{ $t('ui.common.no_upcoming_reminders') }}</em>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useNotificationsStore } from '@/stores/notifications'
import { useUserStore } from '@/stores/user'

import Modal from '@/components/misc/Modal'
import SvgIcon from '@/components/misc/SvgIcon'
import FormatMixin from './formatMixin'

import { HttpError } from '@/helpers/errors'

import { type Notification } from '@/types/services/notifications'

export default defineComponent({
  name: 'ListNotificationModal',

  components: {
    Modal,
    SvgIcon,
  },

  mixins: [FormatMixin],

  emits: ['close'],

  data() {
    return {
      loading: false,
      errorMessage: [] as string[],

      notificationsStore: useNotificationsStore(),
    }
  },

  computed: {
    ...mapState(useNotificationsStore, {
      notifications: (store) => store.userNotifications,
    }),
    ...mapState(useUserStore, {
      rootUserId: (store) => store.root_user?.id,
    }),
  },

  created() {
    this.getData()
  },

  methods: {
    async getData() {
      if (!this.rootUserId) {
        return
      }

      this.loading = true
      try {
        await this.notificationsStore.getUserNotifications(this.rootUserId)
        this.loading = false
      } catch (err) {
        this.loading = false
        this.errorMessage = [(err as HttpError).getFriendlyMessage()]
      }
    },
    markAsDone(notification: Notification) {
      try {
        this.notificationsStore.setDoneNotification(notification.id)
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
  },
})
</script>
