import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8.667 1.334 2.729 8.459c-.232.279-.349.418-.35.536-.002.103.044.2.123.264.092.075.274.075.637.075H8l-.667 5.333 5.938-7.125c.233-.28.349-.419.35-.537a.33.33 0 0 0-.123-.264c-.092-.074-.273-.074-.637-.074h-4.86z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }