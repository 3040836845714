<template>
  <div class="relative">
    <div class="rounded-full bg-brand h-8.5 w-8.5 overflow-hidden text-center">
      <SvgIcon icon="User" class="w-8 h-8 stroke-surface mt-1.5" />
    </div>
    <div
      v-if="showArrow"
      class="rounded-full border-2 border-surface bg-brand absolute -right-2.5 -bottom-0.5 h-5 w-5 flex items-center justify-center"
    >
      <SvgIcon icon="ArrowDown" class="stroke-surface" :size="12" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import SvgIcon from '@/components/misc/SvgIcon'

export default defineComponent({
  components: {
    SvgIcon,
  },

  props: {
    showArrow: {
      type: Boolean,
      default: true,
    },
  },
})
</script>
