import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M2.667 5.5v5.967c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218h4.266c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874c.218-.428.218-.988.218-2.108V5.5m-10.666 0c0-1.12 0-2.647.218-3.074a2 2 0 0 1 .874-.875c.428-.218.988-.218 2.108-.218h4.266c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.875c.218.427.218 1.954.218 3.074m-10.666 0h10.666M8.3 8h-.6M5.5 8h-.6m6.2 0h-.6m-2.2 2h-.6m-2.2 0h-.6m6.2 0h-.6m-2.2 2h-.6m-2.2 0h-.6m6.2 0h-.6" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }