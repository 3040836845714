import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14.667 8.333c0 .737-1.641 1.334-3.666 1.334S7.334 9.07 7.334 8.333m7.333 0c0-.736-1.641-1.333-3.666-1.333s-3.667.597-3.667 1.333m7.333 0V11M7.334 8.333v5.029m0-5.029c0-.414.519-.784 1.333-1.028M7.334 8.333V11m7.333 0c0 .736-1.641 1.333-3.666 1.333S7.334 11.736 7.334 11m7.333 0v2.667c0 .736-1.641 1.333-3.666 1.333s-3.667-.597-3.667-1.333V11m2.746-7.36L5.83 6 1.58 3.64m3.862 7.144-3.7-2.055c-.15-.083-.225-.125-.28-.184a.5.5 0 0 1-.107-.182C1.33 8.287 1.33 8.2 1.33 8.03V3.97c0-.171 0-.257.025-.333a.5.5 0 0 1 .107-.182c.055-.06.13-.101.28-.184l3.7-2.056c.141-.079.212-.118.287-.133a.5.5 0 0 1 .202 0c.075.015.146.054.288.133l3.7 2.056c.15.083.224.124.279.184a.5.5 0 0 1 .107.182c.025.076 0 1.19 0 1.362" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }