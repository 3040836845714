import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M2.225 11.334a6.667 6.667 0 1 0 0-6.667C1.928 5.11 1.333 6.4 1.333 8h9.334M8 10.667 10.667 8m0 0L8 5.334" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }