<template>
  <div class="calendar-header">
    <div
      class="calendar-arrow calendar-arrow-previous"
      :aria-label="ariaLabelPrev"
      @click.stop="$emit('prev')"
    >
      <span>
        <SvgIcon icon="ArrowLeft" block />
      </span>
    </div>
    <div
      class="calendar-name"
      :class="{ disabled: disableSwitch }"
      @click.stop="$emit('next-panel')"
    >
      {{ name }}
    </div>
    <div
      class="calendar-arrow calendar-arrow-next"
      :aria-label="ariaLabelNext"
      @click.stop="$emit('next')"
    >
      <span>
        <SvgIcon icon="ArrowRight" block />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SvgIcon from '@/components/misc/SvgIcon'

export default defineComponent({
  components: {
    SvgIcon,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    disableSwitch: {
      type: Boolean,
      default: false,
    },
    ariaLabelPrev: {
      type: String,
      default: null,
    },
    ariaLabelNext: {
      type: String,
      default: null,
    },
  },

  emits: ['next', 'next-panel', 'prev'],
})
</script>
