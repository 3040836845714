import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14.667 12.667A1.334 1.334 0 0 1 13.333 14H2.667a1.333 1.333 0 0 1-1.334-1.333V3.333A1.333 1.333 0 0 1 2.667 2H6l1.333 2h6a1.333 1.333 0 0 1 1.334 1.333z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }