import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M13.333 14c0-.93 0-1.396-.114-1.774a2.67 2.67 0 0 0-1.778-1.778c-.379-.115-.844-.115-1.774-.115H6.333c-.93 0-1.395 0-1.774.115a2.67 2.67 0 0 0-1.777 1.778c-.115.378-.115.844-.115 1.774M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }