<template>
  <div class="h-screen w-full bg-background flex items-center justify-center">
    <div class="w-[90vw] flex flex-col md:flex-row items-center">
      <div class="w-1/2">
        <svg
          class="image"
          viewBox="0 0 366 261"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="271" cy="95" r="95" fill="#E5F0FA" />
          <rect
            x="231.078"
            y="168.938"
            width="22.8271"
            height="92.2325"
            rx="11.4136"
            transform="rotate(30 231.078 168.938)"
            fill="#E5F0FA"
          />
          <circle cx="271" cy="95" r="76" fill="#EEF5FB" />
          <path
            d="M182.35 203.34C173.11 203.34 165.25 201.48 158.77 197.76C152.35 193.98 147.13 188.88 143.11 182.46C139.15 176.04 136.24 168.75 134.38 160.59C132.58 152.37 131.68 143.82 131.68 134.94C131.68 126.06 132.58 117.51 134.38 109.29C136.24 101.01 139.15 93.63 143.11 87.15C147.13 80.61 152.35 75.45 158.77 71.67C165.25 67.89 173.11 66 182.35 66C191.59 66 199.42 67.89 205.84 71.67C212.32 75.45 217.54 80.61 221.5 87.15C225.52 93.63 228.43 101.01 230.23 109.29C232.09 117.51 233.02 126.06 233.02 134.94C233.02 143.82 232.09 152.37 230.23 160.59C228.43 168.75 225.52 176.04 221.5 182.46C217.54 188.88 212.32 193.98 205.84 197.76C199.42 201.48 191.59 203.34 182.35 203.34ZM182.35 176.25C185.29 176.25 187.81 175.05 189.91 172.65C192.01 170.25 193.63 166.05 194.77 160.05C195.91 154.05 196.48 145.68 196.48 134.94C196.48 124.14 195.91 115.77 194.77 109.83C193.63 103.83 192.01 99.63 189.91 97.23C187.81 94.83 185.29 93.63 182.35 93.63C179.47 93.63 176.95 94.83 174.79 97.23C172.69 99.63 171.07 103.83 169.93 109.83C168.79 115.77 168.22 124.14 168.22 134.94C168.22 145.74 168.79 154.14 169.93 160.14C171.07 166.08 172.69 170.25 174.79 172.65C176.95 175.05 179.47 176.25 182.35 176.25Z"
            fill="white"
          />
          <path
            d="M58.8705 202V179.41H0.730469V155.38L48.0705 69.25H94.2405V153.4H108.82V179.41H94.2405V202H58.8705ZM58.8705 153.4V107.05L34.4805 153.4H58.8705Z"
            fill="white"
          />
          <path
            d="M312.87 201V178.41H254.73V154.38L302.07 68.25H348.24V152.4H362.82V178.41H348.24V201H312.87ZM312.87 152.4V106.05L288.48 152.4H312.87Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="text-center w-1/2 mt-8 md:mt-0">
        <h1>Sorry, we couldn't find your page</h1>
        <p>We suggest you go to Homepage</p>

        <Button class="inline-block mt-8" @click="$router.push('/')"
          >Homepage</Button
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/controls/Button'
</script>
