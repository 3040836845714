import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.333 1.513v2.754c0 .373 0 .56.073.702a.67.67 0 0 0 .291.292c.143.072.33.072.703.072h2.754M2.667 9.002v2.466c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218H6M2.667 9V4.534c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.875c.428-.218.988-.218 2.108-.218h2.14c.49 0 .735 0 .965.056a2 2 0 0 1 .578.24c.202.123.375.296.72.642l2.126 2.125c.346.346.519.52.643.72q.165.271.24.579c.054.23.054.475.054.964v2.342m-10.666 0H6m7.333 0v2.466c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.427.218-.987.218-2.108.218H10M13.333 9H10m-4 0v5.666M6 9h4m-4 5.666h4M10 9v5.666" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }