<template>
  <EmptyAlert :title="$t('ui.common.no_permission')">
    {{ $t('ui.common.no_permission_info') }}
  </EmptyAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import EmptyAlert from '@/components/misc/EmptyAlert'

export default defineComponent({
  components: {
    EmptyAlert,
  },
})
</script>
