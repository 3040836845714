import {
  defineComponent,
  h,
  withDirectives,
  Teleport,
  Transition,
  type PropType,
} from 'vue'

import ClickOutside from '@/components/directives/click-outside'
import ResizableMixin from '@/components/mixins/resizable'

import { findScrollTarget } from '@/helpers/scroll'
import type { MenuPosition } from '@/types/components/menu'

export default defineComponent({
  mixins: [ResizableMixin],

  props: {
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number as PropType<number | null>,
      default: null,
    },
    noMaxHeight: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String as PropType<MenuPosition>,
      default: 'right',
    },
    anchorElement: {
      type: HTMLElement as PropType<HTMLElement | null>,
      default: null,
    },
    include: {
      type: Array as PropType<HTMLElement[]>,
      default: () => [],
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      showing: false,
      scrollTarget: null as Element | (Window & typeof globalThis) | null,
      offset: 4,
    }
  },

  watch: {
    modelValue(val) {
      if (val !== this.showing) {
        if (val === true) {
          this.show()
        } else {
          this.hide()
        }
      }
    },
  },

  beforeMount() {
    this.observeElementResize = document.body
  },

  methods: {
    show() {
      this.showing = true
      this.$nextTick(() => {
        this._addScrollListener()
        this._setDimensions()
      })
    },
    hide() {
      if (!this.showing) {
        return
      }
      this._removeScrollListener()
      this.showing = false
    },
    onResize() {
      if (this.showing) {
        this._setDimensions()
      }
    },
    async _setDimensions() {
      if (!this.$refs.element) {
        return
      }

      const anchorElm =
        this.anchorElement || ((this.$parent?.$el || this.$el) as HTMLElement)
      const elm = this.$refs.element as HTMLElement
      const style = elm.style
      const { top: anchorTop, left: anchorLeft } =
        anchorElm.getBoundingClientRect()
      const minWidth = this.maxWidth
        ? Math.min(this.maxWidth, anchorElm.offsetWidth)
        : anchorElm.offsetWidth
      const maxWidth = this.maxWidth
        ? this.maxWidth
        : anchorLeft + anchorElm.offsetWidth - this.offset
      style.minWidth = `${minWidth}px`
      style.maxWidth = `${maxWidth}px`
      await this.$nextTick()
      const innerHeight = window.innerHeight
      const elmWidth = elm.offsetWidth
      const elmHeight = elm.offsetHeight

      let targetTop = Math.max(
        this.offset,
        anchorTop + anchorElm.offsetHeight + this.offset
      )
      // show on top
      if (targetTop + elmHeight > innerHeight) {
        targetTop = Math.min(
          innerHeight - elmHeight - this.offset,
          Math.max(0, anchorTop - elmHeight - this.offset)
        )
      }
      // position left
      let targetLeft = anchorLeft
      if (this.position == 'right') {
        targetLeft -= elmWidth - anchorElm.offsetWidth
      } else if (this.position == 'center') {
        targetLeft -= (elmWidth - anchorElm.offsetWidth) / 2
      }
      if (targetLeft < 0) {
        targetLeft = 0
      }
      style.top = `${targetTop}px`
      style.left = `${targetLeft}px`
    },
    _closeMenu() {
      this.$emit('update:modelValue', false)
    },
    _removeScrollListener() {
      if (this.scrollTarget !== null) {
        this.scrollTarget.removeEventListener('scroll', this._setDimensions)
        this.scrollTarget = null
      }
    },
    _addScrollListener() {
      if (this.scrollTarget === null) {
        this.scrollTarget = findScrollTarget(this.$parent?.$el ?? this.$el)
        this.scrollTarget?.addEventListener('scroll', this._setDimensions, {
          passive: true,
        })
      }
    },
  },

  render() {
    let contentStaticClass = 'overflow-y-auto h-auto'
    if (!this.noMaxHeight) {
      contentStaticClass += 'max-h-[200px] md:max-h-[300px]'
    }
    return h(
      Teleport,
      {
        to: 'body',
      },
      h(
        Transition,
        {
          name: 'fade',
        },
        () => [
          this.showing
            ? withDirectives(
                h(
                  'div',
                  {
                    ...this.$attrs,
                    id: this.id,
                    ref: 'element',
                    class:
                      'fixed z-1000 shadow-control bg-surface border border-outline rounded overflow-hidden',
                    onClick: this._closeMenu,
                  },
                  [
                    h(
                      'div',
                      {
                        class: contentStaticClass,
                      },
                      this.$slots
                    ),
                  ]
                ),
                [
                  [
                    ClickOutside,
                    this.include.length
                      ? {
                          handler: this._closeMenu,
                          include: () => this.include,
                        }
                      : this._closeMenu,
                  ],
                ]
              )
            : null,
        ]
      )
    )
  },
})
