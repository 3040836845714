import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m6 7 1.333 1.333 3-3m3 8.667V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C11.813 2 11.253 2 10.133 2H5.867c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.875.874c-.217.428-.217.988-.217 2.108V14L4.5 12.667 6.167 14 8 12.667 9.833 14l1.667-1.333z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }