<template>
  <div class="bg-surface w-full fixed top-0 z-101">
    <div
      class="flex justify-center h-12 bg-orange-alpha text-orange-53 items-center text-sm md:text-base"
    >
      Your e-mail hasn't been verified yet
      <Spinner v-if="loading" width="40" class="fill-brand" />
      <span
        v-else
        id="app-email-resend-verification"
        class="ml-4 underline cursor-pointer font-medium"
        @click="resend"
      >
        Resend email
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useUserStore } from '@/stores/user'

import Spinner from '@/assets/loaders/Spinner.svg?component'
import UserService from '@/services/user/user'

export default defineComponent({
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: mapState(useUserStore, {
    email: (store) => store.user?.email,
  }),

  methods: {
    resend(): void {
      if (!this.email) {
        return
      }

      this.loading = true
      UserService.resendVerificationEmail(this.email)
        .then(() => {
          this.$bus.emit('success', {
            message: 'A verification e-mail has been sent.',
          })
        })
        .catch((err) => {
          this.$bus.emit('error', err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
