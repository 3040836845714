import ApiService from '@/services/api.service'
import type { AxiosResponse } from 'axios'
import type {
  LatestVersionResponse,
  ReleaseNotesResponse,
} from '@/types/services/system'

export class SystemService {
  static getLatestVersion(): Promise<AxiosResponse<LatestVersionResponse>> {
    return ApiService.get('/version.json')
  }

  static getReleaseNotes(
    until_tag?: string | null
  ): Promise<AxiosResponse<ReleaseNotesResponse>> {
    let url = '/api/v1/release_notes'
    if (until_tag) {
      url += `?until_tag=${until_tag}`
    }
    return ApiService.get(url)
  }
}
