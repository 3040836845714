import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M13.635 8.595a5.667 5.667 0 0 1-10.543 2.238l-.166-.288m-.562-3.14a5.667 5.667 0 0 1 10.543-2.238l.167.288M2.329 12.044l.488-1.822 1.821.489m6.724-5.422 1.821.488.488-1.821" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }