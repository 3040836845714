import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M1.499 6.751a.46.46 0 0 1 .305-.45L5.45 4.876c.718-.28 1.059-.629 1.059-1.861 0-.142.071-.277.191-.365a.53.53 0 0 1 .428-.084l6.461 1.475c.223.052.38.237.38.45v.07c0 1.369.001 2.273-1.808 2.98-1.211.473-3.435 1.447-3.458 1.457a.52.52 0 0 1-.36.024L1.851 7.171a.47.47 0 0 1-.352-.42M1.562 9.669l6.959 1.986 5.975-2.483M1.568 12.293l6.958 1.985 5.976-2.481" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }