<template>
  <div @click.stop>
    <Label
      v-if="label"
      :label="label"
      :help="help"
      :hide-asterisk="hideAsterisk"
      :required="required"
      class="mb-0 cursor-pointer"
    >
      <input
        :id="id"
        ref="checkbox"
        v-model="internalValue"
        :value="inputValue"
        :disabled="disabled"
        type="checkbox"
        class="crypkit-checkbox"
      />
    </Label>

    <input
      v-else
      :id="id"
      ref="checkbox"
      v-model="internalValue"
      :value="inputValue"
      :disabled="disabled"
      type="checkbox"
      class="crypkit-checkbox"
    />
  </div>
</template>

<script lang="ts">
import './Checkbox.scss'

import { defineComponent } from 'vue'

import { LabelProps } from '@/components/misc/Label'

import InternalValue from '@/components/mixins/internalValue'

import Label from '../../misc/Label'

export default defineComponent({
  components: {
    Label,
  },

  mixins: [InternalValue, LabelProps],

  props: {
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Boolean, Array],
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputValue: {
      type: [String, Object],
      default: null,
    },
  },

  watch: {
    indeterminate(val) {
      const checkboxElm = this.$refs.checkbox as HTMLInputElement
      checkboxElm.indeterminate = val
    },
  },

  mounted() {
    const checkboxElm = this.$refs.checkbox as HTMLInputElement
    checkboxElm.indeterminate = this.indeterminate
  },
})
</script>
