<template>
  <div
    class="flex items-center rounded px-4 py-2 text-base"
    :class="{
      'bg-background text-brand': type === 'info',
      'bg-orange-alpha text-warning': type === 'warning',
      'bg-red-alpha text-negative': type === 'error',
      'justify-start text-left': align == 'left',
      'justify-center text-center': align == 'center',
      'justify-end text-right': align == 'right',
    }"
  >
    <SvgIcon v-if="icon" :icon="icon" class="w-4 h-4 mr-4 shrink-0" />
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

import SvgIcon from '@/components/misc/SvgIcon'

export default defineComponent({
  components: {
    SvgIcon,
  },

  props: {
    type: {
      type: String as PropType<'info' | 'warning' | 'error'>,
      default: 'info',
    },
    icon: {
      type: String as PropType<string | null>,
      default: null,
    },
    align: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'left',
    },
  },
})
</script>
