import { type AxiosResponse } from 'axios'

import ApiService from '@/services/api.service'
import { currentDateInUTCString } from '@/services/date.service'

import { TagType } from '@/constants/tags'
import {
  type CRUDTagResponse,
  type Tag,
  type TagListResponse,
  type AssignTagsResponse,
  type TagTypeValue,
} from '@/types/services/tags'

class AccountTag {
  /**
   * Create Tag
   * @param rootId ID of root.
   * @param tag Tag to create.
   **/
  static async createTag(
    rootId: string,
    tag: Omit<Tag, 'root_id'>
  ): Promise<AxiosResponse<CRUDTagResponse>> {
    return ApiService.post(`/api/v1/roots/${rootId}/tags`, {
      ...tag,
      request_timestamp: currentDateInUTCString(),
    })
  }

  /**
   * Update Tag
   * @param rootId ID of root.
   * @param tag Tag to update
   **/
  static async updateTag(
    rootId: string,
    tag: Omit<Tag, 'root_id'>
  ): Promise<AxiosResponse<CRUDTagResponse>> {
    const { id, ...data } = tag
    return ApiService.put(`/api/v1/roots/${rootId}/tags/${id}`, data)
  }

  /**
   * Delete Tag
   * @param rootId ID of root.
   * @param tagId ID of tag.
   **/
  static async deleteTag(
    rootId: string,
    tagId: string
  ): Promise<AxiosResponse<CRUDTagResponse>> {
    return ApiService.delete(`/api/v1/roots/${rootId}/tags/${tagId}`)
  }

  /**
   * Assign account target tag to accounts with source tag and then remove source tag.
   * @param rootId ID of root
   * @param sourceTagId ID of source tag
   * @param targetTagId ID of target tag
   */
  static async mergeTags(sourceTagId: string, targetTagId: string) {
    return ApiService.post(`/api/v1/roots/merge_tags`, {
      source_tag_id: sourceTagId,
      target_tag_id: targetTagId,
    })
  }

  /**
   * Assign Account Tags to Account (in batch)
   * @param accountId ID of account.
   * @param rootId ID of root.
   * @param tags Array of tags that assign to the account.
   **/
  static async assignAccountTags(
    accountId: string,
    rootId: string,
    tags: Tag[]
  ): Promise<AxiosResponse<AssignTagsResponse>> {
    const validTags = tags.map((tag) => {
      const _tag = {
        tag_id: tag.id, // empty for new tags
        tag_name: tag.name,
        type: TagType.TAG,
      }
      return _tag
    })

    return ApiService.post(`/api/v1/accounts/${accountId}/tags`, {
      root_id: rootId,
      tags: validTags,
    })
  }

  /**
   * Remove Account Tag from Account
   * @param accountId ID of account.
   * @param tagId ID of tag.
   **/
  static async removeAccountTag(
    accountId: string,
    tagId: string
  ): Promise<AxiosResponse<any>> {
    return ApiService.delete(`/api/v1/accounts/${accountId}/tags/${tagId}`)
  }

  /**
   * Get Tags
   * @param rootId ID of root.
   * @param type Type of tag.
   **/
  static async getTags(
    rootId: string,
    type?: TagTypeValue
  ): Promise<AxiosResponse<TagListResponse>> {
    const body: any = {
      aggr: {
        params: {
          root_id: rootId,
        },
      },
    }

    if (type) {
      body.aggr.params.tag_type = type
    }

    return ApiService.post(`/api/v1/tag/search/get_tag_list`, body)
  }
}

export default AccountTag
