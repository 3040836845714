import ApiService from '@/services/api.service'

import type { AxiosResponse, AxiosRequestConfig } from 'axios'
import type {
  UserSettings,
  UserSettingsResponse,
} from '@/types/services/user_settings'

class UserSettingsService {
  /**
   * Get User Settings
   */
  static getUserSettings(
    user_id: string,
    auth_token?: string
  ): Promise<AxiosResponse<UserSettingsResponse>> {
    const config: AxiosRequestConfig = {
      url: ApiService.getResourceUrl(`/api/v2/users/${user_id}/user_settings`),
      method: 'get',
    }

    if (auth_token) {
      config.headers = {
        Authorization: auth_token,
      }
    }

    return ApiService.customRequest(config)
  }

  /**
   * Update User Settings
   **/
  static updateUserSettings(
    user_id: string,
    data: Partial<UserSettings>
  ): Promise<AxiosResponse<UserSettingsResponse>> {
    return ApiService.patch(`/api/v2/users/${user_id}/user_settings`, data)
  }
}

export default UserSettingsService
