import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M1.33 7.706c0 3.84 3.047 6.954 6.806 6.954 3.083 0 5.688-2.095 6.524-4.967.024-.078-.126-.034-.203-.002-2.604 1.106-5.557.704-7.499-1.28C5.22 6.635 4.722 4.03 5.413 1.597c.016-.096.039-.284-.001-.266C3.009 2.405 1.33 4.855 1.33 7.706" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }