import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8 6v2.667m0 2.667h.006m-.93-8.74-5.483 9.472c-.304.525-.456.788-.433 1.003.02.188.118.36.27.47.176.128.48.128 1.087.128h10.966c.607 0 .91 0 1.086-.128a.67.67 0 0 0 .27-.47c.023-.215-.129-.478-.433-1.003L8.923 2.595c-.303-.524-.455-.786-.652-.873a.67.67 0 0 0-.542 0c-.198.087-.35.35-.652.873" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }