import { defineStore } from 'pinia'
import { HttpError } from '@/helpers/errors'
import { SystemService } from '@/services/system/system'
import type {
  LatestVersionResponse,
  VersionReleaseNotes,
} from '@/types/services/system'

export interface SystemState {
  latest_version: {
    loading: boolean
    data: LatestVersionResponse | null
  }
  release_notes: {
    data: VersionReleaseNotes[]
    loading: boolean
  }
}
export const useSystemStore = defineStore('system', {
  state(): SystemState {
    return {
      latest_version: {
        loading: true,
        data: null,
      },
      release_notes: {
        data: [],
        loading: true,
      },
    }
  },

  actions: {
    async getLatestVersion() {
      this.$patch((state) => {
        state.latest_version.data = null
        state.latest_version.loading = true
      })

      try {
        const response = await SystemService.getLatestVersion()
        this.latest_version.data = response.data
      } catch (error) {
        throw new HttpError(error)
      } finally {
        this.latest_version.loading = false
      }
    },

    async getReleaseNotes(until_tag?: string | null) {
      this.$patch((state) => {
        state.release_notes.data = []
        state.release_notes.loading = true
      })

      try {
        const response = await SystemService.getReleaseNotes(until_tag)

        this.$patch((state) => {
          state.release_notes.data = response.data.data.sort((a, b) =>
            b.version.localeCompare(a.version)
          )
          state.release_notes.loading = false
        })
      } catch (error) {
        throw new HttpError(error)
      }
    },
  },
})
