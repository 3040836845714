import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M6.5 5.3H3.4c-.7 0-1.1 0-1.4.1-.2.2-.4.4-.5.7-.2.2-.2.6-.2 1.4v5.1c0 .7 0 1.1.1 1.4q.15.45.6.6c.3.1.7.1 1.4.1h5.1c.7 0 1.1 0 1.4-.1q.45-.15.6-.6c.1-.3.1-.7.1-1.4v-2.5m2.6-8.4c.2-.2.4-.3.7-.3.1 0 .3 0 .4.1.1 0 .2.1.3.2s.2.2.2.3.1.2.1.4c0 .1 0 .3-.1.4 0 .1-.1.2-.2.3L8.8 8.8 7 9.3l.5-1.8z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }