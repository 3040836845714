import { defineComponent } from 'vue'
import { useGlobalStore } from '@/stores/global'
import { registerSW } from 'virtual:pwa-register'
import type { ConfirmMessage } from '@/types/components/snack_bar'

type UpdateSW = (reloadPage?: boolean) => Promise<void>

const intervalMS = 60 * 60 * 1000

export default defineComponent({
  data() {
    return {
      updateSW: undefined as UpdateSW | undefined,

      globalStore: useGlobalStore(),
    }
  },

  async mounted() {
    try {
      this.updateSW = registerSW({
        immediate: true,
        onNeedRefresh: () => {
          this.onNeedRefreshFn()
        },
        onRegisteredSW: (swUrl, swRegistration) => {
          if (swRegistration) {
            this.handleSWManualUpdates(swRegistration)

            this.addHandleUpdateEvent()
          }
        },
        onRegisterError: (e) => {
          this.handleSWRegisterError(e)
        },
      })
    } catch {
      console.log('PWA disabled.')
    }
  },

  methods: {
    onNeedRefreshFn() {
      const message: ConfirmMessage = {
        message: 'New content available, click on reload button to update.',
        confirmText: 'Reload',
        confirmAction: () => this.updateServiceWorker(),
        confirmIcon: 'Refresh',
        cancelText: 'Close',
        cancelIcon: 'Close',
      }
      this.$bus.emit('confirm', message)
    },
    async updateServiceWorker() {
      if (this.updateSW) {
        try {
          await this.updateSW(true)
        } catch (error) {
          this.$bus.emit('error', error)
        }
      }
    },
    handleSWManualUpdates(r: ServiceWorkerRegistration) {
      if (r) {
        setInterval(() => {
          r.update().catch((error) => {
            this.$bus.emit('error', error)
          })
        }, intervalMS)
      }
    },
    handleSWRegisterError(error: any) {
      this.$bus.emit('error', error)
    },
    addHandleUpdateEvent() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', async (event) => {
          // Optional: ensure the message came from workbox-broadcast-update
          if (event.data.meta === 'workbox-broadcast-update') {
            const { cacheName, updatedURL } = event.data.payload

            if (updatedURL.endsWith('/static/currencies.json')) {
              const cache = await caches.open(cacheName)
              const updatedResponse = await cache.match(updatedURL)
              if (updatedResponse) {
                const updatedText = await updatedResponse.text()
                const response = JSON.parse(updatedText)
                this.globalStore.setAllCurrencies(response)
              }
            }
          }
        })
      }
    },
  },
})
