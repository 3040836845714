import { addMethod, string } from 'yup'
import { checkPasswordStrength } from '@/services/password.service'
import i18n from '@/langs'

addMethod(string, 'passStrength', function () {
  // eslint-disable-next-line no-invalid-this
  return this.test(
    'passStrength',
    i18n.global.t('validation.password_is_weak'),
    (value) => {
      if (!value) {
        return false
      }

      const strength = checkPasswordStrength(value)
      return strength >= 3
    }
  )
})

addMethod(string, 'alphaNum', function () {
  // eslint-disable-next-line no-invalid-this
  return this.test(
    'alphaNum',
    i18n.global.t('validation.alpha_num'),
    (value) => {
      return value ? /^[0-9A-Z]*$/iu.test(value) : false
    }
  )
})
