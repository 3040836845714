/**
 * Based on https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/services/breakpoint/index.ts
 */

import { options as defaultOptions } from './default'

// Types
import {
  type Breakpoints as IBreakpoints,
  type BreakpointsOptions,
} from '@/types/plugins/breakpoints'

export default class BreakpointsPlugin implements IBreakpoints {
  public xs = false
  public sm = false
  public md = false
  public lg = false
  public xl = false
  public xxl = false

  public xsOnly = false
  public smOnly = false

  public smAndDown = false
  public smAndUp = false

  public mdOnly = false
  public mdAndDown = false
  public mdAndUp = false

  public lgOnly = false
  public lgAndDown = false
  public lgAndUp = false

  public xlOnly = false
  public xlAndDown = false
  public xlAndUp = false

  public xxlOnly = false

  public name: IBreakpoints['name'] = 'xs'

  public height = 0
  public width = 0

  public mobile = true

  public mobileBreakpoint: IBreakpoints['mobileBreakpoint']

  public thresholds: IBreakpoints['thresholds']

  constructor(options: BreakpointsOptions = {}) {
    this.mobileBreakpoint =
      options.mobileBreakpoint ?? defaultOptions.mobileBreakpoint

    this.thresholds = {
      ...defaultOptions.thresholds,
      ...options.thresholds,
    }
  }

  init() {
    this.update()

    window.addEventListener('resize', this.update.bind(this), { passive: true })
  }

  /* eslint-disable-next-line complexity */
  update() {
    const height = BreakpointsPlugin.getClientHeight()
    const width = BreakpointsPlugin.getClientWidth()

    const xs = width < this.thresholds.sm
    const sm = width < this.thresholds.md && !xs
    const md = width < this.thresholds.lg && !(sm || xs)
    const lg = width < this.thresholds.xl && !(md || sm || xs)
    const xl = width < this.thresholds.xxl && !(lg || md || sm || xs)
    const xxl = width >= this.thresholds.xxl

    this.height = height
    this.width = width

    this.xs = xs
    this.sm = sm
    this.md = md
    this.lg = lg
    this.xl = xl
    this.xxl = xxl

    this.xsOnly = xs
    this.smOnly = sm
    this.smAndDown = (xs || sm) && !(md || lg || xl || xxl)
    this.smAndUp = !xs && (sm || md || lg || xl || xxl)
    this.mdOnly = md
    this.mdAndDown = (xs || sm || md) && !(lg || xl || xxl)
    this.mdAndUp = !(xs || sm) && (md || lg || xl || xxl)
    this.lgOnly = lg
    this.lgAndDown = (xs || sm || md || lg) && !(xl || xxl)
    this.lgAndUp = !(xs || sm || md) && (lg || xl || xxl)
    this.xlOnly = xl
    this.xlAndDown = (xs || sm || md || lg) && !xxl
    this.xlAndUp = !(xs || sm || md) && (xl || xxl)
    this.xxlOnly = xxl

    switch (true) {
      case xs:
        this.name = 'xs'
        break
      case sm:
        this.name = 'sm'
        break
      case md:
        this.name = 'md'
        break
      case lg:
        this.name = 'lg'
        break
      case xl:
        this.name = 'xl'
        break
      default:
        this.name = 'xxl'
        break
    }

    if (typeof this.mobileBreakpoint === 'number') {
      this.mobile = width <= this.mobileBreakpoint

      return
    }

    const breakpoints = {
      xs: 0,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 4,
      xxl: 5,
    }

    const current = breakpoints[this.name]
    const max = breakpoints[this.mobileBreakpoint]

    this.mobile = current <= max
  }

  // Cross-browser support as described in:
  // https://stackoverflow.com/questions/1248081
  static getClientWidth() {
    return Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
  }

  static getClientHeight() {
    return Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )
  }
}
