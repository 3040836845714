import { createI18n } from 'vue-i18n'
import { createYupI18n } from './yup-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

const i18n = createI18n({
  locale: 'en',
  messages,
})

createYupI18n(i18n.global.t)

export default i18n
