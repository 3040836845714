import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M2.667 10s.666-.666 2.666-.666 3.334 1.333 5.334 1.333S13.333 10 13.333 10V2s-.666.667-2.666.667-3.334-1.333-5.334-1.333S2.667 2 2.667 2v12.667m6.583-9.79a1.562 1.562 0 1 0 0 2.5" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }