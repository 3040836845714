<template>
  <div class="text-secondary my-2">
    {{ service.name }}
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

import { type ServicesGroup } from '@/stores/services-defi'

export default defineComponent({
  props: {
    service: {
      type: Object as PropType<ServicesGroup>,
      required: true,
    },
  },
})
</script>
