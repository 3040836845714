/**
 * Usage:
 * - v-click-outside="onClickOutside"
 *
 * Options:
 * - closeConditional - Optionally provide a closeConditional handler that returns true or false.
 *   This function determines whether the outside click function is invoked or not.
 * - include - Optionally provide an include function in the options object that returns
 *   an array of HTMLElements. This function determines which additional elements
 *   that the click must be outsides of.
 *
 * Examples:
 * v-click-outside="{
 *   handler: onClickOutsideWithConditional,
 *   closeConditional: () => { return item.isActive },
 * }"
 *
 * v-click-outside="{
 *   handler: onClickOutsideStandard,
 *   include: () => [document.querySelector('.included')],
 * }"
 */

import type { DirectiveBinding } from 'vue'

interface ClickOutsideElement extends HTMLElement {
  _clickOutside?: (e: Event) => void
}

function defaultConditional() {
  return true
}

function directive(e: Event, el: HTMLElement, binding: DirectiveBinding) {
  const handler =
    typeof binding.value === 'function'
      ? binding.value
      : binding.value.handler
        ? binding.value.handler
        : null

  const isActive =
    (typeof binding.value === 'object' && binding.value.closeConditional) ||
    defaultConditional

  // The include element callbacks below can be expensive
  // so we should avoid calling them when we're not active.
  // Explicitly check for false to allow fallback compatibility
  // with non-toggleable components
  if (!e || isActive(e) === false) {
    return
  }

  // Check if additional elements were passed to be included in check
  // (click must be outside all included elements, if any)
  const elements: HTMLElement[] = (
    (typeof binding.value === 'object' && binding.value.include) ||
    (() => [])
  )()
  // Add the root element for the component this directive was defined on
  elements.push(el)

  // Check if it's a click outside our elements, and then if our callback returns true.
  // Non-toggleable components should take action in their callback and return falsy.
  // Toggleable can return true if it wants to deactivate.
  // Note that, because we're in the capture phase, this callback will occur before
  // the bubbling click event on any outside elements.
  if (!elements.some((_el) => _el.contains(e.target as HTMLElement))) {
    setTimeout(() => {
      if (isActive(e) && handler) {
        handler(e)
      }
    }, 0)
  }
}

export const ClickOutside = {
  // #app may not be found
  // if using bind, inserted makes
  // sure that the root element is
  // available, iOS does not support
  // clicks on body
  mounted(el: ClickOutsideElement, binding: DirectiveBinding): void {
    const onClick = (e: Event) => directive(e, el, binding)
    // iOS does not recognize click events on document
    // or body
    const app = document.body
    app.addEventListener('click', onClick, true)
    el._clickOutside = onClick
  },

  unmounted(el: ClickOutsideElement): void {
    if (!el._clickOutside) {
      return
    }

    const app = document.body
    if (app) {
      app.removeEventListener('click', el._clickOutside, true)
    }
    delete el._clickOutside
  },
}

export default ClickOutside
