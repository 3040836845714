import type { Currency } from '@/types/services/global'

/**
 * Formats a currency object for full text search, highlighting the name and symbol if applicable.
 *
 * @param {Currency} currency - The currency object to format.
 * @returns {string} The formatted currency string.
 */
export function formatFullTextSearch(currency: Currency): string {
  let name = currency.name
  let symbol = currency.symbol
  if (currency.highlight) {
    // Can not split text prop by '-' to decompose highlight to name and symbol. Some currencies has '-' in name or symbol.
    if (!currency.highlight.name && !currency.highlight.symbol) {
      name = `<b>${name}</b>`
      symbol = `<b>${symbol}</b>`
    } else {
      name = currency.highlight.name || currency.name
      symbol = currency.highlight.symbol || currency.symbol
    }
  }
  return `${name} <small>${symbol}</small>`
}
