<template>
  <div class="text-center mx-auto max-w-sm mt-10">
    <div
      class="flex items-center justify-center rounded bg-surface border border-outline mb-4 mx-auto text-brand h-12 w-12"
    >
      <SvgIcon :icon="icon" size="lg" class="text-brand" />
    </div>
    <div v-if="title" class="text-xxl font-semibold mb-3">
      {{ title }}
    </div>
    <div class="text-lg text-secondary">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SvgIcon from '@/components/misc/SvgIcon'

export default defineComponent({
  components: {
    SvgIcon,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'BugReport',
    },
  },
})
</script>
