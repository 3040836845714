<template>
  <div class="min-h-screen overflow-y-auto bg-background">
    <div class="p-8">
      <a href="/">
        <LogoFullWhite
          v-if="
            settings.additional.theme == 'dark' ||
            settings.additional.theme == 'black'
          "
          height="40"
          width="120"
        />
        <LogoFull v-else height="40" width="120" />
      </a>
    </div>

    <svg
      version="1.1"
      viewBox="0 0 1440 150"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      class="fixed bottom-0 left-0 right-0 z-1"
    >
      <g
        transform="matrix(1 0 0 .4761 -.43783 .83965)"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Sign-Up" transform="translate(0,-596)">
          <g id="//-Graph_Paths-//" transform="translate(-168.98 596.18)">
            <path
              d="m6 231.94c103.29 21.539 231.1-10.483 383.43-96.066 228.5-128.37 268.32-71.27 387.86 12.398 119.54 83.668 246.99 78.324 351.52-55.824 104.53-134.15 154.09-93.32 191.26-56.137 37.178 37.184 126.42 217.69 266.44 217.69h172.48v83.477l-1753-7.0703z"
              fill="#409ae5"
              style="mix-blend-mode: darken"
            />
            <path
              id="Path"
              d="m0 261.33c88.236 7.9766 164.89-0.83723 229.97-26.441 97.616-38.406 159.89-200.65 248.02-200.65s110.66 140.29 266.76 177.24c156.1 36.953 203.44-70.719 281.55-100.84 78.106-30.117 158.96-31.242 248.84 12.852 89.878 44.094 226.63 111.4 308.21 111.4h182.64v85.512h-1766z"
              fill="#f45978"
              style="mix-blend-mode: multiply"
            />
          </g>
        </g>
      </g>
    </svg>

    <router-view class="relative" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useUserStore } from '@/stores/user'

import LogoFull from '@/assets/images/LogoFull.svg?component'
import LogoFullWhite from '@/assets/images/LogoFullWhite.svg?component'

export default defineComponent({
  name: 'AuthLayout',

  components: {
    LogoFull,
    LogoFullWhite,
  },

  computed: mapState(useUserStore, ['settings']),
})
</script>
