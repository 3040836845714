import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m5.999 11.469 2-2m0 0 2 2m-2-2v4.666M1.332 6.802h8.667m4.666 0v3.867c0 .746 0 1.12-.145 1.405-.128.251-.332.455-.583.583-.285.145-.658.145-1.405.145h-.2m-8.667 0h-.2c-.746 0-1.12 0-1.405-.145a1.33 1.33 0 0 1-.583-.583c-.145-.285-.145-.659-.145-1.405V5.602c0-.747 0-1.12.145-1.405.128-.251.332-.455.583-.583.285-.145.659-.145 1.405-.145H8m4.7 2.031v-2m0 0v-2m0 2h-2m2 0h2" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }