import type { ResponseResult } from './common'
import type { Role } from '@/types/services/user'

export const themeValues = ['light', 'dark', 'black'] as const
export type Theme = (typeof themeValues)[number]
export type AdminsView = 'table' | 'cards'
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6

export interface TourFlow {
  scenario_key: string
  current_step: number
  finished: boolean
}

export interface DefaultSession {
  entity_id: string
  role: Role
}

export interface UserSettings {
  id: string
  user_id: string
  base_currency_id: string
  base_crypto_id: string
  two_factor: 'none'
  two_factor_code: null
  session_timeout: number | null
  confirmed: boolean
  last_seen_new_defi_services: string | null
  additional: {
    hide_small?: number
    hide_small_bool?: boolean
    table_pagination?: number
    date_format?: string
    privacy_mode?: boolean
    profile_icon_color?: string
    theme?: Theme
    tour_flow?: TourFlow | null
    week_starts_on?: DayOfWeek
    admins_view?: AdminsView
    admins_show_deactivated?: boolean
    clients_show_deactivated?: boolean
    referrals_show_deactivated?: boolean
    dashboard_benchmark?: string | null
    default_session?: DefaultSession | null
    last_read_release_notes_version?: string | null
  }
}

export type UserSettingsWithRequiredAdditional = Omit<
  UserSettings,
  'additional'
> & {
  additional: Required<UserSettings['additional']>
}

export interface UserSettingsResponse {
  data: UserSettings
  result: ResponseResult
}
