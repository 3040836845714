import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14 14H4.133c-.746 0-1.12 0-1.405-.145a1.33 1.33 0 0 1-.583-.583C2 12.987 2 12.613 2 11.867V2m2.667 5v4.667m3-8v8m3-4.667v4.667m3-8v8" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }