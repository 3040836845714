<template>
  <CurrenciesSwitcher
    :crypto="currencyCrypto"
    :fiat="currencyFiat"
    :loading="userLoading"
    @change-currency="changeCurrency"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useUserStore } from '@/stores/user'

import CurrenciesSwitcher from '@/components/controls/CurrenciesSwitcher'

import { type ChangeCurrencyEvent } from '@/components/controls/CurrenciesSwitcher/types'

export default defineComponent({
  components: {
    CurrenciesSwitcher,
  },

  data() {
    return {
      userStore: useUserStore(),
    }
  },

  computed: mapState(useUserStore, [
    'currencyCrypto',
    'currencyFiat',
    'userLoading',
  ]),

  methods: {
    async changeCurrency(event: ChangeCurrencyEvent): Promise<void> {
      const { type, currency } = event
      if (!type) {
        return
      }

      try {
        await this.userStore.changeCurrency(type, currency.id)
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
  },
})
</script>
