import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "14",
      height: "14",
      x: "1",
      y: "1",
      rx: "3"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M8 12.75a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5m-.353-7.578L5.172 7.646a.5.5 0 0 0 0 .708l2.475 2.474a.5.5 0 0 0 .707 0l2.475-2.474a.5.5 0 0 0 0-.708L8.354 5.172a.5.5 0 0 0-.707 0",
      "clip-rule": "evenodd"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }