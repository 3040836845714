import { setLocale } from 'yup'

export const createYupI18n = (
  t: (key: string, values?: Array<any> | object) => string
) => {
  setLocale({
    // use constant translation keys for messages without values
    mixed: {
      default: ({ path }) => t('not_valid', { field: path }),
      required: () => t('validation.required'),
    },

    string: {
      email: () => t('validation.email'),
      min: ({ min }) => t('validation.min', { length: min }),
      max: ({ max }) => t('validation.max', { length: max }),
    },

    number: {
      positive: () => t('validation.positive_number'),
      min: ({ min }) => t('validation.min_value', { min }),
      max: ({ max }) => t('validation.max_value', { max }),
    },

    array: {
      min: ({ min }) => t('validation.array_min', { min }),
    },
  })
}
