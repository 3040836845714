<template>
  <div
    class="crypkit-datepicker-popup"
    :class="{
      'crypkit-datepicker-popup-range': isRange,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isRange: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
