<template>
  <div class="text-center">
    <div
      class="inline-block rounded border mx-auto p-3"
      :class="[bgColor, borderColor]"
    >
      <SvgIcon :icon="icon" :color="color" size="lg" block />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue'
import { BG_TINT_COLOR_MAP, BORDER_TINT_COLOR_MAP } from '@/constants/theme'
import SvgIcon from '@/components/misc/SvgIcon/index'
import type { ColorTint } from '@/types/components/common'

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  color: {
    type: String as PropType<ColorTint>,
    default: 'blue',
  },
})

const bgColor = computed(() => BG_TINT_COLOR_MAP[props.color])
const borderColor = computed(() => BORDER_TINT_COLOR_MAP[props.color])
</script>
