import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.25 8.042h-.876c-.814 0-1.22 0-1.552.1-.746.226-1.33.81-1.556 1.556-.1.33-.1.738-.1 1.552m7.292-7.875a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0m5.233 8.716a.6.6 0 0 0 .12.662l.022.021a.728.728 0 1 1-1.03 1.03l-.021-.022a.6.6 0 0 0-.662-.12.6.6 0 0 0-.364.549v.062a.728.728 0 0 1-1.454 0v-.033a.6.6 0 0 0-.393-.55.6.6 0 0 0-.662.12l-.022.023a.728.728 0 1 1-1.029-1.03l.022-.021a.6.6 0 0 0 .12-.662.6.6 0 0 0-.549-.364h-.062a.728.728 0 0 1 0-1.454h.033a.6.6 0 0 0 .55-.393.6.6 0 0 0-.12-.662l-.023-.022a.727.727 0 1 1 1.03-1.029l.021.022a.6.6 0 0 0 .662.12h.03a.6.6 0 0 0 .363-.549v-.062a.728.728 0 0 1 1.454 0v.033a.6.6 0 0 0 .364.55.6.6 0 0 0 .662-.12l.021-.023a.728.728 0 1 1 1.03 1.03l-.022.021a.6.6 0 0 0-.12.662v.03a.6.6 0 0 0 .549.363h.062a.728.728 0 0 1 0 1.454h-.033a.6.6 0 0 0-.55.364m-1.6-1.092a1.091 1.091 0 1 1-2.182 0 1.091 1.091 0 0 1 2.182 0" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }