<template>
  <div v-if="entities.length > 0" class="currencies">
    <div
      v-if="loading"
      class="w-full h-8 bg-hover border border-background rounded pulsating"
      style="width: 120px"
    />
    <CurrencySelect
      v-else
      id="navigation-crypto"
      class="w-35"
      size="sm"
      :model-value="selectedCrypto"
      type="crypto"
      hide-errors
      bulky-border
      @update:model-value="selectCrypto"
    >
      <template #selected-text-prepend>1</template>
    </CurrencySelect>

    <SvgIcon icon="Arrows" class="currencies-arrows text-secondary" />

    <div
      v-if="loading"
      class="w-full h-8 bg-hover border border-background rounded pulsating"
      style="width: 120px"
    />
    <CurrencySelect
      v-else
      id="navigation-fiat"
      :class="currencyValue && currencyValue.length > 8 ? 'w-48' : 'w-44'"
      size="sm"
      :model-value="selectedFiat"
      type="fiat"
      hide-errors
      bulky-border
      @update:model-value="selectFiat"
    >
      <template #selected-text-prepend>
        <Spinner v-if="currencyValue == null" width="30" class="fill-brand" />
        <template v-else>{{ currencyValue }}</template>
      </template>
    </CurrencySelect>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import BigNumber from 'bignumber.js'

import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'

import Spinner from '@/assets/loaders/Spinner.svg?component'

import CurrencySelect from '@/components/controls/CurrencySelect'
import SvgIcon from '@/components/misc/SvgIcon'

import { type Currency } from '@/types/services/global'

export default defineComponent({
  components: {
    CurrencySelect,
    Spinner,
    SvgIcon,
  },

  data() {
    return {
      userStore: useUserStore(),
    }
  },

  computed: {
    ...mapState(useGlobalStore, ['currenciesRatio']),
    ...mapState(useUserStore, {
      entities: (store) => store.entities,
      selectedCrypto: (store) => store.currencyCrypto,
      selectedFiat: (store) => store.currencyFiat,
      loading: (store) => store.userLoading,
    }),
    currencyValue(): string | null {
      const { currenciesRatio } = this

      if (currenciesRatio.loading) {
        return null
      }

      return currenciesRatio.ratio !== null
        ? new BigNumber(1 / currenciesRatio.ratio).decimalPlaces(2).toFormat()
        : '-'
    },
  },

  methods: {
    async selectFiat(currency: Currency): Promise<void> {
      try {
        await this.userStore.changeCurrency('fiat', currency.id)
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
    async selectCrypto(currency: Currency): Promise<void> {
      try {
        await this.userStore.changeCurrency('crypto', currency.id)
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
  },
})
</script>
