import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8 1.333V4m0 8v2.666M4 8H1.333m13.334 0H12m.719 4.719-1.886-1.886m1.886-7.5-1.886 1.886m-7.552 7.5 1.886-1.886m-1.886-7.5 1.886 1.886" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }