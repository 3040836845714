import type { RouteRecordRaw } from 'vue-router'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, implicit-arrow-linebreak */

const DashboardView = () => import('@/views/Dashboard/Index.vue')

const SettingsView = () => import('@/views/Settings/Index.vue')
const AccountSettingsView = () => import('@/views/Settings/Account/Index.vue')
const SettingsFundsView = () => import('@/views/Settings/Fund/Index.vue')
const SettingsPortfoliosView = () =>
  import('@/views/Settings/Portfolios/Index.vue')
const SettingsAdminsView = () => import('@/views/Settings/Admins/Index.vue')
const SettingsAdminsListView = () =>
  import('@/views/Settings/Admins/AdminsList.vue')
const SettingsAdminDetailView = () =>
  import('@/views/Settings/Admins/Detail/Index.vue')
const SettingsAdminsActivityLogView = () =>
  import('@/views/Settings/Admins/ActivityLog/Index.vue')
const Accounting = () => import('@/views/Accounting/Index.vue')
const AccountingDashboard = () =>
  import('@/views/Accounting/Dashboard/Index.vue')
const AccountingSettings = () => import('@/views/Settings/Accounting/Index.vue')
const AccountingChartOfAccounts = () =>
  import('@/views/Accounting/ChartOfAccounts/Index.vue')
const AccountingChartOfAccountsList = () =>
  import('@/views/Accounting/ChartOfAccounts/List.vue')
const AccountingChartsDetail = () =>
  import('@/views/Accounting/ChartOfAccounts/Detail.vue')
const AccountingNewAccount = () =>
  import('@/views/Accounting/ChartOfAccounts/New.vue')
const AccountingEditAccount = () =>
  import('@/views/Accounting/ChartOfAccounts/Edit.vue')
const AccountingDeleteAccountModal = () =>
  import('@/views/Accounting/ChartOfAccounts/Delete.vue')
const AccountingJournal = () => import('@/views/Accounting/Journal/Index.vue')
const AccountingJournalForm = () =>
  import('@/views/Accounting/Journal/Form.vue')
const AccountingReport = () =>
  import('@/views/Accounting/ReportsTools/Index.vue')
const AccountingReportMain = () =>
  import('@/views/Accounting/ReportsTools/ReportsToolsMain.vue')
const AccountingReportTrialBalance = () =>
  import('@/views/Accounting/ReportsTools/TrialBalance.vue')
const AccountingReportBalanceSheet = () =>
  import('@/views/Accounting/ReportsTools/BalanceSheet.vue')
const AccountingReportPnl = () =>
  import('@/views/Accounting/ReportsTools/Pnl.vue')

const AccountingTransactionsView = () =>
  import('@/views/Accounting/Transactions/Index.vue')
const AccountingCryptoTransactions = () =>
  import('@/views/Accounting/Transactions/Crypto/Index.vue')
const AccountingFiatTransactions = () =>
  import('@/views/Accounting/Transactions/Fiat/Index.vue')

const AccountingContacts = () => import('@/views/Accounting/Contacts/Index.vue')
const AccountingContactsList = () =>
  import('@/views/Accounting/Contacts/List.vue')
const AccountingContactsActiveView = () =>
  import('@/views/Accounting/Contacts/Active.vue')
const AccountingContactsInactiveView = () =>
  import('@/views/Accounting/Contacts/InActive.vue')
const AccountingContactDetail = () =>
  import('@/views/Accounting/Contacts/Detail.vue')

const AccountingPurchaseInvoicesIndex = () =>
  import('@/views/Accounting/Invoices/PurchaseInvoices/Index.vue')
const AccountingPurchaseInvoicesList = () =>
  import('@/views/Accounting/Invoices/PurchaseInvoices/List.vue')
const AccountingPaidPurchaseInvoicesView = () =>
  import('@/views/Accounting/Invoices/PurchaseInvoices/Paid.vue')
const AccountingUnPaidPurchaseInvoicesView = () =>
  import('@/views/Accounting/Invoices/PurchaseInvoices/UnPaid.vue')
const AccountingPartiallyPaidPurchaseInvoicesView = () =>
  import('@/views/Accounting/Invoices/PurchaseInvoices/PartiallyPaid.vue')
const AccountingOverpaidPurchaseInvoicesView = () =>
  import('@/views/Accounting/Invoices/PurchaseInvoices/Overpaid.vue')

const AccountingPurchaseInvoiceDetail = () =>
  import('@/views/Accounting/Invoices/PurchaseInvoices/Detail.vue')
const AccountingNewPurchaseInvoice = () =>
  import('@/views/Accounting/Invoices/PurchaseInvoices/New.vue')
const AccountingEditPurchaseInvoice = () =>
  import('@/views/Accounting/Invoices/PurchaseInvoices/Edit.vue')

const AccountingSalesInvoicesIndex = () =>
  import('@/views/Accounting/Invoices/SalesInvoices/Index.vue')
const AccountingSalesInvoicesList = () =>
  import('@/views/Accounting/Invoices/SalesInvoices/List.vue')
const AccountingPaidSalesInvoicesView = () =>
  import('@/views/Accounting/Invoices/SalesInvoices/Paid.vue')
const AccountingUnPaidSalesInvoicesView = () =>
  import('@/views/Accounting/Invoices/SalesInvoices/UnPaid.vue')
const AccountingPartiallyPaidSalesInvoicesView = () =>
  import('@/views/Accounting/Invoices/SalesInvoices/PartiallyPaid.vue')
const AccountingOverpaidSalesInvoicesView = () =>
  import('@/views/Accounting/Invoices/SalesInvoices/Overpaid.vue')

const AccountingSalesInvoiceDetailView = () =>
  import('@/views/Accounting/Invoices/SalesInvoices/Detail.vue')
const AccountingNewSalesInvoice = () =>
  import('@/views/Accounting/Invoices/SalesInvoices/New.vue')
const AccountingEditSalesInvoice = () =>
  import('@/views/Accounting/Invoices/SalesInvoices/Edit.vue')

const FundView = () => import('@/views/Fund/Index.vue')
const FundCrmView = () => import('@/views/Fund/CRM/Index.vue')
const FundCrmClientsView = () => import('@/views/Fund/CRM/Clients.vue')
const FundCrmClientDetailView = () =>
  import('@/views/Fund/CRM/ClientDetail/Index.vue')
const FundCrmClientFundView = () => import('@/views/Fund/CRM/ClientFund.vue')
const FundFeesView = () => import('@/views/Fund/Fees/Index.vue')
const FundSeriesView = () => import('@/views/Fund/Series/Index.vue')

const AssetsView = () => import('@/views/Assets/Index.vue')
const AssetsPortfolioView = () => import('@/views/Assets/Portfolio/Index.vue')
const AssetSourcesView = () => import('@/views/Assets/AssetSources/Index.vue')
const AssetsExchangesView = () =>
  import('@/views/Assets/AssetSources/Exchanges/Index.vue')
const AssetsWalletsView = () =>
  import('@/views/Assets/AssetSources/Wallets/Index.vue')
const AssetsCryptoAssetsView = () =>
  import('@/views/Assets/AssetSources/CryptoAssets/Index.vue')
const AssetsCustomEntriesView = () =>
  import('@/views/Assets/AssetSources/CustomEntries/Index.vue')

const DeFiView = () => import('@/views/DeFi/Index.vue')
const LiquidityProvidingView = () =>
  import('@/views/DeFi/LiquidityProviding/Index.vue')

const StatisticsView = () => import('@/views/Statistics/Index.vue')
const StatisticsPerformanceIndexView = () =>
  import('@/views/Statistics/Performance/Index.vue')
const StatisticsPerformanceView = () =>
  import('@/views/Statistics/Performance/Performance/Index.vue')
const StatisticsPerformanceComparePeriodsView = () =>
  import('@/views/Statistics/Performance/ComparePeriods/Index.vue')
const StatisticsPerformanceTechnicalAnalysisView = () =>
  import('@/views/Statistics/Performance/TechnicalAnalysis/Index.vue')
const StatisticsPortfolioMakerView = () =>
  import('@/views/Statistics/PortfolioMaker/Index.vue')
const StatisticsRebalanceView = () => import('@/views/Statistics/Rebalance.vue')

// const TradesView = () => import('@/views/Trades/Index.vue')
// const CexView = () => import('@/views/Trades/CEX.vue')
// const CexSpotView = () => import('@/views/Trades/CEX/Spot.vue')
// const CexFuturesView = () => import('@/views/Trades/CEX/Futures.vue')
// const CexOptionsView = () => import('@/views/Trades/CEX/Options.vue')

const ServicesView = () => import('@/views/Services/ServicesIndex.vue')
const SupportedServicesView = () =>
  import('@/views/Services/SupportedServices/Index.vue')
const DeFiSupportedServicesView = () =>
  import('@/views/Services/SupportedServices/DeFi/Index.vue')
const MonitoringView = () =>
  import('@/views/Services/Monitoring/MonitoringIndex.vue')
const ReleaseNotesView = () => import('@/views/ReleaseNotes/Index.vue')

const OnePagerLayout = () => import('@/layouts/OnePager.vue')
/* eslint-enable @typescript-eslint/explicit-module-boundary-types, implicit-arrow-linebreak */

import NoPermissionView from '@/views/Auth/NoPermission.vue'

import AppLayout from '@/layouts/App.vue'

import i18n from '../langs'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: AppLayout,
    children: [
      {
        path: '/',
        alias: '/dashboard',
        name: 'DashboardView',
        component: DashboardView,
        meta: {
          name: i18n.global.t('ui.common.dashboard'),
          permission: 2,
        },
      },
      {
        path: '/assets',
        name: 'AssetsView',
        component: AssetsView,
        meta: {
          permission: 3,
          packages: [2, 3, 5, 37, 43, 47],
        },
        children: [
          {
            path: '/assets/portfolio/:types?',
            name: 'AssetsPortfolioView',
            component: AssetsPortfolioView,
            meta: {
              name: i18n.global.t('ui.common.portfolio'),
              permission: 5,
            },
          },
          {
            path: '/portfolio/sources',
            name: 'AssetSourcesView',
            redirect: {
              name: 'AssetsExchangesView',
            },
            component: AssetSourcesView,
            meta: {
              name: i18n.global.t('ui.common.asset_sources'),
            },
            children: [
              {
                path: '/portfolio/exchanges/:id?',
                name: 'AssetsExchangesView',
                component: AssetsExchangesView,
                meta: {
                  name: i18n.global.t('ui.common.asset_sources'),
                  permission: 7,
                },
              },
              {
                path: '/portfolio/wallets/:id?',
                name: 'AssetsWalletsView',
                component: AssetsWalletsView,
                meta: {
                  name: i18n.global.t('ui.common.asset_sources'),
                  permission: 13,
                },
              },
              {
                path: '/assets/manual-entries/crypto-assets/:id?',
                name: 'AssetsCryptoAssetsView',
                component: AssetsCryptoAssetsView,
                meta: {
                  name: i18n.global.t('ui.common.asset_sources'),
                  permission: 23,
                },
              },
              {
                path: '/assets/manual-entries/custom-entries/:id?',
                name: 'AssetsCustomEntriesView',
                component: AssetsCustomEntriesView,
                meta: {
                  name: i18n.global.t('ui.common.asset_sources'),
                  permission: 31,
                },
              },
            ],
          },
        ],
      },
      {
        path: '/defi',
        name: 'DeFiView',
        redirect: '/defi/lp',
        component: DeFiView,
        meta: {
          // TODO: Temporary set to permission for Assets.
          // Change to permissions for DeFi after permissions refactoring.
          permission: 3,
          packages: [2, 3, 5, 37, 43, 47],
        },
        children: [
          {
            path: '/defi/lp',
            name: 'LiquidityProvidingView',
            component: LiquidityProvidingView,
            meta: {
              name: i18n.global.t('ui.common.liquidity_providing'),
            },
          },
        ],
      },
      {
        path: '/fund',
        component: FundView,
        meta: {
          permission: 277,
          packages: [3, 43],
        },
        children: [
          {
            path: '/fund/crm',
            name: 'FundCrmView',
            component: FundCrmView,
            redirect: {
              name: 'FundCrmClientsView',
            },
            meta: {
              name: i18n.global.t('ui.common.clients'),
              permission: 43,
            },
            children: [
              {
                path: '',
                name: 'FundCrmClientsView',
                component: FundCrmClientsView,
                meta: {
                  name: i18n.global.t('ui.common.clients'),
                  permission: 43,
                },
              },
              {
                path: ':id',
                name: 'FundCrmClientDetailView',
                component: FundCrmClientDetailView,
                meta: {
                  name: i18n.global.t('ui.common.client_detail'),
                  permission: 43,
                },
                props: true,
              },
            ],
          },
          {
            path: '/fund/fees',
            name: 'FundFeesView',
            component: FundFeesView,
            meta: {
              name: i18n.global.t('ui.common.fees_and_referrers'),
              permission: 53,
            },
          },
          {
            path: '/fund/series',
            name: 'FundSeriesView',
            component: FundSeriesView,
            meta: {
              name: i18n.global.t('ui.common.series_of_shares'),
              permission: 61,
            },
          },
        ],
      },
      {
        path: '/accounting',
        component: Accounting,
        meta: {
          permission: 149,
          packages: [5, 47],
        },
        children: [
          {
            path: '/accounting/dashboard',
            name: 'AccountingDashboard',
            component: AccountingDashboard,
            meta: {
              name: i18n.global.t('ui.common.accounting'),
            },
          },
          {
            path: '/accounting/contacts',
            name: 'AccountingContacts',
            component: AccountingContacts,
            redirect: '/accounting/contacts/active',
            meta: {
              name: i18n.global.t('ui.common.contacts'),
            },
            children: [
              {
                path: '',
                name: 'AccountingContactsList',
                component: AccountingContactsList,
                children: [
                  {
                    path: '/accounting/contacts/active',
                    name: 'AccountingContactsActiveView',
                    component: AccountingContactsActiveView,
                    meta: {
                      name: i18n.global.t('ui.common.contacts'),
                    },
                  },
                  {
                    path: '/accounting/contacts/inactive',
                    name: 'AccountingContactsInactiveView',
                    component: AccountingContactsInactiveView,
                    meta: {
                      name: i18n.global.t('ui.common.contacts'),
                    },
                  },
                ],
              },
              {
                path: '/accounting/contacts/:id',
                name: 'AccountingContactDetail',
                component: AccountingContactDetail,
                meta: {
                  name: i18n.global.t('ui.common.contact_detail'),
                },
              },
            ],
          },
          {
            path: '/accounting/purchase-invoices',
            name: 'AccountingPurchaseInvoices',
            component: AccountingPurchaseInvoicesIndex,
            redirect: '/accounting/purchase-invoices/unpaid',
            meta: {
              name: i18n.global.t('ui.common.purchase_invoices'),
            },
            children: [
              {
                path: '',
                name: 'AccountingPurchaseInvoicesList',
                component: AccountingPurchaseInvoicesList,
                children: [
                  {
                    path: '/accounting/purchase-invoices/paid',
                    name: 'AccountingPaidPurchaseInvoicesView',
                    component: AccountingPaidPurchaseInvoicesView,
                    meta: {
                      name: i18n.global.t('ui.common.purchase_invoices'),
                    },
                  },
                  {
                    path: '/accounting/purchase-invoices/unpaid',
                    name: 'AccountingUnPaidPurchaseInvoicesView',
                    component: AccountingUnPaidPurchaseInvoicesView,
                    meta: {
                      name: i18n.global.t('ui.common.purchase_invoices'),
                    },
                  },
                  {
                    path: '/accounting/purchase-invoices/partially-paid',
                    name: 'AccountingPartiallyPaidPurchaseInvoicesView',
                    component: AccountingPartiallyPaidPurchaseInvoicesView,
                    meta: {
                      name: i18n.global.t('ui.common.purchase_invoices'),
                    },
                  },
                  {
                    path: '/accounting/purchase-invoices/overpaid',
                    name: 'AccountingOverpaidPurchaseInvoicesView',
                    component: AccountingOverpaidPurchaseInvoicesView,
                    meta: {
                      name: i18n.global.t('ui.common.purchase_invoices'),
                    },
                  },
                ],
              },
              {
                path: '/accounting/purchase-invoices/add',
                name: 'AccountingNewPurchaseInvoice',
                component: AccountingNewPurchaseInvoice,
                meta: {
                  name: i18n.global.t('ui.common.new_purchase_invoice'),
                },
              },
              {
                path: '/accounting/purchase-invoices/edit/:id',
                name: 'AccountingEditPurchaseInvoice',
                component: AccountingEditPurchaseInvoice,
                meta: {
                  name: i18n.global.t('ui.common.edit_purchase_invoice'),
                },
              },
              {
                path: '/accounting/purchase-invoices/:id',
                name: 'AccountingPurchaseInvoiceDetail',
                component: AccountingPurchaseInvoiceDetail,
                meta: {
                  name: i18n.global.t('ui.common.purchase_invoice_detail'),
                },
              },
            ],
          },
          {
            path: '/accounting/sales-invoices',
            name: 'AccountingSalesInvoicesView',
            component: AccountingSalesInvoicesIndex,
            redirect: '/accounting/sales-invoices/unpaid',
            meta: {
              name: i18n.global.t('ui.common.sales_invoices'),
            },
            children: [
              {
                path: '',
                name: 'AccountingPaidSalesInvoicesList',
                component: AccountingSalesInvoicesList,
                children: [
                  {
                    path: '/accounting/sales-invoices/paid',
                    name: 'AccountingPaidSalesInvoicesView',
                    component: AccountingPaidSalesInvoicesView,
                    meta: {
                      name: i18n.global.t('ui.common.sales_invoices'),
                    },
                  },
                  {
                    path: '/accounting/sales-invoices/unpaid',
                    name: 'AccountingUnPaidSalesInvoicesView',
                    component: AccountingUnPaidSalesInvoicesView,
                    meta: {
                      name: i18n.global.t('ui.common.sales_invoices'),
                    },
                  },
                  {
                    path: '/accounting/sales-invoices/partially-paid',
                    name: 'AccountingPartiallyPaidSalesInvoicesView',
                    component: AccountingPartiallyPaidSalesInvoicesView,
                    meta: {
                      name: i18n.global.t('ui.common.sales_invoices'),
                    },
                  },
                  {
                    path: '/accounting/sales-invoices/overpaid',
                    name: 'AccountingOverpaidSalesInvoicesView',
                    component: AccountingOverpaidSalesInvoicesView,
                    meta: {
                      name: i18n.global.t('ui.common.sales_invoices'),
                    },
                  },
                ],
              },
              {
                path: '/accounting/sales-invoices/add',
                name: 'AccountingNewSalesInvoice',
                component: AccountingNewSalesInvoice,
                meta: {
                  name: i18n.global.t('ui.common.new_sales_invoice'),
                },
              },
              {
                path: '/accounting/sales-invoices/edit/:id',
                name: 'AccountingEditSalesInvoice',
                component: AccountingEditSalesInvoice,
                meta: {
                  name: i18n.global.t('ui.common.edit_sales_invoice'),
                },
              },
              {
                path: '/accounting/sales-invoices/:id',
                name: 'AccountingSalesInvoiceDetailView',
                component: AccountingSalesInvoiceDetailView,
                meta: {
                  name: i18n.global.t('ui.common.sales_invoice_detail'),
                },
              },
            ],
          },
          {
            path: '/accounting/chart-of-accounts',
            name: 'AccountingChartOfAccounts',
            component: AccountingChartOfAccounts,
            redirect: {
              name: 'AccountingChartOfAccountsList',
            },
            children: [
              {
                path: '',
                name: 'AccountingChartOfAccountsList',
                component: AccountingChartOfAccountsList,
                children: [
                  {
                    path: '/accounting/chart-of-accounts/add/:period',
                    name: 'AccountingNewAccount',
                    component: AccountingNewAccount,
                  },
                  {
                    path: '/accounting/chart-of-accounts/edit/:id',
                    name: 'AccountingEditAccount',
                    component: AccountingEditAccount,
                  },
                  {
                    path: '/accounting/chart-of-accounts/delete/:id',
                    name: 'AccountingDeleteAccModalView',
                    component: AccountingDeleteAccountModal,
                  },
                ],
              },
              {
                path: '/accounting/chart-of-accounts/detail/:id',
                name: 'AccountingChartsDetailView',
                component: AccountingChartsDetail,
                children: [
                  {
                    path: '/accounting/chart-of-accounts/detail/:id/edit',
                    name: 'AccountingEditDetailAccount',
                    component: AccountingEditAccount,
                  },
                ],
              },
            ],
            meta: {
              name: i18n.global.t('ui.common.carts_of_accounts'),
            },
          },
          {
            path: '/accounting/journal',
            name: 'AccountingJournalView',
            component: AccountingJournal,
            children: [
              {
                path: '/accounting/journal/entry',
                name: 'AccountingJournalEntryNew',
                component: AccountingJournalForm,
              },
              {
                path: '/accounting/journal/entry/:id',
                name: 'AccountingJournalEntryEdit',
                component: AccountingJournalForm,
              },
            ],
            meta: {
              name: i18n.global.t('ui.common.journal'),
            },
          },
          {
            path: '/accounting/transactions',
            name: 'AccountingTransactionsView',
            component: AccountingTransactionsView,
            redirect: '/accounting/transactions/crypto',
            meta: {
              name: i18n.global.t('ui.common.transactions'),
            },
            children: [
              {
                path: '/accounting/transactions/crypto',
                name: 'AccountingCryptoTransactionsView',
                component: AccountingCryptoTransactions,
              },
              {
                path: '/accounting/transactions/fiat',
                name: 'AccountingFiatTransactionsView',
                component: AccountingFiatTransactions,
              },
            ],
          },
          {
            path: '/accounting/reports',
            name: 'AccountingReportView',
            component: AccountingReport,
            redirect: {
              name: 'AccountingReportMain',
            },
            meta: {
              name: i18n.global.t('ui.common.reports_and_tools'),
            },
            children: [
              {
                path: '',
                name: 'AccountingReportMain',
                component: AccountingReportMain,
              },
              {
                path: '/accounting/reports/trial-balance',
                name: 'AccountingReportTrialBalanceView',
                component: AccountingReportTrialBalance,
                meta: {
                  name: i18n.global.t('ui.common.trial_balance'),
                },
              },
              {
                path: '/accounting/reports/balance-sheet',
                name: 'AccountingReportBalanceSheetView',
                component: AccountingReportBalanceSheet,
                meta: {
                  name: i18n.global.t('ui.common.balance_sheet'),
                },
              },
              {
                path: '/accounting/reports/pnl',
                name: 'AccountingReportPnlView',
                component: AccountingReportPnl,
                meta: {
                  name: i18n.global.t('ui.common.profit_and_loss'),
                },
              },
            ],
          },
        ],
      },
      {
        path: '/statistics',
        name: 'StatisticsView',
        component: StatisticsView,
        meta: {
          permission: 71,
          packages: [2, 3, 5, 37, 43, 47],
        },
        children: [
          {
            path: '/statistics/performance',
            name: 'StatisticsPerformanceIndexView',
            component: StatisticsPerformanceIndexView,
            meta: {
              name: i18n.global.t('ui.common.statistics'),
              permission: 73,
            },
            children: [
              {
                path: '',
                name: 'StatisticsPerformanceView',
                component: StatisticsPerformanceView,
              },
              {
                path: '/statistics/performance/compare-periods',
                name: 'StatisticsPerformanceComparePeriodsView',
                component: StatisticsPerformanceComparePeriodsView,
              },
              {
                path: '/statistics/performance/technical-analysis',
                name: 'StatisticsPerformanceTechnicalAnalysisView',
                component: StatisticsPerformanceTechnicalAnalysisView,
              },
            ],
          },
          {
            path: '/statistics/portfolio-maker',
            name: 'StatisticsPortfolioMakerView',
            component: StatisticsPortfolioMakerView,
            meta: {
              name: i18n.global.t('ui.common.portfolio_maker'),
              permission: 79,
            },
          },
          {
            path: '/statistics/rebalance',
            name: 'StatisticsRebalanceView',
            component: StatisticsRebalanceView,
            meta: {
              name: i18n.global.t('ui.common.rebalance'),
              permission: 83,
            },
          },
        ],
      },
      // {
      //   path: '/trades',
      //   name: 'TradesView',
      //   component: TradesView,
      //   meta: {
      //     permission: 89,
      //     packages: [2, 3, 5, 37, 43, 47],
      //   },
      //   children: [
      //     {
      //       path: '/trades/cex',
      //       name: 'CexView',
      //       redirect: {
      //         name: 'CexSpotView',
      //       },
      //       component: CexView,
      //       meta: {
      //         name: i18n.global.t('ui.common.cex'),
      //         permission: 97,
      //       },
      //       children: [
      //         {
      //           path: '/trades/cex/spot',
      //           name: 'CexSpotView',
      //           component: CexSpotView,
      //           meta: {
      //             name: i18n.global.t('ui.common.cex'),
      //           },
      //         },
      //         {
      //           path: '/trades/cex/futures',
      //           name: 'CexFuturesView',
      //           component: CexFuturesView,
      //           meta: {
      //             name: i18n.global.t('ui.common.cex'),
      //           },
      //         },
      //         {
      //           path: '/trades/cex/options',
      //           name: 'CexOptionsView',
      //           component: CexOptionsView,
      //           meta: {
      //             name: i18n.global.t('ui.common.cex'),
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        path: '/account-settings',
        name: 'AccountSettingsView',
        component: AccountSettingsView,
        meta: {
          name: i18n.global.t('ui.common.account_settings'),
        },
      },
      {
        path: '/settings',
        name: 'SettingsView',
        component: SettingsView,
        meta: {
          permission: 191,
        },
        children: [
          {
            path: '/settings/funds',
            name: 'SettingsFundsView',
            component: SettingsFundsView,
            meta: {
              name: i18n.global.t('ui.common.fund_settings'),
              permission: 223,
              packages: [3, 43],
            },
          },
          {
            path: '/settings/portfolios',
            name: 'SettingsPortfoliosView',
            component: SettingsPortfoliosView,
            meta: {
              name: i18n.global.t('ui.common.portfolios'),
              // TODO: Add perission
              // permission: XXX,
              packages: [2, 37],
            },
          },
          {
            path: '/settings/users',
            name: 'SettingsAdminsView',
            component: SettingsAdminsView,
            redirect: {
              name: 'SettingsAdminsListView',
            },
            meta: {
              name: i18n.global.t('ui.common.admins'),
              permission: 199,
              packages: [3, 5, 43, 47],
            },
            children: [
              {
                path: '',
                name: 'SettingsAdminsListView',
                component: SettingsAdminsListView,
              },
              {
                path: 'activity-log',
                name: 'SettingsAdminsActivityLogView',
                component: SettingsAdminsActivityLogView,
              },
              {
                path: ':rootUserId',
                name: 'SettingsAdminDetailView',
                component: SettingsAdminDetailView,
                props: true,
              },
            ],
          },
          {
            path: '/settings/accounting',
            name: 'AccountingSettingsView',
            component: AccountingSettings,
            meta: {
              name: i18n.global.t('ui.common.accounting_settings'),
              permission: 229,
              packages: [5, 47],
            },
          },
        ],
      },
      {
        path: '/services',
        name: 'Services',
        component: ServicesView,
        meta: {
          permission: 331,
        },
        children: [
          {
            path: '/services/supported-services',
            name: 'SupportedServicesView',
            redirect: '/services/supported-services/defi',
            component: SupportedServicesView,
            meta: {
              permission: 283,
              name: i18n.global.t('ui.common.supported_services'),
            },
            children: [
              {
                path: '/services/supported-services/defi',
                name: 'DeFiSupportedServicesView',
                component: DeFiSupportedServicesView,
              },
            ],
          },
          {
            path: '/services/monitoring',
            name: 'MonitoringView',
            component: MonitoringView,
            meta: {
              permission: 313,
              name: i18n.global.t('ui.common.monitoring'),
            },
          },
        ],
      },
      {
        path: '/no-permission',
        name: 'NoPermissionView',
        component: NoPermissionView,
        meta: {
          name: i18n.global.t('ui.common.no_permission'),
        },
      },
      {
        path: '/release-notes',
        name: 'ReleaseNotesView',
        component: ReleaseNotesView,
        meta: {
          name: i18n.global.t('ui.common.release_notes'),
        },
      },
    ],
  },
  {
    path: '/fund/:fundId/view/:entityId',
    component: OnePagerLayout,
    children: [
      {
        path: '',
        name: 'ClientFund',
        component: FundCrmClientFundView,
      },
    ],
  },
]

export default routes
