import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M10.667 14V3.335A1.334 1.334 0 0 0 9.333 2H6.667a1.333 1.333 0 0 0-1.334 1.334V14M2.667 4.667h10.666c.737 0 1.334.596 1.334 1.333v6.666c0 .737-.597 1.334-1.334 1.334H2.667a1.333 1.333 0 0 1-1.334-1.334V6c0-.737.597-1.333 1.334-1.333" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }