import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M7 1.335c-.45.006-.72.033-.939.144-.25.128-.455.332-.582.583-.112.218-.138.488-.144.938M13 1.335c.45.006.72.033.939.144.25.128.454.332.582.583.112.218.138.488.144.938m0 6c-.006.45-.032.72-.144.939-.128.25-.332.455-.582.582-.219.112-.489.138-.94.144m1.668-5.332v1.334M9.333 1.334h1.334m-7.2 13.333h5.066c.747 0 1.12 0 1.406-.146.25-.127.454-.331.582-.582.146-.285.146-.659.146-1.405V7.467c0-.747 0-1.12-.146-1.405a1.33 1.33 0 0 0-.582-.583c-.286-.146-.659-.146-1.406-.146H3.467c-.747 0-1.12 0-1.406.146-.25.128-.455.332-.582.583-.146.285-.146.658-.146 1.405v5.067c0 .746 0 1.12.146 1.405.127.25.331.455.582.582.285.146.659.146 1.406.146" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }