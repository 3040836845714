<template>
  <div
    class="crypkit-base-input"
    :class="{
      'validation-error': !!errorMessage,
      disabled: disabled,
      [size]: size,
      'crypkit-base-input__password': type === 'password',
    }"
  >
    <Label
      v-if="label !== ''"
      :label="label"
      :required="required"
      :hide-asterisk="hideAsterisk"
      :help="help"
    >
      <template v-if="$slots['label-append']" #append>
        <slot name="label-append" />
      </template>
    </Label>

    <div
      v-click-outside="handleClickOutside"
      class="crypkit-base-input-container"
      :class="{
        'bulky-border': bulkyBorder,
      }"
    >
      <div v-if="$slots['prepend']" class="crypkit-base-input-prepend">
        <slot name="prepend" />
      </div>

      <input
        :id="id"
        ref="input"
        v-model="inputValue"
        :required="required"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :name="name"
        :type="inputType"
        :step="step"
        :min="min ?? undefined"
        :max="max ?? undefined"
        @input="handleChange"
        @keyup.enter="enterPressed()"
        @focus="checkForZero('focus')"
        @blur="
          () => {
            checkForZero('blur')
            handleBlur()
          }
        "
      />

      <div
        v-if="type === 'password'"
        class="crypkit-base-input-eye"
        @click.stop="showPassword = !showPassword"
      >
        <SvgIcon v-if="inputType === 'password'" icon="Show" />
        <SvgIcon v-else icon="Hide" />
      </div>

      <div v-if="type === 'number'" class="crypkit-base-input-arrows">
        <div @click="stepUp()">
          <SvgIcon icon="ArrowUp" />
        </div>
        <div @click="stepDown()">
          <SvgIcon icon="ArrowDown" />
        </div>
      </div>

      <div v-if="$slots['append']" class="crypkit-base-input-append">
        <slot name="append" />
      </div>

      <!-- Input confirm message -->
      <div v-if="confirmMessage && showConfirm" class="confirm-messages">
        {{ confirmMessage }}
        <div class="flex justify-end">
          <button class="mr-2" @click.stop="cancel">Cancel</button>
          <button @click.stop="showConfirm = false">Ok</button>
        </div>
      </div>

      <SelectItems
        v-if="autocompleteItemsFiltered.length && autocompleteCollapsed"
        v-model:collapsed="autocompleteCollapsed"
        :options="autocompleteItemsFiltered"
        :group-values="autocompleteGroupValues"
        :position-to="$refs.input as HTMLInputElement"
        @select="selectAutocompleteItem"
      />
    </div>
    <!-- Input validation message -->
    <div v-if="!hideErrors" class="validation-messages">{{ errorMessage }}</div>
  </div>
</template>

<script lang="ts">
import './BasicInput.scss'

import { type PropType, defineComponent, toRef } from 'vue'
import BigNumber from 'bignumber.js'
import { useField } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'

import fuzzySearch from '@/helpers/fuzzySearch'

import ClickOutside from '@/components/directives/click-outside'

import Label from '@/components/misc/Label'
import SvgIcon from '@/components/misc/SvgIcon'
import SelectItems from '@/components/controls/SelectItems'

export default defineComponent({
  components: {
    Label,
    SelectItems,
    SvgIcon,
  },

  directives: {
    ClickOutside,
  },

  props: {
    type: {
      type: String,
      default: 'text',
      required: false,
    },
    size: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: () => uuidv4(),
    },
    modelValue: {
      type: [String, Number] as PropType<string | number | null>,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideAsterisk: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    hideErrors: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    maxlength: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: '',
    },
    step: {
      type: [String, Number],
      default: null,
    },
    min: {
      type: Number as PropType<number | null>,
      default: null,
    },
    max: {
      type: Number as PropType<number | null>,
      default: null,
    },
    confirmMessage: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: null,
    },
    autocompleteItems: {
      type: Array,
      default: (): any[] => [],
    },
    autocompleteItemKey: {
      type: String,
      default: null,
    },
    autocompleteMinChars: {
      type: Number,
      default: 3,
    },
    autocompleteGroupValues: {
      type: String,
      default: null,
    },
    autocompleteSelectedKey: {
      type: String,
      default: 'text',
    },
    bulkyBorder: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submit', 'change', 'autocomplete-selected', 'update:modelValue'],

  setup(props, { emit }) {
    const name = toRef(props, 'name')
    const initialValue = toRef(props, 'modelValue')

    const {
      value: inputValue,
      errorMessage,
      handleBlur,
    } = useField(name, undefined, {
      initialValue,
      validateOnValueUpdate: false,
    })

    const handleChange = () => {
      emit('update:modelValue', inputValue.value)
    }

    return {
      inputValue,
      errorMessage,
      handleBlur,
      handleChange,
    }
  },

  data() {
    return {
      showConfirm: false,
      showPassword: false,
      autocompleteCollapsed: false,
    }
  },

  computed: {
    inputType(): string {
      const { type, showPassword } = this
      if (type != 'password') {
        return type
      }
      return showPassword ? 'text' : 'password'
    },
    autocompleteItemsFiltered(): any[] {
      const {
        autocompleteItems,
        autocompleteMinChars,
        autocompleteGroupValues,
        inputValue,
      } = this
      if (
        autocompleteItems.length === 0 ||
        String(inputValue).length < autocompleteMinChars
      ) {
        return []
      }

      let items: any[] = autocompleteItems
      if (autocompleteGroupValues) {
        const filteredGroups: any[] = []
        items.forEach((group: any) => {
          const groupOptions = fuzzySearch(
            String(inputValue),
            group[autocompleteGroupValues]
          )
          if (groupOptions.length) {
            const filteredGroup = {
              ...group,
              [autocompleteGroupValues]: groupOptions,
            }
            filteredGroups.push(filteredGroup)
          }
        })
        return filteredGroups
      }
      items = this.mapAutocompleteItems(items)
      return fuzzySearch(String(inputValue), items)
    },
  },

  watch: {
    inputValue(value: string | number): void {
      if (value) {
        if (this.confirmMessage) {
          this.showConfirm = true
        }
        if (this.autocompleteItemsFiltered.length) {
          this.autocompleteCollapsed = true
        }
      }
    },
    modelValue(value) {
      if (this.inputValue !== value) {
        this.inputValue = value
      }
    },
  },

  methods: {
    enterPressed(): void {
      this.$emit('submit')
    },
    checkForZero(direction: string): void {
      const isZero = new BigNumber(this.inputValue || '0').isZero()
      if (direction == 'focus') {
        if (this.type == 'number' && isZero) {
          this.$emit('update:modelValue', null)
        }
      } else if (
        this.type == 'number' &&
        (this.inputValue == null || this.inputValue == '')
      ) {
        this.$emit('update:modelValue', 0)
      }
    },
    blur(): void {
      // This method allows validation providers to work
      // with modes like "lazy" or "eager" (which listen on change event)
      this.$emit('change', this.inputValue)
    },
    cancel(): void {
      this.inputValue = ''
      this.showConfirm = false
    },
    handleClickOutside(): void {
      if (this.showConfirm) {
        this.cancel()
      }
      this.autocompleteCollapsed = false
    },
    mapAutocompleteItems(items: any[]): any[] {
      const { autocompleteItemKey } = this
      if (typeof items[0] === 'string') {
        items = items.map((item) => ({ text: item }))
      } else if (autocompleteItemKey) {
        items = items.map((item) => ({
          ...item,
          text: item[autocompleteItemKey],
        }))
      }
      return items
    },
    selectAutocompleteItem(item: any): void {
      const { autocompleteGroupValues, autocompleteSelectedKey } = this
      if (
        item &&
        autocompleteGroupValues &&
        Array.isArray(item[autocompleteGroupValues])
      ) {
        // do not select group header
        return
      }
      this.$emit('update:modelValue', item[autocompleteSelectedKey])
      this.$emit('autocomplete-selected', item)
      this.$nextTick(() => {
        this.autocompleteCollapsed = false
      })
    },
    stepUp(): void {
      const elm = this.$refs.input as HTMLInputElement
      elm.stepUp()
      this.$emit('update:modelValue', elm.value)
    },
    stepDown(): void {
      const elm = this.$refs.input as HTMLInputElement
      elm.stepDown()
      this.$emit('update:modelValue', elm.value)
    },
  },
})
</script>
