<template>
  <div id="account-selection">
    <div
      class="rounded border border-outline bg-surface h-control flex items-center transition-colors select-none hover:border-brand hover:cursor-pointer text-secondary"
      :class="sidebarToggled ? 'px-2.5 justify-between' : 'justify-center'"
      @click="handleClick"
    >
      <template v-if="sidebarToggled">
        <span class="font-medium text-base truncate mr-2">
          <Avatar :entity="avatarEntity" />
          {{ currentEntityName }}
        </span>
        <SvgIcon
          icon="ArrowDown"
          class="shrink-0 w-2.5 h-2.5 mr-0.5"
          :class="{ 'transform rotate-180 text-brand': show }"
        />
      </template>
      <Avatar v-else :entity="avatarEntity" class="mx-0" />
    </div>

    <Menu v-model="show" :max-width="218">
      <template v-if="showChangeRoot">
        <div
          v-for="root in roots"
          :key="root.id"
          class="flex items-center px-3 py-2 cursor-pointer hover:bg-background"
          @click="switchSession(root.session_item)"
        >
          <Avatar :entity="root" />
          <span class="truncate text-base">
            {{ root.text }}
          </span>
        </div>
      </template>
      <template v-else>
        <div class="p-3 border-b flex items-center">
          <Avatar :entity="avatarEntity" class="mr-4" />
          <span class="font-medium text-base truncate mr-auto">
            {{ root.name }}
          </span>
          <span
            v-if="roots.length > 1"
            class="text-xss inline-block cursor-pointer rounded-full bg-outline hover:bg-background transition-colors text-brand font-medium px-2"
            @click.stop="showChangeRoot = true"
          >
            {{ $t('ui.common.change') }}
          </span>
        </div>
        <EntityRolePanels
          v-if="currentRelation"
          :root-relation="currentRelation"
          class="px-3 text-secondary"
          @start-session="switchSession"
        />
      </template>
    </Menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'

import Avatar from '@/components/misc/Avatar'
import Menu from '@/components/misc/Menu'
import SvgIcon from '@/components/misc/SvgIcon'

import EntityRolePanels, {
  type StartSessionItem,
} from '@/components/navigation/components/EntityRolePanels.vue'

import type { AvatarEntity } from '@/types/components/avatar'
import type { Role, SessionRole, UserRootRelation } from '@/types/services/user'

interface RootItem extends AvatarEntity {
  session_item: StartSessionItem
}

export default defineComponent({
  components: {
    EntityRolePanels,
    Avatar,
    Menu,
    SvgIcon,
  },

  data() {
    return {
      show: false,
      showChangeRoot: false,

      globalStore: useGlobalStore(),
      userStore: useUserStore(),
    }
  },

  computed: {
    ...mapState(useGlobalStore, ['sidebarToggled']),
    ...mapState(useUserStore, [
      'currentView',
      'clientEntity',
      'entity',
      'root',
      'relations',
      'user',
    ]),
    currentEntityName() {
      return this.entity?.name || this.clientEntity?.name
    },
    avatarEntity(): AvatarEntity {
      return {
        id: this.root.id,
        text: this.root.name,
      }
    },
    currentRelation(): UserRootRelation | null {
      return this.relations.find((rel) => rel.root_id == this.root.id) || null
    },
    roots(): RootItem[] {
      return (
        this.relations
          // filter superadmin relations without entities (new account)
          .filter((relation) => relation.entities.length)
          .map((relation) => {
            // select the role with the highest priority
            const priorityRoles: SessionRole[] = ['admin', 'referral']
            const role: Role = relation.is_superadmin
              ? 'superadmin'
              : priorityRoles.find((r) => {
                  return relation.entities.some((entity) => {
                    return entity.roles.includes(r)
                  })
                }) || 'client'

            return {
              id: relation.root_id,
              text: relation.root_name,
              session_item: {
                root_id: relation.root_id,
                root_user_entity_id: relation.root_user_entity_id,
                role,
                entity_id: relation.entities[0].entity_id,
              },
            }
          })
          .sort((a, b) => a.text.localeCompare(b.text))
      )
    },
  },

  watch: {
    show(show: boolean) {
      if (show) {
        this.showChangeRoot = false
      }
    },
  },

  methods: {
    handleClick() {
      if (this.sidebarToggled) {
        this.show = !this.show
      } else {
        this.globalStore.toggleSidebar()
      }
    },
    async switchSession(item: StartSessionItem) {
      if (!this.user) {
        return
      }

      try {
        const targetView = item.role == 'superadmin' ? 'admin' : item.role
        const isSameView = targetView == this.currentView
        if (isSameView && item.root_id == this.root.id) {
          await this.userStore.changeEntity(item.entity_id)
        } else {
          this.userStore.isLoggingOut = true
          await this.userStore.startSession({
            user_id: this.user.id,
            root_id: item.root_id,
            entity_id: item.root_user_entity_id,
            role: item.role,
            selected_entity_id: item.entity_id,
          })

          if (isSameView) {
            window.location.reload()
          } else {
            const base = this.$router.options.history.base
            location.href = base != '' && base != '/' ? `/${base}/` : '/'
          }
        }
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
  },
})
</script>
