<template>
  <div
    class="avatar"
    :class="[sizeClass, loading && 'pulsating']"
    :style="{ backgroundColor: color }"
  >
    {{ letter }}
  </div>
</template>

<script lang="ts">
import './Avatar.scss'

import { type PropType, defineComponent } from 'vue'
import { getColorByString } from '@/helpers/colors'

import type { AvatarEntity } from '@/types/components/avatar'

export default defineComponent({
  props: {
    entity: {
      type: Object as PropType<AvatarEntity | null>,
      default: null,
    },
    size: {
      type: String as PropType<'lg' | null>,
      default: null,
    },
  },

  computed: {
    loading(): boolean {
      return !this.entity
    },
    color(): string {
      if (this.loading) {
        return 'var(--color-surface)'
      }
      return getColorByString(this.entity?.id || '')
    },
    sizeClass(): string | null {
      const { size } = this
      if (size) {
        return `avatar-${size}`
      }
      return null
    },
    letter(): string {
      return this.entity?.text?.trim().charAt(0).toUpperCase() || '-'
    },
  },
})
</script>
