import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.6 2H3.067c-.374 0-.56 0-.703.073a.67.67 0 0 0-.291.291C2 2.507 2 2.694 2 3.067V5.6c0 .373 0 .56.073.703a.67.67 0 0 0 .291.291c.143.073.33.073.703.073H5.6c.373 0 .56 0 .703-.073a.67.67 0 0 0 .291-.291c.073-.143.073-.33.073-.703V3.067c0-.374 0-.56-.073-.703a.67.67 0 0 0-.291-.291C6.16 2 5.973 2 5.6 2M12.933 2H10.4c-.373 0-.56 0-.703.073a.67.67 0 0 0-.291.291c-.073.143-.073.33-.073.703V5.6c0 .373 0 .56.073.703a.67.67 0 0 0 .291.291c.143.073.33.073.703.073h2.533c.374 0 .56 0 .703-.073a.67.67 0 0 0 .291-.291C14 6.16 14 5.973 14 5.6V3.067c0-.374 0-.56-.073-.703a.67.67 0 0 0-.291-.291C13.493 2 13.306 2 12.933 2M12.933 9.333H10.4c-.373 0-.56 0-.703.073a.67.67 0 0 0-.291.291c-.073.143-.073.33-.073.703v2.533c0 .374 0 .56.073.703a.67.67 0 0 0 .291.291c.143.073.33.073.703.073h2.533c.374 0 .56 0 .703-.073a.67.67 0 0 0 .291-.291c.073-.143.073-.33.073-.703V10.4c0-.373 0-.56-.073-.703a.67.67 0 0 0-.291-.291c-.143-.073-.33-.073-.703-.073M5.6 9.333H3.067c-.374 0-.56 0-.703.073a.67.67 0 0 0-.291.291C2 9.84 2 10.027 2 10.4v2.533c0 .374 0 .56.073.703a.67.67 0 0 0 .291.291c.143.073.33.073.703.073H5.6c.373 0 .56 0 .703-.073a.67.67 0 0 0 .291-.291c.073-.143.073-.33.073-.703V10.4c0-.373 0-.56-.073-.703a.67.67 0 0 0-.291-.291c-.143-.073-.33-.073-.703-.073" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }