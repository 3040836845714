<template>
  <div
    class="text-center flex flex-col flex-grow items-center justify-center w-full"
  >
    <SvgLogoIcon width="50" class="flex-shrink-0 mb-8" />
    <h1 class="text-xl font-medium mb-4">
      {{ $t('ui.common.no_packages') }}
    </h1>
    <!-- eslint-disable vue/no-v-html -->
    <p class="text-secondary" v-html="$t('messages.no_packages_info')" />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import SvgLogoIcon from '@/assets/images/LogoIcon.svg?component'

export default defineComponent({
  components: {
    SvgLogoIcon,
  },
})
</script>
