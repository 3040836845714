import type { ColorTint } from '@/types/components/common'

export class RGBColor {
  r: number
  g: number
  b: number
  a: number

  constructor(r: number, g: number, b: number, a = 1) {
    this.r = r
    this.g = g
    this.b = b
    this.a = a
  }

  /**
   * Converts the RGB color values to an RGB string.
   *
   * @return {string} The RGB string representation of the RGB color values.
   */
  toRgbString(): string {
    return `rgb(${this.r}, ${this.g}, ${this.b})`
  }

  /**
   * Converts the RGB color values to an RGBA string.
   *
   * @return {string} The RGBA string representation of the RGB color values.
   */
  toRgbaString(): string {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`
  }
}

const colors: { charts: string[]; blueTintCharts: string[]; std: string[] } = {
  charts: [],
  blueTintCharts: [],
  std: [],
}

const tints: ColorTint[] = [
  'aqua',
  'blue',
  'palablue',
  'purple',
  'pink',
  'rose',
  'red',
  'brown',
  'orange',
  'yellow',
  'grass',
  'green',
  'pea',
]

/**
 * Retrieves the value of a CSS property.
 *
 * @param {string} property - The CSS property of the property to retrieve.
 * @return {string} The value of the CSS property.
 */
function getColorFromCss(property: string): string {
  const style = getComputedStyle(document.body)
  return style.getPropertyValue(property)
}

/**
 * Returns colors defined as CSS variables by property
 *
 * @param {string} property - The CSS property to extract colors from
 * @return {string[]} An array of colors extracted from the CSS variables
 */
function getColorsFromCss(property: string): string[] {
  const style = getComputedStyle(document.body)
  const colors: string[] = []
  let i = 1
  let colorCode = style.getPropertyValue(`${property}-${i}`)
  while (colorCode) {
    i++
    colors.push(colorCode.trim())
    colorCode = style.getPropertyValue(`${property}-${i}`)
  }
  return colors
}

/**
 * Returns colors for charts.
 *
 * @return {string[]} An array of chart colors.
 */
function getChartColors(): string[] {
  if (!colors.charts.length && document.body) {
    colors.charts = getColorsFromCss('--color-chart')
  }
  return colors.charts || []
}

/**
 * Returns an array of blue tint colors for charts.
 *
 * @return {string[]} An array of blue tint chart colors.
 */
function getBlueTintChartColors(): string[] {
  if (!colors.blueTintCharts.length && document.body) {
    const blueColorKeys = [43, 53, 63, 73, 83, 93, 96]
    colors.blueTintCharts = blueColorKeys.map(
      (key) => `rgb(${getColorFromCss(`--color-blue-${key}`)})`
    )
  }
  return colors.blueTintCharts || []
}

/**
 * Returns a CSS color value based on the given tint.
 *
 * @param {ColorTint} tint - The tint value to use for the color.
 * @return {string} The CSS color value in the format "rgb(var(--color-{tint}-53))".
 */
function getColorByTint(tint: ColorTint): string {
  return `rgb(var(--color-${tint}-53))`
}

/**
 * Returns the color tint based on the given index.
 *
 * @param {number} index - The index used to determine the color tint.
 * @return {ColorTint} The color tint corresponding to the given index.
 */
function getTintByIndex(index: number): ColorTint {
  return tints[index % tints.length]
}

/**
 * Calculates the color tint based on the sum of character codes in the input string.
 *
 * @param {string} str - The input string used to calculate the color tint.
 * @return {ColorTint} The color tint calculated based on the input string.
 *
 * @see getTintByIndex
 */
function getTintByString(str: string): ColorTint {
  let sum = 0
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i)
  }
  return getTintByIndex(sum)
}

/**
 * Returns colors
 *
 * @return {string[]} An array of colors.
 */
function getColors(): string[] {
  if (!colors.std.length && document.body) {
    colors.std = getColorsFromCss('--color')
  }
  return colors.std || []
}

/**
 * Retrieves a color based on the input string by calculating the sum of character codes.
 *
 * @param {string} str - The input string used to determine the color.
 * @return {string} The color corresponding to the input string.
 */
const getColorByString = (str: string): string => {
  const colors = getColors()
  let sum = 0
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i)
  }
  const colorIndex = sum % colors.length
  return colors[colorIndex]
}

/**
 * Converts a hexadecimal color value to an RGBColor object.
 *
 * @param {string} hex - The hexadecimal color value to convert.
 * @param {number} [opacity] - The opacity value to apply.
 * @return {RGBColor | null} The RGBColor object representing the converted color, or null if conversion fails.
 */
function hexToRgb(hex: string, opacity?: number): RGBColor | null {
  const result = /^#?(?<r>[a-f\d]{2})(?<g>[a-f\d]{2})(?<b>[a-f\d]{2})$/iu.exec(
    hex
  )

  if (result && result.groups) {
    return new RGBColor(
      parseInt(result.groups.r, 16),
      parseInt(result.groups.g, 16),
      parseInt(result.groups.b, 16),
      opacity
    )
  }

  return null
}

/**
 * Darkens the given color by the specified multiplier.
 *
 * @param {string} color - The color to darken in hexadecimal format (e.g., "#FF0000").
 * @param {number} multiplier - The multiplier to apply to the RGB values of the color.
 * @return {string} The darkened color in hexadecimal format.
 */
function darkenColor(color: string, multiplier: number): string {
  // Get R, G, B values from hex
  const r = parseInt(color.slice(1, 3), 16)
  const g = parseInt(color.slice(3, 5), 16)
  const b = parseInt(color.slice(5, 7), 16)

  // Lower RGB value and transfer to hex
  const darkenedR = Math.floor(r * multiplier)
    .toString(16)
    .padStart(2, '0')
  const darkenedG = Math.floor(g * multiplier)
    .toString(16)
    .padStart(2, '0')
  const darkenedB = Math.floor(b * multiplier)
    .toString(16)
    .padStart(2, '0')

  return `#${darkenedR}${darkenedG}${darkenedB}`
}

export {
  getColorFromCss,
  getColorsFromCss,
  getChartColors,
  getBlueTintChartColors,
  getColorByTint,
  getTintByIndex,
  getColors,
  getColorByString,
  getTintByString,
  hexToRgb,
  darkenColor,
}
