import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8.667 2.333c0 .737-1.641 1.334-3.666 1.334S1.334 3.07 1.334 2.333m7.333 0C8.667 1.597 7.026 1 5.001 1s-3.667.597-3.667 1.333m7.333 0v2.972c0 .326-.889.618-1.333.724-.634.19-1.447.304-2.333.304-2.025 0-3.667-.597-3.667-1.333m0-2.667v8c0 .737 1.642 1.334 3.667 1.334.886 0 1.699-.115 2.333-.305m-6-3.695C1.334 8.403 2.976 9 5.001 9c.886 0 1.699-.114 2.333-.305m7.333-.362c0 .737-1.641 1.334-3.666 1.334S7.334 9.07 7.334 8.333m7.333 0c0-.736-1.641-1.333-3.666-1.333s-3.667.597-3.667 1.333m7.333 0V11M7.334 8.333v5.029m0-5.029c0-.414.519-.784 1.333-1.028M7.334 8.333V11m7.333 0c0 .736-1.641 1.333-3.666 1.333S7.334 11.736 7.334 11m7.333 0v2.667c0 .736-1.641 1.333-3.666 1.333s-3.667-.597-3.667-1.333V11M11.7 2.714 13.2 1m0 0 1.5 1.714M13.2 1v4" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }