import { addDays, endOfDay } from 'date-fns'
import { useUserStore } from '@/stores/user'

export function useEntityDates() {
  const userStore = useUserStore()

  /**
   * Get current date like we are in entity timezone
   * @return Date Current date
   */
  function getEntityCurrentDate() {
    return userStore.getEntityDate(new Date())
  }

  /**
   * Get last close date like we are in entity timezone
   * @return Date Last close date
   */
  function getEntityLastCloseDate() {
    return endOfDay(addDays(getEntityCurrentDate(), -1))
  }

  /**
   * Get date from string like we are in entity timezone of current entity
   * @param entryDateString
   * @return Date Date in current timezone
   */
  function stringToTzDate(entryDateString: string) {
    return userStore.stringToEntityTzDate(entryDateString)
  }

  return {
    getEntityCurrentDate,
    getEntityLastCloseDate,
    stringToTzDate,
  }
}
