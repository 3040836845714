<template>
  <div v-if="messages && messages.length" class="message-box error mt-4">
    <p v-for="(error, index) in messages" :key="index">
      <span
        v-if="index > 0"
        class="w-10 border-t-2 border-negative block opacity-50 absolute top-0"
      />
      {{ friendlyError(error) }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

export default defineComponent({
  props: {
    messages: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
  },

  methods: {
    friendlyError(error: any): string {
      const isDev = localStorage.getItem('debug') === '1'
      if (typeof error === 'string') {
        return error
      } else if (isDev) {
        return JSON.stringify(error)
      } else {
        return 'Something went wrong. Please, try again later.'
      }
    },
  },
})
</script>
