<template>
  <div
    :class="{
      'border-4 rounded-bulky': props.bulkyBorder,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  bulkyBorder: {
    type: Boolean,
    default: false,
  },
})
</script>
