<template>
  <div
    class="text-xs text-clip whitespace-nowrap bg-surface"
    :class="{ 'text-center': !large }"
  >
    <span v-if="large" class="text-secondary mr-1">
      {{ $t('ui.common.daily_close_in') }}:
    </span>
    <span class="text-brand">{{ remainingToClose }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { differenceInMinutes, endOfDay } from 'date-fns'

import DatesMixin from '@/mixins/dates'

import { secToHumanReadableTime } from '@/services/date.service'
import { useUserStore } from '@/stores/user'

export default defineComponent({
  mixins: [DatesMixin],

  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      refreshInterval: null as ReturnType<typeof setInterval> | null,
      remainingToClose: '-',
    }
  },

  computed: mapState(useUserStore, ['entity']),

  created() {
    this.countRemainingTime()
    this.refreshInterval = setInterval(this.countRemainingTime, 1000)
    this.$bus.on('entityChanged', this.countRemainingTime)
  },

  beforeUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
    this.$bus.off('entityChanged', this.countRemainingTime)
  },

  methods: {
    countRemainingTime() {
      const { entity } = this
      if (!entity) {
        this.remainingToClose = '-'
        return
      }

      const currentDate = this.getEntityCurrentDate()
      const delta =
        differenceInMinutes(endOfDay(currentDate), currentDate, {
          roundingMethod: 'ceil',
        }) * 60
      this.remainingToClose = secToHumanReadableTime(delta)
    },
  },
})
</script>
