<template>
  <div
    :id="id"
    class="rounded transition-colors"
    :class="{ 'bg-background': internalValue && !nested }"
  >
    <div
      :id="id ? `${id}-title` : undefined"
      class="flex items-center font-medium cursor-pointer select-none transition-[margin] hover:text-brand"
      :class="[
        {
          'text-brand': internalValue,
          'border-b': internalValue && !nested,
        },
        nested ? 'py-2 px-0.5 text-base' : 'p-3 text-lg',
      ]"
      @click.stop="toggle"
    >
      <div class="truncate" :class="{ grow: !nested }">
        <template v-if="$slots['title']"><slot name="title" /></template>
        <template v-else>{{ title }}</template>
      </div>
      <SvgIcon
        icon="ArrowDown"
        class="shrink-0 mx-2"
        :class="[
          nested ? 'h-2.5 w-2.5' : 'h-3 w-3',
          {
            'transform rotate-180': internalValue,
          },
        ]"
      />
    </div>
    <div
      :id="id ? `${id}-content` : undefined"
      ref="content"
      class="overflow-hidden transition-all"
      :class="internalValue ? 'opacity-100' : 'opacity-0'"
      style="max-height: 0"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import InternalValue from '@/components/mixins/internalValue'

import SvgIcon from '@/components/misc/SvgIcon'

const TRANSITION_DURATION = 300

export default defineComponent({
  components: {
    SvgIcon,
  },

  mixins: [InternalValue],

  props: {
    id: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Boolean,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['toggled'],

  watch: {
    modelValue(expanded: boolean) {
      if (expanded) {
        this.expand()
      } else {
        this.shrink()
      }
    },
  },

  mounted() {
    if (this.modelValue) {
      const content = this.$refs.content as HTMLElement | undefined
      if (!content) {
        return
      }

      content.style.maxHeight = ''
    }
  },

  methods: {
    toggle() {
      this.internalValue = !this.internalValue

      this.$emit('toggled', this.internalValue)
      if (this.internalValue) {
        this.expand()
      } else {
        this.shrink()
      }
    },
    expand() {
      const content = this.$refs.content as HTMLElement | undefined
      if (!content) {
        return
      }

      const contentHeight = content.scrollHeight

      content.style.maxHeight = `${contentHeight}px`

      setTimeout(() => {
        content.style.maxHeight = ''
      }, TRANSITION_DURATION)
    },
    shrink() {
      const content = this.$refs.content as HTMLElement | undefined
      if (!content) {
        return
      }

      const contentHeight = content.scrollHeight

      content.style.transitionDuration = '0'
      content.style.maxHeight = `${contentHeight}px`
      setTimeout(() => {
        content.style.transitionDuration = `${TRANSITION_DURATION}`
        content.style.maxHeight = '0'
      })
    },
  },
})
</script>
