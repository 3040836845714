import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.334 1.835v2.667m5.332-2.667v2.667M2 6.667h12m-10.667-3.5h9.334c.736 0 1.333.597 1.333 1.333V12.5c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 0 1 2 12.5V4.5c0-.736.597-1.333 1.333-1.333" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }