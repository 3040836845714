import AuthLayout from '@/layouts/Auth.vue'

import SignIn from '@/views/Auth/SignIn.vue'
import AccountSelection from '@/views/Auth/AccountSelection.vue'
import SignUp from '@/views/Auth/SignUp.vue'
import ForgotPassword from '@/views/Auth/ForgotPassword.vue'
import EmailConfirmation from '@/views/Auth/EmailConfirmation.vue'

import i18n from '../langs'

import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '/signin/account',
        name: 'AccountSelection',
        component: AccountSelection,
        meta: {
          name: i18n.global.t('ui.common.account_selection'),
          onlyWhenLoggedOut: true,
        },
      },
      {
        path: '/signin',
        name: 'SignIn',
        component: SignIn,
        meta: {
          name: i18n.global.t('ui.common.log_in'),
          onlyWhenLoggedOut: true,
        },
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
          name: i18n.global.t('ui.common.forgotten_password'),
          onlyWhenLoggedOut: true,
        },
      },
      {
        path: '/link/:token/:resetType?',
        name: 'ForgotPasswordToken',
        component: ForgotPassword,
        props: true,
        meta: {
          name: i18n.global.t('ui.common.reset_password'),
          logoutBeforeVisit: true,
        },
      },
      {
        path: '/verification/:token',
        name: 'EmailConfirmation',
        component: EmailConfirmation,
        props: true,
        meta: {
          name: i18n.global.t('ui.common.email_confirmation'),
          logoutBeforeVisit: true,
        },
      },
    ],
    meta: {
      public: true, // Allow access to even if not logged in
    },
  },
]

const isDev = import.meta.env.DEV
if (isDev) {
  routes[0].children?.push({
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      name: i18n.global.t('ui.common.sign_up'),
      onlyWhenLoggedOut: true,
    },
  })
}

export default routes
