<template>
  <Modal :show="showed" hide-buttons @close="showed = false">
    <BoxedSvgIcon icon="BugReport" color="orange" class="-mt-16 mb-2" />

    <div class="text-xl text-center flex-grow font-semibold mb-6 mx-8">
      {{ $t('ui.components.services_notification.title') }}
    </div>

    <div v-if="type == 'init'" class="text-center text-secondary text-base">
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="mb-4"
        v-html="$t('ui.components.services_notification.description_init1')"
      />
      <!-- eslint-enable vue/no-v-html -->
      <p class="mb-4">
        <i18n-t
          keypath="ui.components.services_notification.description_init2"
          tag="strong"
          scope="global"
        >
          <template #link>
            <router-link
              :to="{ name: 'AssetsPortfolioView' }"
              class="text-brand hover:underline"
              @click="showed = false"
            >
              {{ $t('ui.components.services_notification.assets_portfolio') }}
            </router-link>
          </template>
        </i18n-t>
      </p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('ui.components.services_notification.description_init3')" />
    </div>
    <div
      v-else-if="type == 'added'"
      class="text-center text-secondary text-base"
    >
      <p class="mb-4">
        {{ $t('ui.components.services_notification.description_added_only1') }}
      </p>
      <p class="mb-4">
        <i18n-t
          keypath="ui.components.services_notification.description_added_only2"
          tag="strong"
          scope="global"
        >
          <template #link>
            <router-link
              :to="{ name: 'AssetsPortfolioView' }"
              class="text-brand hover:underline"
              @click="showed = false"
            >
              {{ $t('ui.components.services_notification.assets_portfolio') }}
            </router-link>
          </template>
        </i18n-t>
      </p>
      <!-- eslint-disable vue/no-v-html -->
      <p
        v-html="
          $t('ui.components.services_notification.description_added_only3')
        "
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <div
      v-else-if="type == 'removed'"
      class="text-center text-secondary text-base"
    >
      <p class="mb-4">
        {{
          $t('ui.components.services_notification.description_removed_only1')
        }}
      </p>
      <p>
        <i18n-t
          keypath="ui.components.services_notification.description_removed_only2"
          tag="strong"
          scope="global"
        >
          <template #link>
            <router-link
              :to="{ name: 'AssetsCustomEntriesView' }"
              class="text-brand hover:underline"
              @click="showed = false"
            >
              {{
                $t(
                  'ui.components.services_notification.assets_sources_custom_entries'
                )
              }}
            </router-link>
          </template>
        </i18n-t>
      </p>
    </div>
    <div
      v-else-if="type == 'both' && activeView == 'first'"
      class="text-center text-secondary text-base"
    >
      <p>
        {{
          $t('ui.components.services_notification.description_added_when_both')
        }}
      </p>
    </div>

    <div
      v-else-if="type == 'both' && activeView == 'second'"
      class="text-center text-secondary text-base"
    >
      <p class="mb-4">
        {{
          $t(
            'ui.components.services_notification.description_removed_when_both1'
          )
        }}
      </p>
      <p>
        <i18n-t
          keypath="ui.components.services_notification.description_removed_when_both2"
          tag="strong"
          scope="global"
        >
          <template #link>
            <router-link
              :to="{ name: 'AssetSourcesView' }"
              class="text-brand hover:underline"
              @click="showed = false"
            >
              {{
                $t('ui.components.services_notification.assets_assets_sources')
              }}
            </router-link>
          </template>
        </i18n-t>
      </p>
    </div>

    <ServicesList
      class="mt-6"
      :added="data.added"
      :removed="data.removed"
      :type="type"
      :active-view="activeView"
    />

    <div
      v-if="
        type == 'init' ||
        type == 'added' ||
        type == 'removed' ||
        (type == 'both' && activeView == 'second')
      "
      class="text-center text-secondary text-base mt-6"
    >
      <i18n-t
        keypath="ui.components.services_notification.check_full_list"
        tag="p"
        scope="global"
      >
        <template #link>
          <router-link
            :to="{ name: 'SupportedServicesView' }"
            class="text-brand hover:underline"
            @click="showed = false"
          >
            <strong>{{ $t('ui.common.here') }}</strong>
          </router-link>
        </template>
      </i18n-t>
    </div>

    <div
      class="flex mt-8"
      :class="
        type == 'both' && activeView == 'second'
          ? 'justify-between'
          : 'justify-end'
      "
    >
      <Button
        v-if="type == 'both' && activeView == 'first'"
        @click="activeView = 'second'"
      >
        {{ $t('ui.common.next') }}
      </Button>
      <template v-else>
        <Button
          v-if="type === 'both'"
          type="secondary"
          @click="activeView = 'first'"
        >
          {{ $t('ui.common.back') }}
        </Button>
        <Button :loading="working" @click="setLastSeen">
          {{ $t('ui.components.services_notification.confirm') }}
        </Button>
      </template>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import {
  useServicesDeFiStore,
  type ServicesGroup,
} from '@/stores/services-defi'
import { useUserStore } from '@/stores/user'

import Button from '@/components/controls/Button'
import Modal from '@/components/misc/Modal'
import { BoxedSvgIcon } from '@/components/misc/SvgIcon'

import ServicesList from './ServicesList.vue'

import { dateToUTCString } from '@/services/date.service'

import {
  type NotificationServicesView,
  type ServicesNotificationType,
} from './types'

interface UpdatedService {
  added: ServicesGroup[]
  removed: ServicesGroup[]
}

export default defineComponent({
  components: {
    BoxedSvgIcon,
    Button,
    Modal,
    ServicesList,
  },

  data() {
    return {
      showed: false,
      working: false,
      seenDate: null as string | null,
      activeView: 'first' as NotificationServicesView,

      servicesDeFiStore: useServicesDeFiStore(),
    }
  },

  computed: {
    ...mapState(useUserStore, ['user', 'settings']),
    ...mapState(useServicesDeFiStore, ['groupedUpdatedServices']),
    data(): UpdatedService {
      const services: UpdatedService = {
        added: [],
        removed: [],
      }

      this.groupedUpdatedServices.forEach((group: ServicesGroup) => {
        if (group.disabled_date) {
          services.removed.push(group)
        } else {
          services.added.push(group)
        }
      })

      return services
    },
    type(): ServicesNotificationType {
      if (!this.settings.last_seen_new_defi_services) {
        return 'init'
      }
      if (this.data.added.length && this.data.removed.length) {
        return 'both'
      }
      if (this.data.added.length) {
        return 'added'
      }
      return 'removed'
    },
  },

  mounted() {
    this.checkUpdatedServices()
  },

  methods: {
    async checkUpdatedServices() {
      const { user } = this
      if (!user) {
        return
      }

      try {
        await this.servicesDeFiStore.checkUpdatedServices(user.id)
        if (this.groupedUpdatedServices.length) {
          this.showed = true
          this.seenDate = dateToUTCString(new Date())
        }
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
    async setLastSeen() {
      if (!this.seenDate) {
        return
      }

      this.working = true
      try {
        await this.servicesDeFiStore.setLastSeen(this.seenDate)
        this.showed = false
      } catch (error) {
        this.$bus.emit('error', error)
      } finally {
        this.working = false
      }
    },
  },
})
</script>
