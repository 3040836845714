<template>
  <div class="preset-ranges">
    <div
      v-for="(entry, i) in presets"
      :key="i"
      class="preset"
      :class="{
        active: entry === preset,
      }"
      @click.stop="$emit('update:preset', entry)"
    >
      {{ $t(`ui.components.datepicker.presets.${entry}`) }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

export default defineComponent({
  props: {
    presets: {
      type: Array,
      required: true,
    },
    preset: {
      type: String as PropType<string | null>,
      default: null,
    },
  },

  emits: ['update:preset'],
})
</script>
