<template>
  <div class="login-form">
    <template v-if="formErrors">
      <p class="bg-negative text-white px-8 py-2 -mx-8">
        {{ formErrors[0] }}
      </p>
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="text-sm mt-6"
        v-html="$t('messages.if_you_have_signin_trouble')"
      />
      <!-- eslint-enable vue/no-v-html -->
    </template>
    <div
      v-if="loadingVerification"
      id="email-confirmation-loader"
      class="w-full flex items-center justify-center h-40"
    >
      <Spinner width="100" class="fill-brand" />
    </div>
    <div v-else-if="success" class="text-center flex items-center flex-col">
      <p class="text-lg mb-2">
        {{ $t('messages.your_email_has_been_confirmed') }}
      </p>
      <router-link to="/">
        <Button
          id="email-confirmation-sign-in"
          type="primary"
          button-type="submit"
          class="h-10 mt-3"
        >
          {{ $t('ui.common.log_in') }}
        </Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user'

import UserService from '@/services/user/user'
import Spinner from '@/assets/loaders/Spinner.svg?component'
import Button from '@/components/controls/Button'
import { retrieveErrorsMessages } from '@/helpers/errors'

export default {
  components: {
    Spinner,
    Button,
  },

  props: {
    token: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loadingVerification: true,
      success: false,
      formErrors: null,

      userStore: useUserStore(),
    }
  },

  created() {
    if (this.token) {
      this.confirmEmail(this.token)
    } else {
      this.$router.push('/')
    }
  },

  methods: {
    async confirmEmail(token) {
      this.formErrors = null
      try {
        await UserService.confirmEmail(token)

        this.success = true
        this.loadingVerification = false
        this.userStore.setUserSettings({
          confirmed: true,
        })
      } catch (err) {
        this.formErrors = retrieveErrorsMessages(err)
        this.loadingVerification = false
      }
    },
  },
}
</script>
