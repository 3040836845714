import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M1.3 3.1c0-.4 0-.6.1-.7l.3-.3c.1-.1.3-.1.7-.1h11.2c.4 0 .6 0 .7.1l.3.3c.1.1.1.3.1.7v.8c0 .1-.1.1-.1.2s-.1.1-.3.2L10 8c-.1.1-.2.2-.2.3s-.1.1-.1.2v4.2s0 .1-.1.1c0 0-.1.1-.2.1l-2.3.9c-.2.1-.4.1-.5.1s-.2-.1-.2-.1c-.1-.1-.1-.2-.1-.5V8.4c0-.1-.1-.1-.1-.2L6 8 1.7 4.3c-.1-.1-.2-.2-.3-.2 0-.1-.1-.1-.1-.2v-.8" }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      stroke: "#ff0f1e",
      d: "m14.7 10.4-3.4 3.4m0-3.4 3.4 3.4"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }