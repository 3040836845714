import { type Token } from '@/types/services/user'

const AUTH_TOKEN_KEY = 'Authorization'
const REFRESH_TOKEN_KEY = 'refresh_token'

/**
 * Manage all operations related to access and refresh tokens.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 **/
class TokenService {
  static getAuthToken(): string | null {
    return localStorage.getItem(AUTH_TOKEN_KEY)
  }

  static saveAuthToken(accessToken: string): void {
    localStorage.setItem(AUTH_TOKEN_KEY, accessToken)
  }

  static removeAuthToken(): void {
    localStorage.removeItem(AUTH_TOKEN_KEY)
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  }

  static saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  }

  static removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  }

  static getToken(): Token | null {
    const authToken = this.getAuthToken()
    const refreshToken = this.getRefreshToken()

    if (authToken && refreshToken) {
      return {
        Authorization: authToken,
        refresh_token: refreshToken,
      }
    }
    return null
  }
}

export default TokenService
