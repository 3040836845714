<template>
  <div class="bg-background rounded overflow-hidden h-40">
    <div class="h-full overflow-y-auto px-4 py-2 text-base scroll-page">
      <template v-if="type != 'both' || activeView == 'first'">
        <div v-if="type == 'both'" class="flex items-center py-2 font-medium">
          {{ $t('ui.components.services_notification.added_services') }}
          <SvgIcon icon="Check" class="text-positive ml-2" />
        </div>
        <ServiceItem
          v-for="service in added"
          :key="service.name"
          :service="service"
        />
      </template>

      <template v-if="type != 'both' || activeView == 'second'">
        <div v-if="type == 'both'" class="flex items-center py-2 font-medium">
          {{ $t('ui.components.services_notification.not_supported_services') }}
          <SvgIcon icon="Close" class="text-negative ml-2" />
        </div>
        <ServiceItem
          v-for="service in removed"
          :key="service.name"
          :service="service"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

import SvgIcon from '@/components/misc/SvgIcon'

import ServiceItem from './ServiceItem.vue'

import { type ServicesGroup } from '@/stores/services-defi'
import {
  type NotificationServicesView,
  type ServicesNotificationType,
} from './types'

export default defineComponent({
  components: {
    ServiceItem,
    SvgIcon,
  },

  props: {
    added: {
      type: Array as PropType<ServicesGroup[]>,
      default: () => [],
    },
    removed: {
      type: Array as PropType<ServicesGroup[]>,
      default: () => [],
    },
    type: {
      type: String as PropType<ServicesNotificationType>,
      default: 'init',
    },
    activeView: {
      type: String as PropType<NotificationServicesView>,
      default: 'first',
    },
  },
})
</script>
