import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.333 1.513v2.754c0 .373 0 .56.073.703a.67.67 0 0 0 .291.29c.143.074.33.074.703.074h2.754m-3.82 6h-4m5.333-2.667H5.333m8-2.008v4.808c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.427.218-.987.218-2.108.218H5.867c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108V4.534c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.875c.428-.218.988-.218 2.108-.218h2.14c.49 0 .735 0 .965.056a2 2 0 0 1 .578.24c.202.123.375.296.72.642l2.126 2.125c.346.346.519.52.643.72q.165.271.24.579c.054.23.054.475.054.964" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }