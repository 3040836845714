import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.25 8.042h-.876c-.814 0-1.22 0-1.552.1-.746.226-1.33.81-1.556 1.556-.1.33-.1.738-.1 1.552m13.501.083c0 .737-1.641 1.334-3.666 1.334s-3.667-.597-3.667-1.334m7.333 0c0-.736-1.641-1.333-3.666-1.333s-3.667.597-3.667 1.333m7.333 0V14c0 .736-1.641 1.333-3.666 1.333S7.334 14.736 7.334 14v-2.667m0 0c0-.414.519-.784 1.333-1.028M12.7 5.5v-2m0 0v-2m0 2h-2m2 0h2m-6.241-.125a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }