import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.333 11.333h5.334m-3.322-9.49L2.824 5.359c-.303.236-.454.353-.563.5-.096.13-.168.278-.212.434C2 6.469 2 6.66 2 7.043v4.824c0 .746 0 1.12.145 1.405.128.25.332.455.583.583.285.145.659.145 1.405.145h7.734c.746 0 1.12 0 1.405-.145.25-.128.455-.332.583-.583.145-.285.145-.659.145-1.405V7.043c0-.383 0-.574-.05-.75a1.3 1.3 0 0 0-.211-.434c-.11-.147-.26-.264-.563-.5L8.655 1.843c-.234-.182-.351-.274-.48-.309a.67.67 0 0 0-.35 0c-.129.035-.246.127-.48.309" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }