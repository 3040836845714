import { defineComponent, type PropType } from 'vue'

export default defineComponent({
  props: {
    future: {
      type: Boolean,
      default: false,
    },
    past: {
      type: Boolean,
      default: true,
    },
    allowFrom: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    allowTo: {
      type: Date as PropType<Date | null>,
      default: null,
    },
  },
})
