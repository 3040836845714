import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M9.3 1.5v2.8c0 .4 0 .6.1.7l.3.3c.1.1.3.1.7.1h2.8m.1 1.3c0-.5 0-.7-.1-1 0-.2-.1-.4-.2-.6s-.3-.4-.6-.7l-2.1-2.1c-.4-.4-.5-.5-.8-.7-.1-.1-.3-.2-.5-.2-.3-.1-.5-.1-1-.1H5.9c-1.1 0-1.7 0-2.1.2q-.6.3-.9.9c-.2.4-.2 1-.2 2.1v7c0 1.1 0 1.7.2 2.1q.3.6.9.9c.4.2 1 .2 2.1.2h4.2c1.1 0 1.7 0 2.1-.2q.6-.3.9-.9c.2-.4.2-1 .2-2.1V6.7",
      class: "st0"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      "stroke-width": ".5",
      d: "M8.1 10.2c.1 0 .7-.2.8-.2h.2q.75.45 1.5.6c.4.1.7 0 1-.1.1 0 .1-.1.1-.2 0-.2-.4-.4-.6-.5q0 0 0 0c-.5-.2-1.1-.2-2 0h-.2c-.4-.2-.8-.7-1.1-1.2-.2-.2-.3-.4-.4-.6v-.2c.2-.5.4-1.1.4-1.8 0-.4-.2-.7-.4-.7-.1 0-.2.1-.4.4q-.3.75.3 2.1V8c-.1.3-.2.6-.3.8l-.2.4-.6 1.5c0 .1-.1.1-.1.1-.5.2-1 .5-1.4.8-.3.3-.5.6-.5.8 0 .1 0 .2.1.3s.2.1.3.1c.2 0 .5-.1 1-.8.2-.4.4-.7.6-1.1 0-.1.1-.1.1-.1.5-.2 1.1-.3 1.5-.5l.4-.1M7.8 6",
      class: "st1"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }