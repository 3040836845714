import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M2.366 8.596a5.664 5.664 0 0 0 10.541 2.239l.166-.29m.562-3.14A5.664 5.664 0 0 0 3.094 5.165l-.167.288m10.743 6.592-.488-1.822-1.82.488M4.638 5.288l-1.821.489-.488-1.823" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }