<template>
  <div class="crypkit-datepicker-calendar select-none">
    <DatesPanel
      v-if="activePanel === 'dates'"
      v-model="showedDate"
      :selected="selected"
      :future="future"
      :past="past"
      :allow-from="allowFrom"
      :allow-to="allowTo"
      :hovered="hovered"
      @next-panel="activePanel = 'months'"
      @hover-date="$emit('update:hovered', $event)"
      @select-date="selectDate"
    />
    <MonthsPanel
      v-if="activePanel === 'months'"
      v-model="showedDate"
      :selected="selected"
      :future="future"
      :past="past"
      :allow-from="allowFrom"
      :allow-to="allowTo"
      @next-panel="activePanel = 'years'"
      @close="activePanel = 'dates'"
    />
    <YearsPanel
      v-if="activePanel === 'years'"
      v-model="showedDate"
      :selected="selected"
      :future="future"
      :past="past"
      :allow-from="allowFrom"
      :allow-to="allowTo"
      @close="activePanel = 'months'"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import LimitMixin from './limitsMixin'
import DatesPanel from './panels/DatesPanel.vue'
import MonthsPanel from './panels/MonthsPanel.vue'
import YearsPanel from './panels/YearsPanel.vue'

export default defineComponent({
  components: {
    DatesPanel,
    MonthsPanel,
    YearsPanel,
  },

  mixins: [LimitMixin],

  props: {
    modelValue: {
      type: Date as PropType<Date>,
      default: (): Date => new Date(),
    },
    selected: {
      type: Array as PropType<Date[]>,
      default: () => [],
    },
    hovered: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    isSecond: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:hovered', 'update:modelValue', 'select-date'],

  data() {
    return {
      // ['dates', 'months', 'years'],
      activePanel: 'dates',
    }
  },

  computed: {
    showedDate: {
      get(): Date {
        return new Date(this.modelValue)
      },
      set(date: Date): void {
        this.$emit('update:modelValue', new Date(date))
      },
    },
  },

  methods: {
    selectDate(date: Date): void {
      this.$emit('select-date', date)
    },
  },
})
</script>
