import { CHANNEL_MAIN } from '@/constants/broadcast'

const mainBroadcastChannel = new BroadcastChannel(CHANNEL_MAIN)
const callbacks: Map<string, ((event: MessageEvent) => void)[]> = new Map()

mainBroadcastChannel.onmessage = (message) => {
  const event = message.data.event

  const callbacksForEvent = callbacks.get(event)
  if (callbacksForEvent) {
    callbacksForEvent.forEach((callback) => {
      callback(message.data.data)
    })
  }
}

export function useBroadcast() {
  function on(event: string, callback: (event: MessageEvent) => void) {
    callbacks.set(event, [...(callbacks.get(event) || []), callback])
  }

  function off(event: string, callback: (event: MessageEvent) => void) {
    const callbacksForEvent = callbacks.get(event)
    if (callbacksForEvent) {
      const index = callbacksForEvent.indexOf(callback)
      if (index !== -1) {
        callbacksForEvent.splice(index, 1)
        if (callbacksForEvent.length == 0) {
          callbacks.delete(event)
        }
      }
    }
  }

  function emit(event: string, message?: any) {
    mainBroadcastChannel.postMessage({
      event,
      data: message,
    })
  }

  return {
    on,
    off,
    emit,
  }
}
