<template>
  <div
    class="sidebar flex flex-col"
    :class="{ 'sidebar-mini': !sidebarToggled }"
  >
    <div
      v-if="isSmallScreen && sidebarToggled"
      class="sidebar-body-cover"
      :style="{ width: coverWidth }"
      @click="closeMenu"
    />

    <SideMenu class="pt-8" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useGlobalStore } from '@/stores/global'

import SideMenu from '../SideMenu/Index.vue'

export default defineComponent({
  components: {
    SideMenu,
  },

  data() {
    return {
      coverWidth: '0',

      globalStore: useGlobalStore(),
    }
  },

  computed: mapState(useGlobalStore, ['sidebarToggled', 'isSmallScreen']),

  mounted() {
    this.computeCoverWidth()
    window.removeEventListener('resize', this.computeCoverWidth)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.computeCoverWidth)
  },

  methods: {
    computeCoverWidth() {
      this.coverWidth = `${this.$breakpoints.width}px`
    },
    closeMenu() {
      if (this.sidebarToggled && this.isSmallScreen) {
        this.globalStore.toggleSidebar()
      }
    },
  },
})
</script>
