import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14 14H3.067c-.374 0-.56 0-.703-.073a.67.67 0 0 1-.291-.291C2 13.493 2 13.306 2 12.933V2m11.333 3.333-2.612 2.789c-.1.105-.149.158-.209.186a.33.33 0 0 1-.168.028c-.065-.005-.13-.039-.258-.106L7.914 7.103c-.128-.067-.193-.1-.258-.106a.33.33 0 0 0-.169.029c-.06.027-.109.08-.208.186L4.667 10" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }