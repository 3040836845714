<template>
  <div class="small-currencies">
    <div class="small-currency">
      <CurrencyIcon :currency="currencyCrypto" />
    </div>
    <div class="small-currency">
      <CurrencyIcon :currency="currencyFiat" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useUserStore } from '@/stores/user'

import CurrencyIcon from '@/components/misc/CurrencyIcon'

export default defineComponent({
  components: {
    CurrencyIcon,
  },

  computed: mapState(useUserStore, ['currencyCrypto', 'currencyFiat']),
})
</script>
