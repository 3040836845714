import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { formatDateHelper } from '@/services/date.service'
import { useUserStore } from '@/stores/user'

export default defineComponent({
  computed: {
    ...mapState(useUserStore, ['entityId', 'stringToEntityTzDate']),
    ...mapState(useUserStore, {
      dateFormat: (store) => store.settings.additional.date_format,
    }),
  },

  methods: {
    formatDateTime(value: string) {
      const date = this.entityId
        ? this.stringToEntityTzDate(value, this.entityId)
        : value
      return formatDateHelper(date, `${this.dateFormat} H:mm:ss`)
    },
  },
})
