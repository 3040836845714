<template>
  <component :is="icon" v-if="icon" :class="classes" :style="styles" />
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import Accounting from '@/assets/icons/Accounting.svg?component'
import Affiliate from '@/assets/icons/Affiliate.svg?component'
import Alert from '@/assets/icons/Alert.svg?component'
import ArrowDown from '@/assets/icons/ArrowDown.svg?component'
import ArrowDownCircle from '@/assets/icons/ArrowDownCircle.svg?component'
import ArrowLeft from '@/assets/icons/ArrowLeft.svg?component'
import ArrowRight from '@/assets/icons/ArrowRight.svg?component'
import ArrowUp from '@/assets/icons/ArrowUp.svg?component'
import Arrows from '@/assets/icons/Arrows.svg?component'
import Assets from '@/assets/icons/Assets.svg?component'
import Back from '@/assets/icons/Back.svg?component'
import BarChart from '@/assets/icons/BarChart.svg?component'
import Box from '@/assets/icons/Box.svg?component'
import BoxCoins from '@/assets/icons/BoxCoins.svg?component'
import Boxes from '@/assets/icons/Boxes.svg?component'
import BugReport from '@/assets/icons/BugReport.svg?component'
import Calendar from '@/assets/icons/Calendar.svg?component'
import Card from '@/assets/icons/Card.svg?component'
import Cards from '@/assets/icons/Cards.svg?component'
import Check from '@/assets/icons/Check.svg?component'
import Chevron from '@/assets/icons/Chevron.svg?component'
import Clock from '@/assets/icons/Clock.svg?component'
import Close from '@/assets/icons/Close.svg?component'
import CoingeckoHealth from '@/assets/icons/CoingeckoHealth.svg?component'
import Coins from '@/assets/icons/Coins.svg?component'
import CoinsClose from '@/assets/icons/CoinsClose.svg?component'
import Compare from '@/assets/icons/Compare.svg?component'
import Consolidations from '@/assets/icons/Consolidations.svg?component'
import Copy from '@/assets/icons/Copy.svg?component'
import CryptoAsset from '@/assets/icons/CryptoAsset.svg?component'
import CSV from '@/assets/icons/CSV.svg?component'
import CustomEntry from '@/assets/icons/CustomEntry.svg?component'
import Dashboard from '@/assets/icons/Dashboard.svg?component'
import Deactivate from '@/assets/icons/Deactivate.svg?component'
import Deposits from '@/assets/icons/Deposits.svg?component'
import Document from '@/assets/icons/Document.svg?component'
import Droplet from '@/assets/icons/Droplet.svg?component'
import Edit from '@/assets/icons/Edit.svg?component'
import Exchange from '@/assets/icons/Exchange.svg?component'
import ExportDownload from '@/assets/icons/ExportDownload.svg?component'
import ExternalLink from '@/assets/icons/ExternalLink.svg?component'
import FDV from '@/assets/icons/FDV.svg?component'
import FeeReinvestment from '@/assets/icons/FeeReinvestment.svg?component'
import FeeWithdrawal from '@/assets/icons/FeeWithdrawal.svg?component'
import Filters from '@/assets/icons/Filters.svg?component'
import FiltersClear from '@/assets/icons/FiltersClear.svg?component'
import FinancialOptions from '@/assets/icons/FinancialOptions.svg?component'
import Fund from '@/assets/icons/Fund.svg?component'
import Futures from '@/assets/icons/Futures.svg?component'
import Help from '@/assets/icons/Help.svg?component'
import Hide from '@/assets/icons/Hide.svg?component'
import Home from '@/assets/icons/Home.svg?component'
import Info from '@/assets/icons/Info.svg?component'
import InputCheck from '@/assets/icons/InputCheck.svg?component'
import Interface from '@/assets/icons/Interface.svg?component'
import Lightning from '@/assets/icons/Lightning.svg?component'
import LineChart from '@/assets/icons/LineChart.svg?component'
import List from '@/assets/icons/List.svg?component'
import Lock from '@/assets/icons/Lock.svg?component'
import MassEdit from '@/assets/icons/MassEdit.svg?component'
import Menu from '@/assets/icons/Menu.svg?component'
import MiniMenu from '@/assets/icons/MiniMenu.svg?component'
import Next from '@/assets/icons/Next.svg?component'
import NFT from '@/assets/icons/NFT.svg?component'
import Note from '@/assets/icons/Note.svg?component'
import Moves from '@/assets/icons/Moves.svg?component'
import Moon from '@/assets/icons/Moon.svg?component'
import Notification from '@/assets/icons/Notification.svg?component'
import NotificationPlus from '@/assets/icons/NotificationPlus.svg?component'
import PDF from '@/assets/icons/PDF.svg?component'
import People from '@/assets/icons/People.svg?component'
import Plus from '@/assets/icons/Plus.svg?component'
import Plus2 from '@/assets/icons/Plus2.svg?component'
import Pocket from '@/assets/icons/Pocket.svg?component'
import Portfolio from '@/assets/icons/Portfolio.svg?component'
import Price from '@/assets/icons/Price.svg?component'
import Pulse from '@/assets/icons/Pulse.svg?component'
import Refresh from '@/assets/icons/Refresh.svg?component'
import ReleaseNotes from '@/assets/icons/ReleaseNotes.svg?component'
import Repeat from '@/assets/icons/Repeat.svg?component'
import Required from '@/assets/icons/Required.svg?component'
import Search from '@/assets/icons/Search.svg?component'
import Security from '@/assets/icons/Security.svg?component'
import Services from '@/assets/icons/Services.svg?component'
import Settings from '@/assets/icons/Settings.svg?component'
import Settings2 from '@/assets/icons/Settings2.svg?component'
import Show from '@/assets/icons/Show.svg?component'
import SignOut from '@/assets/icons/SignOut.svg?component'
import Spot from '@/assets/icons/Spot.svg?component'
import StackOfSheets from '@/assets/icons/StackOfSheets.svg?component'
import Star from '@/assets/icons/Star.svg?component'
import Statistics from '@/assets/icons/Statistics.svg?component'
import Sun from '@/assets/icons/Sun.svg?component'
import Tags from '@/assets/icons/Tags.svg?component'
import Tools from '@/assets/icons/Tools.svg?component'
import Trades from '@/assets/icons/Trades.svg?component'
import TradesPlus from '@/assets/icons/TradesPlus.svg?component'
import Transfer from '@/assets/icons/Transfer.svg?component'
import Trash from '@/assets/icons/Trash.svg?component'
import TrendingUp from '@/assets/icons/TrendingUp.svg?component'
import TwoArrowsLeft from '@/assets/icons/TwoArrowsLeft.svg?component'
import TwoArrowsRight from '@/assets/icons/TwoArrowsRight.svg?component'
import Undo from '@/assets/icons/Undo.svg?component'
import Unlock from '@/assets/icons/Unlock.svg?component'
import Update from '@/assets/icons/Update.svg?component'
import User from '@/assets/icons/User.svg?component'
import UserCog from '@/assets/icons/UserCog.svg?component'
import UserCoins from '@/assets/icons/UserCoins.svg?component'
import UserCoinsPlus from '@/assets/icons/UserCoinsPlus.svg?component'
import UserPlus from '@/assets/icons/UserPlus.svg?component'
import Users from '@/assets/icons/Users.svg?component'
import Wallet from '@/assets/icons/Wallet.svg?component'
import WithdrawalPlus from '@/assets/icons/WithdrawalPlus.svg?component'
import Withdrawals from '@/assets/icons/Withdrawals.svg?component'
import WithdrawalTotal from '@/assets/icons/WithdrawalTotal.svg?component'
import type { ColorTint } from '@/types/components/common'

export default defineComponent({
  components: {
    Accounting,
    Affiliate,
    Alert,
    ArrowDown,
    ArrowDownCircle,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    Arrows,
    Assets,
    Back,
    BarChart,
    Box,
    BoxCoins,
    Boxes,
    BugReport,
    Calendar,
    Card,
    Cards,
    Check,
    Chevron,
    Clock,
    Close,
    CoingeckoHealth,
    Coins,
    CoinsClose,
    Compare,
    Consolidations,
    Copy,
    CryptoAsset,
    CSV,
    CustomEntry,
    Dashboard,
    Deactivate,
    Deposits,
    Document,
    Droplet,
    Edit,
    Exchange,
    ExportDownload,
    ExternalLink,
    FDV,
    FeeReinvestment,
    FeeWithdrawal,
    Filters,
    FiltersClear,
    FinancialOptions,
    Fund,
    Futures,
    Help,
    Hide,
    Home,
    Info,
    InputCheck,
    Interface,
    Lightning,
    LineChart,
    List,
    Lock,
    MassEdit,
    Menu,
    MiniMenu,
    Moves,
    Moon,
    Next,
    NFT,
    Note,
    Notification,
    NotificationPlus,
    PDF,
    People,
    Plus,
    Plus2,
    Pocket,
    Portfolio,
    Price,
    Pulse,
    Refresh,
    ReleaseNotes,
    Repeat,
    Required,
    Search,
    Security,
    Services,
    Settings,
    Settings2,
    Show,
    SignOut,
    Spot,
    StackOfSheets,
    Star,
    Statistics,
    Sun,
    Tags,
    Tools,
    Trades,
    TradesPlus,
    Transfer,
    Trash,
    TrendingUp,
    TwoArrowsLeft,
    TwoArrowsRight,
    Undo,
    Unlock,
    Update,
    User,
    UserCog,
    UserCoins,
    UserCoinsPlus,
    UserPlus,
    Users,
    Wallet,
    WithdrawalPlus,
    Withdrawals,
    WithdrawalTotal,
  },

  props: {
    icon: {
      type: String as PropType<string | null>,
      default: null,
    },
    size: {
      type: [String, Number] as PropType<'sm' | 'md' | 'lg' | number>,
      default: 'md',
    },
    color: {
      type: String as PropType<ColorTint | null>,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      const { size, color, block } = this

      let classes: string[] = ['transition']

      classes.push(block ? 'block' : 'inline-block')

      if (!color) {
        classes.push('stroke-current')
      }

      if (size == 'lg') {
        classes.push('h-5', 'w-5', 'stroke-lg')
      } else if (size == 'md') {
        classes.push('h-4', 'w-4', 'stroke-md')
      } else if (size == 'sm') {
        classes.push('h-3', 'w-3', 'stroke-md')
      } else {
        classes.push('stroke-md')
      }

      return classes
    },
    styles(): Partial<CSSStyleDeclaration> {
      const styles: Partial<CSSStyleDeclaration> = {}
      if (typeof this.size == 'number') {
        const size = `${this.size}px`
        styles.width = size
        styles.height = size
      }
      if (this.color) {
        styles.stroke = `var(--color-icon-${this.color})`
      }
      return styles
    },
  },
})
</script>
