import { type AxiosResponse } from 'axios'

import ApiService from '@/services/api.service'
import { currentDateInUTCString } from '@/services/date.service'

import { type EmptyDataResponse } from '@/types/services/common'
import {
  type CreateNotificationResponse,
  type NotificationResponse,
  type NotificationsResponse,
} from '@/types/services/notifications'

class NotificationsService {
  // eslint-disable-next-line max-params
  static getNotifications(
    userId: string,
    rootId: string,
    rootUserId: string,
    offset: number,
    limit: number
  ): Promise<AxiosResponse<NotificationsResponse>> {
    const data = {
      aggr: {
        params: {
          user_id: userId,
          root_id: rootId,
          root_user_id: rootUserId,
          offset,
          limit,
        },
      },
    }

    return ApiService.post(
      `/api/v1/notification/search/get_notifications_by_owner`,
      data
    )
  }

  static setDoneNotification(
    notificationId: string
  ): Promise<AxiosResponse<NotificationResponse>> {
    const data = {
      done: true,
    }
    return ApiService.put(`/api/v1/notifications/${notificationId}`, data)
  }

  static setReadNotification(
    notificationId: string
  ): Promise<AxiosResponse<NotificationResponse>> {
    const data = {
      read: true,
    }
    return ApiService.put(`/api/v1/notifications/${notificationId}`, data)
  }

  static setDoneAllNotifications(
    userId: string,
    rootId: string,
    rootUserId: string
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    const data = {
      aggr: {
        params: {
          user_id: userId,
          root_id: rootId,
          root_user_id: rootUserId,
        },
      },
    }
    return ApiService.post(
      '/api/v1/notification/search/set_notifications_as_done',
      data
    )
  }

  static createNotification(
    sender_id: string,
    receiver_id: string,
    message: string,
    date: string
  ): Promise<AxiosResponse<CreateNotificationResponse>> {
    const data = {
      aggr: {
        params: {
          sender_id,
          receiver_id,
          message,
          date_: date,
        },
      },
      request_timestamp: currentDateInUTCString(),
    }
    return ApiService.post(
      '/api/v1/notification/search/create_user_notification',
      data
    )
  }

  static getUserNotifications(
    rootUserId: string
  ): Promise<AxiosResponse<NotificationsResponse>> {
    const data = {
      aggr: {
        params: {
          root_user_id: rootUserId,
        },
      },
    }
    return ApiService.post(
      `/api/v1/notification/search/get_notifications_by_owner`,
      data
    )
  }
}

export default NotificationsService
