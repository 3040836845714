<template>
  <div class="login-form">
    <div class="text-xxl font-bold mb-2">
      {{ $t('ui.auth.select_account_title', { name: userName }) }}
    </div>
    <p class="text-secondary">
      {{ $t('ui.auth.select_account') }}
    </p>
    <div
      v-show="loading"
      class="absolute w-full h-full bg-surface z-100 top-0 left-0 flex justify-center"
    >
      <Spinner width="100" class="fill-brand" />
    </div>
    <div class="mt-4" :class="{ invisible: loading }">
      <AccountExpandPanels
        :relations="relations"
        @start-session="startSession"
      />
    </div>
    <hr class="my-6 border-t -mx-16" />
    <div>
      <Checkbox
        v-model="rememberSelectedSpace"
        :label="$t('ui.auth.remember_selected_space')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { retrieveErrorsMessages } from '@/helpers/errors'
import UserService from '@/services/user/user'
import { useUserStore } from '@/stores/user'

import Spinner from '@/assets/loaders/Spinner.svg?component'

import Checkbox from '@/components/controls/Checkbox/Checkbox.vue'
import AccountExpandPanels from '@/views/Auth/AccountExpandPanels.vue'

import type { StartSessionItem } from '@/components/navigation/components/EntityRolePanels.vue'
import type { UserSettings } from '@/types/services/user_settings'

export default defineComponent({
  components: {
    AccountExpandPanels,
    Checkbox,
    Spinner,
  },

  data() {
    return {
      loading: false,
      privacyModeEnabled: false,
      expandedPanels: [] as boolean[],
      rememberSelectedSpace: false,

      userStore: useUserStore(),
    }
  },

  computed: {
    ...mapState(useUserStore, ['relations', 'user']),
    userName(): string {
      const { user } = this

      if (!user) {
        return ''
      }

      return `${user.first_name} ${user.last_name}`
    },
  },

  created() {
    if (!this.user) {
      // User refreshed the page, redirect to sign in
      this.$router.push({ name: 'SignIn' })
    }
  },

  methods: {
    async startSession(sessionItem: StartSessionItem) {
      const { user } = this
      if (!user) {
        return
      }

      const { root_id, root_user_entity_id, role } = sessionItem

      this.loading = true
      try {
        await this.userStore.startSession({
          user_id: user.id,
          root_id,
          entity_id: root_user_entity_id,
          role,
          selected_entity_id: sessionItem.entity_id,
        })

        const settings: UserSettings['additional'] = {
          privacy_mode: this.$route.query.privacy == 'true',
        }

        if (this.rememberSelectedSpace) {
          settings.default_session = {
            entity_id: sessionItem.entity_id,
            role,
          }
        }

        await this.userStore.saveUserAdditionalSettings(settings)
        this.$router.push('/')
      } catch (err) {
        UserService.saveAuthErrors(retrieveErrorsMessages(err))
        UserService.logout('', true, false)
      }
      this.loading = false
    },
  },
})
</script>
