import { defineComponent } from 'vue'

import { useEntityDates } from '@/composables/entity_dates'

export default defineComponent({
  data() {
    return {
      entityDates: useEntityDates(),
    }
  },

  methods: {
    getEntityCurrentDate(): Date {
      return this.entityDates.getEntityCurrentDate()
    },
    getEntityLastCloseDate() {
      return this.entityDates.getEntityLastCloseDate()
    },
  },
})
