const AccountSettingsView = () => import('@/views/Settings/Account/Index.vue')
const ReferrerView = () => import('@/views/Referrer/Index.vue')
const OnePagerLayout = () => import('@/layouts/OnePager.vue')

import i18n from '../langs'

import { type RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: OnePagerLayout,
    meta: {
      name: i18n.global.t('ui.common.dashboard'),
    },
    children: [
      {
        path: '/',
        alias: '/referrer',
        name: 'ReferrerView',
        component: ReferrerView,
        meta: {
          name: i18n.global.t('ui.common.referral_view'),
        },
      },
      {
        path: '/account-settings',
        name: 'AccountSettingsView',
        component: AccountSettingsView,
        meta: {
          name: i18n.global.t('ui.common.account_settings'),
        },
      },
    ],
  },
]

export default routes
