import ApiService from '@/services/api.service'
import {
  type GeneralNotificationParams,
  type GeneralNotificationUpdateParams,
  type GeneralNotificationsResponse,
} from '@/types/services/general_notifications'
import { type AxiosResponse } from 'axios'

class GeneralNotifications {
  static get(
    entity_id: string
  ): Promise<AxiosResponse<GeneralNotificationsResponse>> {
    return ApiService.post('/api/v1/general_notification/search', {
      filters: [
        {
          column: 'entity_id',
          relations: 'eq',
          value: entity_id,
        },
      ],
    })
  }

  static create(params: GeneralNotificationParams) {
    return ApiService.post('/api/v1/general_notifications', params)
  }

  static update(params: GeneralNotificationUpdateParams) {
    const { id, ...data } = params
    return ApiService.put(`/api/v1/general_notifications/${id}`, data)
  }

  static remove(id: string) {
    return ApiService.delete(`/api/v1/general_notifications/${id}`)
  }
}

export default GeneralNotifications
