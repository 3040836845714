import { acceptHMRUpdate, defineStore } from 'pinia'
import { keyBy } from 'lodash-es'

import { TagType } from '@/constants/tags'
import DuplicateError from '@/errors/DuplicateError'
import { HttpError } from '@/helpers/errors'

import AccountTag from '@/services/account/tag'
import { useUserStore } from './user'

import type { Tag, TagTypeValue } from '@/types/services/tags'

export interface AssignTagsParams {
  accountId: string
  rootId: string
  tags: Tag[]
}

export interface TagsMap {
  [key: string]: Tag
}

export interface GeneralState {
  tagsMap: TagsMap
}

export const useSettingsGeneralStore = defineStore('settingsGeneral', {
  state(): GeneralState {
    return {
      tagsMap: {},
    }
  },

  actions: {
    async getTags() {
      const userStore = useUserStore()
      try {
        const response = await AccountTag.getTags(userStore.root.id)
        this.tagsMap = keyBy(response.data.data, 'id')
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async createTag(tag: Omit<Tag, 'root_id'>) {
      const userStore = useUserStore()
      try {
        const response = await AccountTag.createTag(userStore.root.id, tag)
        return response.data.data
      } catch (err: any) {
        if (err.response?.status == 409) {
          throw new DuplicateError(err.response.data.result.message)
        }
        throw new HttpError(err)
      }
    },

    async updateTag(tag: Omit<Tag, 'root_id'>) {
      const userStore = useUserStore()
      try {
        const response = await AccountTag.updateTag(userStore.root.id, tag)
        return response.data.data
      } catch (err: any) {
        if (err.response?.status == 409) {
          throw new DuplicateError(err.response.data.result.message)
        }
        throw new HttpError(err)
      }
    },

    async deleteTag(tagId: string) {
      const userStore = useUserStore()
      try {
        const response = await AccountTag.deleteTag(userStore.root.id, tagId)
        return response.data.data
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async mergeTags(sourceTagId: string, targetTagId: string) {
      try {
        const response = await AccountTag.mergeTags(sourceTagId, targetTagId)
        return response.data.data
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async assignAccountTags(params: AssignTagsParams) {
      try {
        const response = await AccountTag.assignAccountTags(
          params.accountId,
          params.rootId,
          params.tags
        )
        const assignedTags: Tag[] = response.data.data.map((tag) => ({
          id: tag.tag_id,
          name: tag.tag_name,
          root_id: params.rootId,
          type: TagType.TAG,
        }))

        params.tags.forEach((tag) => {
          if (!tag.id) {
            const newTag = assignedTags.find(
              (assignedTag) => assignedTag.name === tag.name
            )
            if (newTag) {
              newTag.count = 1
              this.addTagToMap(newTag)
            }
          }
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    updateTagInMap(tag: Tag) {
      if (tag.id) {
        this.tagsMap[tag.id] = tag
      }
    },

    addTagToMap(newTag: Tag) {
      if (newTag.id) {
        this.tagsMap[newTag.id] = newTag
      }
    },

    deleteTagFromMap(id: string) {
      delete this.tagsMap[id]
    },
  },

  getters: {
    tags(state) {
      return Object.values(state.tagsMap)
    },

    tagsByType() {
      return (type: TagTypeValue): Tag[] => {
        return this.tags
          .filter((tag) => tag.type === type)
          .sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
      }
    },

    accountTags(): Tag[] {
      return this.tagsByType(TagType.TAG)
    },

    categoryTags(): Tag[] {
      return this.tagsByType(TagType.CATEGORY_TAG)
    },

    tagById(state) {
      return (id: string) => {
        return id in state.tagsMap ? state.tagsMap[id] : null
      }
    },

    tagsByIds() {
      return (ids: string[]): Tag[] => {
        const tags: Tag[] = []

        ids.forEach((id) => {
          const tag = this.tagById(id)
          if (tag) {
            tags.push(tag)
          }
        })

        return tags.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useSettingsGeneralStore, import.meta.hot)
  )
}
