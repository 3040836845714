<template>
  <div class="login-form">
    <div
      v-if="sending || loadingEmail"
      id="forgot-password-loader"
      class="w-full flex items-center justify-center h-40"
    >
      <Spinner width="100" class="fill-brand" />
    </div>
    <template v-else-if="$route.params.token">
      <p class="text-xxl font-bold mb-4">
        {{
          resetType === 'set'
            ? $t('ui.common.set_password')
            : $t('ui.common.reset_password')
        }}
      </p>
      <ModalErrors
        v-if="formErrors"
        :negative-offset="12"
        :messages="formErrors"
      />
      <div
        v-if="sent"
        id="forgot-password-reset-success"
        class="w-full mt-6 text-base"
      >
        <p>{{ $t('ui.common.your_password_was_changed') }}</p>
        <Button
          id="forgot-password-sign-in-link"
          class="mt-6 ml-auto w-1/2"
          type="primary"
          @click="goSignIn"
        >
          {{ $t('ui.common.log_in') }}
        </Button>
      </div>
      <Form
        v-else-if="email"
        :validation-schema="changePwdValidationSchema"
        @submit="resetPassword"
      >
        <BasicInput
          id="forgot-password-new-password"
          v-model="new_password"
          name="new_password"
          :label="$t('ui.common.new_password')"
          type="password"
          class="mt-6"
          autocomplete="new-password"
          required
        />

        <BasicInput
          id="forgot-password-confirm-password"
          v-model="confirm_password"
          name="confirm_password"
          type="password"
          autocomplete="new-password"
          class="mt-2"
          :label="$t('ui.common.confirm_password')"
          required
        />

        <PasswordStrength
          class="w-full flex items-center h-4"
          :password="new_password"
        />

        <Button
          id="forgot-password-reset-password"
          class="w-full mt-6"
          button-type="submit"
          type="primary"
          :loading="sending"
        >
          {{
            resetType === 'set'
              ? $t('ui.common.set_password')
              : $t('ui.common.reset_password')
          }}
        </Button>
      </Form>
    </template>
    <template v-else>
      <p class="text-xxl font-bold mb-4">
        {{ $t('ui.common.forgotten_password') }}
      </p>
      <p class="text-secondary">
        {{ $t('ui.common.log_in_moto') }}
      </p>
      <ModalErrors
        v-if="formErrors"
        :negative-offset="12"
        :messages="formErrors"
      />
      <div
        v-if="sent"
        id="forgot-password-email-success"
        class="w-full mt-6 text-base"
      >
        <p>{{ $t('messages.receive_email_with_reset_link') }}</p>
      </div>
      <Form
        v-else
        :validation-schema="sendEmailValidationSchema"
        @submit="sendEmail"
      >
        <BasicInput
          id="forgot-password-email"
          v-model="email"
          name="email"
          label="Email"
          class="mt-6"
          required
        />

        <div class="mt-2 flex justify-between">
          <Button
            id="forgot-password-sign-in-link"
            type="secondary"
            icon="Back"
            @click="goSignIn"
          >
            {{ $t('ui.common.log_in') }}
          </Button>
          <Button
            id="forgot-password-send-reset-email"
            class="w-1/2"
            button-type="submit"
            type="primary"
          >
            {{ $t('ui.common.reset_password') }}
          </Button>
        </div>
      </Form>
    </template>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { Form } from 'vee-validate'
import { string, object, ref } from 'yup'

import { retrieveErrorsMessages } from '@/helpers/errors'
import Spinner from '@/assets/loaders/Spinner.svg?component'
import ForgottenPassword from '@/services/user/forgotten_password'
import UserService from '@/services/user/user'

import Button from '@/components/controls/Button'
import BasicInput from '@/components/controls/BasicInput'
import ModalErrors from '@/components/misc/Modal/ModalErrors.vue'
import PasswordStrength from '@/components/misc/PasswordStrength'
import i18n from '@/langs'

export default {
  components: {
    BasicInput,
    Button,
    Form,
    ModalErrors,
    PasswordStrength,
    Spinner,
  },

  props: {
    resetType: {
      type: String,
      default: 'reset',
    },
    token: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      email: '',
      new_password: '',
      confirm_password: '',
      sending: false,
      sent: false,
      formErrors: null,
      loadingEmail: false,

      changePwdValidationSchema: markRaw(
        object({
          new_password: string().required().min(8).passStrength(),
          confirm_password: string().oneOf(
            [ref('new_password')],
            i18n.global.t('validation.password_must_match')
          ),
        })
      ),

      sendEmailValidationSchema: markRaw(
        object({
          email: string().required().email(),
        })
      ),
    }
  },

  created() {
    if (this.token) {
      this.fetchEmail()
    }
  },

  methods: {
    async sendEmail() {
      this.formErrors = null
      this.sending = true
      try {
        const response = await ForgottenPassword.sendResetEmail(this.email)
        if (response.data.result.success) {
          this.sent = true
        }
        this.sending = false
      } catch (err) {
        this.formErrors = retrieveErrorsMessages(err)
        this.sending = false
      }
    },
    async resetPassword() {
      this.formErrors = null
      this.sending = true
      try {
        const hashedPassword = UserService.hashPassword(
          this.new_password,
          this.email
        )
        await ForgottenPassword.resetPassword(hashedPassword, this.token)
        this.sent = true
        this.sending = false
      } catch (err) {
        this.formErrors = retrieveErrorsMessages(err)
        this.sending = false
      }
    },
    async fetchEmail() {
      this.loadingEmail = true
      try {
        const response = await ForgottenPassword.getEmailByToken(this.token)
        this.email = response.data.result.message
        this.loadingEmail = false
      } catch (err) {
        this.formErrors = [this.$t('messages.invalid_or_expired_token')]
        this.loadingEmail = false
      }
    },
    goSignIn() {
      window.location.href = '/signin'
    },
  },
}
</script>
