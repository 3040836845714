import axios from 'axios'
import i18n from '../langs'

class HttpError extends Error {
  rawMessage: any
  isCanceled: boolean

  constructor(httpError: any) {
    if (httpError.response) {
      const httpResponse = httpError.response
      const message =
        (httpResponse.data.result && httpResponse.data.result.message) ||
        httpResponse.data.message ||
        httpResponse.data.detail ||
        httpResponse.statusText
      if (typeof message === 'object') {
        if ('message' in message) {
          super(message.message)
        } else {
          super(JSON.stringify(message))
        }
      } else {
        super(message)
      }
      this.rawMessage = message
    } else {
      super(httpError.message)
    }
    this.name = 'HttpError'
    this.isCanceled = axios.isCancel(httpError)
  }

  getFriendlyMessage(): string {
    return this.message || i18n.global.t('messages.something_went_wrong')
  }
}

/**
 * Due to different error formats returning from back-end API
 * we try to normalize error messages with this helper function
 */
function retrieveErrorsMessages(err: any): string[] {
  // An array with string error messages
  const formErrors: string[] = []

  let message: any = null

  if (err instanceof HttpError) {
    try {
      message = JSON.parse(err.message)
    } catch (parseErr) {
      // is not JSON
      message = err.getFriendlyMessage()
    }
  } else if (err instanceof Error) {
    message = err.message
  } else if (err.response && err.response.data) {
    if (err.response.data.result && err.response.data.result.message) {
      message = err.response.data.result.message
    } else if (err.response.data.message) {
      message = err.response.data.message
    }
  }

  if (message === null) {
    formErrors.push('An unknown error occurred. Please try again.')
  } else if (typeof message === 'string') {
    formErrors.push(message)
  } else if (typeof message === 'object') {
    Object.keys(message).forEach((key) => {
      formErrors.push(message[key])
    })
  }

  return formErrors
}

export { HttpError, retrieveErrorsMessages }
