<template>
  <div class="notifications-handler">
    <transition-group name="notifications-snackbar" appear>
      <div
        v-for="(notif, i) in notifications"
        :key="notif.id"
        :class="notif.type"
        class="mt-6"
        @click="close(i)"
      >
        <div v-if="notif.type == 'confirm' && typeof notif.message == 'object'">
          <p>{{ notif.message.message }}</p>
          <div class="flex justify-between mt-2">
            <Button
              type="text"
              size="sm"
              :icon="notif.message.cancelIcon"
              @click="cancelMessage(notif.message, i)"
            >
              {{ notif.message.cancelText || 'Cancel' }}
            </Button>
            <Button
              v-if="!notif.message.hideConfirm"
              type="text"
              size="sm"
              :icon="notif.message.confirmIcon"
              @click="confirmMessage(notif.message, i)"
            >
              {{ notif.message.confirmText || 'Confirm' }}
            </Button>
          </div>
        </div>
        <p v-else>{{ notif.message }}</p>
        <SvgIcon icon="Close" :size="12" class="close" />
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
import './SnackBar.scss'

import { defineComponent } from 'vue'

import Button from '@/components/controls/Button'
import SvgIcon from '@/components/misc/SvgIcon'

import { HttpError } from '@/helpers/errors'

import type { ConfirmMessage } from '@/types/components/snack_bar'

type SnackNotificationType = 'error' | 'success' | 'warning' | 'confirm'

interface SnackNotification {
  id: number
  message: string | ConfirmMessage
  type: SnackNotificationType
}

export default defineComponent({
  components: {
    Button,
    SvgIcon,
  },

  data() {
    return {
      notifications: [] as SnackNotification[],
    }
  },

  mounted() {
    // Register global event listener
    this.$bus.on('error', this.addError)
    this.$bus.on('warning', this.addWarning)
    this.$bus.on('success', this.addSuccess)
    this.$bus.on('confirm', this.addConfirm)
  },

  beforeUnmount() {
    this.$bus.off('error', this.addError)
    this.$bus.off('warning', this.addWarning)
    this.$bus.off('success', this.addSuccess)
    this.$bus.off('confirm', this.addConfirm)
  },

  methods: {
    addError(error: any) {
      let message = error.message
      if (error.name === 'HttpError') {
        // ignore canceled requests
        if ((error as HttpError).isCanceled) {
          return
        }
        message = error.getFriendlyMessage()
      }

      this.addMessage(message, 'error')

      // Display the error in console
      const isDev = import.meta.env.DEV
      if (isDev) {
        console.error(error)
      }
    },
    addSuccess(success: any) {
      this.addMessage(success.message, 'success')
    },
    addWarning(warning: any) {
      this.addMessage(warning.message, 'warning')
    },
    addConfirm(message: ConfirmMessage) {
      const id = Date.now()
      this.notifications.unshift({
        id,
        message,
        type: 'confirm',
      })
    },
    addMessage(message: string, type: SnackNotificationType) {
      // Add message at the beginning of array
      const id = Date.now()
      this.notifications.unshift({
        message,
        id,
        type,
      })

      if (this.notifications.length > 5) {
        this.notifications = this.notifications.slice(0, 5)
      }

      //Dynamic timeout for message fade min 2sec max 7sec
      const matches = message ? message.match(/\w+/gu) : null
      const wordsCount = matches ? matches.length : 0
      const WORDS_IN_SEC = 3
      const timeoutFade = Math.min(
        Math.max((wordsCount / WORDS_IN_SEC) * 1000, 2000),
        7000
      )

      setTimeout(() => {
        let index: number | null = null
        for (let i = 0; i < this.notifications.length; i++) {
          if (this.notifications[i].id === id) {
            index = i
            break
          }
        }
        if (index !== null) {
          this.close(index)
        }
      }, timeoutFade)
    },
    close(index: number) {
      this.notifications.splice(index, 1)
    },
    cancelMessage(message: ConfirmMessage, index: number) {
      if (message.cancelAction) {
        message.cancelAction()
      }
      this.close(index)
    },
    confirmMessage(message: ConfirmMessage, index: number) {
      if (message.confirmAction) {
        message.confirmAction()
      }
      this.close(index)
    },
  },
})
</script>
