import JsSHA512 from 'jssha/sha512'
import { EMOJI } from '@/constants/emoji'

/**
 * Check if string has UUID format
 *
 * @param {string} text - String to check for UUID format
 * @return {boolean} Whether the input text is in UUID format
 */
function isUUID(text: string): boolean {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/iu.test(
    text
  )
}

/**
 * Generates a SHA-512 hash for the input text.
 *
 * @param {string} text - The text to generate the hash from
 * @return {string} The SHA-512 hash in hexadecimal format
 */
function getHash(text: string): string {
  const hash = new JsSHA512('SHA-512', 'TEXT', { encoding: 'UTF8' })
  hash.update(text)
  return hash.getHash('HEX')
}

/**
 * Compares two strings in a case-insensitive manner.
 *
 * @param {string} a - The first string to compare
 * @param {string} b - The second string to compare
 * @return {number} A negative number if a < b, 0 if a = b, a positive number if a > b
 */
function ignoreCaseCompare(a: string, b: string): number {
  const valA = a.toLowerCase()
  const valB = b.toLowerCase()
  return valA.localeCompare(valB)
}

/**
 * Returns a shortened version of a UUID by extracting the last four characters and prefixing them with a '#'.
 *
 * @param {string} uuid - The UUID to shorten.
 * @return {string} The shortened UUID.
 */
function shortenUUID(uuid: string): string {
  return `#${uuid.slice(-4)}`
}

/**
 * Removes accents from a string by normalizing it and replacing all accented characters with their non-accented equivalents.
 *
 * @param {string} str - The input string with accents.
 * @return {string} The input string without accents.
 */
function removeAccent(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

function convertTextEmojisToUnicode(text: string): string {
  return text.replace(/:([a-zA-Z0-9_+-]+):/g, (match, emojiName) => {
    return emojiName in EMOJI
      ? `<span class="font-emoji text-enlarge-110">${EMOJI[emojiName]}</span>`
      : emojiName
  })
}

export {
  isUUID,
  getHash,
  ignoreCaseCompare,
  shortenUUID,
  removeAccent,
  convertTextEmojisToUnicode,
}
