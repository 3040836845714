import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M11.5 15v-4.016M8.105 8.976l3.395 2.012 3.395-2.012M8 8.898V4.882M4.605 2.875 8 4.887l3.395-2.012M4.5 15v-4.016M1.105 8.976 4.5 10.988l3.395-2.012M15 12.577V9.39a.8.8 0 0 0-.104-.398.8.8 0 0 0-.285-.292L11.89 7.107a.76.76 0 0 0-.778 0L8.39 8.7a.8.8 0 0 0-.285.292A.8.8 0 0 0 8 9.39v3.187c0 .14.036.277.104.398a.8.8 0 0 0 .285.291l2.722 1.594a.76.76 0 0 0 .778 0l2.722-1.594a.8.8 0 0 0 .285-.29.8.8 0 0 0 .104-.399m-3.5-6.101V3.288a.8.8 0 0 0-.104-.398.8.8 0 0 0-.285-.291L8.39 1.005a.76.76 0 0 0-.778 0L4.89 2.6a.8.8 0 0 0-.285.291.8.8 0 0 0-.104.398v3.188c0 .14.036.277.104.398a.8.8 0 0 0 .285.29L7.61 8.76a.76.76 0 0 0 .778 0l2.722-1.594a.8.8 0 0 0 .285-.291.8.8 0 0 0 .104-.398M8 12.577V9.39a.8.8 0 0 0-.104-.398.8.8 0 0 0-.285-.292L4.89 7.107a.76.76 0 0 0-.778 0L1.39 8.7a.8.8 0 0 0-.285.292A.8.8 0 0 0 1 9.39v3.187c0 .14.036.277.104.398a.8.8 0 0 0 .285.291L4.11 14.86a.76.76 0 0 0 .778 0l2.722-1.594a.8.8 0 0 0 .285-.29.8.8 0 0 0 .104-.399" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }