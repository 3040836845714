const scrollTargets = [
  null,
  document,
  document.body,
  document.scrollingElement,
  document.documentElement,
]

/**
 * Finds the scroll target element for the given element.
 *
 * @param {Element} el - The element to find the scroll target for.
 * @return {Element | (Window & typeof globalThis) | null} - The scroll target element or the window object if no specific scroll target is found.
 */
export function findScrollTarget(
  el: Element
): Element | (Window & typeof globalThis) | null {
  let target = el.closest('.overflow-scroll,.overflow-y-auto,.overflow-auto')

  if (target === null) {
    let curr: HTMLElement | null = el as HTMLElement
    while ((curr = curr.parentElement) !== null) {
      const style = window.getComputedStyle(curr)
      if (
        style.overflowY === 'auto' ||
        style.overflowY === 'scroll' ||
        style.overflow === 'auto' ||
        style.overflow === 'scroll'
      ) {
        target = curr
        break
      }
    }
  }

  return scrollTargets.includes(target) ? window : target
}
