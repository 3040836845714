import { acceptHMRUpdate, defineStore } from 'pinia'
import { isAfter, isBefore, parseISO } from 'date-fns'

import { HttpError } from '@/helpers/errors'
import NotificationsService from '@/services/user/notifications'

import {
  type GeneralNotification,
  type GeneralNotificationParams,
  type GeneralNotificationUpdateParams,
} from '@/types/services/general_notifications'
import { type Notification } from '@/types/services/notifications'
import GeneralNotifications from '@/services/notifications/general_notification'

export interface NotificationsState {
  notifications: Notification[]
  total: number
  userNotifications: Notification[]
  generalNotifications: GeneralNotification[]
}

export const useNotificationsStore = defineStore('notifications', {
  state(): NotificationsState {
    return {
      notifications: [],
      total: 0,
      userNotifications: [],
      generalNotifications: [],
    }
  },

  actions: {
    async getNotifications({
      userId,
      rootId,
      rootUserId,
      offset,
      limit,
    }: {
      userId: string
      rootId: string
      rootUserId: string
      offset?: number
      limit?: number
    }) {
      try {
        const response = await NotificationsService.getNotifications(
          userId,
          rootId,
          rootUserId,
          offset || 0,
          limit || 10
        )
        const now = new Date()
        const data = response.data.data
        let notifications = data.data.filter((item) => {
          return isBefore(parseISO(item.date), now)
        })
        const filteredCount = data.data.length - notifications.length

        if (offset && offset > 0) {
          notifications = this.notifications.concat(notifications)
        }

        this.$patch({
          notifications,
          total: data.total - filteredCount,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async setDoneNotification(notificationId: string) {
      try {
        await NotificationsService.setDoneNotification(notificationId)

        const idx = this.notifications.findIndex((n) => n.id == notificationId)
        this.notifications.splice(idx, 1)

        const userIdx = this.userNotifications.findIndex(
          (n) => n.id == notificationId
        )
        this.userNotifications.splice(userIdx, 1)
        this.total -= 1
      } catch (err) {
        throw new HttpError(err)
      }
    },

    setReadNotifications(notificationIds: string[]) {
      return new Promise((resolve, reject) => {
        notificationIds.forEach((notificationId) => {
          NotificationsService.setReadNotification(notificationId)
            .then(() => {
              const notification = this.notifications.find(
                (notification) => notification.id == notificationId
              )
              if (notification) {
                notification.read = true
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
        resolve(true)
      })
    },

    async setDoneAllNotifications({
      userId,
      rootId,
      rootUserId,
    }: {
      userId: string
      rootId: string
      rootUserId: string
    }) {
      try {
        await NotificationsService.setDoneAllNotifications(
          userId,
          rootId,
          rootUserId
        )
        this.$patch({
          notifications: [],
          total: 0,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async createNotification({
      sender_id,
      receiver_id,
      message,
      date,
    }: {
      sender_id: string
      receiver_id: string
      message: string
      date: string
    }) {
      try {
        await NotificationsService.createNotification(
          sender_id,
          receiver_id,
          message,
          date
        )
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async getUserNotifications(rootUserId: string) {
      try {
        const response =
          await NotificationsService.getUserNotifications(rootUserId)
        const now = new Date()
        this.userNotifications = response.data.data.data.filter(
          (item) => isAfter(parseISO(item.date), now) && !item.done
        )
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async getGeneralNotifications(entity_id: string) {
      try {
        const response = await GeneralNotifications.get(entity_id)
        this.generalNotifications = response.data.data.sort((a, b) => {
          return b.created.localeCompare(a.created)
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async createGeneralNotification(params: GeneralNotificationParams) {
      try {
        await GeneralNotifications.create(params)
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async updateGeneralNotification(params: GeneralNotificationUpdateParams) {
      try {
        await GeneralNotifications.update(params)
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async deleteGeneralNotification(id: string) {
      try {
        await GeneralNotifications.remove(id)
        const idx = this.generalNotifications.findIndex((gn) => gn.id == id)
        this.generalNotifications.splice(idx, 1)
      } catch (err) {
        throw new HttpError(err)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationsStore, import.meta.hot)
  )
}
