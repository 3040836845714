import { ref } from 'vue'
import { getHash } from '@/helpers/strings'

const TEST_MODE_ENABLED_KEY = 'test_mode_enabled'

const enabled = ref(sessionStorage.getItem(TEST_MODE_ENABLED_KEY) === 'true')

export function useTestMode() {
  function forceState(enable: boolean) {
    enabled.value = enable
    sessionStorage.setItem(TEST_MODE_ENABLED_KEY, JSON.stringify(enable))
  }

  function isStatusForced() {
    return sessionStorage.getItem(TEST_MODE_ENABLED_KEY) !== null
  }

  function toggleByEntity(entity_id: string) {
    const testModeEntityIds = import.meta.env.VUE_APP_TEST_IDS ?? []
    if (testModeEntityIds.length) {
      enabled.value = testModeEntityIds.includes(getHash(entity_id))
    }
  }

  return {
    enabled,
    isStatusForced,
    toggleByEntity,
    forceState,
  }
}
