<template>
  <ExpandPanel
    v-for="(relation, i) in sortedRelations"
    :id="`select-root-${relation.root_id}`"
    :key="i"
    v-model="expandedPanels[i]"
    @toggled="(expanded) => expanded && closeOthers(i)"
  >
    <template #title>
      <Avatar
        :entity="{ id: relation.root_id, text: relation.root_name }"
        size="lg"
      />
      {{ relation.root_name }}
    </template>
    <div class="mx-4 mt-2">
      <EntityRolePanels
        :root-relation="relation"
        :initial-expand-index="0"
        @start-session="(data) => emit('startSession', data)"
      />
    </div>
  </ExpandPanel>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import ExpandPanel from '@/components/misc/ExpandPanel/ExpandPanel.vue'
import Avatar from '@/components/misc/Avatar'
import EntityRolePanels from '@/components/navigation/components/EntityRolePanels.vue'

import type { PropType } from 'vue'
import type { StartSessionItem } from '@/components/navigation/components/EntityRolePanels.vue'
import type { UserRootRelation } from '@/types/services/user'

const props = defineProps({
  relations: {
    type: Array as PropType<UserRootRelation[]>,
    default: () => [],
  },
})

const emit = defineEmits<{
  startSession: [sessionItem: StartSessionItem]
}>()

const expandedPanels = ref<boolean[]>([])

const sortedRelations = computed(() =>
  [...props.relations].sort((a, b) => {
    return a.root_name.localeCompare(b.root_name)
  })
)

watch(
  props.relations,
  (relations: UserRootRelation[]) => {
    expandedPanels.value = []
    relations.forEach((rel, idx) => expandedPanels.value.push(idx == 0))
  },
  { immediate: true }
)

const closeOthers = (idx: number) => {
  for (let i = 0; i < expandedPanels.value.length; i++) {
    if (i != idx) {
      expandedPanels.value[i] = false
    }
  }
}
</script>
