import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50" }, [
      _createElementVNode("animateTransform", {
        attributeName: "transform",
        attributeType: "XML",
        dur: "1s",
        from: "0 50 50",
        repeatCount: "indefinite",
        to: "360 50 50",
        type: "rotate"
      })
    ], -1 /* HOISTED */)
  ])))
}
export default { render: render }