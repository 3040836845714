<template>
  <div>
    <ExpandPanel
      v-for="(entity, j) in entitiesWithSessionItems"
      :id="`select-entity-${entity.entity_id}`"
      :key="entity.entity_id"
      :value="j == initialExpandIndex"
      :class="{ 'mb-2': j == entitiesWithSessionItems.length - 1 }"
      nested
    >
      <template #title>
        <div class="flex items-center">
          <SvgIcon icon="Box" class="text-brand mr-4 shrink-0" />
          <span class="truncate">
            {{ entity.name }}
          </span>
        </div>
      </template>
      <div
        v-for="(role, k) in entity.roles"
        :id="`entity-${entity.entity_id}-select-role-${role.name}`"
        :key="k"
        class="cursor-pointer ml-6 mb-3 flex items-center font-medium text-xs select-none text-secondary hover:text-brand"
        :class="{ 'mt-1': k == 0 }"
        @click="$emit('start-session', role.item)"
      >
        <SvgIcon
          :icon="role.name == 'admin' ? 'UserCoins' : 'User'"
          class="mr-4"
        />
        {{ $t(`ui.auth.${role.name}_view`) }}
      </div>
    </ExpandPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

import ExpandPanel from '@/components/misc/ExpandPanel'
import SvgIcon from '@/components/misc/SvgIcon'

import {
  type Role,
  type SessionRole,
  type UserEntityRelation,
  type UserRootRelation,
} from '@/types/services/user'

export interface StartSessionItem {
  root_id: string
  root_user_entity_id: string
  entity_id: string
  role: Role
}

interface UserEntityRelationWithSessionItem
  extends Omit<UserEntityRelation, 'roles'> {
  roles: {
    name: SessionRole
    item: StartSessionItem
  }[]
}

const SORT_MAP: { [key in SessionRole]: number } = {
  admin: 1,
  client: 2,
  referral: 3,
}

export default defineComponent({
  components: {
    ExpandPanel,
    SvgIcon,
  },

  props: {
    rootRelation: {
      type: Object as PropType<UserRootRelation>,
      required: true,
    },
    initialExpandIndex: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    entitiesWithSessionItems(): UserEntityRelationWithSessionItem[] {
      const { rootRelation } = this

      return rootRelation.entities
        .map((entity) => {
          const roles: Role[] = [...entity.roles].sort(
            (a, b) => SORT_MAP[a] - SORT_MAP[b]
          )
          if (rootRelation.is_superadmin) {
            roles.unshift('superadmin')
          }

          return {
            ...entity,
            roles: roles.map((role) => {
              const roleName = role == 'superadmin' ? 'admin' : role
              return {
                name: roleName,
                item: {
                  root_id: rootRelation.root_id,
                  root_user_entity_id: rootRelation.root_user_entity_id,
                  entity_id: entity.entity_id,
                  role,
                },
              }
            }),
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    },
  },
})
</script>
