import fuzzysort from 'fuzzysort'

export interface SearchedObject {
  highlight?: {
    [key: string]: string | null
  }
  searchScore?: number
}

const SEARCH_THRESHOLD = 0.5

/**
 * Fuzzy search
 *
 * @param search Search text
 * @param items Items that are searched
 * @param options custom search options
 *  e.g.:
 *  {
 *    keys: ['text', 'name'],
 *    // prioritize text key
 *    scoreFn: (a) => Math.max(a[0].score * 1.25, a[1].score, a[2].score),
 *  }
 */
export function fuzzySearch<T extends SearchedObject>(
  search: string,
  items: T[],
  options: Fuzzysort.KeysOptions<T> = { keys: ['text'] }
): T[] {
  const sortedArr = fuzzysort.go(search, items, {
    limit: 100,
    threshold: SEARCH_THRESHOLD,
    ...options,
  })
  const foundArr: T[] = []

  sortedArr.forEach((item) => {
    item.obj.highlight = {}
    for (const [index, key] of options.keys.entries()) {
      if (typeof key == 'string') {
        item.obj.highlight[key] = item[index].highlight()
      }
    }
    foundArr.push(item.obj)
  })

  return foundArr
}

/**
 * Performs a fuzzy search on a single value.
 *
 * @param search The search text.
 * @param value The value to search.
 * @returns True if the value matches the search text, false otherwise.
 */
export function fuzzySearchSingle(search: string, value: string): boolean {
  const result = fuzzysort.single(search, value)

  return !(result === null || result.score < SEARCH_THRESHOLD)
}

/**
 * Performs a fuzzy search on an object.
 *
 * @param search The search text.
 * @param item The object to search.
 * @param keys The keys of the object to search.
 * @returns True if the object matches the search text, false otherwise.
 */
export function fuzzySearchObject<T = any>(
  search: string,
  item: T,
  keys: (keyof T)[]
): boolean {
  return keys.some(
    (key) =>
      typeof item[key] === 'string' && fuzzySearchSingle(search, item[key])
  )
}

export default fuzzySearch
