<template>
  <div>
    <PanelToolbar
      :name="currentDecadeName"
      :aria-label-prev="$t('ui.components.datepicker.previous_decade')"
      :aria-label-next="$t('ui.components.datepicker.next_decade')"
      disable-switch
      @prev="prev"
      @next="next"
    />

    <div class="calendar-years">
      <div
        v-for="year in years"
        :key="year.value"
        class="year"
        :class="{
          active: year.active,
          selectable: year.selectable,
        }"
        @click.stop="selectYear(year)"
      >
        {{ year.value }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { addYears, setYear } from 'date-fns'

import PanelMixin from './panelMixin'
import PanelToolbar from './PanelToolbar.vue'

interface Year {
  value: number
  active: boolean
  selectable: boolean
}

export default defineComponent({
  components: {
    PanelToolbar,
  },

  mixins: [PanelMixin],

  emits: ['update:modelValue', 'close'],

  computed: {
    years(): Year[] {
      const years: Year[] = []
      const startYear = Math.floor(this.modelValue.getFullYear() / 10) * 10 - 1

      for (let i = 0; i < 12; i++) {
        const year = startYear + i
        years.push({
          value: year,
          active: year == this.selectedYear,
          selectable: this.isYearAllowed(year),
        })
      }

      return years
    },
    currentDecadeName(): string {
      const { years } = this
      return `${years[1].value}-${years[this.years.length - 2].value}`
    },
  },

  methods: {
    changeYear(diff: number): void {
      const newDate = addYears(this.modelValue, diff)
      this.$emit('update:modelValue', newDate)
    },
    prev(): void {
      this.changeYear(-10)
    },
    next(): void {
      this.changeYear(10)
    },
    selectYear(year: Year): void {
      const newDate = setYear(this.modelValue, year.value)
      this.$emit('update:modelValue', newDate)
      this.$emit('close')
    },
  },
})
</script>
