/**
 * Returns the release version of the application.
 *
 * @return {string} The release version of the application.
 */
function releaseVersion(): string {
  return __RELEASE_VER__
}

export { releaseVersion }
