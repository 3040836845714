<template>
  <div
    v-tippy="{
      trigger: comingSoon ? 'mouseenter focus' : 'manual',
      content: comingSoon ? $t('ui.common.coming_soon') : null,
    }"
    class="link-container"
    :class="[
      {
        active: isActive,
        'is-expanded': showSubmenu,
        'coming-soon': comingSoon,
        disabled,
      },
    ]"
  >
    <div class="link" @click="redirectTo()">
      <keep-alive>
        <SvgIcon :icon="icon" class="icon" />
      </keep-alive>
      <transition name="link" appear>
        <div v-if="sidebarToggled" class="link-label">
          <span>{{ text }}</span>
          <SvgIcon v-if="children.length" icon="ArrowDown" class="icon-arrow" />
        </div>
      </transition>
    </div>

    <transition name="sublink" appear>
      <div v-if="children.length && showSubmenu" class="sublink-container">
        <SidebarSublink
          v-for="(sublink, i) in children"
          :key="i"
          :sublink="sublink"
          :disabled="sublink.disabled"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { useGlobalStore } from '@/stores/global'

import SvgIcon from '@/components/misc/SvgIcon'
import SidebarSublink from './SidebarSublink.vue'

export default {
  components: {
    SidebarSublink,
    SvgIcon,
  },

  props: {
    linkIndex: {
      type: Number,
      default: null,
    },
    routeTo: {
      required: false,
      type: String,
      default: null,
    },
    icon: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    comingSoon: {
      type: Boolean,
      default: false,
    },
    children: {
      default: () => [],
      required: false,
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      collapsed: false,
      showSubmenu: false,
      fromToggled: false,

      globalStore: useGlobalStore(),
    }
  },

  computed: {
    ...mapState(useGlobalStore, ['collapsedMenuIndex', 'sidebarToggled']),
    isActive() {
      return (
        this.isChildActive ||
        this.$route.matched.some((match) => match.name === this.routeTo)
      )
    },
    isChildActive() {
      let childActive = false

      for (let ch of this.children) {
        // eslint-disable-next-line no-loop-func
        this.$route.matched.forEach((match) => {
          if (match.name === ch.routeTo) {
            childActive = true
          }
        })
      }

      return childActive
    },
    isCollapsed() {
      return this.linkIndex === this.collapsedMenuIndex && this.children.length
    },
  },

  watch: {
    isCollapsed(isCollapsed) {
      if (isCollapsed && this.sidebarToggled) {
        const time = !this.fromToggled ? 500 : 0

        setTimeout(() => {
          this.showSubmenu = true
        }, time)
      } else {
        this.showSubmenu = false
      }
    },
    sidebarToggled(sidebarToggled) {
      if (!sidebarToggled) {
        this.showSubmenu = false
      }
    },
    isActive(isActive) {
      if (isActive) {
        this.setCollapsedIndex()
      }
    },
  },

  created() {
    if (this.isActive) {
      this.setCollapsedIndex()
    }
  },

  methods: {
    setCollapsedIndex() {
      this.globalStore.collapsedMenuIndex = this.linkIndex
    },
    redirectTo() {
      this.fromToggled = this.sidebarToggled
      const newLinkIndex = this.isCollapsed ? null : this.linkIndex

      if (!this.sidebarToggled) {
        this.globalStore.toggleSidebar()
      }

      this.globalStore.collapsedMenuIndex = newLinkIndex

      if (this.children.length === 0) {
        this.$router.push({ name: this.routeTo }).catch(() => {
          // Empty error catch to prevent console errors on duplicate navigation
        })
        if (this.$breakpoints.mdAndDown) {
          this.$bus.emit('hideMobileSidebar')
        }
      }
    },
  },
}
</script>
