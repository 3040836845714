import i18n from '@/langs'
import router from '@/router'

import { useAssetsBlockchainsStore } from '@/stores/assets-blockchains'

import type { Props } from 'tippy.js'

export type StepType = 'modal' | 'tooltip'

export interface Step {
  type: StepType
  content: {
    icon?: string
    title?: string
    text: string
  }
  action?: Function
  actionText?: string
  onHide?: Function
  onShow?: Function
  waitUntil?: Function

  // tooltip options
  selector?: string
  placement?: Props['placement']
}

export interface Scenario {
  key: string
  steps: Step[]
}

const scenarios: Scenario[] = [
  {
    key: 'onboarding',
    steps: [
      {
        type: 'modal',
        content: {
          title: 'Welcome to Crypkit!',
          text: 'To start tracking your assets you first need to create a portfolio so let’s add one!',
        },
        action: async (): Promise<void> => {
          await router.push({ name: 'SettingsPortfoliosView' }).catch(() => {})
        },
      },
      {
        type: 'tooltip',
        content: {
          title: 'Click to add a new portfolio',
          text: 'You can always create more portfolios right here in this section.',
        },
        onShow: (tour: any): void => {
          const elm = document.querySelector('#portfolios-add-portfolio')
          if (elm) {
            elm.addEventListener('click', tour.nextStep)
          }
        },
        onHide: (tour: any): void => {
          const elm = document.querySelector('#portfolios-add-portfolio')
          if (elm) {
            elm.removeEventListener('click', tour.nextStep)
          }
        },
        selector: '#portfolios-add-portfolio',
      },
      {
        type: 'tooltip',
        content: {
          title: 'Congratulations on completing the first step!',
          text: 'Great, now let’s add some assets to track now.',
        },
        waitUntil: (): boolean => {
          const elm = document.querySelector('#portfolio-card-0')
          const btnAdd = document.querySelector('#modal-button-confirm')
          // showed some portfolio card and modal closed
          return Boolean(elm) && !btnAdd
        },
        action: async (): Promise<void> => {
          await router.push({ name: 'AssetSourcesView' }).catch(() => {})
        },
        actionText: i18n.global.t('ui.common.next'),
        selector: '#portfolio-card-0',
      },
      {
        type: 'modal',
        content: {
          icon: 'Pulse',
          title:
            'To start tracking assets you need to add sources from which to stream the data.',
          text: 'You can either use live data streams such as Exchanges or Blockchain addresses (Wallets) or you can add a variety of untracked assets via Manual Entries, those however need to be managed manually.',
        },
      },
      {
        type: 'tooltip',
        content: {
          title: 'Select a type of source you want to track',
          text: 'Simplest to add is a blockchain address (wallet) we recommend you start with that.',
        },
        onShow: (tour: any): void => {
          const tabBlockchain = document.querySelector('#tab-wallets')
          if (tabBlockchain) {
            tabBlockchain.addEventListener('click', tour.nextStep)
          }
        },
        onHide: (tour: any): void => {
          const tabBlockchain = document.querySelector('#tab-wallets')
          if (tabBlockchain) {
            tabBlockchain.removeEventListener('click', tour.nextStep)
          }
        },
        selector: '.tabs',
      },
      {
        type: 'tooltip',
        content: {
          title: 'Add a blockchain address you wish to track',
          text: 'You can always add, edit or remove them in this section in future.',
        },
        waitUntil: (): boolean => {
          const elm = document.querySelector('#wallets-add-wallet')
          // showed wallets tab with add button
          return Boolean(elm)
        },
        onShow: (tour: any): void => {
          const elm = document.querySelector('#wallets-add-wallet')
          if (elm) {
            elm.addEventListener('click', tour.nextStep)
          }
        },
        onHide: (tour: any): void => {
          const elm = document.querySelector('#wallets-add-wallet')
          if (elm) {
            elm.removeEventListener('click', tour.nextStep)
          }
        },
        selector: '#wallets-add-wallet',
      },
      {
        type: 'tooltip',
        content: {
          title: 'All set! Let’s go check out your Dashboard!',
          text: 'You can always come back here to add more assets',
        },
        waitUntil: (): boolean => {
          const store = useAssetsBlockchainsStore()
          const cnt = store.table.data.length
          const addBtn = document.querySelector('#modal-button-confirm')
          // some blockchains in Pinia store and modal closed
          return cnt > 0 && !addBtn
        },
        action: async (): Promise<void> => {
          await router.push({ name: 'DashboardView' }).catch(() => {})
        },
        selector: '#wallets-table .ct-table-container',
      },
      {
        type: 'modal',
        content: {
          icon: 'Pulse',
          title:
            'Congratulations, you are off to a great start in keeping track of your crypto assets.',
          text: 'If you need further help don’t hesitate to contact our support at <a href="mailto:info@crypkit.com" class="text-brand" target="_blank">info@crypkit.com</a> or if you would like to give us feedback we would be more than happy to hear it.',
        },
      },
    ],
  },
]

export default scenarios
