import { type RouteRecordRaw } from 'vue-router'

const AccountSettingsView = () => import('@/views/Settings/Account/Index.vue')
const ClientFundView = () => import('@/views/ClientFund/Index.vue')
const OnePagerLayout = () => import('@/layouts/OnePager.vue')

import i18n from '../langs'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: OnePagerLayout,
    meta: {
      name: i18n.global.t('ui.common.dashboard'),
    },
    children: [
      {
        path: '/',
        alias: '/client-fund',
        name: 'ClientFundView',
        component: ClientFundView,
        meta: {
          name: i18n.global.t('ui.common.fund'),
        },
      },
      {
        path: '/account-settings',
        name: 'AccountSettingsView',
        component: AccountSettingsView,
        meta: {
          name: i18n.global.t('ui.common.account_settings'),
        },
      },
    ],
  },
]

export default routes
