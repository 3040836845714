<template>
  <div
    class="px-4 py-2 text-sm flex items-center whitespace-nowrap"
    :class="{
      'hover:bg-blue-alpha cursor-pointer': isClickable,
      'font-medium text-brand bg-background': selected,
      'text-secondary': !selected && !disabled,
      'text-disabled pointer-events-none': disabled,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isClickable() {
      return 'onClick' in this.$attrs
    },
  },
})
</script>
