<template>
  <span class="ml-1">
    <Tippy :max-width="props.maxWidth">
      <template #content>
        <slot />
      </template>
      <template #default="{ state }">
        <SvgIcon
          icon="Help"
          :class="{ 'text-brand': state.isVisible }"
          size="sm"
          block
        />
      </template>
    </Tippy>
  </span>
</template>

<script lang="ts" setup>
import { Tippy } from 'vue-tippy'
import SvgIcon from '@/components/misc/SvgIcon'

import type { PropType } from 'vue'

const props = defineProps({
  maxWidth: [String, Number] as PropType<string | number | undefined>,
})
</script>
