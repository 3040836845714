import { defineComponent } from 'vue'
import { endOfDay, isAfter, isBefore, startOfDay } from 'date-fns'

import DatesMixin from '@/mixins/dates'
import LimitPropsMixin from './limitPropsMixin'

export interface YearsLimits {
  min: number
  max: number
}

export default defineComponent({
  mixins: [DatesMixin, LimitPropsMixin],

  methods: {
    isDateAllowed(date: Date): boolean {
      const now = this.getEntityCurrentDate()
      if (this.allowFrom && isBefore(date, startOfDay(this.allowFrom))) {
        return false
      }
      if (this.allowTo && isAfter(date, endOfDay(this.allowTo))) {
        return false
      }
      if (!this.future && isAfter(date, endOfDay(now))) {
        return false
      }
      return !(!this.past && isBefore(date, startOfDay(now)))
    },
    isMonthAllowed(month: number, year: number): boolean {
      if (!this.isYearAllowed(year)) {
        return false
      }

      let minMonth = 0
      let maxMonth = 11
      const now = this.getEntityCurrentDate()
      if (!this.past) {
        minMonth = now.getMonth()
      }
      if (this.allowFrom) {
        minMonth = Math.max(this.allowFrom.getMonth(), minMonth)
      }
      if (!this.future) {
        maxMonth = now.getMonth()
      }
      if (this.allowTo) {
        maxMonth = Math.min(this.allowTo.getMonth(), maxMonth)
      }

      const { min, max } = this.getLimitYears()

      const dateToCheck = new Date(year, month)
      return !(
        isBefore(dateToCheck, new Date(min, minMonth)) ||
        isAfter(dateToCheck, new Date(max, maxMonth))
      )
    },
    isYearAllowed(year: number): boolean {
      const { min, max } = this.getLimitYears()

      return year >= min && year <= max
    },
    getLimitYears(): YearsLimits {
      let minYear = 1900
      let maxYear = 3000

      const now = this.getEntityCurrentDate()
      if (!this.past) {
        minYear = now.getFullYear()
      }
      if (this.allowFrom) {
        minYear = Math.max(this.allowFrom.getFullYear(), minYear)
      }
      if (!this.future) {
        maxYear = now.getFullYear()
      }
      if (this.allowTo) {
        maxYear = Math.min(this.allowTo.getFullYear(), maxYear)
      }

      return {
        min: minYear,
        max: maxYear,
      }
    },
  },
})
