import type { Entity } from '@/types/services/root'
import type { ClientEntity } from '@/types/services/user'
import type {
  RootUser,
  RootUserListItem,
  RootUserRelation,
} from '@/types/services/root_user'

export interface RelationsMapByEntityId {
  [key: string]: RootUserRelation
}

type PartialRootUser = Partial<
  Pick<
    RootUser,
    'corporate' | 'first_name' | 'last_name' | 'company_name' | 'email'
  >
>

export function getTextFromRootUser(root_user: PartialRootUser): string {
  if (root_user.corporate) {
    return root_user.company_name || '-'
  }
  if (root_user.first_name == '' && root_user.last_name == '') {
    const email = root_user.email
    return email ? email.split('@')[0] : '-'
  }

  return `${root_user.last_name} ${root_user.first_name}`
}

export function getTextFromEntity(entity: Entity | ClientEntity): string {
  if ('root_user' in entity && entity.root_user) {
    return getTextFromRootUser(entity.root_user)
  }
  return entity.name
}

export function isRelationActive(relation: RootUserRelation): boolean {
  return (
    relation.date_range.to == null ||
    new Date(relation.date_range.to).getTime() > new Date().getTime()
  )
}

export function hasDeactivatedRelations(root_user: RootUserListItem): boolean {
  return root_user.relations.every((relation) => !isRelationActive(relation))
}

export function getActiveRelationsMapByEntityId(relations: RootUserRelation[]) {
  const map: RelationsMapByEntityId = {}

  relations.forEach((relation) => {
    if (isRelationActive(relation)) {
      map[relation.entity_id] = relation
    }
  })

  return map
}
