import ApiService from '@/services/api.service'
import { type AxiosResponse } from 'axios'
import { type ResponseResult } from '@/types/services/common'

class ForgottenPassword {
  static sendResetEmail(
    email: string
  ): Promise<AxiosResponse<{ result: ResponseResult }>> {
    return ApiService.post(`/api/v1/users/send_reset_password`, { email })
  }

  static resetPassword(
    new_password: string,
    token_hash: string
  ): Promise<AxiosResponse<{ result: ResponseResult }>> {
    return ApiService.post(`/api/v1/users/reset_password`, {
      new_password,
      token_hash,
    })
  }

  /**
   * Get user email by reset password token
   *
   * @param token - Reset password token
   * @returns ResponseResult - message contains user email
   */
  static getEmailByToken(
    token: string
  ): Promise<AxiosResponse<{ result: ResponseResult }>> {
    return ApiService.post(`/api/v1/users/get_mail_from_reset_token`, {
      token_hash: token,
    })
  }
}

export default ForgottenPassword
