import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8.667 9.333a3.333 3.333 0 0 0 .36-5.026l-2-2A3.333 3.333 0 0 0 2.313 7.02l1.14 1.147m3.88-1.5a3.333 3.333 0 0 0-.36 5.027l2 2a3.333 3.333 0 0 0 4.714-4.714l-1.14-1.14" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }