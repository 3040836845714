import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M7.522 2.302c.153-.311.23-.467.334-.517a.33.33 0 0 1 .287 0c.105.05.182.206.335.517l1.458 2.953c.045.092.068.138.101.174q.045.047.104.075c.044.02.095.028.196.043l3.26.477c.344.05.516.075.595.159.07.073.102.173.089.273-.015.114-.14.235-.388.477l-2.359 2.298c-.073.071-.11.107-.134.15a.3.3 0 0 0-.04.122.7.7 0 0 0 .02.2l.557 3.245c.059.342.088.513.033.615a.33.33 0 0 1-.232.168c-.114.021-.267-.06-.575-.22l-2.915-1.534c-.09-.048-.136-.072-.184-.081a.3.3 0 0 0-.128 0c-.048.01-.093.033-.184.081L4.837 13.51c-.308.162-.461.242-.575.222a.33.33 0 0 1-.232-.17c-.055-.1-.026-.272.033-.614l.556-3.245a.7.7 0 0 0 .02-.2.3.3 0 0 0-.04-.122.7.7 0 0 0-.133-.15L2.107 6.933c-.249-.242-.373-.363-.388-.477-.013-.1.02-.2.088-.273.08-.084.252-.11.595-.16l3.26-.476c.102-.015.153-.022.197-.043a.3.3 0 0 0 .104-.075c.033-.036.056-.082.1-.174z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }