import type { ColorTint } from '@/types/components/common'

export const TINT_COLORS: ColorTint[] = [
  'blue',
  'grey',
  'aqua',
  'palablue',
  'purple',
  'pink',
  'rose',
  'red',
  'brown',
  'orange',
  'yellow',
  'grass',
  'green',
  'pea',
]

export const BG_TINT_COLOR_MAP: { [key in ColorTint]: string } = {
  blue: 'bg-blue-alpha',
  grey: 'bg-grey-alpha',
  aqua: 'bg-aqua-alpha',
  palablue: 'bg-palablue-alpha',
  purple: 'bg-purple-alpha',
  pink: 'bg-pink-alpha',
  rose: 'bg-rose-alpha',
  red: 'bg-red-alpha',
  brown: 'bg-brown-alpha',
  orange: 'bg-orange-alpha',
  yellow: 'bg-yellow-alpha',
  grass: 'bg-grass-alpha',
  green: 'bg-green-alpha',
  pea: 'bg-pea-alpha',
}

export const BORDER_TINT_COLOR_MAP: { [key in ColorTint]: string } = {
  blue: 'border-blue-53',
  grey: 'border-grey-53',
  aqua: 'border-aqua-53',
  palablue: 'border-palablue-53',
  purple: 'border-purple-53',
  pink: 'border-pink-53',
  rose: 'border-rose-53',
  red: 'border-red-53',
  brown: 'border-brown-53',
  orange: 'border-orange-53',
  yellow: 'border-yellow-53',
  grass: 'border-grass-53',
  green: 'border-green-53',
  pea: 'border-pea-53',
}
