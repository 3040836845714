import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'

import authRoutes from './authRoutes'
import commonRoutes from './commonRoutes'
import platformRoutes from './platformRoutes'
import clientFundRoutes from './clientFundRoutes'
import referrerRoutes from './referrerRoutes'
import devRoutes from './devRoutes'
import UserService from '@/services/user/user'

import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'

import type { RouteRecord, RouteRecordRaw } from 'vue-router'
import type { Role } from '@/types/services/user'

const defaultRoutes = [...commonRoutes, ...authRoutes]

// Enable development only routes (for component previews)
const isDev = import.meta.env.DEV
if (isDev) {
  defaultRoutes.push(...devRoutes)
}

const router = createRouter({
  history: createWebHistory('/'),
  routes: defaultRoutes,
})

const NO_PERMISSION_ROUTE_NAME = 'NoPermissionView'

const checkIfCanAccess = (route: RouteRecordRaw | RouteRecord): boolean => {
  const userStore = useUserStore()

  let hasPackage = true
  let hasPermission = true
  if (
    route.meta?.packages != null &&
    (route.meta.packages as number[]).length
  ) {
    hasPackage = userStore.hasPackage(route.meta.packages as number[])
  }
  if (route.meta?.permission != null) {
    hasPermission = userStore.hasPermission(route.meta.permission as number)
  }
  return hasPackage && hasPermission
}

const findRouteWithAccess = (parent: RouteRecordRaw): string => {
  let routeName = NO_PERMISSION_ROUTE_NAME
  const children = parent.children
  if (children) {
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      if (child.name !== 'AccountSettingsView' && checkIfCanAccess(child)) {
        if (child.children && child.children.length) {
          routeName = findRouteWithAccess(child)
        } else if (child.name) {
          routeName = child.name as string
        }
        break
      }
    }
  }
  return routeName
}

// This function should be called after we load our user from
// the back-end. Only then we know what routes to display

let firstRouteWithAccess: string | { name: string } = '/'
const setupRoutes = (): void => {
  const userStore = useUserStore()

  const viewToShow: Role = userStore.root_user?.role || 'client'

  userStore.currentView = viewToShow == 'superadmin' ? 'admin' : viewToShow

  // Decide which routes to use based on role
  let routes = platformRoutes
  if (viewToShow === 'client') {
    routes = clientFundRoutes
  } else if (viewToShow === 'referral') {
    routes = referrerRoutes
  } else {
    firstRouteWithAccess = {
      name: findRouteWithAccess(platformRoutes[0]),
    }
    platformRoutes[0].redirect = firstRouteWithAccess
  }

  routes.forEach((route) => router.addRoute(route))
}

router.beforeEach(async (to, from, next) => {
  const globalStore = useGlobalStore()
  const userStore = useUserStore()

  NProgress.start()

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.name)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.name} | Crypkit`
  }

  /**
   * Set to store active view name
   */
  let name = 'Crypkit'
  for (let i = to.matched.length - 1; i >= 0; i--) {
    const item = to.matched[i]
    if (item.meta && item.meta.name) {
      name = item.meta.name as string
      break
    }
  }
  globalStore.activeViewName = name

  const isPublic = to.matched.some((record) => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  )

  /**
   * Redirect if not logged in
   */
  if (!isPublic && !userStore.sessionStarted) {
    return next({
      name: 'SignIn',
    })
  }

  /**
   * Log previous user out if required
   */
  const logoutBeforeVisit = to.matched.some(
    (record) => record.meta.logoutBeforeVisit
  )
  if (logoutBeforeVisit) {
    // Log out previously logged user (without redirect)
    await UserService.logout('', false)
    userStore.token = null
  }

  /**
   * Check if "to" is "no permission" route and user has access to another routes (e.g. permission was granted and user refresh)
   */
  const checkRouteName =
    typeof firstRouteWithAccess === 'string'
      ? firstRouteWithAccess
      : firstRouteWithAccess.name

  if (
    to.name === NO_PERMISSION_ROUTE_NAME &&
    checkRouteName !== NO_PERMISSION_ROUTE_NAME
  ) {
    return next(firstRouteWithAccess)
  }

  /**
   * Check if user has required packages or permissions to access the route
   */
  const canAccess = to.matched.every((route) => checkIfCanAccess(route))

  if (!canAccess) {
    return next(firstRouteWithAccess)
  }

  /**
   * Do not allow user to visit login page or register page if they are logged in
   */
  if (userStore.sessionStarted && onlyWhenLoggedOut) {
    return next('/')
  }

  return next()
})

router.beforeResolve((to, from, next) => {
  NProgress.done()
  next()
})

export { setupRoutes }

export default router
