type StrengthValueType = 'caps' | 'length' | 'special' | 'numbers' | 'small'

type StrengthValue = {
  [key in StrengthValueType]: boolean
}

const _strengthValue: StrengthValue = {
  caps: false,
  length: false,
  special: false,
  numbers: false,
  small: false,
}

const checkPasswordStrength = (password: string): number => {
  const strengthValue = { ..._strengthValue }

  if (password.length >= 8) {
    strengthValue.length = true
  }

  for (let index = 0; index < password.length; index++) {
    const char = password.charCodeAt(index)
    if (!strengthValue.caps && char >= 65 && char <= 90) {
      strengthValue.caps = true
    } else if (!strengthValue.numbers && char >= 48 && char <= 57) {
      strengthValue.numbers = true
    } else if (!strengthValue.small && char >= 97 && char <= 122) {
      strengthValue.small = true
    } else if (
      (!strengthValue.special && char >= 33 && char <= 47) ||
      (char >= 58 && char <= 64)
    ) {
      strengthValue.special = true
    }
  }

  let strengthIndicator = 0
  for (const metric in strengthValue) {
    if (strengthValue[metric as StrengthValueType] === true) {
      strengthIndicator++
    }
  }

  return strengthIndicator
}

export { checkPasswordStrength }
