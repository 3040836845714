<template>
  <imask-input
    v-model:value="inputDate"
    :mask="mask"
    :placeholder="_placeholder"
    class="w-full outline-none bg-transparent"
    :disabled="disabled"
    @blur="parseSelected"
    @keydown.enter="setDates"
  />
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { mapState } from 'pinia'
import { format, parse, isValid, isBefore, isEqual, endOfDay } from 'date-fns'
import { IMaskComponent } from 'vue-imask'

import { useUserStore } from '@/stores/user'

import LimitsMixin from './limitsMixin'

export default defineComponent({
  components: {
    'imask-input': IMaskComponent,
  },

  mixins: [LimitsMixin],

  props: {
    selected: {
      type: Array as PropType<Date[]>,
      default: () => [],
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['enteredDates'],

  data() {
    return {
      inputDate: '',
    }
  },

  computed: {
    ...mapState(useUserStore, {
      dateFormat: (store) => store.settings.additional.date_format,
    }),
    mask(): string {
      let stringToMask = this.dateFormat.toLowerCase()
      if (this.isRange) {
        stringToMask += ` ~ ${stringToMask}`
      }

      const chars: string[] = stringToMask.split('')
      const toReplace = ['y', 'm', 'd']

      for (let i = 0; i < chars.length; i++) {
        if (toReplace.indexOf(chars[i]) !== -1) {
          chars[i] = '0'
        }
      }

      return chars.join('')
    },
    _placeholder(): string {
      if (this.placeholder) {
        return this.placeholder
      }

      const upperDateFormat = this.dateFormat.toUpperCase()

      if (this.isRange) {
        return `${upperDateFormat} ~ ${upperDateFormat}`
      }

      return upperDateFormat
    },
  },

  watch: {
    selected: {
      immediate: true,
      handler(): void {
        this.parseSelected()
      },
    },
  },

  methods: {
    parseSelected(): void {
      const { selected, dateFormat } = this

      if (selected?.length) {
        if (this.isRange) {
          if (selected.length == 2) {
            this.inputDate = `${format(selected[0], dateFormat)} ~ ${format(
              selected[1],
              dateFormat
            )}`
            return
          }
          this.inputDate = `${format(selected[0], dateFormat)} ~`
          return
        }

        this.inputDate = format(selected[0], this.dateFormat)
        return
      }

      this.inputDate = ''
    },
    setDates(): void {
      const el: HTMLInputElement = this.$el as HTMLInputElement

      if (this.inputDate == '' && this.nullable) {
        el.blur()
        if (this.isRange) {
          this.$emit('enteredDates', [null, null])
        } else {
          this.$emit('enteredDates', [null])
        }
        return
      }

      if (this.isRange) {
        const [from, to] = this.inputDate.split(' ~ ')
        const fromDate = parse(from, this.dateFormat, new Date())
        const toDate = parse(to, this.dateFormat, new Date())

        if (
          isValid(fromDate) &&
          isValid(toDate) &&
          (isBefore(fromDate, toDate) || isEqual(fromDate, toDate)) &&
          this.isDateAllowed(fromDate) &&
          this.isDateAllowed(toDate)
        ) {
          el.blur()
          this.$emit('enteredDates', [fromDate, endOfDay(toDate)])
        }
      } else {
        const date = parse(this.inputDate, this.dateFormat, new Date())
        if (isValid(date) && this.isDateAllowed(date)) {
          el.blur()
          this.$emit('enteredDates', [date])
        }
      }
    },
  },
})
</script>
