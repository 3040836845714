import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14.667 14v-1.333c0-1.243-.85-2.287-2-2.583m-2.334-7.89a2.668 2.668 0 0 1 0 4.945m1 6.861c0-1.242 0-1.864-.203-2.354a2.67 2.67 0 0 0-1.443-1.443C9.197 10 8.576 10 7.333 10h-2c-1.242 0-1.864 0-2.354.203-.653.27-1.172.79-1.443 1.443-.203.49-.203 1.111-.203 2.354M9 4.667a2.667 2.667 0 1 1-5.333 0 2.667 2.667 0 0 1 5.333 0" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }