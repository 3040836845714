import { defineComponent } from 'vue'
import { debounce } from 'lodash-es'

export default defineComponent({
  data() {
    return {
      debouncedResize: null as ReturnType<typeof debounce> | null,
      resizeObserver: null as ResizeObserver | null,
      observeElementResize: null as HTMLElement | null,
    }
  },

  created() {
    this.debouncedResize = debounce(this.onResize, 100)
  },

  mounted() {
    this.$nextTick(() => {
      if (this.debouncedResize) {
        if (typeof window.ResizeObserver !== 'undefined') {
          this.resizeObserver = new ResizeObserver(this.debouncedResize)
          this.resizeObserver.observe(this.observeElementResize || this.$el)
        } else {
          window.addEventListener('resize', this.debouncedResize)
        }
      }
    })
  },

  beforeUnmount() {
    if (typeof window.ResizeObserver !== 'undefined') {
      this.resizeObserver?.unobserve(this.observeElementResize || this.$el)
      this.resizeObserver = null
    } else if (this.debouncedResize) {
      window.removeEventListener('resize', this.debouncedResize)
    }
  },

  methods: {
    onResize() {
      console.warn('Method onResize() is not implemented')
    },
  },
})
