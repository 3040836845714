import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.127 13.236a5.656 5.656 0 1 1-.06-10.508m-1.008 8.23a3.195 3.195 0 0 1-3.007-5.592 3.2 3.2 0 0 1 2.973-.34M10 8.004l1.333 1.333 3-3" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }