<template>
  <li
    :class="{
      active: isSelected,
      'items-header': hasChildren,
      'item-hovered': isHovered,
    }"
    @mouseenter="$emit('hovered', hasChildren ? null : hoverIndex)"
    @click.stop="$emit('select', option)"
  >
    <slot name="item-text" :option="option">
      <SvgIcon v-if="hasChildren && option.icon" :icon="option.icon" />
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-html="
          (option.highlight && option.highlight[textKey]) || option[textKey]
        "
      />
      <!-- eslint-enable vue/no-v-html -->
    </slot>
    <ul v-if="hasChildren">
      <SelectItem
        v-for="(item, index) in option[groupValues]"
        :key="`${index}_${item.highlight && item.highlight.text}`"
        :ref="`item-${index}`"
        :option="item"
        :selected-value="selectedValue"
        :group-index="optionIndex"
        :option-index="index"
        :hovered-index="hoveredIndex"
        @select="$emit('select', $event)"
        @hovered="$emit('hovered', $event)"
      />
    </ul>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { isEqual } from 'lodash-es'
import SvgIcon from '@/components/misc/SvgIcon'
import type { PropType } from 'vue'
import type { HoveredIndex } from '@/types/components/select'

export default defineComponent({
  name: 'SelectItem',

  components: {
    SvgIcon,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
    textKey: {
      type: String,
      default: 'text',
    },
    selectedValue: {
      type: [Object, Array] as PropType<object | object[] | null>,
      default: null,
    },
    groupIndex: {
      type: Number,
      default: null,
    },
    optionIndex: {
      type: Number,
      required: true,
    },
    groupValues: {
      type: String,
      default: null,
    },
    hoveredIndex: {
      type: Object as PropType<HoveredIndex | null>,
      default: null,
    },
  },

  emits: ['hovered', 'select'],

  computed: {
    isSelected(): boolean {
      const { option, selectedValue } = this
      if (Array.isArray(selectedValue)) {
        return selectedValue.findIndex((item) => isEqual(item, option)) !== -1
      }

      return isEqual(option, selectedValue)
    },
    hasChildren(): boolean {
      return Array.isArray(this.option[this.groupValues])
    },
    hoverIndex(): HoveredIndex {
      return {
        group: this.groupIndex,
        option: this.optionIndex,
      }
    },
    isHovered(): boolean {
      const { hoveredIndex, hoverIndex } = this
      if (!hoveredIndex) {
        return false
      }
      return (
        hoveredIndex.group === hoverIndex.group &&
        isEqual(hoveredIndex.option, hoverIndex.option)
      )
    },
  },
})
</script>
