<template>
  <div id="app" class="font-epilogue h-screen overflow-visible">
    <TrialNotification v-if="trialCountdown != null" :days="trialCountdown" />
    <VerifyEmailNotification v-if="!isEmailConfirmed" />
    <ServicesNotification v-if="hasPermission(283)" />
    <ReleaseNotesNotification v-if="$route.name !== 'ReleaseNotesView'" />

    <div id="app-container" :class="appClass">
      <MobileSidebar v-if="$breakpoints.mdAndDown" />
      <Sidebar v-else />

      <div id="app-content-wrapper" :class="contentClass">
        <Navbar
          v-if="$breakpoints.mdAndDown"
          :class="{ 'mt-12': trialCountdown != null || !isEmailConfirmed }"
        />

        <h1 v-if="$breakpoints.mdAndUp && !customHeader">
          {{ activeViewName }}
        </h1>

        <NoPackages v-if="noPackages && !canShowWithNoPackage" />
        <NoProduct
          v-else-if="!hasProduct && !canShowWithNoProduct"
          class="flex-1"
        />
        <router-view v-else class="flex-1" />

        <div
          class="flex flex-col-reverse md:flex-row justify-between mt-5 text-secondary text-sm"
        >
          <p>Copyright &copy; {{ new Date().getFullYear() }}</p>
          <p class="mb-4 md:mb-0" @click.ctrl.alt.shift="toggleTestMode">
            Crypkit's path to flawlessness counter:
            <router-link
              :to="{ name: 'ReleaseNotesView' }"
              class="underline hover:text-primary"
              >{{ releaseVersion }}</router-link
            >
          </p>
        </div>
      </div>
    </div>

    <SwitchRootDialog />

    <Tour
      v-if="tourSteps.length"
      :steps="tourSteps"
      :start-step="activeTourFlow?.current_step"
      :theme="theme == 'dark' || theme == 'black' ? 'dark' : 'light'"
      @step-changed="updateTourFlow($event, false)"
      @finished="updateTourFlow($event, true)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { differenceInDays } from 'date-fns'

import { useTestMode } from '@/composables/test_mode'
import { releaseVersion } from '@/helpers/system'
import { stringToUTCDate } from '@/services/date.service'
import TourScenarios, { type Step } from '@/services/tour.service'
import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'

import Navbar from '@/components/navigation/Navbar/Index.vue'
import MobileSidebar from '@/components/navigation/MobileSidebar/Index.vue'
import Sidebar from '@/components/navigation/Sidebar/Index.vue'
import SwitchRootDialog from '@/components/misc/SwitchRootDialog'
import Tour from '@/components/misc/Tour'

import ServicesNotification from '@/components/misc/Notification/ServicesNotification'
import TrialNotification from '@/components/misc/Notification/TrialNotification.vue'
import VerifyEmailNotification from '@/components/misc/Notification/VerifyEmailNotification.vue'

import NoPackages from '@/views/Auth/NoPackages.vue'
import NoProduct from '@/views/Auth/NoProduct.vue'
import { ReleaseNotesNotification } from '@/components/misc/Notification/ReleaseNotesNotification'

export default defineComponent({
  name: 'AppLayout',

  components: {
    Navbar,
    MobileSidebar,
    Sidebar,
    SwitchRootDialog,
    Tour,
    ReleaseNotesNotification,
    ServicesNotification,
    TrialNotification,
    VerifyEmailNotification,
    NoPackages,
    NoProduct,
  },

  data() {
    return {
      userStore: useUserStore(),
      testMode: useTestMode(),
    }
  },

  computed: {
    ...mapState(useUserStore, {
      isEmailConfirmed: (store) => store.settings.confirmed,
      theme: (store) => store.settings.additional.theme,
    }),
    ...mapState(useUserStore, [
      'noPermissionsAdmin',
      'noPackages',
      'hasPermission',
      'hasProduct',
      'activeTourFlow',
      'packages_full',
    ]),
    ...mapState(useGlobalStore, {
      isToggled: 'sidebarToggled',
      activeViewName: 'activeViewName',
      customHeader: 'customHeader',
    }),
    releaseVersion(): string {
      return releaseVersion()
    },
    trialCountdown(): number | null {
      let expiryDate: Date | null = null
      this.packages_full.forEach((p) => {
        // Check for trial package
        if ([37, 43, 47].includes(p.package_id)) {
          expiryDate = stringToUTCDate(p.date_to)
        }
      })

      // Trial days left
      if (expiryDate) {
        return differenceInDays(expiryDate, new Date())
      }
      return null
    },
    appClass(): string {
      const { trialCountdown, isEmailConfirmed } = this
      return trialCountdown == null && isEmailConfirmed
        ? ''
        : 'with-notification'
    },
    contentClass(): string {
      const { isToggled, noPermissionsAdmin, noPackages } = this
      if (noPermissionsAdmin || noPackages) {
        return ''
      }
      if (this.$breakpoints.mdAndDown) {
        return 'with-navbar'
      }
      return isToggled ? 'with-sidebar-toggled' : 'with-sidebar'
    },
    canShowWithNoPackage(): boolean {
      return this.checkActiveRoute(['AccountSettingsView', 'NoPermissionView'])
    },
    canShowWithNoProduct(): boolean {
      return this.checkActiveRoute([
        'SettingsView',
        'AccountSettingsView',
        'NoPermissionView',
      ])
    },
    tourSteps(): Step[] {
      const { activeTourFlow } = this
      if (activeTourFlow) {
        const scenario = TourScenarios.find(
          (scenario) => scenario.key == activeTourFlow.scenario_key
        )
        return scenario?.steps || []
      }
      return []
    },
  },

  beforeUpdate() {
    const wrapper = document.getElementById('app-content-wrapper')
    if (wrapper) {
      wrapper.scrollTo(0, 0)
    }
  },

  methods: {
    updateTourFlow(stepIndex: number, finished: boolean): void {
      if (this.activeTourFlow) {
        this.userStore.saveTourFlow({
          ...this.activeTourFlow,
          current_step: stepIndex,
          finished,
        })
      }
    },
    checkActiveRoute(enabledRoutes: string[]): boolean {
      return this.$route.matched.some((route) => {
        return (
          typeof route.name === 'string' &&
          enabledRoutes.indexOf(route.name) !== -1
        )
      })
    },
    toggleTestMode() {
      this.testMode.forceState(!this.testMode.enabled)
      alert(`Test mode enabled: ${this.testMode.enabled}`)
    },
  },
})
</script>
