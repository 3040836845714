export const options = {
  mobileBreakpoint: 1024,
  thresholds: {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
  },
}
