import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 29 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#3C8BDB",
      d: "m28.733 23.048-6.045-3.097-14.63 8.96 6.046 3.09z"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#409AE5",
      d: "M28.731 23.047 28.723 7.21 14.628 0h-.008l.008 6.792 4.025 2.054-4.025 2.464.008 4.525 4.025 2.062 4.033-2.472v4.525z"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#3C8BDB",
      d: "M18.662 17.897 4.033 26.849zl-4.026-2.062-.008-4.525L0 20.262l.008 4.526 4.025 2.061zM14.63 0 0 8.953v6.792l14.63-8.96z"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#214FA4",
      d: "M18.663 8.846 4.025 17.798 0 15.745l14.63-8.96zm4.033 6.579-14.638 8.96v4.526l14.638-8.96z"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }