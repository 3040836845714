import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M1.333 3.067c0-.374 0-.56.073-.703a.67.67 0 0 1 .291-.291C1.84 2 2.027 2 2.4 2h11.2c.373 0 .56 0 .703.073a.67.67 0 0 1 .29.291c.074.143.074.33.074.703v.446c0 .18 0 .269-.022.352a.7.7 0 0 1-.095.207c-.048.07-.116.13-.252.247l-4.263 3.695c-.136.118-.203.176-.252.247a.7.7 0 0 0-.095.207c-.021.084-.021.173-.021.352v3.486c0 .13 0 .195-.021.252a.33.33 0 0 1-.089.13c-.044.04-.105.064-.226.113l-2.266.906c-.245.098-.368.147-.466.127a.33.33 0 0 1-.21-.142c-.056-.084-.056-.216-.056-.48V8.82c0-.179 0-.268-.022-.352a.7.7 0 0 0-.094-.207c-.049-.07-.116-.13-.252-.247L1.701 4.32c-.135-.117-.203-.176-.251-.247a.7.7 0 0 1-.095-.207c-.022-.083-.022-.173-.022-.352z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }