import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.829 7.315 8.44 4.656 5.83 2M8.47 4.658h-6.87v4.81M7.529 11.342h6.87v-4.81m-4.228 2.153L7.56 11.343 10.17 14" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }