import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8 5.333V2M6.092 5.334H9.91m-8.91 4h1.91m0 0h1.908m-1.908 0v4.667M8 14V8m5.092 6v-3.334m0 0h-1.908m1.908 0h1.91m-12.092-4V2m10.18 6V2" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }