import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8 10 6 8m2 2c.931-.354 1.825-.801 2.667-1.333M8 10v3.333s2.02-.367 2.667-1.333c.72-1.08 0-3.333 0-3.333M6 8c.355-.92.802-1.803 1.333-2.633a8.59 8.59 0 0 1 7.334-4.034c0 1.814-.52 5-4 7.334M6 8H2.667S3.033 5.98 4 5.333c1.08-.72 3.333 0 3.333 0M3 11c-1 .84-1.333 3.333-1.333 3.333S4.16 14 5 13c.473-.56.467-1.42-.06-1.94A1.454 1.454 0 0 0 3 11" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }