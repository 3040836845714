<template>
  <router-link
    v-tippy="{
      trigger: sublink.comingSoon ? 'mouseenter focus' : 'manual',
      content: sublink.comingSoon ? $t('ui.common.coming_soon') : null,
    }"
    :to="{ name: sublink.comingSoon ? null : sublink.routeTo }"
    class="sublink"
    :class="{ active: isActive, 'coming-soon': sublink.comingSoon, disabled }"
    @click="$bus.emit('hideMobileSidebar')"
  >
    <span>{{ sublink.text }}</span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    sublink: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isActive() {
      return this.$route.matched.some(
        (route) => route.name === this.sublink.routeTo
      )
    },
  },
})
</script>
