import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14.667 4.667 9.42 9.912c-.264.264-.396.396-.548.446a.67.67 0 0 1-.412 0c-.153-.05-.285-.182-.549-.446L6.088 8.087c-.265-.264-.397-.396-.549-.445a.67.67 0 0 0-.412 0c-.152.05-.284.181-.548.445l-3.246 3.246m13.334-6.666H10m4.667 0v4.666" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }