<template>
  <Form
    v-slot="{ handleSubmit }"
    :validation-schema="validationSchema"
    @submit="save"
  >
    <Modal
      :confirm="($event) => handleSubmit($event, save)"
      :confirm-text="$t('ui.common.create')"
      :title="$t('ui.common.create_new_reminder')"
      icon="Plus2"
      :loading="loading"
      :error-message="errorMessage"
      show
      @close="$emit('close')"
    >
      <div class="flex mt-2">
        <div class="w-1/2 pr-2">
          <DatePicker
            id="notification-create-modal-date"
            v-model:date="formData.date"
            :allow-from="getEntityCurrentDate()"
            :label="$t('ui.common.date')"
            future
            fullwidth
            required
          />
        </div>
        <div class="w-1/2 pl-2">
          <Timepicker
            id="notification-create-modal-time"
            v-model="formData.date"
            :label="$t('ui.common.time')"
            required
          />
        </div>
      </div>
      <div class="flex mt-2">
        <div class="w-full">
          <TextArea
            id="notification-create-modal-note"
            v-model="formData.message"
            name="message"
            :label="$t('ui.common.note')"
            maxlength="2048"
            required
          />
        </div>
      </div>
    </Modal>
  </Form>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue'
import { mapState } from 'pinia'
import { Form } from 'vee-validate'
import { object, string } from 'yup'

import { useNotificationsStore } from '@/stores/notifications'
import { useUserStore } from '@/stores/user'

import DatesMixin from '@/mixins/dates'

import { HttpError } from '@/helpers/errors'

import DatePicker from '@/components/controls/DatePicker'
import TextArea from '@/components/controls/TextArea'
import Timepicker from '@/components/controls/TimePicker'
import Modal from '@/components/misc/Modal'

export default defineComponent({
  name: 'CreateNotificationModal',

  components: {
    DatePicker,
    Form,
    Modal,
    TextArea,
    Timepicker,
  },

  mixins: [DatesMixin],

  emits: ['close'],

  data() {
    return {
      loading: false,
      errorMessage: [] as string[],
      formData: {
        date: null as Date | null,
        message: '',
      },

      notificationsStore: useNotificationsStore(),

      validationSchema: markRaw(
        object({
          message: string().max(2048).required(),
        })
      ),
    }
  },

  computed: {
    ...mapState(useUserStore, {
      rootUserId: (store) => store.root_user?.id,
    }),
    ...mapState(useUserStore, ['entity', 'dateToEntityTzString']),
  },

  mounted() {
    this.resetForm()
  },

  methods: {
    resetForm() {
      const date = this.getEntityCurrentDate()
      date.setHours(12)
      date.setMinutes(0)
      date.setSeconds(0)
      this.formData.date = date
      this.formData.message = ''
    },
    async save() {
      const { entity, formData, rootUserId } = this

      if (!entity || !formData.date || !rootUserId) {
        return
      }

      this.errorMessage = []

      this.loading = true

      const payload = {
        date: this.dateToEntityTzString(formData.date, entity.id),
        message: formData.message,
        sender_id: rootUserId,
        receiver_id: rootUserId,
      }

      try {
        await this.notificationsStore.createNotification(payload)

        this.$emit('close', true)
        this.$bus.emit('success', {
          message: this.$t('messages.reminder_was_created'),
        })
      } catch (err) {
        this.errorMessage = [(err as HttpError).getFriendlyMessage()]
      } finally {
        this.loading = false
      }
    },
  },
})
</script>
