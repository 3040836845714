import { defineComponent, type PropType } from 'vue'

export default defineComponent({
  props: {
    // eslint-disable-next-line
    modelValue: null as any as PropType<any>,
  },

  emits: ['update:modelValue'],

  data() {
    return {
      lazyValue: this.modelValue,
    }
  },

  // Proxy for `lazyValue`
  // This allows an input
  // to function without
  // a provided model
  computed: {
    internalValue: {
      get(): any {
        return this.lazyValue
      },
      set(val: any) {
        this.lazyValue = val
        this.$emit('update:modelValue', val)
      },
    },
  },

  watch: {
    modelValue(val) {
      this.lazyValue = val
    },
  },
})
