<template>
  <div class="crypkit-collapsible" :class="{ collapsed: collapsed }">
    <div
      ref="button"
      v-click-outside="close"
      class="crypkit-collapsible-button cursor-pointer"
      @click="toggle"
    >
      <slot name="button">
        <span>Button</span>
      </slot>
      <div
        ref="body"
        class="crypkit-collapsible-body"
        :style="{ maxHeight, ...positionStyles }"
      >
        <transition name="fade" appear>
          <slot v-if="collapsed" name="body">
            <div>Collapsed body</div>
          </slot>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import './Collapsible.scss'

import { defineComponent, type PropType } from 'vue'
import ClickOutside from '@/components/directives/click-outside'
import ResizableMixin from '../../mixins/resizable'

/**
 * A generic button that can "collapse" into a select.
 * Use slots to place content inside the button and body.
 */
export default defineComponent({
  directives: {
    ClickOutside,
  },

  mixins: [ResizableMixin],

  props: {
    direction: {
      type: String as PropType<'right' | 'left' | null>,
      default: null,
    },
  },

  emits: ['opened', 'closed'],

  data() {
    return {
      collapsed: false,
      maxHeight: '100vh',
      positionStyles: {},
      mDirection: this.direction,
      observeElementResize: document.body,
    }
  },

  watch: {
    collapsed(collapsed): void {
      if (collapsed) {
        this.$nextTick(this.onResize)
      }
    },
  },

  methods: {
    onResize(): void {
      if (!this.direction) {
        this.getDirection()
      }
      this.getMaxHeight()
      this.getPositionStyles()
    },
    getMaxHeight(): void {
      const vh = this.$breakpoints.height
      const body = this.$refs.body as HTMLElement | undefined
      const top = body?.offsetTop || 0

      this.maxHeight = vh - top - 42 + 'px'
    },
    getDirection(): void {
      const btnElm = this.$refs.button as HTMLElement | undefined
      const maxRight = btnElm
        ? btnElm.getBoundingClientRect().left + btnElm.offsetWidth
        : 0
      const body = this.$refs.body as HTMLElement | undefined

      if (maxRight - (body?.offsetWidth || 0) < 0) {
        this.mDirection = 'right'
      } else {
        this.mDirection = 'left'
      }
    },
    getPositionStyles(): void {
      if (this.mDirection == 'left') {
        this.positionStyles = {
          right: 0,
        }
      } else if (this.mDirection == 'right') {
        this.positionStyles = {
          left: 0,
        }
      } else {
        const btnElm = this.$refs.button as HTMLElement | undefined
        const body = this.$refs.body as HTMLElement | undefined
        const btnWidth = btnElm?.getBoundingClientRect().width || 0
        const bodyWidth = body?.getBoundingClientRect().width || 0
        const left = btnWidth / 2 - bodyWidth / 2
        this.positionStyles = { left }
      }
    },
    toggle(): void {
      if (!this.collapsed) {
        this.$emit('opened')
      } else {
        this.$emit('closed')
      }
      this.collapsed = !this.collapsed
    },
    close(): void {
      if (this.collapsed) {
        this.$emit('closed')
        this.collapsed = false
      }
    },
  },
})
</script>
