<template>
  <div class="select-none" :class="{ 'pr-2': menuFromRight }">
    <AccountBadge class="cursor-pointer" @click="show = !show" />
    <Menu v-model="show" :position="menuFromRight ? 'right' : 'center'">
      <List>
        <ListItem
          id="navigation-small-menu-settings"
          :disabled="isSettingsDisabled"
          @click="$router.push({ name: 'AccountSettingsView' })"
        >
          <SvgIcon icon="Settings" class="mr-4 shrink-0" />
          <span class="mr-8">{{ $t('ui.common.settings') }}</span>
        </ListItem>
        <ListItem id="navigation-small-menu-logout" @click="logOut">
          <SvgIcon icon="SignOut" class="mr-4 shrink-0" />
          <span class="mr-8">{{ $t('ui.common.logout') }}</span>
        </ListItem>
      </List>
    </Menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useUserStore } from '@/stores/user'

import UserService from '@/services/user/user'

import { List, ListItem } from '@/components/misc/List'
import Menu from '@/components/misc/Menu/Menu'
import SvgIcon from '@/components/misc/SvgIcon'
import AccountBadge from '@/components/navigation/components/AccountBadge.vue'

export default defineComponent({
  components: {
    AccountBadge,
    List,
    ListItem,
    Menu,
    SvgIcon,
  },

  props: {
    menuFromRight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,

      userStore: useUserStore(),
    }
  },

  computed: {
    ...mapState(useUserStore, ['activeTourFlow']),
    isSettingsDisabled(): boolean {
      return this.activeTourFlow?.scenario_key === 'onboarding'
    },
  },

  methods: {
    async logOut() {
      await UserService.logout()
    },
  },
})
</script>
