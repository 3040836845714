<template>
  <div class="currency-item" :class="{ opened }" @click="$emit('open', type)">
    <div v-if="loading" class="h-16 bg-background rounded pulsating" />
    <template v-else>
      <div class="currency-amount">
        {{ amount }}
        <Spinner v-if="amount == null" width="30" />
      </div>
      <div class="currency-select">
        <input
          v-if="opened"
          :id="`currency-switcher-${type}-search`"
          ref="input"
          type="text"
          @input="debounceInput"
          @keyup.enter="$emit('select-hovered')"
          @keydown.esc="$emit('close')"
          @keydown.up.prevent="$emit('item-up')"
          @keydown.down.prevent="$emit('item-down')"
          @click.stop
        />
        <template v-else-if="currency">
          <CurrencyIcon :currency="currency" />
          {{ currency.symbol }}
        </template>
        <template v-else>
          <span class="text-placeholder">Select</span>
        </template>
        <SvgIcon
          icon="ArrowDown"
          class="ml-auto text-secondary flex-shrink-0"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import CurrencyIcon from '@/components/misc/CurrencyIcon'
import SvgIcon from '@/components/misc/SvgIcon'
import Spinner from '@/assets/loaders/Spinner.svg?component'
import { debounce } from 'lodash-es'
import { type Currency } from '@/types/services/global'

export default defineComponent({
  components: {
    CurrencyIcon,
    SvgIcon,
    Spinner,
  },

  props: {
    currency: {
      type: Object as PropType<Currency | null>,
      default: null,
    },
    amount: {
      type: String as PropType<string | null>,
      default: null,
    },
    type: {
      type: String,
      default: 'crypto',
    },
    opened: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select-hovered', 'close', 'item-up', 'item-down', 'open', 'search'],

  data() {
    return {
      debounceInput: undefined as ReturnType<typeof debounce> | undefined,
    }
  },

  watch: {
    opened(opened: boolean) {
      if (opened) {
        this.$nextTick(() => {
          const input = this.$refs.input as HTMLInputElement
          input.focus()
        })
      }
    },
  },

  created() {
    this.debounceInput = debounce((e: InputEvent) => {
      this.$emit('search', (e.target as HTMLInputElement).value)
    }, 100)
  },
})
</script>
