import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M6.236 14c.47.415 1.088.667 1.764.667s1.294-.252 1.764-.667M12 5.334v-4m-2 2h4M8.667 1.388A4 4 0 0 0 4 5.333c0 2.06-.52 3.471-1.1 4.404-.49.787-.735 1.18-.726 1.29.01.122.036.168.134.24.088.067.487.067 1.285.067h8.815c.797 0 1.196 0 1.284-.066.098-.073.124-.12.134-.24.009-.11-.236-.504-.726-1.291a7 7 0 0 1-.843-2.07" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }