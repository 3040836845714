<template>
  <div class="login-form">
    <p class="text-xxl font-bold mb-4">
      {{ $t('ui.common.log_in') }}
    </p>
    <p class="text-secondary">
      {{ $t('ui.common.log_in_moto') }}
    </p>

    <ModalErrors
      v-if="formErrors"
      :negative-offset="12"
      :messages="formErrors"
    />

    <Form
      id="sign-in-form"
      :validation-schema="validationSchema"
      class="relative"
      @submit="logIn"
    >
      <div
        v-show="showLoader"
        class="absolute w-full h-full bg-surface z-100 top-0 items-center justify-center flex"
      >
        <Spinner width="100" class="fill-brand" />
      </div>

      <Alert
        v-if="isExpiredSession"
        id="sign-in-session-expired"
        type="warning"
        align="center"
        class="mt-4"
      >
        {{ $t('messages.auth.your_session_has_expired') }}
      </Alert>

      <BasicInput
        id="sign-in-email"
        v-model="email"
        name="email"
        :label="$t('ui.common.email')"
        placeholder="john@company.com"
        autocomplete="email"
        required
        hide-asterisk
        class="mt-6"
      />

      <div class="relative">
        <BasicInput
          id="sign-in-password"
          v-model="password"
          name="password"
          type="password"
          :label="$t('ui.common.password')"
          autocomplete="current-password"
          required
          hide-asterisk
          class="mt-1"
        />
        <a
          id="sign-in-forgot-password"
          class="absolute top-0 right-0 text-xs text-brand font-medium"
          href="/forgot-password"
        >
          {{ $t('ui.common.forgot_password') }}
        </a>
      </div>

      <div class="mt-3">
        <Checkbox
          v-model="privacyModeEnabled"
          :label="$t('ui.auth.login_with_privacy_enabled')"
        />
      </div>

      <div class="mt-6">
        <Button
          id="sign-in-submit"
          type="primary"
          button-type="submit"
          class="w-1/2 ml-auto"
        >
          {{ $t('ui.common.log_in') }}
        </Button>
      </div>

      <!-- <div class="text-center border-b border-outline my-8" />

        <div class="text-xs text-secondary mt-2 text-center">
          {{ $t('ui.common.dont_have_account') }}
          <a
            id="sign-in-sign-up-link"
            class="text-brand font-medium ml-1"
            href="/signup"
          >
            {{ $t('ui.common.sign_up_here') }}
          </a>
        </div> -->
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue'
import { mapState } from 'pinia'
import { Form } from 'vee-validate'
import { object, string } from 'yup'

import { retrieveErrorsMessages } from '@/helpers/errors'
import UserService from '@/services/user/user'
import { useUserStore } from '@/stores/user'

import Spinner from '@/assets/loaders/Spinner.svg?component'
import BasicInput from '@/components/controls/BasicInput'
import Button from '@/components/controls/Button'
import Checkbox from '@/components/controls/Checkbox'
import Alert from '@/components/misc/Alert/Alert.vue'
import ModalErrors from '@/components/misc/Modal/ModalErrors.vue'

import type { StartSessionWithSelectedEntityParams } from '@/stores/user'

export default defineComponent({
  components: {
    Alert,
    BasicInput,
    Button,
    Checkbox,
    Form,
    Spinner,
    ModalErrors,
  },

  data() {
    return {
      showLoader: false,
      isExpiredSession: false,
      email: '',
      password: '',
      privacyModeEnabled: false,
      formErrors: [] as string[],

      userStore: useUserStore(),

      validationSchema: markRaw(
        object({
          email: string().required().email(),
          password: string().required(),
        })
      ),
    }
  },

  computed: {
    ...mapState(useUserStore, [
      'hasMultipleAccounts',
      'relations',
      'user',
      'settings',
      'relationFromDefaultSession',
    ]),
  },

  mounted() {
    this.getUrlQuery()
    this.formErrors = UserService.getAuthErrors()
  },

  methods: {
    getUrlQuery() {
      if (this.$route.query.expired) {
        this.isExpiredSession = true
      }
    },
    async logIn() {
      this.showLoader = true
      this.isExpiredSession = false
      this.formErrors = []
      const { email, password, privacyModeEnabled } = this

      try {
        await this.userStore.login(email, password)
        await this.userStore.getUserSettings()
        await this.userStore.getRelations()

        if (!this.user) {
          this.$router.push('/')
          return
        }

        const user_id = this.user.id

        if (this.hasMultipleAccounts) {
          const defaultSession = this.settings.additional.default_session
          const { relationFromDefaultSession } = this

          if (defaultSession && relationFromDefaultSession) {
            await this.startSession({
              user_id,
              root_id: relationFromDefaultSession.root_id,
              entity_id: relationFromDefaultSession.root_user_entity_id,
              role: defaultSession.role,
              selected_entity_id: defaultSession.entity_id,
            })
          } else {
            this.$router.push({
              name: 'AccountSelection',
              query: { privacy: privacyModeEnabled.toString() },
            })
          }
        } else {
          const relation = this.relations[0]
          const root_id = relation.root_id

          const entity_id = relation.root_user_entity_id
          const role = relation.is_superadmin
            ? 'superadmin'
            : relation.entities[0].roles[0]

          await this.startSession({
            user_id,
            root_id,
            entity_id,
            role,
          })
        }
      } catch (err) {
        this.formErrors = retrieveErrorsMessages(err)
      } finally {
        this.showLoader = false
      }
    },
    async startSession(params: StartSessionWithSelectedEntityParams) {
      const { privacyModeEnabled } = this

      await this.userStore.startSession(params)
      await this.userStore.saveUserAdditionalSettings({
        privacy_mode: privacyModeEnabled,
      })

      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('redirect')) {
        window.location.replace('https://' + urlParams.get('redirect'))
      } else {
        this.$router.push('/')
      }
    },
  },
})
</script>
