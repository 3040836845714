import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "5",
  height: "4",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#FF414D",
      d: "m3.418 2.235 1.075 1.14L3.518 4 2.496 2.63 1.482 4l-.975-.626 1.075-1.14L0 1.737.507.812l1.444.559L1.889 0h1.222l-.062 1.37L4.493.813 5 1.736z",
      style: {"stroke":"none"}
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }