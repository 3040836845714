import type { PropType } from 'vue'

export default {
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  hideAsterisk: {
    type: Boolean,
    default: false,
  },
  help: {
    type: String as PropType<string | null>,
    default: null,
  },
}
