import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8 14.72V8M2.18 4.641 8 8.008l5.82-3.367m.18 6.025V5.333a1.33 1.33 0 0 0-.667-1.153L8.667 1.513a1.33 1.33 0 0 0-1.334 0L2.667 4.18A1.33 1.33 0 0 0 2 5.333v5.333a1.33 1.33 0 0 0 .667 1.154l4.666 2.666a1.33 1.33 0 0 0 1.334 0l4.666-2.666A1.33 1.33 0 0 0 14 10.666" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }