import SvgIcon from '@/components/misc/SvgIcon';

<template>
  <SvgIcon
    v-tippy
    :content="
      privacyModeEnabled
        ? $t('ui.common.disable_privacy_mode')
        : $t('ui.common.enable_privacy_mode')
    "
    :icon="privacyModeEnabled ? 'Hide' : 'Show'"
    class="cursor-pointer outline-none text-secondary hover:text-brand"
    @click="togglePrivacyMode"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useUserStore } from '@/stores/user'

import SvgIcon from '@/components/misc/SvgIcon'

export default defineComponent({
  components: {
    SvgIcon,
  },

  data() {
    return {
      userStore: useUserStore(),
    }
  },

  computed: mapState(useUserStore, {
    privacyModeEnabled: (store) => store.settings.additional.privacy_mode,
  }),

  methods: {
    togglePrivacyMode() {
      this.userStore
        .saveUserAdditionalSettings({
          privacy_mode: !this.privacyModeEnabled,
        })
        .catch((err) => {
          this.$bus.emit('error', err)
        })
    },
  },
})
</script>
