import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.333 5.334h.007M1.333 3.467V6.45c0 .326 0 .489.037.642q.05.206.16.386c.082.134.197.25.428.48l5.113 5.113c.792.792 1.188 1.188 1.644 1.336a2 2 0 0 0 1.236 0c.457-.148.853-.544 1.645-1.336l1.474-1.475c.793-.792 1.189-1.188 1.337-1.644.13-.402.13-.835 0-1.237-.148-.456-.544-.852-1.337-1.644L7.958 1.958c-.23-.23-.346-.346-.48-.428a1.3 1.3 0 0 0-.386-.16c-.153-.037-.316-.037-.642-.037H3.467c-.747 0-1.12 0-1.406.146-.25.128-.455.332-.582.583-.146.285-.146.658-.146 1.405m4.334 1.866a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }