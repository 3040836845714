<template>
  <div class="font-semibold">
    {{ versionNotes.name }}
  </div>
  <div v-for="(notes, category) in groupedNotes" :key="category">
    <template v-if="notes.length">
      <div class="font-medium text-sm mt-4 mb-2">
        {{ $t(`ui.system.release_notes.categories.${category}`) }}
      </div>
      <ul class="list-disc list-inside marker:text-brand">
        <li v-for="(item, idx) in notes" :key="idx" class="text-sm">
          <span v-html="convertTextEmojisToUnicode(item.note)" />
          <a
            v-if="item.link"
            :href="item.link"
            class="text-brand hover:underline ml-1"
            target="_blank"
          >
            <SvgIcon icon="ExternalLink" size="sm" class="relative -top-0.5" />
          </a>
        </li>
      </ul>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue'
import SvgIcon from '@/components/misc/SvgIcon'
import { convertTextEmojisToUnicode } from '@/helpers/strings'
import type {
  ReleaseNote,
  ReleaseNotesCategory,
  VersionReleaseNotes,
} from '@/types/services/system'

const props = defineProps({
  versionNotes: {
    type: Object as PropType<VersionReleaseNotes>,
    required: true,
  },
})

const groupedNotes = computed(() => {
  const grouped: { [key in ReleaseNotesCategory]: ReleaseNote[] } = {
    feat: [],
    fix: [],
  }

  for (const note of props.versionNotes.release_notes) {
    grouped[note.category].push(note)
  }

  return grouped
})
</script>
