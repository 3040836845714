import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.02 5.568a2.333 2.333 0 1 1 .627-3.136M4 13.392h1.74q.342 0 .672.08l1.84.447a2.85 2.85 0 0 0 1.217.029l2.033-.396a2.8 2.8 0 0 0 1.418-.738l1.438-1.4c.411-.398.411-1.046 0-1.445-.37-.36-.955-.4-1.373-.095l-1.677 1.223c-.24.175-.532.27-.832.27h-1.62 1.031c.581 0 1.051-.458 1.051-1.023v-.204c0-.47-.328-.879-.795-.992l-1.59-.387a3.4 3.4 0 0 0-.791-.094c-.643 0-1.807.532-1.807.532L4 10.017m9.333-5.683a2.333 2.333 0 1 1-4.666 0 2.333 2.333 0 0 1 4.666 0m-12 5.4V13.6c0 .373 0 .56.073.703a.67.67 0 0 0 .291.291c.143.073.33.073.703.073h.533c.374 0 .56 0 .703-.073a.67.67 0 0 0 .291-.291C4 14.16 4 13.973 4 13.6V9.733c0-.373 0-.56-.073-.702a.67.67 0 0 0-.291-.292c-.143-.072-.33-.072-.703-.072H2.4c-.373 0-.56 0-.703.072a.67.67 0 0 0-.291.292c-.073.142-.073.33-.073.702" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }